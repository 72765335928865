import { Link, useNavigate } from '@tanstack/react-location'
import ScreenLayout from 'MediaManager/layouts/ScreenLayout'
import { getRealEstateCompanies } from 'MediaManager/services/realEstateCompanies'
import React, { useEffect, useState } from 'react'
import Button from 'shared/components/Button'
import Loader from 'shared/components/Loader'
import { getCdnAssetUrl } from 'shared/helpers/assets'

const ManageRealEstateCompaniesMainScreen = () => {
  const [data, setData] = useState()

  useEffect(() => {
    ;(async () => {
      const { data } = await getRealEstateCompanies()
      setData(data.real_estate_companies)
    })()
  }, [])

  const navigate = useNavigate()

  return (
    <ScreenLayout title="Manage Real Estate Companies">
      {!data && (
        <div className="flex items-center justify-center">
          <Loader />
        </div>
      )}
      {data && (
        <>
          <div>
            <div className="flex justify-between items-center mb-5">
              <div className="text-2xl">Real Estate Companies</div>
              <div>
                <Link to={`/real-estate-companies/edit`}>
                  <Button size="small" variant="secondary" rounded>
                    Add
                  </Button>
                </Link>
              </div>
            </div>
            <div className="data-table">
              <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>State</th>
                    <th>Website</th>
                    <th>Email</th>
                    <th>Tel. Number</th>
                    <th>Logo</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((company) => (
                    <tr
                      key={company.id}
                      className="hover:bg-[#f9f9f9]"
                      onClick={() => {
                        navigate({ to: `/real-estate-companies/edit?id=${company.id}` })
                      }}
                    >
                      <td>{company.id}</td>
                      <td>{company.company_name}</td>
                      <td>{company.state || <span className="opacity-30">-</span>}</td>
                      <td>{company.website || <span className="opacity-30">-</span>}</td>
                      <td>{company.email || <span className="opacity-30">-</span>}</td>
                      <td>{company.phone_number || <span className="opacity-30">-</span>}</td>
                      <td>
                        {company.company_logo ? (
                          <img
                            src={getCdnAssetUrl(company.company_logo)}
                            alt={company.company_name}
                            className="h-[32px] w-auto object-contain"
                          />
                        ) : (
                          <span className="opacity-30">-</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </ScreenLayout>
  )
}

export default ManageRealEstateCompaniesMainScreen
