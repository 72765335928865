import { appEnvPrefix, isProductionEnv } from 'shared/helpers/general'

export function apartmentPageHost() {
  if (isProductionEnv()) {
    return 'apartment-page'
  } else {
    return `apartment-page-${appEnvPrefix()}`
  }
}

export function residentAppHost() {
  if (isProductionEnv()) {
    return 'resident'
  } else {
    return `resident-${appEnvPrefix()}`
  }
}
