import React, { useEffect, useState } from 'react'
import BottomDrawer from '../BottomDrawer'
import ChipPicker from '../ChipPicker'
import ArrowDropdownIcon from './icons/ArrowDropdown'
import { useMediaQuery } from '@react-hook/media-query'

function DropdownSheet({ id, label, title, items, picked, onPicked, activeDropdownId, columnView, chipClassNames, activeItemClassNames, onToggle, isOpen }) {
  // const [open, setOpen] = useState(false)
  const isMobile = useMediaQuery('(max-width: 1023px)')

  const open = isOpen

  useEffect(()=>{
    if(activeDropdownId === id){
      document.getElementsByTagName('body')[0].classList.add('freeze')
    }
    if(!activeDropdownId){
      document.getElementsByTagName('body')[0].classList.remove('freeze')
    }
  }, [activeDropdownId, id])



  return (
    <div className="relative">
      <button
        onClick={() => {
          if (items.length > 1) {
            onToggle(open ? false : id)
          }
        }}
        className={`${open ? 'bg-black  text-white ' : 'text-black bg-white border-[black]'} ${
          items.length === 1 ? 'opacity-50' : ''
        } text-[14px] lg:text-[16px] transition-all leading-[14px] w-full lg:w-max lg:min-w-[157px] rounded-full flex items-center justify-between px-[14px] h-[32px] border-[1px] border-solid `}
      >
        {label}{' '}
        {items.length > 1 && (
          <span className="ml-[16px]">
            <ArrowDropdownIcon fill={`${open ? 'white' : 'black'}`} />
          </span>
        )}
      </button>
      {!isMobile && (
        <div
          className={`absolute shadow-lg top-[50px] left-[0px] w-full ${
            open ? '' : 'hidden'
          } px-[16px] bg-[white] rounded-[16px] py-[12px] w-max min-w-full`}
        >
          {items
            .filter((i) => i !== picked)
            .map((i, index) => (
              <div
                key={index}
                className={`${
                  index > 0 ? 'mt-[24px]' : 'mt-0'
                } leading-[19px] font-medium text-[14px] text-black cursor-pointer hover:opacity-75`}
                onClick={(e) => {
                  onPicked(
                    null,
                    items.findIndex((item) => item === i),
                  )
                  onToggle(false)
                }}
              >
                {i}
              </div>
            ))}
        </div>
      )}
      {isMobile && (
        <BottomDrawer
          backdrop={false}
          title={title}
          className="!pl-0 !pr-0"
          isOpen={open}
          onClose={() => onToggle(false)}
        >
          <div className="max-h-[50vh] overflow-y-auto mb-[32px]">
            {items.map((name, index) => (
              <button
                type="button"
                onClick={() => {
                  onPicked(name, index)
                  onToggle(false)
                }}
                className="py-[20px] block w-full text-left border-t-[1px] border-solid border-[#E5E7ED] px-[24px] first:border-t-[0px]"
              >
                {name}
              </button>
            ))}
          </div>
        </BottomDrawer>
      )}
    </div>
  )
}

export default DropdownSheet
