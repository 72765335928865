export default function use3DViewer(viewer) {
  return {
    send: (eventName, eventPayload) => {
      if (!viewer || !viewer.current) {
        console.error(`Viewer not initialized, error sending event ${eventName}`)
        return
      }
      viewer.current.contentWindow.postMessage(
        {
          event: eventName,
          payload: eventPayload, // Replace with your data
        },
        '*', // Specify the iframe origin
      )
    },
  }
}
