import React, { useState, useEffect, useRef } from 'react'
import { useSwipeable } from 'react-swipeable'
import { ReactComponent as SettingsIcon } from 'shared/assets/icons/settings.svg'
import { ReactComponent as InfoIcon } from 'shared/assets/icons/infoMobile.svg'
import { ReactComponent as AssetsSwitchGalleryIcon } from 'shared/assets/icons/assetsSwitchGallery.svg'
import { ReactComponent as AssetsSwitchPriceIcon } from 'shared/assets/icons/assetsSwitchPrice.svg'
import { ReactComponent as AssetsSwitchGalleryIconWhite } from 'shared/assets/icons/assetsSwitchGalleryWhite.svg'
import { ReactComponent as AssetsSwitchPriceIconWhite } from 'shared/assets/icons/assetsSwitchPriceWhite.svg'
import { ReactComponent as NoAssetsIconMobile } from 'shared/assets/icons/noDesignItemsMobile.svg'
import { ReactComponent as ArrowRight } from 'shared/assets/icons/arrowRightExtraSmall.svg'
import { ReactComponent as ArrowRightDotsPopupIcon } from 'shared/assets/icons/arrowRightDotsPopup.svg'
import { ReactComponent as AssetCardBack } from 'shared/assets/icons/back-asset-card.svg'
import { ReactComponent as FavoriteSmallIcon } from 'shared/assets/icons/favoriteHeartSmall.svg'
import { ReactComponent as FurnitureIcon } from 'shared/assets/icons/furnitureMobile.svg'
import { ReactComponent as UnfurnishedIcon } from 'shared/assets/icons/unfurnishedMobile.svg'
import Carousel from 'shared/components/Carousel'
import ChipPicker from 'shared/components/ChipPicker'
import { isApartmentApp, priceSum, purchaseType } from 'shared/helpers/general'
import { events, posttour } from 'shared/analytics'
import AssetsSlider from '../AssetsSlider'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import { useStore } from 'TourApp/store'
import { Tooltip } from 'react-tooltip'
import { useProperty } from 'TourApp/hooks/useProperty'
import { showFurnitureDetails, showPrices } from 'shared/helpers/gallery'
import { disclaimerText } from 'shared/components/Disclaimer'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const SliderState = {
  OPEN: 1,
  CLOSED: 2,
  INITIAL: 0,
}

function MobileBottomSheet({
  apartment = {},
  assets,
  rooms,
  pickedRoom,
  pickedImage,
  pickedLayout,
  pickedAsset,
  previewMode,
  onAssetPick,
  goToVendorWebsite,
  onPreviewModeChanged,
  onRoomPicked,
  onLayoutPicked,
  onStagingCarouselInit,
  onStagingSlider,
  renderStagingSliderItems,
  getGalleryEventProperties,
  setPickedAsset,
  className,
  rawImageDisplay,
  onAddToCardClick,
  onRawImageDisplayToggled,
  hideDesignNames = false,
  lineMode = false,
}) {
  const { source, selectedApartmentId } = useStore()
  const [filterViewActive, setFilterViewActive] = useState(false)
  const [sliderToggled, setSliderToggled] = useState(SliderState.INITIAL)
  const [designLayoutDropdownToggled, setDesignLayoutDropdownToggled] = useState(false)
  const [infoShown, setInfoShown] = useState(null)
  const prevAsset = usePrevious(pickedAsset)
  const property = useProperty()
  const handlers = useSwipeable({
    onSwipedDown: () => {
      if (pickedAsset) {
        setPickedAsset(null)
        return
      }
      if (sliderToggled && !filterViewActive) {
        setSliderToggled(SliderState.CLOSED)
        return
      }
      if (filterViewActive) {
        setFilterViewActive(false)
        return
      }
    },
    onSwipedUp: () => {
      if (sliderToggled !== SliderState.OPEN) {
        if (previewMode === 'assets' && pickedImage.assets.length === 0) {
          return
        }
        setSliderToggled(SliderState.OPEN)
        onPreviewModeChanged(previewMode)
        return
      }
    },
    preventScrollOnSwipe: true,
  })

  useEffect(() => {
    setFilterViewActive(false)
    setSliderToggled(false)
  }, [selectedApartmentId])

  useEffect(() => {
    switch (previewMode) {
      case 'assets':
        if (sliderToggled === SliderState.OPEN) posttour(events.dgarvo, getGalleryEventProperties)
        if (sliderToggled === SliderState.CLOSED) posttour(events.dgarvc, getGalleryEventProperties)
        break
      case 'staging':
        setPickedAsset(null)
        if (sliderToggled === SliderState.OPEN) posttour(events.dgirvo, getGalleryEventProperties)
        if (sliderToggled === SliderState.CLOSED) posttour(events.dgirvc, getGalleryEventProperties)
        break
      default:
        break
    }
  }, [previewMode, sliderToggled])

  useEffect(() => {
    setInfoShown(null)
  }, [pickedImage])

  useEffect(() => {
    if (prevAsset && pickedAsset?.id !== prevAsset.id) {
      posttour(events.dgsavc, () => ({
        ...getGalleryEventProperties(),
        asset_id: prevAsset.id, // Group_7
        asset_name: prevAsset?.asset_name, // Group_7
      }))
    }
    if (pickedAsset) {
      posttour(events.dgsavo, () => ({
        ...getGalleryEventProperties(),
        asset_id: pickedAsset.id, // Group_7
        asset_name: pickedAsset.asset_name, // Group_7
      }))
      setFilterViewActive(false)
    }
  }, [pickedAsset])

  useEffect(() => {
    if (!previewMode) {
      setFilterViewActive(false)
      setPickedAsset(null)
      // setSliderToggled(SliderState.CLOSED)
    }
  }, [previewMode])

  // useEffect(() => {
  //   if (pickedImage.assets.length === 0) {
  //     setSliderToggled(SliderState.CLOSED)
  //   } else {
  //     setSliderToggled(SliderState.OPEN)
  //   }
  // }, [pickedImage])

  useEffect(() => {
    if (!previewMode) {
      setSliderToggled(SliderState.CLOSED)
    } else {
      setSliderToggled(SliderState.OPEN)
    }
  }, [previewMode])

  useEffect(() => {
    if (!filterViewActive) {
      setDesignLayoutDropdownToggled(false)
    }
  }, [filterViewActive])

  function getPickedDesignIndex(layout) {
    return getSelectedItemRemappedDesigns().find(({ id }) => layout.id === id)
  }

  function hasMultipleDesigns() {
    if (!isApartmentApp()) {
      return true
    }
    return pickedRoom.room_designs.length > 1
  }

  function getTitle(pickedLayout) {
    if (!pickedLayout) {
      return
    }

    if (pickedLayout.is_more) {
      return `Other images${pickedImage && pickedImage.display_name ? ` · ${pickedImage.display_name}` : ''}`
    }
    if (!pickedImage.assets.length) {
      if (pickedImage.display_name && pickedImage.display_name !== (pickedRoom.name || pickedRoom.classification)) {
        return pickedImage.display_name
      }
      return pickedRoom.name || pickedRoom.classification
    }
    if (hideDesignNames) {
      return hasMultipleDesigns() ? `Design ${pickedImage.design_index}` : ''
    }
    if (pickedLayout.short_description) {
      return `${pickedLayout.design_name} (${pickedLayout.short_description}) by ${pickedLayout.main_vendor.full_name}`
    }

    return `${pickedLayout.design_name} by ${pickedLayout.main_vendor.full_name}`
  }

  function getDesignDropdownFilterTitle(layout) {
    if (layout.is_more) {
      return layout.main_vendor.full_name
    }
    if (!layout.is_more && hideDesignNames) {
      return `Design ${getPickedDesignIndex(layout).design_index}`
    }
    if (layout.short_description) {
      return `${layout.design_name} (${layout.short_description}) by ${layout.main_vendor.full_name}`
    }
    return `${layout.design_name} by ${layout.main_vendor.full_name}`
  }

  function isAssetsPreviewMode() {
    return previewMode === 'assets' && !filterViewActive && sliderToggled === SliderState.OPEN
  }

  function isStagingPreviewMode() {
    return previewMode === 'staging' && !filterViewActive && sliderToggled === SliderState.OPEN
  }

  function hasMultipleDesignLayouts() {
    return pickedRoom.room_designs.length > 1
  }

  function isRent() {
    return purchaseType(pickedLayout.main_vendor.is_rental).toLowerCase() === 'rent'
  }

  function hasDesignItems() {
    return pickedRoom && pickedImage.assets.length > 0
  }

  function getSelectedItemRemappedDesigns() {
    const designs = JSON.parse(JSON.stringify(pickedRoom.room_designs)).map((design, index) => ({
      ...design,
      design_index: index + 1,
    }))
    const selectedDesignIndex = designs.findIndex((design) => design.id === pickedLayout.id)
    const [elem] = designs.splice(selectedDesignIndex, 1)
    designs.unshift(elem)
    return designs
  }

  const circleIconClasses =
    'flex items-center justify-center drop-shadow p-[5px] h-[42px] w-[42px] lg:h-[32px] lg:w-[32px] rounded-full shadow-[0px_2px_6px_rgba(0,0,0,0.15)] lg:shadow-[rgba(0,0,0,0.5)]'

  function getWrapHeight() {
    if (filterViewActive) {
      if (designLayoutDropdownToggled) {
        return '315px'
      }
      return '215px'
    }
    if (sliderToggled === SliderState.OPEN) {
      if (previewMode === 'staging') {
        return '250px'
      }
      if (previewMode === 'assets' && !pickedAsset) {
        return showPrices(property) ? '368px' : '295px'
      }
    }
    if (pickedAsset) {
      return '242px'
    }
    return '110px'
  }

  useEffect(() => {
    let timeout
    if (infoShown) {
      timeout = setTimeout(() => {
        setInfoShown(false)
      }, 5000)
    }
    return function () {
      clearTimeout(timeout)
    }
  }, [infoShown])

  function getPickedRoomTitle() {
    if (pickedLayout.is_amenities) {
      return pickedRoom.classification
    }
    if (pickedLayout.is_more && pickedRoom.room_classification.classification_name) {
      return pickedRoom.room_classification.classification_name
    }
    if (pickedImage.display_name && pickedImage.display_name !== (pickedRoom.name || pickedRoom.classification)) {
      return pickedImage.display_name
    }
    return pickedRoom.name || pickedRoom.classification
  }

  function getPrice() {
    return priceSum(
      pickedImage.assets.map(
        ({ asset, quantity }) => (asset.listings.length > 0 ? asset.listings[0].price : 0) * quantity,
      ),
    )
  }

  function generateAssetsForEversetLink() {
    return pickedImage.assets.map((asset) => ({
      quantity: asset.quantity,
      variant_id: asset.asset.variant_id,
    }))
  }

  function showDesignSection() {
    if (pickedRoom.room_designs.length === 1) {
      const [design] = pickedRoom.room_designs
      const assets = design.design_components.map(({ assets }) => assets).flat()
      const sparxDesignVendorId = 12
      if (assets.length === 0 || (design.main_vendor && design.main_vendor.id === sparxDesignVendorId)) {
        return false
      }
    }
    return true
  }

  function updateSlides(swiper) {
    //==========================================================================
    // This workaround uses setTimeout 100 for catching slider init timing and calls internal update method.
    // Due to lack of event or callback for catching this properly safe timing of 100ms is used.
    // TODO: Discover this problem more properly.
    //==========================================================================
    setTimeout(() => {
      if (!swiper.destroyed) {
        swiper.update()
      }
    }, 100)
  }

  function getFloorLabel(level) {
    return level.show
      ? ` (${level.display_name || (level.floor_number === 0 ? 'Ground floor' : `Floor ${level.floor_number}`)})`
      : ''
  }


  return (
    <div
      className={`relative transition-[height] duration-[375ms] bg-white flex-shrink-0 w-full rounded-t-[16px] shadow-[0px_0px_22px_rgba(95,103,135,0.1)] ${
        !filterViewActive ? 'p-[24px] ' : ''
      } pt-0 z-20 ${className || ''}`}
      style={{ height: getWrapHeight() }}
    >
      <Tooltip
        id="tooltip"
        noArrow
        isOpen
        className="default-tooltip mobile"
        positionStrategy={'fixed'}
        openOnClick={true}
        delayHide={3000}
      />

      {!pickedAsset && (
        <div className={`w-full justify-center flex py-[16px]`} {...handlers}>
          <div className="h-[4px] rounded-full w-[32px] bg-[#E5E7ED]"></div>
        </div>
      )}
      {pickedAsset && (
        <button className='mt-[17px] mb-[9px] p-[5px] ml-[-5px]' onClick={()=>{setPickedAsset(null)}}>
          <AssetCardBack />
        </button>
      )}
      <div className={`absolute z-10 top-[-67px] left-[16px] flex justify-between items-center`}>
        <div className="flex">
          {(pickedImage.tour_unit || pickedImage.direction || (pickedImage.captured_in && !lineMode)) && (
            <button onClick={() => setInfoShown(!infoShown)} type="button" className={`bg-white ${circleIconClasses}`}>
              <InfoIcon />
            </button>
          )}
          {infoShown !== null && (
            <div
              className={`bg-white drop-shadow-md rounded-[4px] absolute bottom-[calc(100%+12px)] left-0 w-[calc(100vw-34px)] lg:w-[280px] flex justify-center items-center px-[24px] py-[20px] lg:p-[17px] lg:truncate ${
                infoShown ? 'fadeShow' : 'fadeHide'
              }`}
            >
              <div>
                {pickedImage.captured_in && !lineMode && (
                  <div className="text-[16px] lg:text-[18px] font-semibold leading-[22px]">
                    Captured in apartment #{pickedImage.captured_in?.unit_number}
                  </div>
                )}
                {pickedImage.direction && (
                  <div className="text-[16px] font-medium text-[#5F6787] leading-[21px]">
                    Direction {pickedImage.direction}
                  </div>
                )}
                <div className="text-[14px] mt-[12px] font-normal">* {disclaimerText.second}</div>
              </div>
            </div>
          )}
          {pickedImage.raw_image && (
            <button
              type="button"
              className="bg-[white] ml-[8px] flex items-center justify-center drop-shadow  h-[40px] w-[40px] lg:h-[32px] lg:w-[32px] rounded-full shadow-[0px_2px_6px_rgba(0,0,0,0.15)] lg:shadow-[rgba(0,0,0,0.5)]"
              onClick={() => {
                onRawImageDisplayToggled()
              }}
            >
              {rawImageDisplay ? <UnfurnishedIcon /> : <FurnitureIcon />}
            </button>
          )}
        </div>
      </div>
      <div className={`absolute z-10 top-[-67px] flex justify-between items-center right-[16px]`}>
        <div className="flex">
          <button
            type="button"
            onClick={() => {
              setFilterViewActive(false)
              onPreviewModeChanged(previewMode === 'staging' ? null : 'staging', true)
              isStagingPreviewMode() ? setSliderToggled(SliderState.CLOSED) : setSliderToggled(SliderState.OPEN)
            }}
            className={`${isStagingPreviewMode() ? 'bg-black' : 'bg-white'} ${circleIconClasses}`}
          >
            {isStagingPreviewMode() ? <AssetsSwitchGalleryIconWhite /> : <AssetsSwitchGalleryIcon />}
          </button>
          {showFurnitureDetails(property) && (
            <button
              type="button"
              onClick={() => {
                setFilterViewActive(false)
                onPreviewModeChanged(previewMode === 'assets' ? null : 'assets', true)
              }}
              className={`${isAssetsPreviewMode() ? 'bg-black' : 'bg-white'} ${circleIconClasses} ml-[8px]`}
            >
              {isAssetsPreviewMode() ? <AssetsSwitchPriceIconWhite /> : <AssetsSwitchPriceIcon />}
            </button>
          )}
        </div>
      </div>
      {!filterViewActive && !pickedAsset && (
        <>
          <div className="flex items-center justify-between">
            {hasDesignItems() && (
              <div className="w-5/6">
                <div className="text-[14px] font-semibold leading-[14px] text-[#5F6787] ">
                  {pickedRoom.name || pickedRoom.classification}
                </div>
                <div
                  className="text-[18px] font-semibold leading-[20px] truncate mt-[9px]"
                  data-tooltip-position-strategy="fixed"
                  data-tooltip-id="tooltip"
                  data-tooltip-content={getTitle(pickedLayout, hideDesignNames)}
                  data-tooltip-offset="32"
                  data-tooltip-place="top"
                >
                  {getTitle(pickedLayout, hideDesignNames)}
                </div>
              </div>
            )}
            {!hasDesignItems() && (
              <div className="w-5/6">
                <div className="text-[14px] font-semibold text-[#5F6787]">{getPickedRoomTitle()}</div>
                {pickedLayout.is_more && (
                  <div
                    className="text-[18px] font-semibold leading-[20px] truncate mt-[9px]"
                    data-tooltip-position-strategy="fixed"
                    data-tooltip-id="tooltip"
                    data-tooltip-content={getTitle(pickedLayout)}
                    data-tooltip-offset="32"
                    data-tooltip-place="top"
                  >
                    {getTitle(pickedLayout)}
                  </div>
                )}
                {pickedLayout.is_amenities && pickedImage.display_name && (
                  <div
                    className="text-[18px] font-semibold leading-[20px] truncate mt-[9px]"
                    data-tooltip-position-strategy="fixed"
                    data-tooltip-id="tooltip"
                    data-tooltip-content={pickedImage.display_name}
                    data-tooltip-offset="32"
                    data-tooltip-place="top"
                  >
                    {pickedImage.display_name}
                  </div>
                )}
              </div>
            )}
            <div>
              <button
                type="button"
                className="bg-[#EDEFF3] border-none rounded-[4px] h-[42px] w-[42px] flex items-center justify-center mt-[-1px]"
                onClick={() => {
                  setFilterViewActive(true)
                  posttour(events.dgfc, getGalleryEventProperties)
                }}
              >
                <SettingsIcon />
              </button>
            </div>
          </div>

          {sliderToggled === SliderState.OPEN && (
            <div className="mt-[16px]">
              {isStagingPreviewMode() && (
                <div className="h-[93px]">
                  <Carousel
                    slidesPerView="auto"
                    spaceBetween={0}
                    onSwiper={(swiper) => {
                      onStagingSlider(swiper)
                      onStagingCarouselInit(swiper)
                      updateSlides(swiper)
                    }}
                    slideClassName="!w-max"
                    // onSwiper={setStagingCarouselInstance}
                    items={renderStagingSliderItems()}
                  />
                </div>
              )}
              {isAssetsPreviewMode() && hasDesignItems() && (
                <div>
                  <div>
                    <AssetsSlider
                      items={assets}
                      pickedAsset={pickedAsset}
                      pickedLayout={pickedLayout}
                      purchaseType={purchaseType(pickedLayout.main_vendor.is_rental)}
                      onAssetPick={(asset) => onAssetPick(asset, false, true)}
                    />
                  </div>
                  {source !== 'mobile' && (
                    <>
                      <div className="mt-[16px]" onClick={goToVendorWebsite}>
                        <a
                          href={pickedLayout.main_vendor.website_link}
                          target="_blank"
                          className="underline text-[14px]"
                          rel="noreferrer"
                        >
                          More products by {pickedLayout.main_vendor.full_name}
                        </a>
                      </div>
                    </>
                  )}
                </div>
              )}
              {isAssetsPreviewMode() && !hasDesignItems() && (
                <div className="flex flex-col items-center justify-center lg:py-[90px] h-[200px]">
                  <div>
                    <NoAssetsIconMobile />
                  </div>
                  <div className="text-[#5F6787] mt-[16px]">No Design Items</div>
                </div>
              )}
            </div>
          )}
        </>
      )}
      {filterViewActive && (
        <div>
          <div className="my-[4px] mb-[20px]">
            <div className="text-[14px] font-medium text-[#5F6787] pl-[24px]">Room Type</div>
            <div className="mt-[12px]">
              <ChipPicker
                items={rooms.map(
                  (room) => `${room.name || room.classification}${room.level ? getFloorLabel(room.level) : ''}`,
                )}
                itemClassNames={(index) => `!h-[32px] ${index === 0 ? 'ml-[24px]' : ''}`}
                onPicked={(_, index) => onRoomPicked(rooms[index])}
                activeItemClassNames="!bg-black"
                picked={pickedRoom.name || pickedRoom.classification}
              />
            </div>
          </div>
          <div className="bg-[#E5E7ED] w-[calc(100%+24px)] h-[1px] ml-[-24px]"></div>
          {pickedImage.roomId > 0 && (
            <div className="my-[20px] pr-[24px] pl-[24px]">
              <div
                className="flex items-center justify-between w-full flex-wrap"
                onClick={() =>
                  hasMultipleDesignLayouts() && setDesignLayoutDropdownToggled(!designLayoutDropdownToggled)
                }
              >
                {!designLayoutDropdownToggled && showDesignSection() && (
                  <div className="w-[90%]">
                    <div className="text-[14px] font-medium text-[#5F6787]">Design</div>
                    <div className="text-[16px] font-medium leading-[22px] truncate text-ellipsis mt-[4px]">
                      {getDesignDropdownFilterTitle(pickedLayout)}
                    </div>
                  </div>
                )}
                {designLayoutDropdownToggled && (
                  <div>
                    <div className="text-[14px] font-medium text-black">Choose design</div>
                  </div>
                )}
                {hasMultipleDesignLayouts() && (
                  <div
                    className={`w-[10%] flex  items-center flex-shrink-0 ${
                      designLayoutDropdownToggled ? 'rotate-90 justify-center' : 'justify-end'
                    }`}
                  >
                    <ArrowRight />
                  </div>
                )}
              </div>
              {designLayoutDropdownToggled && (
                <div className="pl-[24px] min-h-[40px] max-h-[124px] overflow-y-auto">
                  {getSelectedItemRemappedDesigns().map((layout) => (
                    <div
                      onClick={() => {
                        onLayoutPicked(layout)
                        setDesignLayoutDropdownToggled(false)
                      }}
                      className={`mt-[16px] h-[24px] flex items-center relative cursor-pointer ${
                        layout.id === pickedLayout.id ? 'text-black relative' : 'text-[#5F6787]'
                      }`}
                    >
                      {layout.id === pickedLayout.id && (
                        <div className="h-[5px] w-[5px] bg-black rounded-full absolute left-[-12px]"></div>
                      )}
                      {getDesignDropdownFilterTitle(layout)}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {pickedAsset && (
        <div className="flex h-[158px] mt-[8px]" onClick={() => onAssetPick(pickedAsset, false, true)}>
          <div className="flex-shrink-0 relative h-[158px] w-[158px]">
            <img
              src={getCdnAssetUrl(pickedAsset.thumbnail)}
              className="h-full w-auto rounded-[8px]"
              alt={pickedAsset.text || pickedAsset.asset_name}
            />
            {pickedAsset.count > 1 && (
              <div className="absolute top-[10px] left-[8px] text-[10px] h-[20px] px-[7px] bg-white rounded-full font-medium leading-[20px]">
                x{pickedAsset.count}
              </div>
            )}
            {pickedAsset.is_favourite && (
              <>
                <div className="absolute top-[10px] right-[10px]">
                  <FavoriteSmallIcon />
                </div>
              </>
            )}
          </div>
          <div className="pl-[20px] h-full flex flex-col justify-between ">
            <div>
              <div className="flex items-start justify-between">
                <div className="font-semibold leading-[21px] pr-3">{pickedAsset.asset_name}</div>
                <div className="mt-2">
                  <ArrowRightDotsPopupIcon />
                </div>
              </div>
              <div className=" text-[#5F6787] mt-[2px] text-[14px]">
                By <span className="uppercase">{pickedLayout.main_vendor.full_name}</span>
              </div>
            </div>
            <div>
              {!showPrices(property) && <div className="bg-[#C7CCD9] h-[1px] w-full my-[12px]"></div>}
              <div className="text-[12px] text-[#5F6787] font-medium">
                W{pickedAsset.width} × D{pickedAsset.depth} × H{pickedAsset.height}
              </div>
              {showPrices(property) && (
                <>
                  <div className="bg-[#C7CCD9] h-[1px] w-full my-[12px]"></div>
                  <div className="flex items-center">
                    <label
                      className={`uppercase ${
                        isRent() ? 'bg-[#AC74AA]' : 'bg-[#387EE5]'
                      } text-white rounded-full px-[12px] h-[18px] flex items-center justify-center font-semibold text-[10px] tracking-[1px]`}
                    >
                      {purchaseType(pickedLayout.main_vendor.is_rental)}
                    </label>
                    {pickedLayout && !pickedLayout.main_vendor.is_sparx && (
                      <div className="ml-[8px] font-medium text-[14px]">
                        $
                        {priceSum([pickedAsset.listings.length > 0 ? pickedAsset.listings[0].price : 0]).toLocaleString(
                          'en-US',
                        )}
                        {isRent() && <span>mo</span>}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default MobileBottomSheet
