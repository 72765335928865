import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import ErrorMessage from 'shared/components/ErrorMessage'
import Input from 'shared/components/Input'
import { EMAIL_REGX } from 'shared/constants'
import { errorMessages } from 'shared/settings'
import * as Yup from 'yup'

function InstantForm({ onChange }) {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(errorMessages.required),
    lastName: Yup.string().required(errorMessages.required),
    email: Yup.string().matches(EMAIL_REGX, 'Invalid email address').required(errorMessages.required),
  })

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onChange: (e) => {
      // console.log(e)
    },
    isInitialValid: false,
  })

  useEffect(() => {
    const { email, firstName, lastName } = formik.values
    const isValid = email && firstName && lastName && formik.isValid
    onChange({ ...formik.values, form: { isValid: isValid && Object.keys(formik.errors).length === 0 } })
  }, [formik.values, formik.errors, formik.isValid, onChange])

  function hasError(field) {
    return formik.errors[field] && formik.touched[field]
  }

  return (
    <div className="w-full lg:w-1/2 lg:pr-[68px]">
      <div className="text-[#C7CCD9]">Contact Information</div>
      <div className="grid gap-[16px] lg:gap-[24px] grid-cols-2 mt-[12px] lg:mt-[24px]">
        <div className="flex">
          <div className="w-full">
            <div className="hidden lg:block font-semibold text-[rgba(95,103,135,0.3)] uppercase text-[12px] mt-[8px]">
              First
            </div>
            <div className="mt-[8px]">
              <Input
                onBlur={formik.handleBlur}
                placeholder="First name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
            </div>
            {hasError('firstName') && <ErrorMessage text={formik.errors.firstName} />}
          </div>
        </div>
        <div className="flex">
          <div className="w-full">
            <div className="hidden lg:block font-semibold text-[rgba(95,103,135,0.3)] uppercase text-[12px] mt-[8px]">
              Last name
            </div>
            <div className="mt-[8px]">
              <Input
                onBlur={formik.handleBlur}
                placeholder="Last name"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
            </div>
            {hasError('lastName') && <ErrorMessage text={formik.errors.lastName} />}
          </div>
        </div>
        <div className="flex col-span-2 lg:col-span-1">
          <div className="w-full">
            <div className="hidden lg:block font-semibold text-[rgba(95,103,135,0.3)] uppercase text-[12px] mt-[8px]">
              Email
            </div>
            <div className="mt-[8px]">
              <Input
                onBlur={formik.handleBlur}
                placeholder="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </div>
            {hasError('email') && <ErrorMessage text={formik.errors.email} />}
          </div>
        </div>
        <div className="flex col-span-2 lg:col-span-1">
          <div className="w-full">
            <div className="hidden lg:block font-semibold text-[rgba(95,103,135,0.3)] uppercase text-[12px] mt-[8px]">
              Phone number
            </div>
            <div className="mt-[8px]">
              <Input
                onBlur={formik.handleBlur}
                placeholder="Phone number"
                name="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstantForm
