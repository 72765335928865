import React from 'react'
// Icons
import { ReactComponent as Error } from './icons/error.svg'
import Button from 'shared/components/Button'
import { logout } from 'CRM/helpers/auth'

function AccessSplash({className = ''}) {
  return (
    <div className={`h-full min-h-[inherit] w-[100dvw] flex items-center flex-col justify-center bg-white ${className}`}>
      <div className="h-[128px]">
        <Error />
      </div>

      <div className="text-md lg:text-xl mt-[20px] text-center px-[32px] lg:px-0">Access Denied</div>
      <div className="text-xs mt-[10px] text-center px-[32px] lg:px-0 opacity-50">
        There is no available properties or you don’t have permission to view it.
      </div>
      <Button rounded className="mt-5" onClick={logout}>
        Logout
      </Button>
    </div>
  )
}

export default AccessSplash
