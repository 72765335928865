import { ReactComponent as ResidentInviteFurniture } from 'shared/assets/icons/crm-invite-furniture.svg'
import { ReactComponent as ResidentInviteFurnitureDark } from 'shared/assets/icons/crm-invite-furniture-dark.svg'
import { residentAppHost } from '../../helpers'
import { useStore } from 'CRM/store'

function FurnitureAmenitiesInvite({ user, unit }) {
  const { fakeToken } = useStore()
  async function openInvite() {
    const link = `http://${residentAppHost()}.sparx.ai?agent_id=${user.id}&agent_email=${
      user.email
    }&token=${fakeToken}&app_type=agent&instant_unit_id=${unit.id}`
    console.log(link)
    window.open(link, '_blank').focus()
  }

  return (
    <button type="button" onClick={openInvite} className={`flex items-center lg:group `}>
      <div className="hidden group-hover:block">
        <ResidentInviteFurniture />
      </div>
      <div className="group-hover:hidden">
        <ResidentInviteFurnitureDark />
      </div>
    </button>
  )
}

export default FurnitureAmenitiesInvite
