import React from 'react'
import { useSwipeable } from 'react-swipeable'
import { ReactComponent as BackArrowBottomSheet } from 'shared/assets/icons/back-arrow-bottom-sheet.svg'
import './styles.scss'
function BottomSheet({ children, onToggle, onBack, className = '' }) {
  const handlers = useSwipeable({
    onSwipedDown: onToggle,
    onSwipedUp: onToggle,
    preventScrollOnSwipe: true,
  })

  return (
    <div
      className={`bottom-sheet bg-[white] transition-all pb-[16px] px-[16px] rounded-t-[16px] mt-[-16px] relative z-[100] ${className}`}
    >
      {!onBack && (
        <div className="h-[34px] flex items-center justify-center" {...handlers}>
          <div className="h-[3px] w-[32px] bg-[#C7CCD9] rounded-full"></div>
        </div>
      )}
      {onBack && (
        <div
          className="py-[16px]"
          onClick={(e) => {
            onBack()
          }}
        >
          <BackArrowBottomSheet />
        </div>
      )}
      {children}
    </div>
  )
}

export default BottomSheet
