import Carousel from 'shared/components/Carousel'
import { PackageItem } from './PackageItem'
import { useEffect, useMemo, useState } from 'react'
import { groupAssets } from 'Resident/helpers/general'
import { isAgentApp } from 'Resident/helpers/app'
import PackagePhotoItem from '../Agent/components/PackagePhotoItem'
import { useStore } from 'Resident/store'
import { objectCopy } from 'shared/helpers/general'
import { sortByCategoryWeight } from 'shared/helpers/arrays'

export function PackageAssetsSlider({
  summaryMode,
  checkout,
  assetPopup,
  navigation = true,
  onClick,
  assets,
  onAssetAdd,
  onAssetUpdate,
  hoveredAsset,
  onAssetRemove,
  onAssetHover,
  pack,
  onMinusCountAssetClick,
  onPlusCountAssetClick,
  ...props
}) {
  const { appType } = useStore()
  // const [carousel, setCarousel] = useState()
  const [carousel, setCarousel] = useState()
  const [hovered, setHovered] = useState(false)
  function isCore(asset) {
    return asset.type === 'core'
  }

  const localAssets = useMemo(()=>{
    return objectCopy(assets).sort((a, b) => {
      if(!a.room_classification && !b.room){
        return b.category.weight - a.category.weight;
      }
      if (a.room_classification.weight === b.room_classification.weight) {
        return b.category.weight - a.category.weight;
      } else {
        return a.room_classification.weight - b.room_classification.weight;
      }
    }).reverse()
  }, [assets])

  // useEffect(()=>{
  //   const assetIndex = assets.findIndex(asset=>asset.id === assetPopup)
  //   if(assetIndex > -1 && carousel){
  //     carousel.slideTo(assetIndex, 0)
  //   }
  // }, [assetPopup, carousel, assets])

  const selectedAssets = useMemo(() => {
    return localAssets
      .filter((asset) => isCore(asset) || asset.added)
      .sort((x, y) => Number(y.type === 'core') - Number(x.type === 'core'))
  }, [localAssets])

  const grouped = useMemo(() => {
    const nonSelectedAssets = [...localAssets.filter((asset) => !isCore(asset) && !asset.added)]
    const items = [...selectedAssets, ...(!checkout ? nonSelectedAssets : [])]
    return summaryMode ? groupAssets(items, pack) : items
  }, [checkout, selectedAssets, localAssets, pack, summaryMode])

  useEffect(() => {
    if (carousel && hoveredAsset && carousel && !hovered) {
      const itemIndex = grouped.findIndex(
        (i) => i.identifier === hoveredAsset.identifier && i.type === hoveredAsset.type,
      )
      carousel.slideTo(itemIndex - 1, 400)
    }
  }, [hoveredAsset, assetPopup, carousel, grouped, hovered])

  return (
    <div
      onMouseEnter={() => {
        setHovered(true)
      }}
      onMouseLeave={() => {
        setHovered(false)
      }}
      className="lg:py-[15px] lg:mt-[-15px]"
    >
      {localAssets.length > 0 ? (
        <Carousel
          className="grid-mode custom-navigation-slider rp-slider sparx-slider"
          slidesPerView="auto"
          navigation={navigation}
          freeMode={true}
          onSwiper={setCarousel}
          mousewheel={true}
          slideClassName="!w-max"
          separatorIndex={!checkout ? selectedAssets.length : -1}
          spaceBetween={16}
          items={(isAgentApp(appType) ? localAssets : grouped).map((item, index) =>
            isAgentApp(appType) ? (
              <PackagePhotoItem
                rent={pack && pack.vendor.is_rental}
                item={item}
                pack={pack}
                hoveredAsset={hoveredAsset}
                assetPopup={assetPopup}
                onClick={onClick}
                checkout={checkout}
                onHover={onAssetHover}
                onAdd={() => onAssetAdd(item, index)}
                onUpdate={(payload) => onAssetUpdate(item, payload)}
                onRemove={() => onAssetRemove(item, index)}
                summaryMode={summaryMode}
                separatorIndex={!checkout ? selectedAssets.length : -1}
                index={index}
                key={`${item.added}`}
              />
            ) : (
              <PackageItem
                rent={pack && pack.vendor.is_rental}
                item={item}
                pack={pack}
                hoveredAsset={hoveredAsset}
                assetPopup={assetPopup}
                onClick={onClick}
                checkout={checkout}
                onHover={onAssetHover}
                onAdd={() => onAssetAdd(item, index)}
                onUpdate={(payload) => onAssetUpdate(item, payload)}
                onRemove={() => onAssetRemove(item, index)}
                onMinusCountAssetClick={()=>{
                  onMinusCountAssetClick && onMinusCountAssetClick(item, index)
                }}
                onPlusCountAssetClick={()=>{
                  onPlusCountAssetClick && onPlusCountAssetClick(item, index)
                }}
                summaryMode={summaryMode}
                separatorIndex={!checkout ? selectedAssets.length : -1}
                index={index}
                key={`${item.added}`}
              />
            ),
          )}
          {...props}
        />
      ) : (
        <div className="text-[18px] opacity-50 flex items-center justify-center w-full h-[100px]">No items</div>
      )}
    </div>
  )
}
