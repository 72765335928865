import { useStore } from "Resident/store"

export function usePropertyLogo(props){
    const { appData } = useStore()

    const unitData = appData && appData.unit
  
    const property = unitData ? appData.unit.unit_type.property : props.property
  
    const logo = property ? property.logo_path : null

    return {
        logo,
        property,
        unitData
    }
}