import * as React from 'react'
const FeaturedVideosIcon = (props) => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1 6.14286C1 4.77889 1.46825 3.47078 2.30175 2.50631C3.13524 1.54183 4.2657 1 5.44444 1H16.5556C17.7343 1 18.8648 1.54183 19.6983 2.50631C20.5317 3.47078 21 4.77889 21 6.14286V13.8571C21 15.2211 20.5317 16.5292 19.6983 17.4937C18.8648 18.4582 17.7343 19 16.5556 19H5.44444C4.2657 19 3.13524 18.4582 2.30175 17.4937C1.46825 16.5292 1 15.2211 1 13.8571V6.14286Z"
      stroke={props.stroke || `#5F6787`}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.36365 6.72656L15.0909 9.99929L9.36365 13.272V6.72656Z"
      stroke={props.stroke || `#5F6787`}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
export default FeaturedVideosIcon
