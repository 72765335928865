import * as React from 'react'
const SvgComponent = (props) => (
  <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 7.28571C1 5.61864 1.5619 4.01984 2.5621 2.84104C3.56229 1.66224 4.91885 1 6.33333 1H19.6667C21.0812 1 22.4377 1.66224 23.4379 2.84104C24.4381 4.01984 25 5.61864 25 7.28571V16.7143C25 18.3814 24.4381 19.9802 23.4379 21.159C22.4377 22.3378 21.0812 23 19.6667 23H6.33333C4.91885 23 3.56229 22.3378 2.5621 21.159C1.5619 19.9802 1 18.3814 1 16.7143V7.28571Z"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 8L17 12L10 16V8Z"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
export default SvgComponent
