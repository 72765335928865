import { useEffect } from 'react'

import { Router, Outlet } from '@tanstack/react-location'
import { useMediaQuery } from '@react-hook/media-query'
import { events, posttour } from 'shared/analytics'
import { location, routes } from 'TourApp/routes'
import { useStore } from 'TourApp/store'

import Footer from 'shared/components/Footer'
import Navbar from 'shared/components/Navbar'
import Sidebar from './components/Sidebar'
import BottomBar from 'shared/components/BottomBar'
import HotJar from 'shared/analytics/hotjar'

function App() {
  const { tourData, selectedApartmentId, sidebarShown, toggleNavbar, sidebar, navbarShown } = useStore()
  const apartment = tourData?.units.find(({ unit }) => unit.id === selectedApartmentId)

  const isMobile = useMediaQuery('(max-width: 1023px)')
  const isMobileLandscape = useMediaQuery('(orientation: landscape) and (max-width: 1023px)')

  useEffect(() => {
    posttour(events.pl)
  }, [])

  useEffect(() => {
    if (isMobile) {
      posttour(isMobileLandscape ? events.alctl : events.alctp, () => ({ path: window.location.href }))
    }
  }, [isMobile, isMobileLandscape])

  const onVisibilityChange = () => {
    if (document.hidden) {
      posttour(events.abh)
    } else {
      posttour(events.abv)
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange)
    return () => document.removeEventListener('visibilitychange', onVisibilityChange)
  }, [])

  const wrapperClasses = sidebarShown
    ? `min-h-[calc(100%-72px)] ${
        sidebar.minimized ? 'lg:w-[calc(100%-56px)]' : 'lg:w-[calc(100%-336px)]'
      } mt-[72px] lg:mt-0`
    : ''

  const title = apartment ? `#${apartment.unit.unit_number}` : null
  const navbarLabel = apartment ? apartment.unit.unit_type.property.property_name : null

  return (
    <>
      <HotJar />
      <Router location={location} routes={routes}>
        <div className={`w-screen h-full overflow-hidden lg:pb-[30px] ${sidebarShown ? 'bg-[#FAFAFA]' : ''}`}>
          <div
            className={`w-full ${!navbarShown ? 'h-[calc(100%-72px)]' : 'h-full'} lg:h-full flex flex-col lg:flex-row`}
          >
            {sidebarShown && (
              <>
                <Navbar
                  className="lg:hidden"
                  companyLogo={apartment.unit.unit_type.property.real_estate_company.company_logo}
                  {...{ toggleNavbar, navbarLabel, title }}
                />
                <Sidebar />
              </>
            )}
            <div
              className={`w-full h-full transition-all duration-300 
      ease-out ${wrapperClasses} bg-[#FAFAFA]`}
            >
              <Outlet />
            </div>
          </div>

          {apartment && isMobile && !navbarShown && <BottomBar />}
          <Footer disableLink className="absolute bottom-0 left-0 hidden lg:flex" eventsSpace="posttour" />
        </div>
      </Router>
    </>
  )
}

export default App
