import axios, { Axios } from 'axios'
import { isProductionEnv } from './general'

export const apiHost = !isProductionEnv()
  ? localStorage.getItem('apiHost') || process.env.REACT_APP_API_HOST
  : process.env.REACT_APP_API_HOST

export const apiClient = new Axios({
  ...axios.defaults,
  baseURL: apiHost,
  headers: {
    Authorization: process.env.REACT_APP_API_TOKEN,
  },
})

export function isAccessErrorResponse(status) {
  return [403, 401].includes(status)
}
