export function group3(store) {
  const { appData = {} } = store
  return {
    real_estate_id: appData.unit?.unit_type.property.real_estate_company.id,
    real_estate_name: appData.unit?.unit_type.property.real_estate_company.company_name,
    property_id: appData.unit?.unit_type.property.id,
    property_name: appData.unit?.unit_type.property.property_name,
    user_id: appData.user?.id,
    resident_name: [appData.user?.first_name, appData.user?.last_name].join(' '),
    resident_email: appData.user?.email,
    unit_type_id: appData.unit?.unit_type.id,
    unit_type_name: appData.unit?.unit_type.name,
    unit_type_marketing_name: `${appData.unit?.unit_type.name}${appData.unit?.unit_type.nick_name ? ` - ${appData.unit?.unit_type.nick_name} ` : ''}`,
    unit_id: appData.unit?.id,
    unit_number: appData.unit?.unit_number,
  }
}

export function group4(pack) {
  return (store) => {
    return {
      ...group3(store),
      design_package_id: pack.id,
      design_package_name: pack.name,
      vendor_id: pack.vendor.id,
      vendor_name: pack.vendor.full_name,
    }
  }
}

export function group5(pack, room) {
  return (store) => {
    return {
      ...group4(pack)(store),
      room_classification_id: room.room_classification.id,
      room_classification_name: room.room_classification.classification_name,
    }
  }
}

export function group6(pack, room, package_asset) {
  //==========================================================================
  // We need to globally enrich asset, since concrete asset is not avaikable in components scope and do not want to spoil business logic codebase.
  //==========================================================================
  let assets

  if(room.room_classification.id === -1){
    assets = pack.room_designs.map(d=>d.room_design).flat().map(d=>d.photos).flat().map(p=>p.assets).flat()
  } else {
    assets = pack.room_designs.find(d=>d.room_design.room.room_classification.id === room.room_classification.id).room_design.photos.map(p=>p.assets).flat()
  }


  const a = assets.find(a=>a.identifier === package_asset.identifier) || {}
  const asset = {
    ...package_asset,
    asset: a.asset
  }
  return (store) => {
    return {
      ...group5(pack, room)(store),
      asset_category_id: asset.category.id,
      asset_category_name:asset.category.category_name,
      asset_id: asset.asset?.id || null,
      asset_name: asset.asset?.asset_name || null
    }
  }
}