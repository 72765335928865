export function getHiddenScreensList() {
  return `${new URL(window.location.href).searchParams.get('hide_screens') || ''},`.split(',').map(item=>item.trim())
}

export function getDefaultRoute(routes) {
  //==========================================================================
  // This array should follow order of available screens.
  // Means, if default screen is not found, return 'gallery'
  // If both screens 'default' and 'gallery' are not found, return floorplans
  //==========================================================================

  const defaultScreen = 'videos'

  const screens = [defaultScreen, 'gallery', 'floorplans']

  let route = '/empty'
   
  for (let index = 0; index < screens.length; index++) {
    const foundRoute = routes.find((route) => route.path === `/${screens[index]}`)
    if (foundRoute) {
      route = foundRoute.path
      break
    }
  }
  return route
}
