import * as React from 'react'
const SvgComponent = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.6666 7.11133H15.6783"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 4.66667C1 3.69421 1.38631 2.76158 2.07394 2.07394C2.76158 1.38631 3.69421 1 4.66667 1H19.3333C20.3058 1 21.2384 1.38631 21.9261 2.07394C22.6137 2.76158 23 3.69421 23 4.66667V19.3333C23 20.3058 22.6137 21.2384 21.9261 21.9261C21.2384 22.6137 20.3058 23 19.3333 23H4.66667C3.69421 23 2.76158 22.6137 2.07394 21.9261C1.38631 21.2384 1 20.3058 1 19.3333V4.66667Z"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 16.8887L7.11111 10.7776C8.24533 9.68612 9.64356 9.68612 10.7778 10.7776L16.8889 16.8887"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.4445 14.4431L15.6667 13.2209C16.8009 12.1295 18.1991 12.1295 19.3333 13.2209L23 16.8876"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
export default SvgComponent
