import React from 'react'
import Video from '../../Video'

export function renderVideosSlide(video, activeSlideId, onVideoStateChange) {
  return (
    <div className=" h-full">
      <div className="h-full flex items-center justify-center ">
        <Video video={video} activeSlideId={activeSlideId} onStateChange={onVideoStateChange} />
      </div>
    </div>
  )
}
