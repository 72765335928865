import Page from 'CRM/components/Page'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
// Icons

import { ReactComponent as PlusIconWhite } from 'shared/assets/icons/plus-icon-crm-white.svg'

import Button from 'shared/components/Button'
import { SearchInput } from '../Apartments/components/Search'
import { Link } from '@tanstack/react-location'
import { getInvitesForAgent } from 'CRM/services/invites'
import { useStore } from 'CRM/store'

import { smartSort } from 'shared/helpers/arrays'
import { objectCopy } from 'shared/helpers/general'
import { InviteItem } from './components/InviteCard'
import { searchFields, tableCols } from './constants'
import MobileFilterChips from './components/MobileFilter'
import InvitesTable from './components/InvitesTable'
import Loader from 'shared/components/Loader'
import { useRequest } from 'shared/hooks/useRequest'

function ResidentInvitesScreen(props) {
  const { user, selectedProperty } = useStore()

  const getInvites = useCallback(async () => {
    const { data } = await getInvitesForAgent(user.id)
    return data.prospects_invitations_by_agent_id
  }, [user.id])

  const {
    data: invites,
    loading,
    refresh,
  } = useRequest(getInvites, {
    default: [],
  })

  const [statusFilter, setStatusFilter] = useState(null)
  const [search, setSearch] = useState(null)
  const [sortingKey, setSortingKey] = useState('last_sent_date')
  const [sortingOrder, setSortingOrder] = useState('desc')

  useEffect(() => {
    setSortingOrder('desc')
  }, [sortingKey])

  const sortedInvites = useMemo(() => {
    return objectCopy(invites).filter(invite=>invite.unit.unit_type)
      .filter((invite) => invite.unit.unit_type.property.id === selectedProperty.id)
      .sort(smartSort(sortingKey, sortingOrder))
  }, [invites, sortingKey, sortingOrder, selectedProperty])

  const searchFilteredInvites = useMemo(() => {
    if (!search) {
      return sortedInvites
    }
    return sortedInvites.filter((invite) => {
      return searchFields(invite).some((field) => field.toLowerCase().includes(search.replace('#', '').toLowerCase()))
    })
  }, [sortedInvites, search])

  const mobileInvitesFiltered = useMemo(() => {
    return searchFilteredInvites.filter((invite) =>
      statusFilter ? invite.invitation_status.toLowerCase() === statusFilter.toLowerCase() : true,
    )
  }, [searchFilteredInvites, statusFilter])

  return (
    <Page className="min-h-[calc(100vh-124px)]">
      <div className="flex flex-col lg:flex-row lg:items-center justify-between">
        <div className="text-[24px] font-medium lg:font-semibold">Resident Invites</div>
        <div className="flex items-center mt-[42px] lg:mt-0">
          <SearchInput value={search} onChange={setSearch} className="mr-[10px] lg:mr-[16px] w-full" />
          <Link to="/resident-invites/add" className="hidden lg:block">
            <Button rounded className="!min-w-[unset] w-[90px] lg:w-auto !lg:min-w-[160px] lg:h-[40px]">
              <PlusIconWhite />
              <span className="ml-[8px] font-medium">
                <span className="hidden lg:inline-block">New Resident Invite</span>
              </span>
            </Button>
          </Link>
          <div className="flex w-1/3 lg:hidden">
            <Link to="/resident-invites/add" className="w-full ml-[8px]">
              <Button rounded className="!min-w-[unset] w-[90px] lg:w-auto !lg:min-w-[160px]">
                <PlusIconWhite />
                <span className="ml-[8px] font-medium">
                  <span>New</span>
                </span>
              </Button>
            </Link>
          </div>
        </div>
      </div>

      {loading && (
        <div className="flex items-center justify-center p-[32px]">
          <Loader />
        </div>
      )}

      {!loading && (
        <>
          <div className="hidden lg:block">
            <InvitesTable
              cols={tableCols(selectedProperty)}
              rows={searchFilteredInvites}
              sortingOrder={sortingOrder}
              sortingKey={sortingKey}
              onDelete={() => {
                refresh()
              }}
              onSortingOrderChange={setSortingOrder}
              onSortingKeyChange={setSortingKey}
            />
          </div>

          <div className="lg:hidden">
            {mobileInvitesFiltered.length ? (
              <MobileFilterChips
                invites={mobileInvitesFiltered}
                statusFilter={statusFilter}
                onChange={setStatusFilter}
              />
            ) : null}
            {mobileInvitesFiltered.map((invite, index) => (
              <InviteItem
                key={index}
                className="mb-[16px]"
                invite={invite}
                onDelete={() => {
                  refresh()
                }}
              />
            ))}
            {!mobileInvitesFiltered.length && (
              <div className="py-[32px] flex items-center justify-center opacity-50">No items</div>
            )}
          </div>
        </>
      )}
    </Page>
  )
}

export default ResidentInvitesScreen
