import { ApolloClient, gql, InMemoryCache } from '@apollo/client'
import { apiHost } from 'shared/helpers/api'

export async function getUserById(userId) {
  const client = new ApolloClient({
    uri: `${apiHost}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      Authorization: process.env.REACT_APP_API_TOKEN || 'xZRuBuykeDuRRei0lw0Oe5WJOXbwuRQK',
    },
  })
  return await client.query({
    variables: {
      userId,
    },
    query: gql`
      query User_by_id($userId: Int!) {
        user_by_id(id: $userId) {
          id
          email
          phone_number
          first_name
          profile_confirmed
          last_name
          user_role
          user_type {
            user_type
          }
          prospects {
            invitation_status
          }
        }
      }
    `,
  })
}
