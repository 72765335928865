import React, { useState } from 'react'
import Button from 'shared/components/Button'
import { ReactComponent as BuildingIcon } from 'shared/assets/icons/building-crm.svg'
import { ReactComponent as UserIcon } from 'shared/assets/icons/user-crm.svg'
import { ReactComponent as ArrowDown } from 'shared/assets/icons/arrowDownSmall.svg'

import { Link, useNavigate } from '@tanstack/react-location'
import logo from 'shared/assets/images/sparx-logo.png'
import { clearAuth } from 'CRM/helpers/auth'
import Header from 'CRM/components/Header'
import ContentGuard from 'CRM/components/ContentGuard'
import Footer from 'CRM/components/Footer'
import FooterLayout from './FooterLayout'

function PropertyDropdown({ property, properties, className = '', onPicked = () => {} }) {
  const [toggled, setToggled] = useState(false)

  function hasMultipleProperties() {
    return properties.length > 1
  }

  return (
    <>
      <div
        className={`${
          hasMultipleProperties() ? 'cursor-pointer' : ''
        } relative flex items-center justify-between select-none ${className}`}
        onClick={() => {
          setToggled(!toggled)
        }}
      >
        <div className="flex">
          <BuildingIcon />
          <span className="ml-[10px]">{property.property_name}</span>
        </div>
        {hasMultipleProperties() && (
          <div className="ml-[15px]">
            <ArrowDown />
          </div>
        )}
        {toggled && hasMultipleProperties() && (
          <div className="absolute top-[30px] right-0 h-[500px] min-w-[300px] bg-white  shadow-[0px_0px_12px_rgba(0,0,0,0.05)] rounded-lg max-h-[264px] overflow-y-auto">
            {properties.map((property) => (
              <button
                type="button"
                className="bg-transparent hover:bg-[rgba(0,0,0,.1)] w-full border-none flex items-center font-semibold h-[44px]  px-4 !justify-start !text-black"
                onClick={() => {
                  setToggled(false)
                  onPicked(property)
                }}
              >
                {property.property_name}
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

function HeaderLayout({ children, property, properties = [], onPropertySelected = () => {}, ...props }) {
  return (
    <FooterLayout>
      <ContentGuard>
        <Header />
        <div className="overflow-y-auto w-screen overflow-hidden">{children}</div>
      </ContentGuard>
    </FooterLayout>
  )
}

export default HeaderLayout
