import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'

import './index.scss'
// Apps
import TourApp from 'TourApp'
import CRM from 'CRM/'
import MediaManager from 'MediaManager/'
import IFrameApp from 'IFrameApp'
import Resident from './Resident'
import reportWebVitals from './reportWebVitals'
// MirageJS Mocks
// import './mirage' // Uncomment to enable MirageJS

import { getAppName, isProductionBuild } from 'shared/helpers/general'
import DevHelper from 'shared/components/DevHelper'
import EventLogBuffer from 'shared/components/EventLogBuffer'
import FeatureGuard, { EFeatures } from 'shared/components/FeatureGuard'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      cacheTime: false,
    },
  },
})

function getReactApp() {
  switch (getAppName()) {
    case 'crm':
      return <CRM />
    case 'posttour':
      return <TourApp />
    case 'apartment':
      return <TourApp />
    case 'iframeapp':
      return <IFrameApp />
    case 'mediamanager':
      return <MediaManager />
    case 'resident':
      return (
        <FeatureGuard feature={EFeatures.INVITES}>
          <Resident />
        </FeatureGuard>
      )
    default:
      return 'Invalid configuration!'
  }
}

console.log(`App version : ${process.env.REACT_APP_VERSION}`)

window.getEnv = () => {
  console.log(process.env)
}

console.log('REACT_APP_API_TOKEN', process.env.REACT_APP_API_TOKEN)
const container = document.getElementById('root')
const root = createRoot(container)

function AppWrap({ dev = false, children }) {
  return dev ? <React.StrictMode>{children}</React.StrictMode> : children
}

root.render(
  <AppWrap>
    {!isProductionBuild() && localStorage.getItem('enableDevTools') && <DevHelper />}
    <EventLogBuffer />
    <QueryClientProvider client={queryClient}>{getReactApp()}</QueryClientProvider>
  </AppWrap>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
