import SidebarLayout from 'Resident/layouts/SidebarLayout'
import { useMemo, useState } from 'react'
// Icons
import { ReactComponent as ArrowDown } from 'shared/assets/icons/arrow-black-packages.svg'
import { ReactComponent as ArrowBack } from 'shared/assets/icons/arrow-back-resident.svg'

// Screen icons
import { ReactComponent as FloorPlansScreenIcon } from './components/ScreenPicker/icons/floorPlans.svg'
import { ReactComponent as PhotosScreenIcon } from './components/ScreenPicker/icons/photos.svg'
import { ReactComponent as VideosScreenIcon } from './components/ScreenPicker/icons/videos.svg'
import PurchaseTypeTag from 'shared/components/PurchaseTypeTag'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStore } from 'Resident/store'
import { useEffect } from 'react'
import { objectCopy, priceSum } from 'shared/helpers/general'
import { getPurchaseType, produceAllRoom } from 'Resident/helpers/general'
import { usePackages } from 'Resident/hooks/usePackages'
import { PackageInfo } from 'Resident/components/PackageRow/components/PackageInfo'
import { CheckoutButton } from 'Resident/components/PackageRow/components/ViewButton'
import { RoomsSlider } from 'Resident/components/PackageRow/components/RoomsSlider'
import { PackageAssetsSlider } from 'Resident/components/PackageRow/components/AssetsSlider'

import { useMediaQuery } from '@react-hook/media-query'
import { MobileBottomSheet } from './components/MobileBottomSheet'

import './styles.scss'
import { smartSort, sortByWeight } from 'shared/helpers/arrays'
import { logDevelopment } from 'shared/helpers/log'
import { useTitle } from 'shared/hooks/useTitle'
import Photos, { renderPhotoSlide } from './components/Content/Photos'
// import Videos from './components/Content/Videos'
import { renderFloorPlanSlide } from './components/Content/FloorPlans'
import { isAgentApp, isMinimalApp, isStandartApp } from 'Resident/helpers/app'
import Carousel from 'shared/components/Carousel'
import { renderVideosSlide } from './components/Content/Videos'
import ContentIndicator from './components/ContentIndicator'
import SelectPackage from 'Resident/components/SelectPackage'
import CortBadge from 'Resident/components/CortBadge'
import { ZoomControls } from 'shared/components/ZoomControls'
import { usePropertyLogo } from 'Resident/hooks/usePropertyLogo'
import { useAnalytics } from 'shared/analytics/hooks/useAnalytics'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { group4, group5, group6 } from 'shared/analytics/resident'
import { RPScreens } from 'Resident/constants/appType'
import { useScreen } from 'Resident/hooks/useScreen'

const PreviewScreens = {
  floorPlans: {
    icon: <FloorPlansScreenIcon />,
    label: 'Floor Plan',
    id: 'plans',
    available: (pack, appData) => {
      return pack.unit_plans.length > 0 && !pack.generated
    },
  },
  photos: {
    icon: <PhotosScreenIcon />,
    label: 'Photos',
    id: 'photos',
    available: (pack, appData) => {
      return (
        pack.room_designs
          .map((r) => r.room_design)
          .flat()
          .map((d) => d.photos)
          .flat().length > 0
      )
    },
  },
  video: {
    icon: <VideosScreenIcon />,
    label: 'Video',
    id: 'videos',
    available: (pack, appData) => {
      return pack.videos.length > 0
    },
  },
}

function PreviewScreen(props) {
  const [error, setError] = useState(false)
  const [toggled, setToggled] = useState(true)
  const [previewScreen, setPreviewScreen] = useState()
  const [activePhoto, setActivePhoto] = useState(null)
  const [activeSlide, setActiveSlide] = useState(null)
  const [carousel, setCarousel] = useState()
  const [videoState, setVideoState] = useState({ playing: false })
  const [zoomed, setZoomed] = useState(false)
  const location = useLocation()
  useTitle('View')
  const { packages, appData, setHoveredAsset, hoveredAsset, appType } = useStore()

  function getFullRoom(pickedRoom){
    return rooms.find((room) => room.room_classification.id === pickedRoom) || {room_classification: {}}
  }

  useScreen(RPScreens.GALLERY) 

  const navigate = useNavigate()

  const pack = useMemo(() => {
    return packages.find((pack) => pack.id === location.state.packageId)
  }, [packages, location])

  const rooms = useMemo(() => {
    return [produceAllRoom(pack), ...pack.room_classifications]
  }, [pack])

  const [pickedRoom, setPickedRoom] = useState(null)

  useEffect(() => {
    if (!previewScreen) {
      if (pack.unit_plans && pack.unit_plans.length) {
        setPreviewScreen(PreviewScreens.floorPlans.id)
      } else {
        setPreviewScreen(PreviewScreens.photos.id)
      }
    }
    if (previewScreen && previewScreen === PreviewScreens.floorPlans.id && !pickedRoom) {
      setPickedRoom(-1)
    }
  }, [pickedRoom, pack, previewScreen])

  useEffect(() => {
    if (!pickedRoom) {
      setPickedRoom(rooms[0].room_classification.id)
    }
  }, [rooms, pickedRoom])

  const assets = useMemo(() => {
    if (pickedRoom) {
      return rooms.find((item) => item.room_classification.id === pickedRoom).package_assets
    } else {
      return []
    }
  }, [pickedRoom, rooms])

  const designs = useMemo(() => {
    const designs = pack.room_designs
    return designs.map((d) => ({
      ...d,
      room_design: {
        ...d.room_design,
        room_classification: d.room_design.room.room_classification,
        photos: d.room_design.photos.map((photo) => ({
          ...photo,
          room_classification: d.room_design.room.room_classification,
          room: d.room_design.room.room_classification.id,
          design: d.room_design.id,
        })),
      },
    }))
  }, [pack])

  //==========================================================================
  // Test functionality to take all the assets from room and then find matching from photo assets, so showing only room assets.
  // Previous solution was working opposite, it was taking all from photos and then finding matches.
  //==========================================================================

  // const photos = useMemo(() => {
  //   return designs
  //     ? designs
  //         .map((d) => {
  //           const room = pack.room_classifications.find(
  //             (r) => r.room_classification.id === d.room_design.room.room_classification.id,
  //           )
  //           const photos = d.room_design.photos.map((photo) => {
  //             let assets = []

  //             if (!pack.generated) {
  //               room.package_assets.forEach((package_asset, index) => {
  //                 const photoAsset = photo.assets.find((p) => {
  //                   console.log({  p })
  //                   return (
  //                     package_asset.identifier === p.asset.identifier && !assets.find((asset) => {
  //                       // console.log({asset, p})
  //                       return asset.asset.id === p.asset.id
  //                     })
  //                   )
  //                 })
  //                 console.log({ photoAsset })
  //                 if(photoAsset){
  //                   assets.push(
  //                     photoAsset
  //                       ? {
  //                           ...photoAsset,
  //                           package_asset,
  //                           room: d.room_design.room.room_classification,
  //                         }
  //                       : null,
  //                   )
  //                 }
  //               })
  //             } else {
  //               assets = photo.assets
  //             }

  //             const p = {
  //               ...photo,
  //               assets: assets
  //                 .filter((i) => !!i)
  //                 .filter((asset) => asset.package_asset.id)
  //                 .map((asset, index) => ({ ...asset, index })),
  //             }
  //             return p
  //           })
  //           return photos
  //         })
  //         .flat()
  //         .sort(sortByWeight)
  //     : []
  // }, [designs, pack])

  const photos = useMemo(() => {
    return designs
      ? designs
          .map((d) => {
            const room = pack.room_classifications.find(
              (r) => r.room_classification.id === d.room_design.room.room_classification.id,
            )
            if(!room){
              return []
            }
            const photos = d.room_design.photos.map((photo) => {
              const p = {
                ...photo,
                level: d.room_design.room.unit_type_level,
                assets: !pack.generated
                  ? photo.assets
                      .map((rawPhotoAsset, index) => {
                        const photoAsset = objectCopy(rawPhotoAsset)
                        photoAsset.type = isAgentApp(appType) ? 'core' : photoAsset.is_core_item ? 'core' : 'add_on'
                        const package_asset =
                          room.package_assets.find((asset) => {
                            return asset.identifier === photoAsset.asset.identifier && asset.type === photoAsset.type
                          }) || {}

                        return {
                          ...photoAsset,

                          package_asset,
                          room: d.room_design.room.room_classification,
                        }
                      })
                      .filter((asset) => asset.package_asset.id)
                      .map((asset, index) => ({ ...asset, index }))
                  : photo.assets,
              }
              return p
            })
            return photos
          })
          .flat()
          .sort(sortByWeight)
      : []
  }, [designs, pack, appType])

  const floorPlans = useMemo(() => {
    if (!pack.generated && pack.unit_plans.length) {
      return pack.unit_plans.filter(p=>!p.suitable_for_unit_model)
        .map((unit_plan, index) => {
          const package_assets = rooms.find(
            (item) => item.room_classification.id === (pickedRoom || rooms[0].id),
          ).package_assets
          return {
            ...unit_plan,
            assets: unit_plan.assets
              .filter((asset) =>
                pickedRoom > -1 && asset.room_classification ? asset.room_classification.id === pickedRoom : true,
              )
              .map((rawPhotoAsset, index) => {
                const photoAsset = objectCopy(rawPhotoAsset)
                photoAsset.type = isAgentApp(appType) ? 'core' : photoAsset.is_core_item ? 'core' : 'add_on'
                const package_asset =
                  package_assets.find((asset) => {
                    return asset.identifier === photoAsset.asset.identifier && asset.type === photoAsset.type
                  }) || {}
                return {
                  ...photoAsset,
                  package_asset,
                }
              })
              .filter((asset) => asset.package_asset.id)
              .map((asset, index) => ({ ...asset, index })),
          }
        })
        .sort(smartSort('unit_type_level.floor_number'))
    } else {
      return []
    }
  }, [pack, rooms, pickedRoom, appType])

  // useEffect(() => {
  //   if (photos && photos.length && !pickedRoom) {
  //     setActivePhoto(photos[0])
  //     setPickedRoom(photos[0].room)
  //   }
  // }, [photos, pickedRoom])

  const pickedAssets = useMemo(() => {
    return rooms
      .filter((room) => room.room_classification.id > 0)
      .map((room) => room.package_assets.filter((asset) => asset.type === 'core' || asset.added))
      .flat()
  }, [rooms])

  const { updatePackageAsset } = usePackages()

  const availableScreens = useMemo(() => {
    return Object.values(PreviewScreens).filter((screen) => !screen.available || screen.available(pack, appData))
  }, [pack, appData])

  // useEffect(() => {
  //   if (pickedRoom && carousel && !carousel.destroyed && pickedRoom >= 0) {
  //     if (photos[carousel.realIndex].room !== pickedRoom) {
  //       const photoIndex = photos.findIndex((photo) => photo.room === pickedRoom)
  //       if (photoIndex > -1) {
  //         carousel.slideToLoop(photoIndex, 400)
  //       } else {
  //         carousel.slideToLoop(0, 400)
  //       }
  //     }
  //   }
  // }, [pickedRoom, carousel, photos])

  function onVideoStateChange(state) {
    if(state.isPlaying){
      rpevent(eventDictionary.gallery_screen_video_play_tapped, group5(pack, getFullRoom(pickedRoom)))
    }
    if(!state.isPlaying){
      rpevent(eventDictionary.gallery_screen_video_pause_tapped, group5(pack, getFullRoom(pickedRoom)))
    }
    setVideoState({
      playing: state.isPlaying,
    })
  }

  function onDotHovered(asset){
    rpevent(eventDictionary.gallery_screen_furniture_dot_hovered, group6(pack, getFullRoom(pickedRoom), asset.package_asset))
  }

  const content = useMemo(() => {
    return { ...(floorPlans.length ? { plans: floorPlans } : {}), photos, videos: pack.videos }
  }, [floorPlans, pack, photos])

  const slides = useMemo(() => {
    function renderContent([type, data]) {
      if (type === 'plans') {
        return data.map((plan) => ({
          ...plan,
          level: plan.unit_type_level,
          content: renderFloorPlanSlide(plan, pack, activeSlide && activeSlide.id === plan.id, zoomed, onDotHovered),
          type: 'plans',
        }))
      }
      if (type === 'videos') {
        return data.map((video) => ({
          ...video,
          level: video.unit_type && video.unit_type.unit_type_levels[0],
          content: renderVideosSlide(video, activeSlide && activeSlide.id, onVideoStateChange),
          type: 'videos',
        }))
      }
      if (type === 'photos') {
        return data.map((photo) => {
          return {
            content: renderPhotoSlide(photo, pack, activeSlide && activeSlide.id === photo.id, zoomed, onDotHovered),
            type: 'photos',
            ...photo,
          }
        })
      }
      return []
    }

    return Object.entries(content)
      .map(renderContent)
      .flat()
      .map((item, index) => ({
        ...item,
        index,
      }))
  }, [content, pack, activeSlide, zoomed])

  useEffect(() => {
    if (!activeSlide) {
      setActiveSlide(slides[0])
    }
  }, [slides, activeSlide])

  const dotsLabel = useMemo(() => {
    if (!activeSlide) {
      return ''
    }
    switch (activeSlide.type) {
      case PreviewScreens.photos.id:
        return activeSlide.room_classification.classification_name
      default:
        return null
    }
  }, [activeSlide])

  // useEffect(()=>{
  //   if(pickedRoom && activeSlide){
  //     if(pickedRoom !== activeSlide.room){
  //       const slideIndex = slides.findIndex(s=>s.room === pickedRoom)
  //       carousel.slideTo(slideIndex)
  //     }
  //   }
  // }, [pickedRoom, activeSlide, carousel, slides])

  function onContentTypeViewed(type){
    switch (type) {
      case 'plans':
        rpevent(eventDictionary.gallery_screen_floor_plan_viewed, group5(pack, getFullRoom(pickedRoom)))
        return
      case 'photos':
        rpevent(eventDictionary.gallery_screen_photo_viewed, group5(pack, getFullRoom(pickedRoom)))
        return
      case 'videos':
        rpevent(eventDictionary.gallery_screen_video_viewed, group5(pack, getFullRoom(pickedRoom)))
        return
      default:
        break;
    }
  }

  const screen = (
    <>
      <div className="relative h-full w-full">
        {carousel && activeSlide && !videoState.playing && (
          <ContentIndicator activeSlide={activeSlide} dotsLabel={dotsLabel} slides={slides} carousel={carousel} />
        )}
        <Carousel
          zoom
          centeredSlides
          className={`custom-navigation-slider ${!videoState.playing ? '' : 'no-arrows'}`}
          navigation={photos.length > 1}
          onSwiper={(swiper) => {
            setCarousel(swiper)
            onContentTypeViewed(slides[swiper.activeIndex].type)
          }}
          spaceBetween={0}
          slidesPerView={'auto'}
          // loop
          onSlideChange={(swiper) => {
            if (isMobile) {
              setHoveredAsset(null)
            }
            setActiveSlide(slides[swiper.activeIndex])
            onContentTypeViewed(slides[swiper.activeIndex].type)
          }}
          // loop={Object.values(content).flat().length > 2}
          items={slides.map((s, index) => s.content)}
          onZoomChange={(swiper) => {
            setZoomed(!zoomed)
          }}
          onNavigationNext={()=>{
            rpevent(eventDictionary.gallery_screen_right_nav_tapped, group5(pack, getFullRoom(pickedRoom)))
          }}
          onNavigationPrev={()=>{
            rpevent(eventDictionary.gallery_screen_left_nav_tapped, group5(pack, getFullRoom(pickedRoom)))
          }}
        />
        <ZoomControls
          className="hidden lg:flex absolute bottom-[24px] right-[24px] z-[500]"
          zoomIn={() => {
            if (activeSlide.type === 'plans') {
              rpevent(eventDictionary.gallery_screen_floor_plan_zoom_in_tapped, group5(pack, getFullRoom(pickedRoom)))
            }
            if (activeSlide.type === 'photos') {
              rpevent(eventDictionary.gallery_screen_photo_zoom_in_tapped, group5(pack, getFullRoom(pickedRoom)))
            }
          
            carousel.zoom.in()
          }}
          zoomOut={() => {
            if (activeSlide.type === 'plans') {
              rpevent(eventDictionary.gallery_screen_floor_plan_zoom_out_tapped, group5(pack, getFullRoom(pickedRoom)))
            }
            if (activeSlide.type === 'photos') {
              rpevent(eventDictionary.gallery_screen_photo_zoom_out_tapped, group5(pack, getFullRoom(pickedRoom)))
            }
            carousel.zoom.out()
          }}
        />
      </div>
    </>
  )

  useEffect(() => {
    if (activeSlide) {
      if (activeSlide.type === 'plans' || activeSlide.type === 'photos') {
        setActivePhoto(activeSlide)
      }
    }
  }, [activeSlide])

  function onScreenSelectedAnalyticsEvent(screen){
    switch (screen) {
      case 'plans':
        rpevent(eventDictionary.gallery_screen_floor_plans_tapped, group5(pack, getFullRoom(pickedRoom)))
        return
      case 'photos':
        rpevent(eventDictionary.gallery_screen_photos_tapped, group5(pack, getFullRoom(pickedRoom)))
        return
      case 'videos':
        rpevent(eventDictionary.gallery_screen_video_tapped, group5(pack, getFullRoom(pickedRoom)))
        return
      default:
        break;
    }
  }
  
  function onScreenSelected(id) {
    onScreenSelectedAnalyticsEvent(id)
    const slideIndex = slides.findIndex((s) => s.type === id)
    carousel.slideTo(slideIndex)
    setPreviewScreen(id)
    // if (id === PreviewScreens.photos.id) {
    //   setPickedRoom(photos[0].room)
    // } else {
    //   setPickedRoom(-1)
    // }
  }

  useEffect(() => {
    if (activeSlide) {
      if (activeSlide.type === PreviewScreens.photos.id) {
        setPickedRoom(activeSlide.room)
      } else {
        setPickedRoom(-1)
      }
    }
  }, [activeSlide, previewScreen])

  const packagePriceLabel = useMemo(() => {
    if (pickedAssets.length === 0) {
      return <span className="text-[#5F6787]">No items selected yet</span>
    }
    if (pickedAssets.every((asset) => asset.type === 'core')) {
      return `${!isAgentApp(appType) ? 'Starting at ' : ''}$${pack.price.toLocaleString('en-US')}${
        pack.vendor.is_rental ? '/mo' : ''
      }`
    } else {
      return `${pickedAssets.reduce((acc, val) => (val.selectedCount || val.count) + acc, 0)} items for $${(
        pack.price +
        priceSum(
          pickedAssets
            .filter((asset) => asset.added)
            .map((asset) => asset.price * (asset.selectedCount || asset.count)),
        )
      ).toLocaleString('en-US')}${pack.vendor.is_rental ? '/mo' : ''}`
    }
  }, [pickedAssets, pack, appType])

  const isMobile = useMediaQuery('(max-width: 1023px)')

  const assetPopup = useMemo(() => {
    if (activePhoto) {
      const photo = slides.find(({ id }) => id === activePhoto.id)
      if (pack.generated) {
        return false
      }
      const photoAsset =
        hoveredAsset &&
        photo.assets.find((asset) => {
          return (
            asset.asset.identifier === hoveredAsset.identifier &&
            asset.x_axis <= 100 &&
            asset.y_axis <= 100 &&
            (asset.type ? asset.type === hoveredAsset.type : true)
          )
        })
      if (hoveredAsset && !photoAsset) {
        return hoveredAsset.id
      } else {
        return null
      }
    }
    // if (previewScreen === PreviewScreens.floorPlans.id) {
    //   if (hoveredAsset) {
    //     const planAsset = pack.unit_plan.assets.find((asset) => asset.asset.identifier === hoveredAsset.identifier)
    //     if (!planAsset || planAsset.x_axis > 100 || planAsset.y_axis > 100) {
    //       return hoveredAsset.id
    //     } else {
    //       return null
    //     }
    //   }
    // }
  }, [activePhoto, hoveredAsset, pack, slides])

  useEffect(() => {
    logDevelopment({ pack })
  }, [pack])



  useEffect(() => {
    if (previewScreen === PreviewScreens.photos.id) {
      if (hoveredAsset && activePhoto) {
        const assetOnCurrentImage = activePhoto.assets.find(
          ({ package_asset, x_axis, y_axis, type }) =>
            package_asset.identifier === hoveredAsset.identifier &&
            x_axis <= 100 &&
            y_axis <= 100 &&
            type === hoveredAsset.type,
        )

        if (assetOnCurrentImage && assetOnCurrentImage.x_axis <= 100 && assetOnCurrentImage.y_axis <= 100) {
        } else {
          logDevelopment(`No asset found on current image`)
          const nearestPhoto = photos.find((photo) =>
            photo.assets.find(
              ({ package_asset, x_axis, y_axis, type }) =>
                package_asset.identifier === hoveredAsset.identifier &&
                x_axis <= 100 &&
                y_axis <= 100 &&
                type === hoveredAsset.type &&
                photo.room === pickedRoom,
            ),
          )

          if (nearestPhoto) {
            const nearestPhotoSlideIndex = slides.findIndex((s) => s.id === nearestPhoto.id)
            if (nearestPhotoSlideIndex > -1) {
              carousel.slideTo(nearestPhotoSlideIndex, 400)
            }
          }
        }
      }
    }
  }, [hoveredAsset, activePhoto, photos, carousel, slides, pickedRoom, previewScreen])

  function onRoomPicked(room) {
  
    setPickedRoom(room)
    rpevent(eventDictionary.gallery_screen_room_selected, group5(pack, getFullRoom(room)))
    if (activeSlide.type === 'plans') {
      return
    }
    const slideIndex = slides.findIndex((s) => s.type === activeSlide.type && s.room === room)
    carousel.slideTo(slideIndex)
  }

  const { logo } = usePropertyLogo()

  const {sendOnLoad} = useAnalytics()


  sendOnLoad(()=>{
    rpevent(eventDictionary.gallery_screen_package_view, group5(pack, getFullRoom(pickedRoom)))
  })

  const handlersAssetEvents = {
    onAssetAdd: (asset) => {
      rpevent(eventDictionary.gallery_screen_asset_added, group6(pack, getFullRoom(pickedRoom), asset))
    },
    onAssetRemove: (asset) => {
      rpevent(eventDictionary.gallery_screen_asset_removed, group6(pack, getFullRoom(pickedRoom), asset))
    },
    onMinusCountAssetClick: (asset) => {
      rpevent(eventDictionary.gallery_screen_asset_quantity_minus, group6(pack, getFullRoom(pickedRoom), asset))
    },
    onPlusCountAssetClick: (asset) => {
      rpevent(eventDictionary.gallery_screen_asset_quantity_plus, group6(pack, getFullRoom(pickedRoom), asset))
    },
  }


  return (
    <div className="h-full w-full flex flex-col overflow-y-auto">
      <div className="bg-[#FAFAFA] flex-1 relative ">
        <>
          {!videoState.playing && (
            <div
              className={`w-full absolute z-[300] left-[0px] top-[16px] ${
                logo ? 'lg:top-[24px]' : 'lg:top-[32px]'
              } flex justify-between items-center px-[16px] lg:px-[24px] text-[14px]`}
            >
              <div className="md:w-1/3 hidden md:block h-[36px]">
                <button
                  type="button"
                  onClick={() => {
                    rpevent(eventDictionary.gallery_screen_back_tapped, group5(pack, getFullRoom(pickedRoom)))
                    navigate(-1)
                  }}
                  className="flex items-center uppercase text-black h-[36px] rounded-full px-[12px] text-[10px] font-semibold bg-white back-btn"
                >
                  <ArrowBack /> <span className="ml-[8px]">Back</span>
                </button>
              </div>
              {activeSlide && !isAgentApp(appType) && (
                <div className="flex items-center w-full md:w-1/3 justify-center">
                  <>
                    {availableScreens.map(({ label, id, disabled }) => (
                      <button
                        type="button"
                        key={id}
                        onClick={() => (!disabled ? onScreenSelected(id) : null)}
                        className={`${
                          activeSlide.type === id
                            ? 'bg-[black] border-[#000] text-white cursor-default pointer-events-none'
                            : ' text-white bg-[rgba(0,0,0,.15)] border-none'
                        } w-ful min-w-max lg:w-auto border-[1px]  h-[44px] lg:h-[32px] ml-[16px] first:ml-0 px-[16px] rounded-full flex items-center justify-center transition-all  leading-[22px]`}
                      >
                        {label}
                      </button>
                    ))}
                  </>
                </div>
              )}
              <div className="md:w-1/3 hidden md:block"></div>
            </div>
          )}
        </>
        {/* <ScreenPicker className='hidden lg:flex' screens={availableScreens} onSelect={onScreenSelected} selected={previewScreen} /> */}
        <div
          className="h-full relative z-[100] transition-all"
          style={{ maxHeight: `calc(100vh - ${toggled ? `322px` : `88px`})` }}
        >
          {screen}
        </div>
      </div>
      {isMobile && !isAgentApp(appType) && (
        <MobileBottomSheet
          packagePriceLabel={packagePriceLabel}
          pack={pack}
          assets={assets}
          updatePackageAsset={updatePackageAsset}
          pickedRoom={pickedRoom}
          {...handlersAssetEvents}
        />
      )}
      {(!isMobile || isAgentApp(appType)) && (
        <div className="shrink-0 disable-child-select hidden md:block">
          <div
            className="bg-[#fff]  relative z-[500] lg:z-[150] h-[56px] flex items-center justify-between px-[24px] border-t-[1px] border-solid box-content border-[#C7CCD9]"
            onClick={() => {
              if(!toggled){
                rpevent(eventDictionary.gallery_screen_expand_tapped, group5(pack, getFullRoom(pickedRoom)))
              }
              if(toggled){
                rpevent(eventDictionary.gallery_screen_collapse_tapped, group5(pack, getFullRoom(pickedRoom)))
              }
              setToggled(!toggled)
            }}
          >
            <div className="flex items-center">
              <div className="flex items-center">
                <>
                  <div className={toggled ? '' : 'rotate-180'}>
                    <ArrowDown />
                  </div>
                  <div className="bg-[#C7CCD9] h-[30px] w-[1px] mx-[16px]"></div>
                </>
                <span className="mr-[16px] leading-[22px] text-[18px]">
                  {pack.display_name || pack.name}
                  {!isAgentApp(appType) ? ` by ${pack.vendor.full_name}` : ''}
                </span>
              </div>
              {pack.description && !isAgentApp(appType) && (
                <PackageInfo text={pack.description} extra={pack.package_configuration} />
              )}
            </div>
            <div className="flex items-center">
              {!isAgentApp(appType) && <PurchaseTypeTag purchaseType={getPurchaseType(pack.vendor.is_rental)} />}
              <span className="ml-[10px] leading-[22px] text-[18px]">{packagePriceLabel}</span>
              {pack.vendor.supports_checkout && !isAgentApp(appType) && (
                <CheckoutButton onClick={()=>{
                  rpevent(eventDictionary.gallery_screen_cart_button_tapped, group5(pack, getFullRoom(pickedRoom)))
                }} className="h-[33px]" pack={pack} />
              )}
              {isAgentApp(appType) && <SelectPackage pack={pack} />}
            </div>
          </div>
          <div
            className={`${toggled ? 'h-[235px]' : 'h-[0px]'} transition-all overflow-hidden ${
              isAgentApp(appType) ? 'bg-white' : 'bg-[#FAFAFA]'
            } border-[1px] border-solid border-[rgba(199,204,217,0.3)]`}
          >
            <div className="border-t-[1px] flex items-start border-solid border-[rgba(199,204,217,0.4)] w-full p-[25px] pr-[0] ">
              <RoomsSlider pack={pack} rooms={rooms} onRoomPicked={onRoomPicked} pickedRoom={pickedRoom} />
            
              <div className="ml-[46px] w-[calc(100%-154px)]">
                <PackageAssetsSlider
                  pack={pack}
                  summaryMode={pickedRoom === -1}
                  assets={assets}
                  assetPopup={assetPopup}
                  onAssetHover={(asset) => {
                    if(asset){
                      rpevent(eventDictionary.gallery_screen_furniture_card_hovered, group6(pack, getFullRoom(pickedRoom), asset) )
                    }
                    setHoveredAsset(asset ? { ...asset, card: true } : null)
                  }}
                  hoveredAsset={hoveredAsset}
                  onAssetUpdate={(asset, fields) => updatePackageAsset(pack, pickedRoom, asset, fields)}
                  onAssetAdd={(asset) => {
                    updatePackageAsset(pack, pickedRoom, asset)
                    handlersAssetEvents.onAssetAdd(asset)
                  }}
                  onAssetRemove={(asset) => {
                    updatePackageAsset(pack, pickedRoom, asset)
                    handlersAssetEvents.onAssetRemove(asset)
                  }}
                  onMinusCountAssetClick={handlersAssetEvents.onMinusCountAssetClick}
                  onPlusCountAssetClick={handlersAssetEvents.onPlusCountAssetClick}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PreviewScreen
