import { residentAppHost } from 'CRM/screens/Apartments/helpers'
import { getAuth } from './auth'
import { getDummySystemUserToken } from 'CRM/services/user'

export async function generateInstantAppLink({ user, unit, token }) {
  // const {data} = await getDummySystemUserToken()
  // const { data } = await getInvitesForProspect(user.id)
  // const [invite] = data.prospects_invitations
  // if (invite) {
  //   await deleteInvite(invite.unit.id, user.id)
  // }
  // await upsertInvite({
  //   invitation_status: 'accepted',
  //   unit_id: Number(unit.id),
  //   // prospect_full_name: `${user.first_name} ${user.last_name}`,
  //   prospect_email: user.email,
  //   // phone_number: user.phone,
  //   user_id: user.id,
  //   // last_sent_date: '',
  //   // invitation_status: '',
  //   agent_id: user.id,
  // })
  return `http://${residentAppHost()}.sparx.ai?instant_unit_id=${
    unit.id
  }&app_type=instant&flow=packages&token=${token}`
}
