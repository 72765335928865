import React from 'react'
import { isCRMApp, isProductionBuild } from 'shared/helpers/general'

export const EFeatures = {
  INVITES: 'INVITES',
  FA: 'FURNITURE_AS_AMENITY',
}

//==========================================================================
// Invites feature
//==========================================================================
function getInvitesFeatureAvailability(data) {
  if (isCRMApp()) {
    if (!data || !data.property) {
      return false
    }

    const { id, properties_configuration_options } = data.property

    return (
      id === 3000018 || (properties_configuration_options && properties_configuration_options.approves_prospect_app)
    )
  } else {
    return true
  }
}

//==========================================================================
// Furniture as amenities feature
//==========================================================================
function getFurnitureAsAmenitiesFeatureAvailability(data) {
  if (!data || !data.property) {
    return false
  }
  const { properties_configuration_options } = data.property

  return !isProductionBuild() || (properties_configuration_options && properties_configuration_options.supports_furniture_as_amenity)
}

export function isFeatureAvailable(feature, data) {
  if (feature === EFeatures.INVITES) {
    return getInvitesFeatureAvailability(data)
  }
  if (feature === EFeatures.FA) {
    return getFurnitureAsAmenitiesFeatureAvailability(data)
  }
}

function FeatureGuard({ feature, data, children }) {
  return isFeatureAvailable(feature, data) ? children : null
}

export default FeatureGuard
