import { appEnvPrefix } from './general'

export const appS3Bucket = {
  bucket: 'sparx-cdn',
  region: 'us-east-2',
}

export function getS3PathPrefix() {
  return process.env.REACT_APP_CDN_HOST || `https://storage.googleapis.com/sparx-cdn` 
}

export function getCdnAssetUrl(path) {
  const reg = new RegExp('^(?:[a-z+]+:)?//', 'i')
  // Check for absolute URL and convert this to relative
  if (reg.test(path)) {
    const url = new URL(path)
    return `${getS3PathPrefix()}${url.pathname}`
  } else {
    // Generate absolute CDN url for relative path
    return `${getS3PathPrefix()}/${path}`
  }
}
