import { PackageAssetsSlider } from 'Resident/components/PackageRow/components/AssetsSlider'
import { PackageItem } from 'Resident/components/PackageRow/components/PackageItem'
import { getPurchaseType } from 'Resident/helpers/general'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BottomSheet from 'shared/components/BottomSheet'
import PurchaseTypeTag from 'shared/components/PurchaseTypeTag'
import { ReactComponent as CheckoutMobile } from 'shared/assets/icons/checkout-resident-mobile.svg'
import { ReactComponent as ArrowRightProductIcon } from 'shared/assets/icons/resident-arrow-right-card-product.svg'
import { objectCopy } from 'shared/helpers/general'
import { useStore } from 'Resident/store'
import { isMinimalApp } from 'Resident/helpers/app'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { rpevent } from 'shared/analytics'
import { group6 } from 'shared/analytics/resident'

function PackageCard({ item, pack, onAdd, onRemove, onAssetUpdate }) {
  const name = (item.category || {}).category_name || (item.asset || {}).asset_name
  return (
    <div className="flex">
      <PackageItem
        minimal
        big
        rent={pack && pack.vendor.is_rental}
        item={item}
        onAdd={() => {
          onAdd(item)
        }}
        onRemove={() => {
          onRemove(item)
        }}
        onUpdate={(fields) => {
          onAssetUpdate(item, fields)
        }}
      />
      <div className="px-[24px] flex flex-col justify-between w-full">
        <div>
          <a
            target="_blank"
            href={item.asset && item.asset.affiliation_link ? item.asset.affiliation_link : '-'}
            onClick={(e) => {
              if (item.asset && !item.asset.affiliation_link) {
                e.preventDefault()
              }
            }}
            rel="noreferrer"
          >
            <div>{name}</div>
            <div className="mt-[8px] leading-[14px] flex items-center text-[14px] text-[#5F6787]">
              By <span className="uppercase">{pack.vendor.full_name}</span>
              {item.asset && item.asset.affiliation_link && (
                <span className="mt-[-2px] ml-[6px]">
                  <ArrowRightProductIcon />
                </span>
              )}
            </div>
          </a>
        </div>
        <div className="w-full">
          {item.asset && (
            <div className="text-[12px] text-[#5F6787]">
              {' '}
              W{item.asset.width} × D{item.asset.depth} × H{item.asset.height}
            </div>
          )}
          <div className="h-[1px] bg-[#E5E7ED] w-full my-[14px]"></div>
          <div className="flex items-center">
            <PurchaseTypeTag purchaseType={getPurchaseType(pack.vendor.is_rental)} />
            {item.type === 'core' && (
              <div className="text-[14px] ml-[8px] font-medium text-[#A93DCB]">Base Package</div>
            )}
            {item.price && (
              <span className="text-[14px] ml-[8px]">
                {`${item.added && item.type !== 'core' ? '' : '+'}`} ${item.price.toLocaleString('en-US')}
                {`${pack.vendor.is_rental ? '/mo' : ''}`}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export function MobileBottomSheet({
  pack,
  assets,
  updatePackageAsset,
  pickedRoom,
  packagePriceLabel,
  onAssetAdd,
  onAssetRemove,
  onMinusCountAssetClick,
  onPlusCountAssetClick,
}) {
  const [mobileSheetToggled, setMobileSheetToggled] = useState(false)
  const { hoveredAsset, setHoveredAsset, appType } = useStore()
  const navigate = useNavigate()

  function onAssetPick(item) {
    setHoveredAsset(item)
  }

  function hasPickedAsset() {
    return !!hoveredAsset
  }

  useEffect(() => {
    if (hoveredAsset) {
      setMobileSheetToggled(true)
    }
  }, [hoveredAsset])

  const pickedAsset = hasPickedAsset() ? assets.find((asset) => asset.id === hoveredAsset.id) : null

  return (
    <BottomSheet
      className={`${mobileSheetToggled ? 'max-h-[500px]' : 'max-h-[250px]'}`}
      onBack={
        hasPickedAsset()
          ? () => {
              setHoveredAsset(null)
            }
          : null
      }
      toggled={mobileSheetToggled}
      onToggle={() => setMobileSheetToggled(!mobileSheetToggled)}
    >
      {!hasPickedAsset() && (
        <div
          className={`flex items-center justify-between ${mobileSheetToggled ? 'mb-[22px]' : 'mb-[13px]'}`}
          onClick={() => {
            setMobileSheetToggled(true)
          }}
        >
          <div>
            <span className="leading-[22px] text-[18px]">
              {pack.display_name || pack.name} by {pack.vendor.full_name}
            </span>
            <div className="flex items-center">
              <PurchaseTypeTag purchaseType={getPurchaseType(pack.vendor.is_rental)} />
              <span className="ml-[10px] leading-[22px] text-[13px] text-[#5F6787]">{packagePriceLabel}</span>
            </div>
          </div>
          {pack.vendor.supports_checkout && !isMinimalApp(appType) && (
            <button
              type="button"
              onClick={() => {
                navigate('/checkout', { state: { packageId: pack.id } })
              }}
            >
              <CheckoutMobile />
            </button>
          )}
        </div>
      )}
      {mobileSheetToggled && !hasPickedAsset() && (
        <div>
          <PackageAssetsSlider
            pack={pack}
            navigation={false}
            assets={assets}
            onAssetAdd={(asset) => {
              updatePackageAsset(pack, pickedRoom, asset)
              onAssetAdd && onAssetAdd(asset)
            }}
            onAssetRemove={(asset) => {
              updatePackageAsset(pack, pickedRoom, asset)
              onAssetRemove && onAssetRemove(asset)
            }}
            onAssetUpdate={(asset, fields) => updatePackageAsset(pack, pickedRoom, asset, fields)}
            onMinusCountAssetClick={(asset) => {
              onMinusCountAssetClick && onMinusCountAssetClick(asset)
            }}
            onPlusCountAssetClick={(asset) => {
              onPlusCountAssetClick && onPlusCountAssetClick(asset)
            }}
            onClick={onAssetPick}
          />
          <div className={`#5F6787 text-[12px] ${pack.generated ? 'mt-[16px]' : ''}`}>
            *Additional items may exist in other rooms
          </div>
        </div>
      )}
      {hasPickedAsset() ? (
        <div>
          <PackageCard
            onAdd={(asset) => updatePackageAsset(pack, pickedRoom, asset)}
            onRemove={(asset) => updatePackageAsset(pack, pickedRoom, asset)}
            onAssetUpdate={(asset, fields) => updatePackageAsset(pack, pickedRoom, asset, fields)}
            pack={pack}
            item={pickedAsset}
          />
        </div>
      ) : null}
    </BottomSheet>
  )
}
