import { ApolloClient, gql, InMemoryCache } from '@apollo/client'
import { getAuth } from 'CRM/helpers/auth'
import { filterPropertiesData } from 'MediaManager/helpers/general'
import { properties_configuration_options } from 'shared/gql'
import { apiHost } from 'shared/helpers/api'
import { objectCopy } from 'shared/helpers/general'
import { mutate, query } from 'shared/helpers/graphql'
import { unitType } from 'shared/services/tour.service'

export async function getPropertyById(propertyId, configurationOptionsFields = []) {
  const configuration_options =
    configurationOptionsFields.length > 0
      ? `properties_configuration_options { ${configurationOptionsFields.join(` `)} }`
      : `${properties_configuration_options}`

  console.log({ configuration_options })
  const client = new ApolloClient({
    uri: `${apiHost}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      Authorization: `Bearer ${getAuth()}`,
    },
  })
  const { data } = await client.query({
    variables: {
      propertyId,
    },
    query: gql`
      query Query($propertyId: Int!) {
        properties_by_id(id: $propertyId) {
          property_name
          street
          city
          state
          country
          number_of_units
          listing_option
          number_of_buildings
          class
          website
          zip
          phone_number
          external_properties {
            vendor_id
            internal_property_id
            external_property_id
            account_id
          }
          photos {
            src
            display_name
            id
          }
          long_name
          get_lat
          get_long
          logo_path
          property_portrait_image
          property_landscape_image
          leasing_office_main_email
          city
          design_profiles {
            design_profile {
              id
              name
              plural_name
              icon_path
            }
          }
          real_estate_company {
            id
            company_name
            state
            email
            company_logo
            company_name
            phone_number
            website
          }
          ${configuration_options}
          unit_types {
            ${unitType}
          }
          details_json
          id
          date_modified
          tour_in_background_ttl
          assets {
            id
            uuid
            asset_name
            display_name
            thumbnail
            sku
            description
            width
            depth
            height
            color

            vendor {
              id
            }
            variant_id
            web_page
            listings {
              price
            }
          }

          vendors {
            id
            logo_path
            description
            full_name
            display_name
            logo_path_color
            logo_path_black
            logo_path_white
            branding
            is_rental
            is_sparx
            website_link
          }
          agents {
            is_default_agent
            title
            user {
              id
              first_name
              last_name
              email
            }
          }
        }
      }
    `,
  })
  return {
    data: {
      properties_by_id: filterPropertiesData([data.properties_by_id])[0],
    },
  }
}

export async function getUnitType(unitTypeId) {
  return await query({
    variables: {
      filters: {
        unit_types: {
          ids: unitTypeId,
        },
      },
    },
    query: gql`
    query Unit_types($filters: all_unit_types_filters) {
      unit_types(filters: $filters) {
        ${unitType}
           
      }
    }
     
    `,
  })
}

export async function upsertPropertyConfiguration(values) {
  return mutate(
    {
      propertiesConfigurationOptions: [values],
    },
    gql`
      mutation Upsert_properties_configuration_options(
        $propertiesConfigurationOptions: [properties_configuration_options_input]
      ) {
        upsert_properties_configuration_options(properties_configuration_options: $propertiesConfigurationOptions) {
          id
          is_active
        }
      }
    `,
  )
}

export async function getPropertyConfigurationOptionsFields(client) {
  const { data } = await query(gql`
    query {
      __type(name: "properties_configuration_options") {
        fields {
          name
          type {
            name
            kind
          }
        }
      }
    }
  `)
  return data.__type.fields
}

export async function upsertProperty(values) {
  return mutate(
    {
      properties: [values],
    },
    gql`
      mutation Upsert_properties($properties: [properties_input]) {
        upsert_properties(properties: $properties) {
          id
        }
      }
    `,
  )
}

export async function createExternalMapping(payload) {
  return mutate(
    {
      externalUnits: [payload],
    },
    gql`
      mutation Create_external_properties($externalUnits: [external_properties_input]) {
        create_external_properties(external_units: $externalUnits) {
          external_property_id
          internal_property_id
        }
      }
    `,
  )
}

export async function deleteExternalMapping(payload) {
  return mutate(
    payload,
    gql`
      mutation Delete_external_properties($internalPropertyId: Int!, $externalPropertyId: String!, $vendorId: Int!) {
        delete_external_properties(
          internal_property_id: $internalPropertyId
          external_property_id: $externalPropertyId
          vendor_id: $vendorId
        ) {
          account_id
        }
      }
    `,
  )
}
