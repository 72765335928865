import React, { useState, useRef, useEffect, useMemo } from 'react'
import Draggable from 'react-draggable'
import Button from 'shared/components/Button'
import Select from 'shared/components/Select'
import styles from './styles.module.scss'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import Toggle from 'shared/components/Toggle'
import { objectCopy } from 'shared/helpers/general'

export function ImageAssetsEditor({ formValues, image, photo, addedAssets, showRooms = false, coreAddonModeSupport = false, ...props }) {
  const dotSize = 20
  const imageRef = useRef()
  const [assets, setAssets] = useState([])
  const [assetsList, setAssetsList] = useState([])
  const [previewUrl, setPreviewUrl] = useState(null)
  const [hoveredAsset, setHoveredAsset] = useState(null)
  const [isCoreMode, setIsCoreMode] = useState(false)
  
  useEffect(() => {
    if (image) {
      const objectUrl = URL.createObjectURL(image)
      setPreviewUrl(objectUrl)
      return () => URL.revokeObjectURL(objectUrl)
    }
    if(photo){
      setPreviewUrl(getCdnAssetUrl(photo.src))
    }
  }, [image, photo])

  useEffect(() => {
    if (props.assets) {
      setAssetsList(props.assets)
      if (!assets.length) {
        setAssets(addedAssets)
      }
    }
  }, [props.assets, addedAssets, assets])

  function onDragStop(event, data, index) {
    const { x, y } = data
    const assetsCopy = JSON.parse(JSON.stringify(assets))
    const { offsetHeight, offsetWidth } = imageRef.current
    assetsCopy[index].current = {
      x,
      y,
      topPercent: Math.round((100 * y) / offsetHeight),
      leftPercent: Math.round((100 * x) / offsetWidth),
    }
    setAssets(assetsCopy)
  }

  function addItem(asset, assetIndex) {
    function getYPos() {
      const lastAsset = assets.length && assets[assets.length - 1]

      if (assetIndex) {
        return imageRef.current.offsetHeight / 2 - assetIndex * 10
      }

      if (lastAsset && lastAsset.current.y) {
        return lastAsset.current.y - 20
      } else {
        return imageRef.current.offsetHeight / 2
      }
    }

    const x = imageRef.current.offsetWidth / 2
    const y = getYPos()
    const assetData = props.assets.find(({ id }) => asset.value === id)
    const assetToAdd = {
      ...assetData,
      isNew: true,
      id: asset.value,
      is_core_item: isCoreMode,
      name: asset.originalLabel || asset.label,
      current: {
        y,
        x,
        topPercent: Math.ceil((100 * (y - dotSize)) / imageRef.current.offsetHeight),
        leftPercent: Math.ceil((100 * (x - dotSize)) / imageRef.current.offsetWidth),
      },
    }

    setAssets((assets) => [
      ...assets,
      ...[
        assetToAdd
      ],
    ])

    const assetListCopy = JSON.parse(JSON.stringify(assetsList))
    const index = assetIndex || assetListCopy.findIndex(({ id }) => id === asset.value)
    assetListCopy.splice(index, 1)
    setAssetsList(assetListCopy)
  }

  function showItemMenu(e, asset, index) {
    e.preventDefault()
    let assetsCopy = JSON.parse(JSON.stringify(assets))
    assetsCopy = assetsCopy.map(({ menu, ...asset }) => asset)
    assetsCopy[index].menu = true
    setAssets(assetsCopy)
  }

  function onSave() {
    props.onSave(assets.map(({ isNew, ...asset }) => asset))
  }

  const availableAssets = useMemo(() => {
    return assetsList.filter(({ id, count }) => assets.filter((asset) => asset.id === id).length !== count)
  }, [assets, assetsList])

  const assetDropdownOptions = useMemo(() => {
    const result = objectCopy(availableAssets)
      .filter(({ id, count }) => assets.filter((asset) => asset.id === id).length !== count)
      .map((asset) => ({ value: asset.id, originalLabel: asset.name, label: `${asset.name}${showRooms ? ` - ${asset.room_classification_name}` : ''}` }))
    return result
  }, [availableAssets, assets, showRooms])

  function addAllAssets() {
    availableAssets.forEach((asset, index) => {
      addSingleItem({ value: asset.id, label: asset.name }, index)
    })

    setAssetsList([])
  }

  function deleteAsset(asset, index) {
    // if (window.confirm(`Are you sure you wish to delete ${asset.name} from the image?`)) {}
    const assetsCopy = JSON.parse(JSON.stringify(assets))
    assetsCopy.splice(index, 1)
    setAssets(assetsCopy)
    const assetsListCopy = JSON.parse(JSON.stringify(assetsList))
    if (!assetsListCopy.find(({ id }) => asset.id === id)) {
      assetsListCopy.push(asset)
      setAssetsList(assetsListCopy)
    }
  }

  function onClose() {
    // if (assets.length > 0) {
    //   if (window.confirm('Are you sure you want to close assets editor ?')) {
    //     props.onClose()
    //   }
    // } else {
    //   props.onClose()
    // }
    props.onClose()
  }

  function addSingleItem(val, itemIndex) {
    const asset = props.assets.find(({ id }) => val.value === id)
    const count = asset.count - assets.filter(({ id }) => id === asset.id).length
    Array(count)
      .fill(val)
      .map((item, index) => addItem(item, itemIndex || index))
  }

  function setHovereAsset(asset) {
    setHoveredAsset(asset)
  }

  function isAssetHovered(index) {
    return hoveredAsset === index
  }

  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.toggle('freeze')
    return function () {
      document.getElementsByTagName('body')[0].classList.toggle('freeze')
    }
  }, [])

  function getHorizontalPosition({ x }) {
    if(!imageRef || !imageRef.current){
      return
    }
    return Math.ceil((100 * (x - dotSize)) / imageRef.current.offsetWidth) > 70 ? { right: 40 } : { left: 40 }
  }

  function hideAssetsMenu() {
    let assetsCopy = JSON.parse(JSON.stringify(assets))
    assetsCopy = assetsCopy.map(({ menu, ...asset }) => asset)
    setAssets(assetsCopy)
  }

  const ref = useDetectClickOutside({
    onTriggered: () => {
      hideAssetsMenu()
    },
  })

  function onAssetCoreItemChange(asset, index) {
    const assetsCopy = objectCopy(assets)
    assetsCopy[index].is_core_item = !assetsCopy[index].is_core_item
    setAssets(assetsCopy)
  }

  const {clientWidth, clientHeight} = imageRef && imageRef.current ? imageRef.current : {clientWidth: 0, clientHeight: 0}
  

  const [photoRendered, setPhotoRendered] = useState(false)

  

  return (
    <div
      className={`fixed ${styles.modal} top-0 left-0 bg-black/30 w-screen h-screen z-[100] flex items-center justify-center overflow-y-auto`}
    >
      <div className={`bg-white p-[32px] rounded-xl overflow-y-auto overflow-x-hidden min-w-[800px]`}>
        <div className="flex z-[200] relative">
          <Select
            isDisabled={assetDropdownOptions.length === 0}
            options={assetDropdownOptions}
            onChange={(val) => addSingleItem(val)}
            className="flex-1"
          />
          {coreAddonModeSupport && <div className='border-solid border-[#CCCCCC] border-[1px] rounded-[4px] ml-[16px] px-3 flex items-center'>
            <span>Core item</span>
            <Toggle checked={isCoreMode} onChange={()=>{setIsCoreMode(!isCoreMode)}}/>
          </div>}
          <Button disabled={assetDropdownOptions.length === 0} onClick={addAllAssets} rounded className={`ml-[16px]`}>
            Add all items
          </Button>
        </div>
        <div className="relative w-full h-full parent mt-[16px]">
          {assets.map((asset, index) => {
            const { current } = asset
            return (
              photoRendered && <Draggable
                defaultPosition={{
                  ...current,
                  x: current.x  ? current.x : clientWidth * current.leftPercent / 100,
                  y: current.y  ? current.y : clientHeight * current.topPercent / 100,
                }}
                position={{
                  ...current,
                  x: current.x ? current.x : clientWidth * current.leftPercent / 100,
                  y: current.y ? current.y : clientHeight * current.topPercent / 100,
                }}
                bounds=".image"
                scale={1}
                onStop={(e, data) => {
                  onDragStop(e, data, index)
                }}
              >
                <div
                  className={`h-[20px] w-[20px] rounded-full ${
                    asset.isNew && addedAssets.length > 0 ? 'border-[orange]' : 'border-[rgba(255,255,255, .7)]'
                  } rounded-full bg-[rgba(0,0,0,.3)] group shadow-[0px_2px_6px_rgba(0,0,0,0.5)] absolute border-solid border-[1px]  flex items-center justify-center group ${
                    isAssetHovered(asset) ? 'z-[100]' : 'z-[10]'
                  }`}
                  style={{ height: `${dotSize}px`, width: `${dotSize}px` }}
                  onContextMenu={(e) => showItemMenu(e, asset, index)}
                >
                  <div
                    className={`group-hover:w-[8px] group-hover:h-[8px] ${'h-[4px] w-[4px]'} ${
                      asset.isNew && addedAssets.length > 0 ? 'bg-[orange]' : 'bg-[#fff]'
                    } rounded-full`}
                  ></div>
                  <div
                    onMouseEnter={() => setHovereAsset(index)}
                    onMouseLeave={() => setHovereAsset(null)}
                    className="h-[20px] w-[20px] top-[-1px] absolute left-[-1px] rounded-full"
                  ></div>
                  {!asset.menu && isAssetHovered(index) && (
                    <>
                      <div
                        style={getHorizontalPosition(current)}
                        className="absolute cursor-default top-[65px] w-max bg-white py-1 px-3 rounded-full border-[1px] border-solid border-black text-[14px] z-[50]"
                      >
                        {asset.name}{showRooms ? ` - ${asset.room_classification_name}` : ''}
                      </div>
                      <div
                        style={getHorizontalPosition(current)}
                        className="flex justify-center absolute w-max top-[-50px] z-[100]"
                      >
                        <img
                          className="h-[100px] w-[100px] rounded-xl mt-2 border-[1px] border-solid border-black"
                          alt={asset.name}
                          src={getCdnAssetUrl(asset.thumbnail)}
                        />
                      </div>
                    </>
                  )}

                  {asset.menu && (
                    <div
                      ref={ref}
                      style={getHorizontalPosition(current)}
                      className={`absolute bg-white rounded-xl overflow-hidden top-[-10px]`}
                    >
                      <div
                        className="px-5 py-2 hover:bg-[orange] cursor-pointer w-full"
                        onClick={() => {
                          deleteAsset(asset, index)
                        }}
                      >
                        Delete
                      </div>
                      {coreAddonModeSupport && <div className="px-5 py-2 w-max cursor-default">
                        <div className="flex items-center">
                          <Toggle
                            checked={asset.is_core_item}
                            onChange={() => {
                              onAssetCoreItemChange(asset, index)
                            }}
                          />
                          <span className="ml-3"> Core item</span>
                        </div>
                      </div>}
                    </div>
                  )}
                </div>
              </Draggable>
            )
          })}

          {previewUrl && <img alt="sample_image" ref={imageRef} src={previewUrl} onLoad={()=>{setPhotoRendered(true)}} className="image h-[600px]" />}
          <div className="mt-[16px] flex justify-end">
            <div className="flex">
              <Button size="small" rounded variant="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button size="small" rounded variant="primary" className="ml-2" onClick={onSave}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageAssetsEditor
