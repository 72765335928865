export const feedbackUrl = 'mailto:info@sparx.ai'
export const sparxUrl = 'https://sparx.ai/'
export const eversetBrand = 'The Everset'

export const errorMessages = {
  required: 'Please fill this field',
  wrongEmail: 'Wrong email. Try again.',
  wrongPassword: 'Wrong password. Try again or click Forgot password to reset it.',
  invalidPassword: 'Invalid password, include 8 digits contains numbers and letters lorem ipsum dolor sit amat.'
}
