import React, { useMemo, useState, useEffect } from 'react'
import { ReactComponent as BuildingIcon } from 'shared/assets/icons/building-crm.svg'
import { ReactComponent as UserIcon } from 'shared/assets/icons/user-crm.svg'
import { ReactComponent as ArrowDown } from 'shared/assets/icons/arrow-down-dropdown-property.svg'
import { ReactComponent as ArrowDownHeader } from 'shared/assets/icons/arrow-down-header.svg'
import { ReactComponent as SettingsIcon } from 'shared/assets/icons/settings-crm.svg'
import { ReactComponent as LogoutIcon } from 'shared/assets/icons/logout-crm.svg'
import { ReactComponent as BurgerMobile } from 'shared/assets/icons/mobile-burger-crm.svg'
import { ReactComponent as ResidentAppIcon } from 'shared/assets/icons/resident-app-icon.svg'
import { ReactComponent as SupportIcon } from 'shared/assets/icons/support.svg'
import { ReactComponent as DashboardIcon } from 'shared/assets/icons/dashboard.svg'
import { ReactComponent as MenuIcon } from 'shared/assets/icons/menu.svg'

import { useDetectClickOutside } from 'react-detect-click-outside'
import sparxLogo from 'shared/assets/images/sparx-logo.png'
import { Link, useNavigate } from '@tanstack/react-location'
import { clearAuth } from 'CRM/helpers/auth'
import { useStore } from 'CRM/store'
import MobileMenu from './MobileMenu'
import { EUserType } from 'shared/enums/roles'
import { smartSort } from 'shared/helpers/arrays'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import FeatureGuard, { EFeatures } from 'shared/components/FeatureGuard'

function PropertyDropdown({ property, properties, className = '', onPicked = () => {} }) {
  const [toggled, setToggled] = useState(false)

  const ref = useDetectClickOutside({
    onTriggered: () => {
      setToggled(false)
    },
  })

  const filteredProperties = useMemo(() => {
    return properties
      .filter(({ property_name }) => property_name !== property.property_name)
      .filter(({ unit_types }) => unit_types.some(({ unit_types_configuration_options }) => unit_types_configuration_options.ready_for_publish))
  }, [properties, property])

  function hasMultipleProperties() {
    return properties.length > 1
  }

  return (
    <>
      <div
        className={`${
          hasMultipleProperties() ? 'cursor-pointer' : ''
        } relative flex items-center justify-between select-none ${className}`}
        onClick={() => {
          setToggled(!toggled)
        }}
        ref={ref}
      >
        <div className="flex">
          <BuildingIcon />
          <span className="ml-[10px]">{property.property_name}</span>
        </div>
        {hasMultipleProperties() && (
          <div className="ml-[10px]">
            <ArrowDown />
          </div>
        )}
        {toggled && hasMultipleProperties() && (
          <div className="absolute z-[100] top-[30px] left-0 w-[300px] bg-white  shadow-[0px_0px_12px_rgba(0,0,0,0.05)] rounded-lg max-h-[264px] overflow-y-auto">
            {filteredProperties.sort(smartSort('property_name')).map((property) => (
              <button
                type="button"
                className="bg-transparent inline-block hover:bg-[rgba(0,0,0,.1)] text-left truncate  border-none w-full font-semibold h-[44px]  px-4 !justify-start !text-black"
                onClick={() => {
                  setToggled(false)
                  onPicked(property)
                }}
              >
                {property.property_name}
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

function UserSection({ className = '', onLogout }) {
  const { user } = useStore()
  const [toggled, setToggled] = useState(false)
  const navigate = useNavigate()

  function toggle() {
    setToggled(!toggled)
  }

  const ref = useDetectClickOutside({
    onTriggered: () => {
      setToggled(false)
    },
  })

  return (
    <div
      className={`h-[43px] bg-[rgba(229,231,237,.5)] px-[18px] rounded-full flex items-center relative select-none ${className}`}
      ref={ref}
    >
      <div className="flex items-center cursor-pointer" onClick={toggle}>
        <UserIcon />
        <span className="ml-[12px]">
          {user.first_name} {user.last_name}
        </span>
        <div className="ml-[10px] mt-[3px]">
          <ArrowDownHeader />
        </div>
      </div>
      {toggled && (
        <div
          className="bg-[#FAFAFA] min-w-full absolute right-0 top-[calc(100%+8px)] rounded-[6px] py-[20px]"
          onClick={toggle}
        >
          <Link to="/settings" className="flex items-center px-[20px]">
            <SettingsIcon />
            <span className="ml-[10px]">Settings</span>
          </Link>
          <a href="mailto:support@sparx.ai" className="flex items-center px-[20px] mt-[20px]">
            <SupportIcon />
            <span className="ml-[10px]">Support</span>
          </a>
          <a href="!#" onClick={onLogout} className="flex items-center px-[20px] mt-[20px]">
            <LogoutIcon />
            <span className="ml-[10px]">Logout</span>
          </a>
        </div>
      )}
    </div>
  )
}

function Header({ children, ...props }) {
  const { selectedProperty: property, properties, user, setSelectedProperty, setProperties } = useStore()
  const [mobileMenuToggled, setMobileMenuToggled] = useState(false)
  const navigate = useNavigate()

  const logo = useMemo(() => {
    if (property && property.unit_types.length) {
      const companyLogo = property.unit_types[0].property.real_estate_company.company_logo
      return companyLogo ? getCdnAssetUrl(companyLogo) : sparxLogo
    }
    return null
  }, [property])

  function getDashboardURL() {
    const userType = user.user_type.user_type
    if (userType === EUserType.AGENT) {
      return `https://lookerstudio.google.com/u/0/reporting/89e06a29-9444-49c7-821b-2311bd6b6998/page/p_i3vprzvz5c?params=%7B%22df65%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${user.first_name}%20${user.last_name}%20@%20${property.property_name}%22%7D`
    } else {
      return `https://lookerstudio.google.com/u/0/reporting/92cddd99-491e-487b-a8b4-a643893d2071/page/p_i3vprzvz5c?params=%7B%22df102%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${property.property_name}%22%7D`
    }
  }

  function logout(e) {
    e.preventDefault()
    clearAuth()
    window.location.reload()
  }

  return (
    <nav
      className={`h-[72px] px-[24px] lg:px-[33px] flex items-center justify-between ${
        mobileMenuToggled ? '' : 'border-solid border-[#C7CCD9] border-b-[1px]'
      }  box-border bg-white`}
    >
      <div className="flex items-center">
        <Link to="/apartments" className="flex-shrink-0 w-max">
          <img src={logo} alt="logo" className={`${logo ? '' : 'hidden'} h-[32px] lg:h-[34px]`} />
        </Link>
        <div className="ml-[35px] hidden lg:block">
          {property && (
            <PropertyDropdown
              onPicked={(property) => {
                setSelectedProperty(property)
                navigate({ to: '/apartments' })
              }}
              property={property}
              properties={properties}
            />
          )}
        </div>
      </div>
      {property && user && (
        <div className="lg:hidden">
          <button
            type="button"
            className="h-[30px]"
            onClick={() => {
              setMobileMenuToggled(!mobileMenuToggled)
            }}
          >
            <MenuIcon/>
          </button>
        </div>
      )}
      <div className="items-center hidden lg:flex">
        <FeatureGuard data={{property}} feature={EFeatures.INVITES}>
          <div>
            {property && user && (
              <Link to="/resident-invites">
                <div className="flex items-center mr-[40px]" rel="noreferrer">
                  <ResidentAppIcon />
                  <span className="ml-[10px]">Residents</span>
                </div>
              </Link>
            )}
          </div>
        </FeatureGuard>
        <div>
          {property && user && (
            <a href={getDashboardURL()} target="_blank" className="flex items-center" rel="noreferrer">
              <DashboardIcon />
              <span className="ml-[10px]">Dashboard</span>
            </a>
          )}
        </div>
        {user && <UserSection onLogout={logout} className="ml-[24px]" />}
      </div>
      {property && user && mobileMenuToggled && (
        <MobileMenu
          onLogout={logout}
          user={user}
          logo={logo}
          dashboardUrl={getDashboardURL()}
          onClose={() => {
            setMobileMenuToggled(false)
          }}
          property={property}
          properties={properties}
          onPropertySelected={(property) => {
            navigate({ to: '/apartments' })
            setSelectedProperty(property)
            setMobileMenuToggled(false)
          }}
        />
      )}
    </nav>
  )
}

export default Header
