import { RPScreens } from 'Resident/constants/appType'
import { useStore } from 'Resident/store'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { group5 } from 'shared/analytics/resident'
import { sortByWeight } from 'shared/helpers/arrays'

export function RoomsSlider({ onRoomPicked, pickedRoom, rooms, enabledRooms, pack }) {
  const {currentScreen} = useStore()
  function getPickedAssetsForRoom(room) {
    const assets = room.package_assets.filter((asset) => asset.type === 'core' || asset.added)
    return assets.reduce((acc, val) => (val.selectedCount || val.count) + acc, 0)
  }

  function isDisabled(room) {
    return enabledRooms && !enabledRooms.find((r) => r.room_classification.id === room.room_classification.id)
  }

  return (
    <div className="w-[160px] shrink-0 max-h-[182px] overflow-hidden">
      <div className="w-[175px] max-h-[182px] overflow-y-auto">
        {rooms.sort(sortByWeight).map((item, index) => (
          <button
            key={item.room_classification.id}
            onClick={() => {
              if(currentScreen === RPScreens.DP){
                rpevent(eventDictionary.dp_screen_room_selected, group5(pack, item))
              }
              onRoomPicked(item.room_classification.id)
            }}
            disabled={isDisabled(item)}
            className={`border-t-[1px] border-[rgba(199,204,217,0.3)] border-solid first:border-none py-[10px] first:pt-[0] flex items-center pr-[13px] ${
              isDisabled(item) ? 'opacity-20' : ''
            } truncate w-full ${
              item.room_classification.id === pickedRoom
                ? 'text-black cursor-pointer pointer-events-none'
                : 'text-[#5F6787] opacity-60'
            }`}
          >
            <span
              className="w-full truncate text-left"
              title={`${item.room_classification.classification_name} (${getPickedAssetsForRoom(item)})`}
            >
              {item.room_classification.classification_name} ({getPickedAssetsForRoom(item)})
            </span>
          </button>
        ))}
      </div>
    </div>
  )
}
