import { useRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { ReactComponent as CloseIcon } from 'shared/assets/icons/close.svg'

export default function Modal({ children, toggleModal, showCloseBtn = false, closeAnywhere = false, className = '', plain = false }) {
  const ref = useRef()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.getElementById('modal')
    setMounted(true)
  }, [])

  const handleClose = (e) => {
    if (closeAnywhere && Array.from(e.target.classList).includes('modal')) {
      toggleModal(false)
    }
  }

  const modal = (
    <div
      className={`modal flex fixed justify-center items-center bg-white z-50 inset-0 ${className}`}
      aria-hidden
      onClick={handleClose}
    >
      {plain ? (
        children
      ) : (
        <div className="flex h-full flex-col relative overflow-x-hidden">
          {showCloseBtn && (
            <CloseIcon
              className="absolute top-[20px] right-[10px] cursor-pointer z-10 p-[10px] w-[40px] h-[40px]"
              onClick={() => toggleModal(false)}
              data-test-id="close-modal-btn"
            />
          )}
          {children}
        </div>
      )}
    </div>
  )

  return mounted ? createPortal(modal, ref.current) : null
}
