import * as React from 'react'
const SvgComponent = (props) => (
  <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 5.5H5.5C6.29565 5.5 7.05871 5.18393 7.62132 4.62132C8.18393 4.05871 8.5 3.29565 8.5 2.5C8.5 2.10218 8.65804 1.72064 8.93934 1.43934C9.22064 1.15804 9.60218 1 10 1H19C19.3978 1 19.7794 1.15804 20.0607 1.43934C20.342 1.72064 20.5 2.10218 20.5 2.5C20.5 3.29565 20.8161 4.05871 21.3787 4.62132C21.9413 5.18393 22.7044 5.5 23.5 5.5H25C25.7956 5.5 26.5587 5.81607 27.1213 6.37868C27.6839 6.94129 28 7.70435 28 8.5V22C28 22.7956 27.6839 23.5587 27.1213 24.1213C26.5587 24.6839 25.7956 25 25 25H4C3.20435 25 2.44129 24.6839 1.87868 24.1213C1.31607 23.5587 1 22.7956 1 22V8.5C1 7.70435 1.31607 6.94129 1.87868 6.37868C2.44129 5.81607 3.20435 5.5 4 5.5Z"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 14.5C10 15.6935 10.4741 16.8381 11.318 17.682C12.1619 18.5259 13.3065 19 14.5 19C15.6935 19 16.8381 18.5259 17.682 17.682C18.5259 16.8381 19 15.6935 19 14.5C19 13.3065 18.5259 12.1619 17.682 11.318C16.8381 10.4741 15.6935 10 14.5 10C13.3065 10 12.1619 10.4741 11.318 11.318C10.4741 12.1619 10 13.3065 10 14.5Z"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
export default SvgComponent
