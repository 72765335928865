import AuthLayout from 'Resident/layouts/AuthLayout'
import FooterLayout from 'Resident/layouts/FooterLayout'
import React, { useEffect, useState } from 'react'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
// Icons
import { ReactComponent as SuccessIcon } from 'shared/assets/icons/resident-password-set-success.svg'

import ErrorMessage from 'shared/components/ErrorMessage'

import { useStore } from 'Resident/store'
import { upsertUser } from 'Resident/services/user'
import { useSplashError } from 'shared/components/ErrorSplash'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { group3 } from 'shared/analytics/resident'

const EStages = {
  REVIEW_PROFILE: 'REVIEW_PROFILE',
  DONE: 'DONE',
}

function ReviewProfileScreen(props) {
  const { appData } = useStore()
  const [loading, setLoading] = useState(false)
  const splashError = useSplashError()
  const navigate = useNavigate()
  const [stage, setStage] = useState(EStages.REVIEW_PROFILE)

  useEffect(()=>{
    if(appData && appData.user) {
      rpevent(eventDictionary.review_profile_launched, group3)
    }
  }, [appData])

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
  })

  const formik = useFormik({
    initialValues: {
      firstName: appData.user.first_name,
      lastName: appData.user.last_name,
      phoneNumber: appData.user.phone_number,
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema,
    // validateOnMount:true,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        await upsertUser({
          id: appData.user.id,
          last_name: values.lastName,
          first_name: values.firstName,
          phone_number: values.phoneNumber,
          profile_confirmed: true,
        })
        rpevent(eventDictionary.review_profile_successful, group3)
        setStage(EStages.DONE)
      } catch (error) {
        splashError.show()
      } finally {
        setLoading(false)
      }
    },
  })

  function isStage(target) {
    return stage === target
  }

  function onContinue() {
    navigate('/packages')
  }

  return (
    <FooterLayout>
      <AuthLayout back={false} help={false}>
        {appData && (
          <>
            {isStage(EStages.REVIEW_PROFILE) && (
              <div className="h-full flex flex-col items-center w-full">
                <div className="font-medium text-center  leading-[21px] my-[30px]">
                  Please review your profile details and
                  <br /> make sure we got everything!
                </div>
                <form className="w-full flex flex-col justify-between flex-1" onSubmit={formik.handleSubmit}>
                  <div>
                    {appData.unit && (
                      <div className="flex items-center font-medium leading-[18px]">
                        <span className="text-[#5F6787]">Unit</span>
                        <span className="ml-[10px]">#{appData.unit.name}</span>
                      </div>
                    )}
                    <div className="flex items-center font-medium mt-[14px] leading-[18px]">
                      <span className="text-[#5F6787]">Email</span>
                      <span className="ml-[10px]">{appData.user.email}</span>
                    </div>
                    <div className="h-[1px] bg-[#C7CCD9] w-full opacity-80 my-[20px]"></div>
                    <div className="grid grid-cols-2 gap-[16px]">
                      <div>
                        <Input
                          onChange={formik.handleChange}
                          onBlur={() => formik.validateField('firstName')}
                          value={formik.values.firstName}
                          required
                          error={formik.errors.firstName}
                          name="firstName"
                          placeholder="Last name"
                        />
                        <ErrorMessage text={formik.errors.firstName} />
                      </div>
                      <div>
                        <Input
                          onChange={formik.handleChange}
                          onBlur={() => formik.validateField('lastName')}
                          value={formik.values.lastName}
                          required
                          error={formik.errors.lastName}
                          name="lastName"
                          placeholder="First name"
                        />
                        <ErrorMessage text={formik.errors.lastName} />
                      </div>
                      <div className="col-span-2">
                        <Input
                          onChange={formik.handleChange}
                          onBlur={() => formik.validateField('phoneNumber')}
                          value={formik.values.phoneNumber}
                          error={formik.errors.phoneNumber}
                          name="phoneNumber"
                          placeholder="Phone Number..."
                        />
                        <ErrorMessage text={formik.errors.phoneNumber} />
                      </div>
                    </div>
                  </div>
                  <div className="lg:mt-[110px]">
                    <Button loading={loading} disabled={loading} rounded type="submit" className="w-full mt-[16px]">
                      Continue
                    </Button>
                  </div>
                </form>
              </div>
            )}
            {isStage(EStages.DONE) && (
              <div className="h-full flex flex-col items-center justify-between w-full text-center">
                <div></div>
                <div className="flex flex-col justify-center items-center">
                  <SuccessIcon />
                  <div className="mt-[28px] text-[18px] font-semibold">All set!</div>
                  <div className="text-[18px]">Profile confirmed</div>
                </div>
                <div className="w-full">
                  <Button className="w-full mt-[16px]" rounded onClick={onContinue}>
                    Continue
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </AuthLayout>
    </FooterLayout>
  )
}

export default ReviewProfileScreen
