import React, { useEffect, useState } from 'react'
import ContentGuard from '../ContentGuard'
import { useStore } from 'Resident/store'
import { getUnitTypeWithPackages } from 'Resident/services/packages'
import { getProspect } from 'Resident/services/user'
import Loader from 'shared/components/Loader'
import DataLoadError from '../DataLoadError'
import { useSplashError } from 'shared/components/ErrorSplash'
import { isAgentApp, isMinimalApp, isStandartApp } from 'Resident/helpers/app'
import { startHotJarRecording } from 'shared/analytics/hotjar'

function Error({ onRetry }) {
  return (
    <div className="h-full w-full justify-center items-center flex">
      <DataLoadError onRetry={onRetry} />
    </div>
  )
}

function PackagesContentGuard({ children }) {
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const { appData, packages, setPackages, appType } = useStore()
  const splash = useSplashError()
  const [error, setError] = useState(false)
  useEffect(() => {
    if (!loading) {
      if (!packages) {
        ;(async () => {
          try {
            setLoading(true)
            const packages = await getUnitTypeWithPackages(appData.unit.unit_type.id)
            const user = await getProspect(appData.user.id)
            try {
              const prospect = isStandartApp(appType) ? user.data.user_by_id.prospects[0] : { packages: [] }
              const [unitType] = packages.data.unit_types
              //==========================================================================
              // Enrich categories
              //==========================================================================
              const packs = unitType.packages.map((p) => ({
                ...p,
                room_classifications: p.room_classifications.map((r) => ({
                  ...r,
                  package_assets: r.package_assets.map((a) => ({
                    ...a,
                    category: a.category || a.asset.category,
                  })),
                })),
              }))
              //==========================================================================
              // Enrich categories
              //==========================================================================
              let finalPackages = [...packs]
                .map((pack) => {
                  //==========================================================================
                  // Filter out rooms without packages
                  //==========================================================================
                  return {
                    ...pack,
                    unit_type: unitType,
                    room_classifications: pack.room_classifications.filter(
                      ({ package_assets }) => package_assets.length > 0,
                    ),
                  }
                })
                .map((pack) => {
                  //==========================================================================
                  // Map addons
                  //==========================================================================
                  const prospectPackage = prospect.packages.find((a) => a.package.id === pack.id)
                  const addons = prospectPackage ? prospectPackage.addons : []
                  return {
                    ...pack,
                    unit_plans: pack.unit_plans.map(p=>({
                      ...p,
                      assets: p.assets ? p.assets.map(a=>({
                        ...a,
                        is_core_item: a.is_core_item || false,
                        asset: {
                          ...a.asset,
                          identifier: isAgentApp(appType) ? a.asset.id : (a.asset.category || {}).id,
                        },
                        identifier: isAgentApp(appType) ? a.asset.id : (a.asset.category || {}).id,
                      })) : null
                    })),
                    room_designs: pack.room_designs.map(d=>({
                      room_design: {
                        ...d.room_design,
                        photos: d.room_design.photos.map(p=>({
                          ...p,
                          assets: p.assets.map(a=>({
                            ...a,
                            is_core_item: a.is_core_item || false,
                            asset: {
                              ...a.asset,
                              identifier: isAgentApp(appType) ? a.asset.id : (a.asset.category || {}).id,
                            },
                            identifier: isAgentApp(appType) ? a.asset.id : (a.asset.category || {}).id,
                          }))
                        }))
                      }
                    })),
                    room_classifications: pack.room_classifications.map((room) => ({
                      ...room,
                      package_assets: room.package_assets.map((asset) => {
                        const addon = addons.find((addon) => addon.package_asset.id === asset.id)

                        return {
                          ...asset,
                          identifier: isAgentApp(appType) ? (asset.asset || {}).id : (asset.category || {}).id,
                          room_classification_id: room.room_classification.id,
                          added: !!addon,
                          selectedCount: addon ? addon.quantity : asset.count,
                        }
                      }),
                    })),
                  }
                })
              //==========================================================================
              // Filter only CORT packages in minimal version or for users with @cort.com email
              //==========================================================================
              if (isMinimalApp(appType) || appData.user.email.includes('@cort.com')) {
                finalPackages = finalPackages.filter((p) => p.vendor.full_name.toLowerCase().includes('cort'))
              }
              setPackages(finalPackages)
              setLoaded(true)
              startHotJarRecording()
            } catch (error) {
              console.error({ error })
              splash.show(() => {}, {
                actions: [
                  {
                    label: 'Reload',
                    action: () => {
                      window.location.reload()
                    },
                  },
                ],
              })
            }
          } catch (error) {
            setError(true)
          }
        })()
      } else {
        setLoaded(true)
      }
    }
  }, [packages, appData, setPackages, loading, splash, appType])

  return (
    <ContentGuard>
      {loaded || error ? (
        error ? (
          <Error
            onRetry={() => {
              window.location.reload()
            }}
          />
        ) : (
          children
        )
      ) : (
        <div className="h-full w-full flex justify-center items-center">
          <Loader />
        </div>
      )}
    </ContentGuard>
  )
}

export default PackagesContentGuard
