import React from 'react'
import { ReactComponent as ArrowRightDotsPopupIcon } from 'shared/assets/icons/arrowRightDotsPopup.svg'
import { ReactComponent as NoImageIcon } from 'shared/assets/icons/noImagePopup.svg'
import { ReactComponent as NoImageIconFound } from 'shared/assets/icons/noImagePopupFound.svg'
import { events, posttour } from 'shared/analytics'
import { purchaseType } from 'shared/helpers/general'
import { useProperty } from 'TourApp/hooks/useProperty'
import { showPrices } from 'shared/helpers/gallery'

function Divider({ className = '' }) {
  return <div className={`bg-[#E5E7ED] h-[1px] ml-[-17px] mr-[-17px] ${className}`}></div>
}

function AssetPopover({ onMouseEnter = () => {}, onMouseLeave = () => {}, layout, point, asset = {}, ...props }) {
  function isRent() {
    return purchaseType(layout.main_vendor.is_rental) === 'rent'
  }

  function produceExtraEventData({ galleryState }) {
    const { pickedDesignLayout, pickedRoom, pickedImage } = galleryState
    // Group_5
    return {
      physical_room_id: pickedRoom?.id,
      physical_room_name: pickedRoom?.classification,
      design_id: pickedImage?.designLayoutId,
      design_name: pickedImage?.designLayoutName,
      vendor_id: pickedDesignLayout?.main_vendor?.id,
      vendor_name: pickedDesignLayout?.main_vendor?.full_name,
      image_id: pickedImage?.id, // Group_6
      asset_id: point.id, // Group_7
      asset_name: point.asset_name, // Group_7
      product_link: point.web_page,
      affiliation_link: point.affiliation_link,
    }
  }

  function onAssetNameHover() {
    if (point.is_favourite) {
      posttour(events.dgihph, produceExtraEventData)
    } else {
      posttour(events.dgidph, produceExtraEventData)
    }
  }

  function getPrice() {
    return point.listings[0]?.price
  }

  const property = useProperty()

  return (
    <div
      className="w-[237px] rounded-[8px] border-[1px] border-[#E5E7ED] border-solid bg-white p-[16px] pt-[13px] pb-0 relative hidden lg:block"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="h-full flex flex-col justify-between ">
        <div className="mb-[12px]">
          <div className="flex justify-between items-center text-[16px] leading-[20px] mb-[5px]">
            <div
              data-name="assetname"
              className="font-semibold text-left hover:underline w-full"
              onMouseEnter={onAssetNameHover}
            >
              <a
                href={point.affiliation_link || point.web_page}
                target="_blank"
                rel="noreferrer"
                className='flex items-center justify-between w-full'
                onClick={(e) => e.stopPropagation()}
              >
                {point.asset_name}
                <div className='ml-[16px]'><ArrowRightDotsPopupIcon /></div>
              </a>
            </div>
          </div>
          <div className="text-left text-[#5F6787] text-[14px] leading-[14px] font-medium">
            By <span className='uppercase'>{layout.main_vendor.full_name}</span>
          </div>
          <div className={`flex items-end ${showPrices(property) ? 'justify-between' : 'justify-end'}`}>
            {showPrices(property) && (
              <div className="text-[12px] text-[#5F6787] font-medium mt-[26px] leading-[12px]">
                W{point.width} × D{point.depth} × H{point.height}
              </div>
            )}
          </div>
        </div>
        {(!props.emptyImage || (props.emptyImage && showPrices(property))) && <Divider />}
        {!showPrices(property) && (
          <div
            className={`text-[12px]  text-[#5F6787] font-medium ${
              props.emptyImage ? 'mt-[10px]' : 'my-[10px]'
            } leading-[10px]`}
          >
            W{point.width} × D{point.depth} × H{point.height}
          </div>
        )}
        {showPrices(property) && (
          <div className={`flex items-center my-[12px] ${props.emptyImage ? 'mb-0' : ''}`}>
            <div
              className={`uppercase ${
                isRent() ? 'bg-[#AC74AA]' : 'bg-[#387EE5]'
              } text-white font-semibold rounded-full leading-[10px] w-[42px] h-[18px] text-[10px] flex items-center justify-center`}
            >
              {purchaseType(layout.main_vendor.is_rental)}
            </div>
            {getPrice() > 0 && !layout.main_vendor.is_sparx && (
              <div className="ml-[10px] font-medium text-[14px]">
                ${getPrice().toLocaleString()}{isRent() && <span>/mo</span>}
              </div>
            )}
          </div>
        )}
        {props.emptyImage && !showPrices(property) && <Divider className="mt-[12px]" />}
        {props.emptyImage && (
          <div className="text-[10px] leading-[7px]  text-[#4267EA] text-medium my-[12px]">
            {props.emptyImage && !asset.presentsOnOtherImage && (
              <div className="text-[#5F6787]">*Item not on any image</div>
            )}
            {props.emptyImage && asset.presentsOnOtherImage && (
              <button type="button" className="cursor-pointer text-[#4267EA] underline" onClick={asset.onFindClick}>
                Item on another image
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default AssetPopover
