import React, { useEffect, useState, useMemo } from 'react'
import { useMediaQuery } from '@react-hook/media-query'

import { useStore } from 'TourApp/store'
import { events, posttour } from 'shared/analytics'

import FloorPlansLayout from "shared/components/FloorPlansLayout"
import { useNavigate } from '@tanstack/react-location'
import { isApartmentApp } from 'shared/helpers/general'
import { showDesignNames, showFurnitureDetails } from 'shared/helpers/gallery'

export default function FloorPlansScreen() {
  const { selectedApartmentId, tourData } = useStore()
  const { units } = tourData
  const [unit, setUnit] = useState()
  const [currentFloor, setFloor] = useState(0)
  const [showModal, toggleModal] = useState(false)
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const navigate = useNavigate()
  const [levelIndex, setLevelIndex] = useState(0)

  function getFloorPlansProps(_, floorIndex) {
    const { vendor: currentFloorPlanVendor } = floorPlans[currentFloor]
    const props = {
      floorplan_design_id: currentFloorPlanVendor ? currentFloorPlanVendor.id : null,
      floorplan_design_name: currentFloorPlanVendor ? currentFloorPlanVendor.full_name : 'Unfurnished'
    }
    if (floorIndex !== undefined) {
      const { vendor: newFloorPlanVendor } = floorPlans[floorIndex]
      props.new_floorplan_design_id = newFloorPlanVendor ? newFloorPlanVendor.id : null
      props.new_floorplan_design_name = newFloorPlanVendor ? newFloorPlanVendor.full_name : 'Unfurnished'
    }
    return props
  }

  useEffect(() => {
    if (units && units.length && selectedApartmentId) {
      const { unit: U } = units.find(({ unit }) => unit.id === selectedApartmentId)
      if (U) setUnit(U)
    }
  }, [units, selectedApartmentId, navigate])

  const openModal = () => {
    if (isMobile) {
      toggleModal(true)
      posttour(events.fpdeo, getFloorPlansProps)
    }
  }

  const closeModal = () => {
    posttour(events.fpdec, getFloorPlansProps)
    toggleModal(false)
  }

  const levelsWithFloorPlans = useMemo(()=>{
    if(!unit){
      return []
    }
    return unit.unit_type.unit_type_levels.filter(l=>l.unit_plans.length > 0)
  }, [unit])

  const floorPlans = useMemo(() => {
    if (unit) {
      const hideDesignNames = !showDesignNames(unit.unit_type.property)
      const plans = [
        ...levelsWithFloorPlans[levelIndex].unit_plans.filter(p=>!p.vendor),
        ...levelsWithFloorPlans[levelIndex].unit_plans.filter(p=>p.vendor).map((p, i)=>({...p, index: i+1})).sort((i) => (!!i.vendor?.full_name ? 1 : -1))
      ]
      return plans
        .map((item) => ({
        ...item,
        vendor: {
          full_name: item.vendor ? hideDesignNames ? `Design ${item.index}` : item.vendor.full_name : null,
          id: item?.vendor?.id || null
        }
      })).filter(p=>!p.suitable_for_unit_model)
    }
    return []
  }, [unit, levelIndex, levelsWithFloorPlans])

  useEffect(() => {
    if(unit && floorPlans.length < 1){
      navigate({ to: '/gallery' })
    }
  }, [floorPlans, navigate, unit])
  

  function onFloorPlanChange(floorIndex) {
    setFloor(floorIndex)
    posttour(events.fpdc, () => getFloorPlansProps('', floorIndex))
  }

  const levels = useMemo(()=>{
    return levelsWithFloorPlans ? levelsWithFloorPlans : []
  }, [levelsWithFloorPlans])

  function onLevelChange(item){
    setLevelIndex(item)
    setFloor(0)
  }

  return (
    <FloorPlansLayout
      {...{
        levels,
        levelIndex,
        onLevelChange,
        unit,
        floorPlans,
        currentFloor,
        onFloorPlanChange,
        showModal,
        openModal,
        closeModal
      }}
    />
  )
}
