import { gql } from '@apollo/client'
import { query } from 'shared/helpers/graphql'
import { mutate } from 'shared/helpers/graphql'
import { unitType } from 'shared/services/tour.service'

export async function getUnitTypeWithPackages(unitTypeId) {
  return await query({
    variables: {
      filters: {
        unit_types: {
          ids: unitTypeId,
        },
      },
    },
    query: gql`
    query Unit_types($filters: all_unit_types_filters) {
      unit_types(filters: $filters) {
        units {
          id
          unit_number
        }
            ${unitType}
        
            packages {
              id
              price
              name
              package_configuration {
                disclaimer
                id
              }
              description
              display_name
              terms_and_conditions
              videos {
                id
                display_name
                src
                thumbnail
                is_official
                is_verified
                name
                timestamp
                unit_type {
                  id
                  unit_type_levels {
                    id
                    display_name
                  }
                }
              }
              unit_plans {
                suitable_for_unit_model
                unit_type_level {
                  id
                  floor_number
                  display_name
                  unit_type {
                    id
                  }
                }
                id
                src
                display_name
                vendor {
                  id
                  full_name
                }
                assets {
                  room_classification {
                    id
                  }
                  is_core_item
                  asset {
                    id
                    width
                    height
                    depth
                    category {
                      id
                      weight
                      category_name
                    }
                  }
                  x_axis
                  y_axis
                }
              }
              vendor {
                full_name
                supports_checkout
                is_rental
                id
              }
              room_classifications {
                id
                room_classification {
                  id
                  weight
                  classification_name
                }
                package_assets {
                  id
                  type
                  price
                  category {
                    id
                    weight
                    category_name
                    image_path
                    assets {
                      width
                      height
                      depth
                    }
                  }
                  count
                  asset {
                    id
                    thumbnail
                    affiliation_link
                    asset_name
                    width
                    depth
                    height
                    category {
                      id
                      weight
                      category_name
                      image_path
                      assets {
                        width
                        height
                        depth
                      }
                    }
                  }
                }
              }
              room_designs {
                room_design {
                  id
                  packages {
                    package {
                      id
                    }
                  }
                  room {
                    room_classification {
                      id
                      weight
                      classification_name
                    }
                    unit_type_level {
                      id
                      display_name
                      unit_type {
                        id
                      }
                    }
                  }
                  photos {
                    id
                    src
                    assets {
                      x_axis
                      y_axis
                      is_core_item
                      asset {
                        id
                        affiliation_link
                        width
                        depth
                        height
                        asset_name
                        category {
                          id
                          weight
                          category_name
                        }
                      }
                      package_asset {
                        id
                      }
                    }
                  }
                }
              }
            }
      }
    }
     
    `,
  })
}

export async function upsertProspectAddons(userId, prospectId, unitId, packageId, addons) {
  return mutate(
    {
      prospects: [
        {
          prospect_packages: [
            {
              package_id: packageId,
              prospect_package_addons: addons,
            },
          ],
          id: prospectId,
          user_id: userId,
          unit_id: unitId,
        },
      ],
    },
    gql`
      mutation Upsert_prospects($prospects: [prospects_input]) {
        upsert_prospects(prospects: $prospects) {
          id
        }
      }
    `,
  )
}
