// Icons
import { ReactComponent as PriceIcon } from 'shared/assets/icons/pricetag.svg'
import { ReactComponent as BedroomIcon } from 'shared/assets/icons/bedroom.svg'
import { ReactComponent as BathroomIcon } from 'shared/assets/icons/bathroom.svg'
import { ReactComponent as MeasuretoolIcon } from 'shared/assets/icons/measuretool.svg'
import { ReactComponent as UnitIcon } from 'shared/assets/icons/unit-resident.svg'
import { pluralEnds, safeConfigurationOptions } from 'shared/helpers/general'
import DetailsDropdown from 'shared/components/DetailsDropdown'

function getLineLabel(details, lineOnly) {
  if (lineOnly) {
    return `Line ${details.unit_type.name}`
  } else {
    return `#${details.unit_number}`
  }
}

export function getUnitDetailsLabel(details, unit_type, lineOnly) {
  return `${getLineLabel(details, lineOnly)}${
    unit_type.property.properties_configuration_options.building_as_property ? ` · ${details.building_name.toUpperCase()}` : ''
  }`
}

function PropertyDescriptionDetailsDropdown({
  disabled,
  active,
  onToggle,
  name,
  details = {},
  highlighted = false,
  className = '',
  plain,
  hideTooltip = false,
  hideUnit = false,
  lineOnly = false,
}) {
  const { unit_type } = details
  const items = [
    {
      icon: <BedroomIcon className="w-[20px]" />,
      text: unit_type.number_of_bedrooms
        ? `${unit_type.number_of_bedrooms} Bedroom${pluralEnds(unit_type.number_of_bedrooms)}`
        : 'Studio',
    },
    {
      icon: <BathroomIcon className="w-[20px]" />,
      text:
        unit_type.number_of_bathrooms &&
        `${unit_type.number_of_bathrooms} Bathroom${pluralEnds(unit_type.number_of_bathrooms)}`,
    },
    {
      icon: <PriceIcon className="w-[20px]" />,
      text: details.unit_type.property.properties_configuration_options.show_prices && details.price && `$${details.price.toLocaleString('en-US')}`,
    },
    (safeConfigurationOptions(details.unit_type.property.properties_configuration_options).show_unit_size ? {
      icon: <MeasuretoolIcon className="w-[20px]" />,
      text: unit_type.size && `${unit_type.size} ft sq`,
    } : null),
  ]

  const options = [
    items
      .filter(i => i)
      .filter(({ text }) => text)
      .map(({ text, icon }, i) => (
        <div className={`flex items-center w-full h-[22px] mt-[13px] ${plain ? 'mb-[13px] !mt-[0]' : ''}`} key={i}>
          <span>{icon}</span>
          <span className="ml-2 text-[14px] leading-[21px] font-normal">{text}</span>
        </div>
      )),
  ]

  return !hideUnit ? (
    <DetailsDropdown
      className={className}
      name={name}
      plain={plain}
      active={active} 
      disabled={disabled}
      onToggle={onToggle}
      highlighted
      icon={<span className='mr-[12px]'><UnitIcon/></span>}
      tooltip={!hideTooltip && getUnitDetailsLabel(details, unit_type, lineOnly)}
      title={
        // TODO: Rewrite this to JS string
        getUnitDetailsLabel(details, unit_type, lineOnly)
      }
      options={options}
    />
  ) : (
    <div className="w-full border-solid border-[rgba(199,204,217,.4)] border-[1px] rounded-[8px] px-[28px] pt-[3px] pb-[14px] bg-[#E5E7ED66]">
      {options}
    </div>
  )
}

export default PropertyDescriptionDetailsDropdown
