import React from 'react'
import { useStore } from 'CRM/store'
import Page from '../Page'

function PropertyGuard({ children }) {
  const { selectedProperty: property } = useStore()
  return property ? (
    children
  ) : (
    <Page>
      <span className="text-[24px] lg:leading-[24px] font-semibold">No apartments are available yet.</span>
    </Page>
  )
}

export default PropertyGuard
