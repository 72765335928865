import { gql } from '@apollo/client'
import { validateToken } from 'CRM/helpers/auth'
import { client, mutate, query } from 'shared/helpers/graphql'

export async function getVendors() {
  return validateToken(async () => {
    return await client().query({
      query: gql`
        query Vendors {
          vendors {
            id
            full_name
            display_name
          }
        }
      `,
    })
  })
}

export async function upsertUnitPlan(values) {
  return mutate(
    {
      unitPlans: [values],
    },
    gql`
      mutation Upsert_unit_plans($unitPlans: [unit_plans_input]) {
        upsert_unit_plans(unit_plans: $unitPlans) {
          id
          src
        }
      }
    `,
  )
}

export async function upsertFeaturedVideos(values) {
  return mutate(
    {
      videos: [values],
    },
    gql`
      mutation Upsert_videos($videos: [videos_input_type]) {
        upsert_videos(videos: $videos) {
          id
        }
      }
    `,
  )
}

export async function upsertPhotos(photos = []) {
  return mutate(
    {
      photos,
    },
    gql`
      mutation Mutation($photos: [photos_input_type]) {
        upsert_photos(photos: $photos) {
          id
          captured_in {
            id
          }
        }
      }
    `,
  )
}

export async function deletePhotos(photoId) {
  return mutate(
    {
      deletePhotosId: photoId,
    },
    gql`
      mutation Delete_photos($deletePhotosId: Int!) {
        delete_photos(id: $deletePhotosId) {
          id
        }
      }
    `,
  )
}

export async function deleteUnitPlans(unitPlanId) {
  return mutate(
    {
      deleteUnitPlansId: unitPlanId,
    },
    gql`
      mutation Delete_unit_plans($deleteUnitPlansId: Int!) {
        delete_unit_plans(id: $deleteUnitPlansId) {
          id
        }
      }
    `,
  )
}

export async function deleteVideos(videoId) {
  return mutate(
    {
      deleteVideosId: videoId,
    },
    gql`
      mutation Delete_videos($deleteVideosId: Int!) {
        delete_videos(id: $deleteVideosId) {
          id
        }
      }
    `,
  )
}

export async function getApartments() {
  return await query(gql`
    query Properties {
      properties {
        property_name
        id
        unit_types {
          id
          size
          size_to_display
          number_of_bathrooms
          number_of_bedrooms
        }
      }
    }
  `)
}

export async function upsertUnitTypesConfiguration(id, unitTypeId, options) {
  return mutate(
    {
      unitTypesConfigurationOptions: [
        {
          ...options,
          id,
          unit_type_id: unitTypeId,
        },
      ],
    },
    gql`
      mutation Mutation($unitTypesConfigurationOptions: [unit_types_configuration_options_input]) {
        upsert_unit_types_configuration_options(unit_types_configuration_options: $unitTypesConfigurationOptions) {
          id
        }
      }
    `,
  )
}

export async function getPhoto(photoId) {
  return await query({
    variables: {
      filters: {
        photos: {
          ids: [photoId],
        },
      },
    },
    query: gql`
      query Photos($filters: all_photos_filters) {
        photos(filters: $filters) {
          src
          id
          raw_image
          display_name
          direction
          assets {
            id
            is_core_item
            asset {
              affiliation_link
              asset_name
              thumbnail
              id
              width
              height
              display_name
              depth
            }
            x_axis
            y_axis
          }
          property {
            id
          }
          captured_in {
            id
            unit_type {
              id
              property {
                id
              }
            }
          }
          room_design {
            main_vendor {
              id
            }
            room {
              id
              unit_type_level {
                id
              }
              room_classification {
                  weight
                  classification_name
              }
            }
            id
          }
        }
      }
    `,
  })
}

export async function getUnitPlan(unitPlanId) {
  return await query({
    variables: {
      filters: {
        unit_plans: {
          ids: [unitPlanId],
        },
      },
    },
    query: gql`
      query Unit_plans($filters: all_unit_plans_filters) {
        unit_plans(filters: $filters) {
          id
          display_name
          contains_angle
          suitable_for_unit_model
          unit_type {
            id
            property {
              id
            }
          }
          src
          unit_type_level {
            design_layouts {
              id
              main_vendor {
                id
                full_name
              }
            }
            id
            unit_type {
              id
              property {
                id
              }
            }
          }
          design_layout {
            id
            main_vendor {
              id
              full_name
            }
          }
            
          package {
            id
          }  
          assets {
            id
            is_core_item
            asset {
              affiliation_link
              asset_name
              thumbnail
              id
              width
              height
              display_name
              depth
            }
            x_axis
            y_axis
          }
        }
      }
    `,
  })
}