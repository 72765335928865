import * as React from 'react'
const SvgComponent = (props) => (
  <svg width={18} height={21} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 1.5L17 5.99996V14.9999L9 19.4998L1 14.9999V5.99996L9 1.5Z"
      stroke={props.stroke || "black"}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9 10.5L17 6" stroke={props.stroke || "black"} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 10.5V19.4999" stroke={props.stroke || "black"} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 10.5L1 6" stroke={props.stroke || "black"} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13 3.75L5 8.24996" stroke={props.stroke || "black"} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
export default SvgComponent
