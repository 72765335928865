import React, { useEffect, useState } from 'react'
import Button from 'shared/components/Button'
import DragFileUpload from 'shared/components/DragFileUpload'
import { getCdnAssetUrl } from 'shared/helpers/assets'

function ImageUploaders({ arPicker, onArImagePicked, onRawImagePicked, positionedAssets, arImage, rawImage, photo }) {
  const [arImagePreviewURL, setArImagePreviewURL] = useState(null)
  const [rawImagePreviewURL, setRawImagePreviewURL] = useState(null)

  useEffect(() => {
    if (rawImage) {
      const objectUrl = URL.createObjectURL(rawImage)
      setRawImagePreviewURL(objectUrl)
      return () => URL.revokeObjectURL(objectUrl)
    } else {
      setRawImagePreviewURL(null)
    }
  }, [rawImage])

  useEffect(() => {
    if (arImage) {
      const objectUrl = URL.createObjectURL(arImage)
      setArImagePreviewURL(objectUrl)
      return () => URL.revokeObjectURL(objectUrl)
    } else {
      setArImagePreviewURL(null)
    }
  }, [arImage])

  function onRawImagePick(files) {
    onRawImagePicked && onRawImagePicked(files[0])
  }

  function onArImagePick(files) {
    onArImagePicked && onArImagePicked(files[0])
  }

  function resetRawImage() {
    setRawImagePreviewURL(null)
    onRawImagePicked && onRawImagePicked(null)
  }

  function resetArImage() {
    setArImagePreviewURL(null)
    onArImagePicked && onArImagePicked(null)
  }

  function getRawImageLabel() {
    return arPicker ? 'raw image' : 'image'
  }


  useEffect(()=>{
    if(photo){
      if(photo.src && !arImage){
        setArImagePreviewURL(getCdnAssetUrl(photo.src))
      }
      if(photo.raw_image && !rawImage){
        setRawImagePreviewURL(getCdnAssetUrl(photo.raw_image))
      }
    }
  }, [photo, rawImage, arImage])


  return (
    <>
      {arPicker && (
        <div className="mt-5 relative">
          {!arImagePreviewURL && <DragFileUpload required hintText="Drag AR image" onPicked={onArImagePick} />}
          {arImagePreviewURL && (
            <div className="relative flex justify-center bg-[#D9D9D9]">
              <Button
                rounded
                className="p-2 px-4 absolute right-[15px] top-[15px] h-[32px] text-sm z-10"
                onClick={resetArImage}
              >
                Upload different AR image
              </Button>
              {arImagePreviewURL && (
                <div className="relative ">
                  <img className="max-w-full max-h-[600px]" alt="rawImage" src={arImagePreviewURL} />
                  {positionedAssets &&
                    positionedAssets.map(({ current, thumbnail }) => {
                      return (
                        <div
                          className="h-[20px] w-[20px] rounded-full bg-[rgba(0,0,0,.3)] group shadow-[0px_2px_6px_rgba(0,0,0,0.5)] absolute border-solid border-[1px] border-[rgba(255,255,255, .7)] flex items-center justify-center absolute group"
                          style={{
                            top: `${current.topPercent}%`,
                            left: `${current.leftPercent}%`,
                          }}
                        >
                          <div className={`h-[4px] w-[4px] bg-[#fff] rounded-full`}></div>
                          <div className="hidden group-hover:flex justify-center absolute w-max left-[40px] top-[-50px] ">
                            <img
                              className="h-[100px] w-[100px] rounded-xl mt-2 border-[1px] border-solid border-black"
                              alt={thumbnail}
                              src={getCdnAssetUrl(thumbnail)}
                            />
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <div className="mt-5 relative bg-[#D9D9D9]">
        {!rawImagePreviewURL && (
          <DragFileUpload required={!arPicker} hintText={`Drag ${getRawImageLabel()}`} onPicked={onRawImagePick} />
        )}
        {rawImagePreviewURL && (
          <div className="relative flex justify-center">
            <Button
              rounded
              className="p-2 px-4 absolute right-[15px] top-[15px] h-[32px] text-sm z-10"
              onClick={resetRawImage}
            >
              Upload different {getRawImageLabel()}
            </Button>
            {rawImagePreviewURL && <img className="max-w-full max-h-[680px]" alt="rawImage" src={rawImagePreviewURL} />}
          </div>
        )}
      </div>
    </>
  )
}

export default ImageUploaders
