import { gql } from '@apollo/client'
import { query } from 'shared/helpers/graphql'

export async function getInvitesForProspect(prospectId) {
  return await query({
    variables: {
      filters: {
        prospects: {
          ids: [prospectId],
        },
      },
    },
    query: gql`
      query Prospects_invitations($filters: all_prospects_filters) {
        prospects_invitations(filters: $filters) {
          invitation_link_ios
          id
          invitation_link_short_ios
          instruction_screen_visible
          unit {
            id
          }
        }
      }
    `,
  })
}
