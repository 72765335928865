import Carousel from 'shared/components/Carousel'
import './styles.scss'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import SliderVideo from './components/SliderVideo'
import { useState } from 'react'

export default function VideosLayout({ videoId, setVideoId, videos, background, lineMode = false }) {
  const openVideo = (id) => {
    const [video] = document.querySelectorAll(`#video_${videoId} video`)
    video.pause()
    setVideoId(id)
  }
  const [isPlaying, setPlaying] = useState(false)

  function displayName(v) {
    return (
      (v.display_name || v.name) && (
        <>
          {v.display_name || v.name.replace(/\.[^/.]+$/, '')}
          {}
        </>
      )
    )
  }

  function capturedIn(v) {
    return v.captured_in && !lineMode && <div>Captured in apartment #{v.captured_in.unit_number}</div>
  }

  function onPlay(e) {
    e.target.controls = true
    setPlaying(true)
  }
  function onPause(e) {
    setPlaying(false)
  }
  function onEnded(e) {
    setPlaying(false)
    e.target.currentTime = 0
    e.target?.webkitExitFullscreen()
  }

  return (
    <div className={`px-[24px] lg:p-0 w-full h-full overflow-x-auto ${videos.length === 1 ? 'flex items-center' : ''}`}>
      <div className={`lg:h-full flex flex-col lg:justify-center ${background || 'lg:bg-black'} w-full`}>
        {videos.map((v) => (
          <div
            className={`frameVideoWrapper group relative lg:mb-0 flex flex-col lg:flex-row lg:justify-center lg:h-[100%]
            ${videos.length > 1 ? 'mb-[24px]' : ''}
            ${v.id !== videoId ? 'lg:hidden' : ''}`}
            key={v.id}
            id={`video_${v.id}`}
          >
            <video
              className="h-auto lg:h-[100%] lg:w-full"
              controls
              controlsList="nodownload"
              poster={getCdnAssetUrl(v.thumbnail)}
              onLoadStart={(e) => {
                e.target.volume = 0.2
              }}
              onPlay={onPlay}
              onPause={onPause}
              onEnded={onEnded}
            >
              <source src={getCdnAssetUrl(v.src)} type="video/mp4"></source>
            </video>
            <div className={`mt-[10px] lg:absolute ${window.chrome ? 'lg:bottom-[100px]' : 'lg:bottom-[54px]'} lg:right-[34px] ${videos.length > 1 ? "lg:hidden" : 'lg:group-hover:block lg:hidden'}`}>
              <div className="lg:text-[24px] lg:font-bold lg:leading-[24px] lg:text-white text-[18px] font-semibold">
                {displayName(v)}
              </div>
              <div className="lg:mt-[5] lg:text-white text-[14px] text-[#3D4256]">{capturedIn(v)}</div>
            </div>
            {videos.length > 1 && (
              <div
                className={`absolute hidden lg:block ${isPlaying ? (window.chrome ? 'h-[199px]' : 'h-[104px]') : (window.chrome ? 'h-[250px]' : 'h-[200px]')} bottom-0 w-full ${
                  !isPlaying ? 'bg-gradient-to-b from-transparent to-black' : ''
                }`}
              >
                {!isPlaying && (
                  <div className="text-[#5F6787] text-[14px] lg:text-[white] pl-[40px] my-[15px]">More Videos</div>
                )}

                <div className={`flex ${isPlaying ? 'justify-end' : ''}`}>
                  {!isPlaying && (
                    <Carousel
                      className=" h-max hidden lg:block !px-[40px] flex-1"
                      slidesPerView="auto"
                      slideClassName="!w-max"
                      navigation
                      spaceBetween={20}
                      items={videos
                        .filter((v) => v.id !== videoId)
                        .map((v) => (
                          <>
                            <SliderVideo
                              key={v.id}
                              onOpenVideo={(e) => {
                                openVideo(v.id)
                                e.stopPropagation()
                              }}
                              video={v}
                            />
                          </>
                        ))}
                    />
                  )}
                  <div className={`flex-shrink-0 text-white mx-[34px] z-[100] rounded-[4px] ${isPlaying ? 'hidden lg:group-hover:block' : ''}`}>
                    <div className="text-[24px] font-bold leading-[24px]">{displayName(v)}</div>
                    <div className="mt-[5px]">{capturedIn(v)}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
