import React, { useState } from 'react'
import { useStore } from 'TourApp/store'
import { events, posttour } from 'shared/analytics'
import { Navigate } from '@tanstack/react-location'
import VideosLayout from 'shared/components/FrameVideoLayout'

export default function VideosScreen() {
  const { tourData, selectedApartmentId, isLineApp } = useStore()
  const [currentVideoDuration, setCurrentVideoDuration] = useState(null)
  const [videoPlayedOver50PercentEventSent, setVideoPlayedOver50PercentEventSent] = useState(false)
  const apartment = tourData.units.find(({ unit }) => unit.id === selectedApartmentId)
  const videos = apartment.unit.unit_type.videos.filter((v) => v.is_official && v.is_verified)

  const [videoId, setVideoId] = useState(videos[0]?.id)
  function getVideoProps() {
    const { id, src, name } = videos.find((video) => video.id === videoId)

    return {
      video_id: id,
      video_file_name: src,
      video_display_name: name,
    }
  }

  function onVideoPlay(e) {
    setCurrentVideoDuration(e.currentTarget.duration)
    setVideoPlayedOver50PercentEventSent(false)
    posttour(events.fvvs, getVideoProps)
  }

  function onVideoEnd() {
    posttour(events.fvve, getVideoProps)
  }

  function onVideoPlayProgress({ currentTime }) {
    const currentlyPlayedPercentage = Math.round((currentTime * 100) / currentVideoDuration)

    if (currentlyPlayedPercentage > 50 && !videoPlayedOver50PercentEventSent) {
      posttour(events.fvvo5p, getVideoProps)
      setVideoPlayedOver50PercentEventSent(true)
    }
  }

  return videos.length > 0 ? (
    <VideosLayout
      {...{
        videoId,
        lineMode: isLineApp,
        setVideoId,
        videos,
        apartment,
        videoEventProps: {
          onVideoPlay,
          onVideoEnd,
          onVideoPlayProgress,
          setCurrentVideoDuration,
        },
      }}
    />
  ) : (
    <Navigate to="/gallery" />
  )
}
