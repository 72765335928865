import { isStandartApp } from 'Resident/helpers/app'
import { upsertProspectAddons } from 'Resident/services/packages'
import { useStore } from 'Resident/store'
import { objectCopy } from 'shared/helpers/general'

export function usePackages() {
  const { packages, setPackages, appData, appType } = useStore()

  async function updatePackageAsset(pack, room, asset, fields) {
    const updatedPackages = objectCopy(packages)
    const packageIndex = updatedPackages.findIndex(({ id }) => id === pack.id)

    const roomIndex = updatedPackages[packageIndex].room_classifications.findIndex(
      (i) => i.room_classification.id === asset.room_classification_id,
    )

    const assetIndex = updatedPackages[packageIndex].room_classifications[roomIndex].package_assets.findIndex(
      ({ id }) => id === asset.id,
    )

    const packageUpdate = objectCopy(
      updatedPackages[packageIndex].room_classifications[roomIndex].package_assets[assetIndex],
    )

    updatedPackages[packageIndex].room_classifications[roomIndex].package_assets[assetIndex] = {
      ...packageUpdate,
      ...fields ? fields : {added: !packageUpdate.added, recentlyAdded: !packageUpdate.added, selectedCount: packageUpdate.count}
    }
    setPackages(updatedPackages)
    //==========================================================================
    // Update prospect addons
    //==========================================================================

    if(isStandartApp(appType)){
      if(!asset.generated){
        await upsertProspectAddons(
          appData.user.id,
          appData.user.prospect.id,
          appData.unit.id,
          pack.id,
          updatedPackages[packageIndex].room_classifications[roomIndex].package_assets
            .filter((a) => a.added)
            .map((a) => ({
              id: a.id,
              quantity: a.selectedCount
            })),
        )
      }
    }
  }

  return {
    updatePackageAsset,
  }
}
