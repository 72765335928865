import { useEffect, useState } from 'react'
import { Link, useLocation } from '@tanstack/react-location'
import { useNavigate } from '@tanstack/react-location'

import { useStore } from 'TourApp/store'
import { getAvailableMenuItems, navLinks, pageTitles } from 'TourApp/routes'
import { ReactComponent as CrossIcon } from 'shared/assets/icons/cross.svg'
import { ReactComponent as SidebarToggleIcon } from 'shared/assets/icons/sidebar-toggle.svg'
import { ReactComponent as PropertyIcon } from 'shared/assets/icons/property-sidebar.svg'
import sparxLogo from 'shared/assets/images/sparx-logo.png'
import { feedbackUrl, sparxUrl } from 'shared/settings'
import { events, posttour } from 'shared/analytics'
// Components
import Greeting from 'shared/components/Greeting'
import PropertyDescriptionDetailsDropdown, {
  getUnitDetailsLabel,
} from 'shared/components/PropertyDescriptionDetailsDropdown'
import PropertyTourDetailsDropdown from 'shared/components/PropertyTourDetailsDropdown'

import './styles.scss'
import { isApartmentApp, isGalleryAvailable, isPostTourApp, randomID } from 'shared/helpers/general'
import TermsModal from 'shared/components/TermsModal'
import { Fragment } from 'react'

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import UnitIcon from './icons/unit'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import { Disclaimer } from 'Resident/layouts/SidebarLayout'

import { useMediaQuery } from '@react-hook/media-query'


const PILLS_UNITS = {
  height: 40, // pill height
  topPadding: 16,
  pillItemHeight: 35, // item height including top padding
  paddings: 18, // top, bottom padding and bottom border for active pill
  maxVisibleItems: 3,
}

export const ESidebarState = {
  NORMAL: 'normal',
  MINIMIZED: 'minimized',
}

const baseHeight = (PILLS_UNITS.height + PILLS_UNITS.topPadding) * PILLS_UNITS.maxVisibleItems

export function IconButton({ className = '', children, tooltip, active, ...props }) {
  const id = randomID()
  return (
    <div
      data-tooltip-position-strategy="fixed"
      data-tooltip-id={`tooltip-${id}`}
      data-tooltip-content={tooltip}
      data-tooltip-offset="32"
      data-tooltip-place="right-start"
      className={`h-[40px] w-[40px] border-[1px] flex justify-center items-center border-solid ${
        active
          ? 'border-[rgba(95,103,135,0.5)] bg-[rgba(245,245,248,1)]'
          : 'bg-[rgba(250,250,251,1)] border-[rgba(199,204,217,0.2)]'
      } bg-[rgba(245,245,248,1)] rounded-[5px] ${className}`}
    >
      {tooltip && <Tooltip id={`tooltip-${id}`} noArrow className="default-tooltip" />}
      {children}
    </div>
  )
}

export function SidebarToggleButton({ className, onToggle, minimized = false, ...props }) {
  return (
    <div className={`${className} flex items-center`}>
      <button
        type="button"
        onClick={onToggle}
        className={`w-[16px] h-[16px] ${!minimized ? 'inline-block' : ''} mt-[10px] transition-all duration-300 z-[100]
  ease-out ${minimized ? 'scale-x-[-1]' : ''}`}
      >
        <SidebarToggleIcon />
      </button>
    </div>
  )
}

function Sidebar() {
  const navigate = useNavigate()
  const {
    sidebar,
    setSidebar,
    tourData,
    navbarShown,
    toggleNavbar,
    setSelectedApartmentId,
    selectedApartmentId,
    hideUnit,
    isLineApp,
    setIsEngaged,
  } = useStore()
  const [activeDropdowns, setActiveDropdowns] = useState({})
  const { units, agent, startDateTime, agency, property, liked_assets, ...tour } = tourData
  const apartment = units.find(({ unit }) => unit.id === selectedApartmentId)
  const PHOTOS = apartment.photos
  const VIDEOS = apartment.unit.unit_type.videos.filter((v) => v.is_official && v.is_verified)
  const logoPath = apartment.unit.unit_type.property.real_estate_company.company_logo
  const logo = logoPath ? getCdnAssetUrl(logoPath) : sparxLogo
  const [termsModalShown, setTermsModalShown] = useState()
  const visiblePillsHeigth =
    units.length < 3 ? (PILLS_UNITS.height + PILLS_UNITS.topPadding) * units.length : baseHeight
  const [pillContainerHeight, setHeight] = useState(visiblePillsHeigth)
  const goToUrl = (name, url) => posttour(name, () => ({ url }))
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const location = useLocation()
  function calcPillHeight(apartmentId, isPillOpen) {
    let count = 0

    if (apartmentId && isPillOpen) {
      const { unit } = units.find(({ unit }) => unit.id === apartmentId)
      const arr = [
        unit.unit_type.number_of_bedrooms,
        unit.unit_type.number_of_bathrooms,
        unit.unit_type.size,
        unit.price,
      ]
      arr.forEach((val) => val && count++)
      setHeight(visiblePillsHeigth + PILLS_UNITS.paddings + PILLS_UNITS.pillItemHeight * count)
    } else {
      setHeight(visiblePillsHeigth)
    }
  }

  function onToggle(name, item) {
    if (item && selectedApartmentId !== item.id) {
      setSelectedApartmentId(item.id)
      posttour(events.mas)
      navigate({ to: '/gallery' })
    }
    calcPillHeight(item?.id, !activeDropdowns[name])
    setActiveDropdowns({ [name]: !activeDropdowns[name] })
  }

  function onMenuLinkClick(key) {
    if(`/${key}` !== location.current.pathname){
      setIsEngaged(true)
    }
    const eventNames = {
      gallery: events.mdgc,
      wishlist: events.mwc,
      floorplans: events.mfpc,
      videos: events.mfvc,
      photos: events.mpgc,
    }
    posttour(eventNames[key])
  }

  useEffect(() => {
    const propertyDropdown = activeDropdowns['propertytourdetailsdropdown']
    const apartmentDropdown = activeDropdowns[`apartment${selectedApartmentId}`]
    if (propertyDropdown !== undefined) {
      posttour(propertyDropdown ? events.mppco : events.mppcc)
    }
    if (apartmentDropdown !== undefined) {
      posttour(apartmentDropdown ? events.mapco : events.mapcc)
    }
  }, [activeDropdowns, selectedApartmentId])

  useEffect(() => {
    if (units && units.length && selectedApartmentId) {
      const { unit } = units.find(({ unit }) => unit.id === selectedApartmentId)
      const labelItems = [isLineApp ? `Line ${unit.unit_type.name}` : `#${unit.unit_number}`]
      if (unit.unit_type.property.properties_configuration_options.building_as_property) {
        labelItems.push(unit.building_name)
      } else {
        labelItems.push(unit.unit_type.property.property_name)
      }
    }
  }, [units, selectedApartmentId])

  useEffect(() => {
    if (window.innerHeight > 900) {
      setActiveDropdowns({ [`apartment${selectedApartmentId}`]: true })
    }
  }, [selectedApartmentId])

  useEffect(() => {
    // calc pills block max height only on the first rendering
    calcPillHeight(selectedApartmentId, true)
  }, [])

  function getTourDetailsTitle() {
    return units[0].unit.unit_type.property.property_name
  }

  const forceActiveDropdowns = isApartmentApp() && !isMobile

  
  const PropertyDropdown = (
    <PropertyTourDetailsDropdown
      details={{
        unit: apartment.unit,
        startTime: tourData.start_time,
        agent: tourData.agent,
        title: getTourDetailsTitle(),
      }}
      name="propertytourdetailsdropdown"
      onToggle={!forceActiveDropdowns ? onToggle : null}
      active={activeDropdowns['propertytourdetailsdropdown'] || forceActiveDropdowns}
    />
  )

  const ApartmentsDropdowns = units.map(({ unit }, index) => (
    <PropertyDescriptionDetailsDropdown
      className="mt-[16px]"
      details={unit}
      lineOnly={isLineApp}
      key={index}
      hideUnit={hideUnit}
      name={`apartment${unit.id}`}
      onToggle={!forceActiveDropdowns ? (name) => {
        onToggle(name, unit)
      } : null}
      active={activeDropdowns[`apartment${unit.id}`] || forceActiveDropdowns}
      highlighted={selectedApartmentId === unit.id}
    />
  ))

  const links = getAvailableMenuItems(tourData, selectedApartmentId, VIDEOS, PHOTOS, isLineApp)

  const delimiterIndex = links.findIndex((l) => l.title === pageTitles.WISHLIST)

  function openTermsModal(e) {
    e.preventDefault()
    setTermsModalShown(true)
  }

  function getUnitLabel() {
    if (isLineApp) {
      return 'Line'
    } else {
      return isApartmentApp() ? 'Unit' : 'Units'
    }
  }

  function toggleSidebar() {
    setSidebar({ minimized: !sidebar.minimized })
  }

  function renderNormalSidebar() {
    return (
      <div
        className={`${
          sidebar.minimized ? 'hidden' : 'fadeShow'
        } w-full h-full overflow-y-auto  lg:mt-[-50px] flex flex-col justify-between px-[24px] lg:px-[32px] pt-[50px] lg:pt-[22px] pb-[104px] lg:pb-[20px] `}
      >
        <div>
          <div className="flex items-center justify-between">
            <div>
              <img src={logo} className="h-[40px] max-w-[90%] object-contain" alt="RE company" />
            </div>
            <div
              className="cursor-pointer fixed p-[10px] top-[40px] right-[14px] z-20 lg:hidden"
              onClick={toggleNavbar}
            >
              <CrossIcon />
            </div>
          </div>
          <Greeting
            personalized={tourData.personalized}
            firstName={tour.app_user_first_name}
            lastName={tour.app_user_last_name}
            isVirtualTour={isApartmentApp()}
          />
          {PropertyDropdown}
          <div className="text-[#C7CCD9] uppercase text-[14px] font-semibold mt-[40px] lg:mb-[4px]">
            {getUnitLabel()}
          </div>
          <div>
            <div className="overflow-x-hidden overflow-y-auto h-max">{ApartmentsDropdowns}</div>
          </div>
          <nav className="font-medium text-[16px] leading-[22px] mt-[20px] lg:mt-[24px] flex flex-col">
            {links.slice(0, delimiterIndex).map((l, index) => (
              <Fragment key={index}>
                {l.separated && <div className="bg-[#C7CCD9] opacity-40 h-[1px] w-full mt-[20px] lg:mt-[16px]" />}
                <Link
                  to={`/${l.path}`}
                  key={l.path}
                  className="screen-link mt-[20px] lg:mt-[16px]"
                  data-test-id={l.path}
                  onClick={() => {
                    toggleNavbar('', false)
                    onMenuLinkClick(l.path)
                  }}
                >
                  {l.title}
                </Link>
              </Fragment>
            ))}
            {isPostTourApp() && links.length > 1 && (
              <div className="bg-[#C7CCD9] opacity-40 h-[1px] w-full mt-[20px] lg:mt-[16px]" />
            )}
            {links.slice(delimiterIndex).map((l) => (
              <Link
                to={`/${l.path}`}
                key={l.path}
                className="screen-link mt-[20px] lg:mt-[16px]"
                data-test-id={l.path}
                onClick={() => {
                  toggleNavbar('', false)
                  onMenuLinkClick(l.path)
                }}
              >
                {l.title}
              </Link>
            ))}
          </nav>
        </div>
        <div>
          <Disclaimer />
        </div>
      </div>
    )
  }

  function renderMinimizedSidebar() {
    return (
      <div
        className={`h-full flex flex-col items-center justify-between ${sidebar.minimized ? '' : 'hidden'}`}
      >
        <div className='flex flex-col items-center'>
          <IconButton tooltip={getTourDetailsTitle()} active className="mt-[26px]">
            <PropertyIcon />
          </IconButton>
          <div className="h-[1px] w-[40px] bg-[rgba(199,204,217,1)] my-[16px] "></div>
          {units.map(({ unit }, index) => (
            <button
              onClick={() => {
                onToggle(unit.name, unit)
              }}
            >
              <IconButton
                className={`mb-[8px]`}
                active={selectedApartmentId === unit.id}
                tooltip={getUnitDetailsLabel(unit, unit.unit_type, isLineApp)}
              >
                {' '}
                <UnitIcon stroke={selectedApartmentId === unit.id ? 'black' : ''} />
              </IconButton>
            </button>
          ))}
          <div className="h-[1px] w-[40px] bg-[rgba(199,204,217,1)] my-[16px] mb-[8px] mt-[8px]"></div>
          <>
            {links.slice(0, delimiterIndex).map((l, index) => (
              <Fragment key={index}>
                <Link
                  to={`/${l.path}`}
                  key={l.path}
                  data-test-id={l.path}
                  onClick={() => {
                    toggleNavbar('', false)
                    onMenuLinkClick(l.path)
                  }}
                >
                  {({ isActive }) => {
                    return (
                      <IconButton tooltip={l.title} className={`mt-[8px]`} active={isActive}>
                        {l.icon(isActive)}
                      </IconButton>
                    )
                  }}
                </Link>
              </Fragment>
            ))}
            {links.slice(delimiterIndex).map((l) => (
              <Link
                to={`/${l.path}`}
                key={l.path}
                data-test-id={l.path}
                onClick={() => {
                  toggleNavbar('', false)
                  onMenuLinkClick(l.path)
                }}
              >
                {({ isActive }) => {
                  return (
                    <IconButton tooltip={l.title} className={`mt-[8px]`} active={isActive}>
                      {l.icon(isActive)}
                    </IconButton>
                  )
                }}
              </Link>
            ))}
          </>
        </div>
        <div className="mb-[16px]">
          <Disclaimer icon />
        </div>
      </div>
    )
  }

  return (
    <div
      id="sidebar"
      className={`tp-sidebar relative z-[100] group lg:flex h-full w-screen transition-all duration-300 overflow-x-hidden
      ease-out ${
        sidebar.minimized ? 'lg:w-[56px]' : 'lg:w-[336px]'
      }  bg-white flex-col fixed z-50 lg:static shrink-0 overflow-y-auto justify-between border-solid lg:border-r-[1px] border-[#C7CCD9]
      ${navbarShown ? '' : 'hidden'}`}
    >
      <SidebarToggleButton
        onToggle={toggleSidebar}
        className={`"w-full relative h-[24px] lg:mt-[25px] hidden  lg:flex ${
          sidebar.minimized ? `justify-center px-[18px]` : `justify-end px-[32px]`
        }`}
        minimized={sidebar.minimized}
      />
      {renderNormalSidebar()}
      {renderMinimizedSidebar()}
      <div className="lg:hidden h-[84px] px-[24px] py-[18px] flex flex-col items-center justify-between absolute z-20 bottom-0 left-0 w-full bg-[#FAFAFA] border-solid border-t-[1px] border-[#C7CCD9]">
        <div className="flex items-center">
          <a href="!#" onClick={openTermsModal}>
            <span className="font-medium text-[14px] mr-[10px] text-[#5F6787] underline">Virtually Staged by</span>
          </a>
          <a href={sparxUrl} target="_blank" rel="noreferrer" onClick={() => goToUrl(events.mll, sparxUrl)}>
            <img src={sparxLogo} className="h-[16px]" alt="sparx-logo" />
          </a>
        </div>
        <span className="text-[#C7CCD9] text-[10px] leading-[18px]">
          © All Rights Reserved, SparX Inc. {new Date().getFullYear()}
        </span>
      </div>
      {termsModalShown && <TermsModal onClose={setTermsModalShown} />}
    </div>
  )
}

export default Sidebar
