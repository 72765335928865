import { ReactComponent as WarningIcon } from 'shared/assets/icons/warning.svg'

export default function ErrorMessage(props) {
  return props.text
    ? <div {...props} className={`w-full flex items-center mt-[8px] ${props.className}`}>
      <div className="h-[22px] w-[22px]">
        <WarningIcon />
      </div>
      <span className="text-[#F13C3C] ml-[5px] text-[12px] font-medium leading-[16px] first-letter:uppercase">{props.text}</span>
    </div>
    : null
}
