import { useState } from 'react'
import { ReactComponent as CrmContentDownload } from 'shared/assets/icons/crm-content-download.svg'
import { downloadContentAsZip } from 'CRM/helpers/contentDownload'

function ContentDownload({ unit, isLine }) {
  const [loading, setLoading] = useState(false)
  async function handleZip() {
    setLoading(true)
    await downloadContentAsZip(unit, isLine)
    setLoading(false)
  }

  return (
    <button type="button" disabled={loading} onClick={handleZip} className="text-white px-5" alt="Download content zip">
      {loading ? `Downloading ...` : <CrmContentDownload />}
    </button>
  )
}

export default ContentDownload
