import { ApolloClient, gql, InMemoryCache } from '@apollo/client'
import { apiHost } from 'shared/helpers/api'
import {  properties_configuration_options } from 'shared/gql'
import { unitType } from 'shared/services/tour.service'
import { getAuth } from 'CRM/helpers/auth'
export async function getVideosByUnitTypeId(unitTypeId) {
  const client = new ApolloClient({
    uri: `${apiHost}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      Authorization: process.env.REACT_APP_API_TOKEN || 'xZRuBuykeDuRRei0lw0Oe5WJOXbwuRQK',
    },
  })
  return await client.query({
    variables: {
      unitTypeId,
    },
    query: gql`
      query Videos_by_unit_type_id($unitTypeId: Int) {
        videos_by_unit_type_id(unit_type_id: $unitTypeId) {
          id
          src
          display_name
          thumbnail
          is_official
          is_verified
          name
          timestamp
          captured_in {
            unit_number
          }
        }
      }
    `,
  })
}

export async function getUnitByUnitId(unitId, realAuth) {
  const client = new ApolloClient({
    uri: `${apiHost}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      Authorization: realAuth ? `Bearer ${getAuth()}` : (process.env.REACT_APP_API_TOKEN || 'xZRuBuykeDuRRei0lw0Oe5WJOXbwuRQK'),
    },
  })
  return await client.query({
    variables: {
      unitsByIdId: unitId,
      includeInSparxStaging: true,
    },
    query: gql`
      query Units_by_id($unitsByIdId: Int!, $includeInSparxStaging: Boolean) {
        units_by_id(id: $unitsByIdId, include_in_sparx_staging: $includeInSparxStaging) {
          id
          name
          unit_number
          floor
          price
          building_name
          is_available
          virtual_tour {
            tour_provider {
              name
              id
            }
            tour_path
            id
          }
          unit_type {
            ${unitType}
          }
        }
      }
    `,
  })
}
