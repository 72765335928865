import { useEffect, useState } from 'react'
import { useStore } from 'CRM/store'
import { generateInstantAppLink } from 'CRM/helpers/links'

export function InstantAppLinkCopy({ unit, icon, extra = '', className = '', copyTooltip = false, label, line }) {
  const { lastCopiedUnit, user, fakeToken } = useStore()
  const [loading, setLoading] = useState(false)
  const [hideTimeout, setHideTimeout] = useState(null)
  const [tooltipShown, setTooltipShown] = useState(false)
  async function onCopy(prospectName) {
    setLoading(true)
    const content = await generateInstantAppLink({ user, unit, token: fakeToken })
    setTooltipShown(true)
    hideTooltipWithTimeout()

    try {
      navigator.clipboard.writeText(content)
    } catch (error) {
      console.log(error)
      console.log(content)
    }
    setLoading(false)
  }

  function hideTooltipWithTimeout() {
    if (hideTimeout) {
      clearTimeout(hideTimeout)
    }
    const timeout = setTimeout(() => {
      setTooltipShown(false)
    }, 3000)
    setHideTimeout(timeout)
  }

  useEffect(() => {
    if (lastCopiedUnit.id !== unit.id) {
      setTooltipShown(false)
    }
  }, [lastCopiedUnit, unit])

  return (
    <>
      <button
        type="button"
        disabled={loading}
        className={`p-[5px] mr-[-5px] lg:mr-[0] border-none ml-[16px] lg:ml-[10px] group relative bg-[rgba(229,231,237,.4)] lg:bg-transparent h-[44px] w-[44px] shrink-0 lg:w-auto rounded-full flex items-center justify-center text-[#A93DCB] ${className} ${
          loading ? '!opacity-50' : ''
        } `}
        onClick={() => onCopy()}
      >
        {copyTooltip && (
          <div
            className={`bg-[#16C270] w-[calc(100vw-48px)] lg:w-max px-[16px] overflow-hidden left-[24px] bottom-[40px] z-[100] lg:bottom-0 lg:top-[-42px] lg:translate-x-[-50%] fixed lg:absolute flex items-center justify-center h-[48px] lg:h-[31px] text-[16px] lg:text-[14px] rounded-full lg:rounded-[3px]  font-medium lg:font-normal text-white ${
              tooltipShown ? 'fadeIn' : 'fadeOut'
            }`}
            onMouseEnter={() => {
              clearTimeout(hideTimeout)
            }}
            onMouseLeave={() => {
              hideTooltipWithTimeout()
            }}
          >
            Design Packages Link Copied
          </div>
        )}
        <div className="lg:h-[20px] overflow-hidden  flex items-center">{icon}</div>
        {label && <span className="ml-[7px] font-medium">{label}</span>}
      </button>
    </>
  )
}
