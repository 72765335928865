import React, { useEffect, useMemo, useState } from 'react'
import Button from 'shared/components/Button'
import PurchaseTypeTag from 'shared/components/PurchaseTypeTag'

import { ReactComponent as ArrowDown } from 'shared/assets/icons/arrow-black-packages.svg'

import { ReactComponent as ResidentInfo } from 'shared/assets/icons/resident-info.svg'

import { ReactComponent as ArrowRight } from 'shared/assets/icons/arrow-small-resident.svg'

import Carousel from 'shared/components/Carousel'

import { useMediaQuery } from '@react-hook/media-query'
import TinyModal from 'shared/components/TinyModal'
import { useNavigate } from 'react-router-dom'
import { priceSum } from 'shared/helpers/general'
import { getPurchaseType, groupAssets, produceAllRoom } from 'Resident/helpers/general'
import { usePackages } from 'Resident/hooks/usePackages'
import { PackageAssetsSlider } from './components/AssetsSlider'
import { PackageInfo } from './components/PackageInfo'
import { CheckoutButton, ViewButton } from './components/ViewButton'
import { RoomsSlider } from './components/RoomsSlider'
import { sortByWeight } from 'shared/helpers/arrays'
import { useStore } from 'Resident/store'
import { isMinimalApp } from 'Resident/helpers/app'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { group4, group5, group6 } from 'shared/analytics/resident'
import { RPProductMap, RPScreens } from 'Resident/constants/appType'

function PackageRow({ checkout, opened, pack, minimal = false, ...props }) {
  const { appType, currentScreen } = useStore()
  const [toggled, setToggled] = useState(false)
  const [infoToggled, setInfoToggled] = useState(false)
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const [pickedRoom, setPickedRoom] = useState(null)

  useEffect(() => {
    if (!pickedRoom) {
      setPickedRoom(-1)
    }
  }, [pack, pickedRoom])

  const navigate = useNavigate()

  function onPreview() {
    rpevent(eventDictionary.dp_screen_gallery_button_tapped, group4(pack))
    navigate('/preview', { state: { packageId: pack.id } })
  }

  const rooms = useMemo(() => {
    return [produceAllRoom(pack), ...pack.room_classifications]
  }, [pack])

  useEffect(() => {
    setToggled(opened)
  }, [opened])

  const assets = useMemo(() => {
    if (pickedRoom) {
      return rooms.find((item) => item.room_classification.id === pickedRoom).package_assets
    } else {
      return []
    }
  }, [pickedRoom, rooms])

  const { updatePackageAsset } = usePackages()

  function getPickedAssetsForRoom(room) {
    const assets = room.package_assets.filter((asset) => asset.type === 'core' || asset.added)
    return room.id === -1 ? groupAssets(assets, pack) : assets
  }

  const pickedAssets = useMemo(() => {
    return rooms
      .filter((room) => room.room_classification.id > 0)
      .map((room) => room.package_assets.filter((asset) => asset.type === 'core' || asset.added))
      .flat()
  }, [rooms])

  const packagePriceLabel = useMemo(() => {
    if (pickedAssets.length === 0) {
      return <span className="text-[#5F6787]">No items selected yet</span>
    }
    if (pickedAssets.every((asset) => asset.type === 'core') && !checkout) {
      return `Starting at $${pack.price.toLocaleString('en-US')}${pack.vendor.is_rental ? '/mo' : ''}`
    } else {
      return `${pickedAssets.reduce((acc, val) => (val.selectedCount || val.count) + acc, 0)} items for $${(
        pack.price +
        priceSum(pickedAssets.filter((asset) => asset.added).map((asset) => asset.price * asset.selectedCount))
      ).toLocaleString('en-US')}${pack.vendor.is_rental ? '/mo' : ''}`
    }
  }, [pickedAssets, pack, checkout])

  const handlersAssetEvents = {
    onAssetAdd: (asset) => {
      if (currentScreen === RPScreens.DP) {
        rpevent(eventDictionary.dp_screen_asset_added, group6(pack, getFullRoom(pickedRoom), asset))
      }
    },
    onAssetRemove: (asset) => {
      if (currentScreen === RPScreens.DP) {
        rpevent(eventDictionary.dp_screen_asset_removed, group6(pack, getFullRoom(pickedRoom), asset))
      }
      if (currentScreen === RPScreens.CART) {
        rpevent(eventDictionary.cart_screen_asset_removed, group6(pack, getFullRoom(pickedRoom), asset))
      }
    },
    onMinusCountAssetClick: (asset) => {
      rpevent(eventDictionary.dp_screen_asset_quantity_minus, group6(pack, getFullRoom(pickedRoom), asset))
    },
    onPlusCountAssetClick: (asset) => {
      rpevent(eventDictionary.dp_screen_asset_quantity_plus, group6(pack, getFullRoom(pickedRoom), asset))
    },
  }

  function renderMinimal() {
    return (
      <div>
        <div
          className={`h-[50px] rounded-[8px] bg-[#E8EAF0] border-[1px] border-solid border-[#DCDEE7] flex justify-between items-center px-[24px] ${
            toggled ? 'rounded-b-none border-b-[transparent]' : ''
          }`}
          onClick={() => {
            setToggled(!toggled)
          }}
        >
          <div>{packagePriceLabel}</div>
          <div className={`${toggled ? 'rotate-90' : ''}`}>
            <ArrowRight />
          </div>
        </div>
        {toggled && (
          <div className=" bg-white border-[1px] border-solid border-[#DCDEE7] border-t-[0px] rounded-b-[8px] p-[24px]">
            {pack.room_classifications.map((item) => (
              <div className="mt-[40px] first:mt-[0]">
                <div className="text-[14px] text-[#5F6787]">
                  {item.room_classification.classification_name} ({getPickedAssetsForRoom(item).length})
                </div>
                <div className="mt-[12px]">
                  <PackageAssetsSlider
                    pack={pack}
                    onAssetRemove={(asset) => {
                      handlersAssetEvents.onAssetRemove(asset)
                      updatePackageAsset(pack, item.room_classification.id, asset)
                    }}
                    onAssetUpdate={(asset, fields) =>
                      updatePackageAsset(pack, item.room_classification.id, asset, fields)
                    }
                    checkout
                    onMinusCountAssetClick={(asset)=>{
                      handlersAssetEvents.onMinusCountAssetClick(asset)
                    }}
                    onPlusCountAssetClick={(asset)=>{
                      handlersAssetEvents.onPlusCountAssetClick(asset)
                    }}
                    assets={item.package_assets}
                    navigation={false}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  function renderDesktop() {
    return (
      <div className=" bg-white border-[1px] border-solid overflow-hidden border-[rgba(199,204,217,0.4)] mt-[20px] first:mt-0">
        <div className="h-[88px] pr-[32px]">
          <div className="flex justify-between items-center h-full">
            <div className="h-full flex items-center">
              <div className="relative flex items-center h-full w-[46px] border-r-[1px] shrink-0">
                <button
                  type="button"
                  className="absolute p-[13px]"
                  onClick={() => {
                    if(currentScreen === RPScreens.DP){
                      if (toggled) {
                        rpevent(eventDictionary.dp_screen_collapse_tapped, group4(pack))
                      }
                      if (!toggled) {
                        rpevent(eventDictionary.dp_screen_expand_tapped, group4(pack))
                      }
                    }
                    if(currentScreen === RPScreens.CART){
                      if (!toggled) {
                        rpevent(eventDictionary.cart_screen_package_expanded_tapped, group4(pack))
                      }
                    }
                    setToggled(!toggled)
                  }}
                >
                  <div className={`transition-all ${!toggled ? 'rotate-180' : ''}`}>
                    <ArrowDown />
                  </div>
                </button>
              </div>
              <div className="ml-[24px] min-w-max">
                <div className="flex items-center">
                  <div className="text-[20px] font-semibold">{pack.display_name || pack.name}</div>
                  {!checkout && pack.description && (
                    <div className="ml-[12px]">
                      <PackageInfo pack={pack} text={pack.description} extra={pack.package_configuration} />
                    </div>
                  )}
                </div>
                <div className="flex items-center">
                  <PurchaseTypeTag purchaseType={getPurchaseType(pack.vendor.is_rental)} />
                  <div className="ml-[8px] font-medium text-[#5F6787]">
                    by <span className="uppercase">{pack.vendor.full_name}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="min-w-max">{packagePriceLabel}</div>
              {!checkout && (
                <>
                  <ViewButton className="h-[37px]" onClick={onPreview} />
                  {pack.vendor.supports_checkout && !isMinimalApp(appType) && (
                    <CheckoutButton
                      onClick={() => {
                        rpevent(eventDictionary.dp_screen_cart_button_tapped, group4(pack), [
                          RPProductMap.RP,
                          RPProductMap.IARP,
                          RPProductMap.FAA,
                        ])
                      }}
                      className="h-[37px]"
                      pack={pack}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {toggled && (
          <div className="border-t-[1px] flex items-start border-solid border-[rgba(199,204,217,0.4)] w-full p-[25px]">
            <RoomsSlider pack={pack} onRoomPicked={setPickedRoom} pickedRoom={pickedRoom} rooms={rooms} />
            <div className="ml-[46px] w-[calc(100%-174px)]">
              <PackageAssetsSlider
                pack={pack}
                summaryMode={pickedRoom === -1}
                checkout={checkout}
                onAssetAdd={(asset) => {
                  handlersAssetEvents.onAssetAdd(asset)
                  updatePackageAsset(pack, pickedRoom, asset)
                }}
                onAssetRemove={(asset) => {
                  handlersAssetEvents.onAssetRemove(asset)
                  updatePackageAsset(pack, pickedRoom, asset)
                }}
                onMinusCountAssetClick={(asset)=>{
                  handlersAssetEvents.onMinusCountAssetClick(asset)
                }}
                onPlusCountAssetClick={(asset)=>{
                  handlersAssetEvents.onPlusCountAssetClick(asset)
                }}
                onAssetUpdate={(asset, fields) => updatePackageAsset(pack, pickedRoom, asset, fields)}
                assets={assets}
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  function getFullRoom(pickedRoom){
    return rooms.find((room) => room.room_classification.id === pickedRoom) || {}
  }



  function renderMobile() {
    return (
      <div className="first:mt-0 mt-[16px] py-[20px] px-[16px] shadow-[0px-4px-20px-0px-rgba(95,103,135,0.05)] border-[1px] bg-white border-solid border-[rgba(199,204,217,0.6)] rounded-[6px]">
        <div className="flex items-center justify-between">
          <div className="text-[12px] uppercase font-semibold text-[rgba(95,103,135,0.6)]">{pack.vendor.full_name}</div>
          <PurchaseTypeTag purchaseType={getPurchaseType(pack.vendor.is_rental)} />
        </div>
        <div className="mt-[5px]" onClick={() => setInfoToggled(true)}>
          <div className="text-[20px] font-semibold w-[calc(100%-20px)]">
            {pack.display_name || pack.name}{' '}
            {!checkout && pack.description && (
              <PackageInfo
              pack={pack}
                text={pack.description}
                extra={pack.package_configuration}
                onClick={() => setInfoToggled(true)}
              />
            )}
          </div>
        </div>

        <div className="min-w-max">{packagePriceLabel}</div>
        <div className="flex items-center mt-[24px]">
          <ViewButton onClick={onPreview} className="!ml-0 !max-w-auto !w-full" />
          {pack.vendor.supports_checkout && !isMinimalApp(appType) && (
            <CheckoutButton
              onClick={() => {
                rpevent(eventDictionary.dp_screen_cart_button_tapped, group4(pack), [
                  RPProductMap.RP,
                  RPProductMap.IARP,
                  RPProductMap.FAA,
                ])
              }}
              pack={pack}
              className="!ml-[8px] !max-w-auto !w-full"
            />
          )}
          <button
            type="button"
            onClick={() => {
              if(currentScreen === RPScreens.DP){
                if (toggled) {
                  rpevent(eventDictionary.dp_screen_collapse_tapped, group4(pack))
                }
                if (!toggled) {
                  rpevent(eventDictionary.dp_screen_expand_tapped, group4(pack))
                }
              }
              if(currentScreen === RPScreens.CART){
                if (!toggled) {
                  rpevent(eventDictionary.cart_screen_package_expanded_tapped, group4(pack))
                }
              }
              setToggled(!toggled)
            }}
            className={`w-[40px] h-[40px] transition-all  shrink-0  ml-[8px] rounded-full border-[1px] border-solid border-black flex items-center justify-center ${
              !toggled ? 'rotate-180' : ''
            }`}
          >
            <ArrowDown />
          </button>
        </div>
        {/* Mobile modal */}
        {infoToggled && (
          <TinyModal className="!py-[32px] !px-[18px]">
            <ResidentInfo />
            <div className="text-[18px] font-semibold mt-[3px]">{pack.display_name || pack.name}</div>
            <div className="text-[#5F6787] mt-[24px]">
              <div className="leading-[21px] text-left">{pack.description}</div>
            </div>
            <div className="mt-[48px]">
              <Button rounded className="w-[233px]" onClick={() => setInfoToggled(false)}>
                OK
              </Button>
            </div>
          </TinyModal>
        )}

        {/* Expandable */}
        {toggled && (
          <div>
            <div className="my-[16px]">
              <Carousel
                className="grid-mode"
                slidesPerView="auto"
                freeMode={true}
                slideClassName="!w-max"
                separatorIndex={4}
                spaceBetween={16}
                items={rooms.sort(sortByWeight).map((item, index) => (
                  <button
                    type="button"
                    onClick={() => {
                      if(currentScreen === RPScreens.DP){
                        rpevent(eventDictionary.dp_screen_room_selected, group5(pack, item))
                      }
                      setPickedRoom(item.room_classification.id)
                    }}
                    className={`text-[14px] font-medium ${
                      item.room_classification.id === pickedRoom
                        ? 'text-black cursor-pointer pointer-events-none'
                        : 'text-[#5F6787] opacity-60'
                    }`}
                  >
                    {item.room_classification.classification_name} (
                    {getPickedAssetsForRoom(item).reduce((acc, v) => acc + v.selectedCount, 0)})
                  </button>
                ))}
              />
            </div>
            <PackageAssetsSlider
              pack={pack}
              navigation={false}
              summaryMode={pickedRoom === -1}
              assets={assets}
              onAssetAdd={(asset) => {
                handlersAssetEvents.onAssetAdd(asset)
                updatePackageAsset(pack, pickedRoom, asset)
              }}
              onAssetRemove={(asset) => {
                handlersAssetEvents.onAssetRemove(asset)
                updatePackageAsset(pack, pickedRoom, asset)
              }}
              onMinusCountAssetClick={(asset)=>{
                handlersAssetEvents.onMinusCountAssetClick(asset)
              }}
              onPlusCountAssetClick={(asset)=>{
                handlersAssetEvents.onPlusCountAssetClick(asset)
              }}
              onAssetUpdate={(asset, fields) => updatePackageAsset(pack, pickedRoom, asset, fields)}
            />
          </div>
        )}
      </div>
    )
  }

  return minimal ? renderMinimal() : isMobile ? renderMobile() : renderDesktop()
}

export default PackageRow
