import React, { useState, useEffect } from 'react'
import Carousel from 'shared/components/Carousel'
import ImagePointMap from '../ImagePointMap'
// Icons
import { ReactComponent as InfoIcon } from 'shared/assets/icons/info.svg'
import { ReactComponent as DragIcon } from 'shared/assets/icons/drag.svg'
import { ReactComponent as FurnishedIcon } from 'shared/assets/icons/furniture.svg'
import { ReactComponent as UnfurnishedIcon } from 'shared/assets/icons/unfurnished.svg'
import { ReactComponent as InfoIconSmall } from 'shared/assets/icons/infoSmall.svg'
import { ReactComponent as FurnishedIconSmall } from 'shared/assets/icons/furnitureSmall.svg'
import { ReactComponent as UnfurnishedIconSmall } from 'shared/assets/icons/unfurnishedSmall.svg'
import { useMediaQuery } from '@react-hook/media-query'
import { isQuadraticDisplayMode } from 'shared/helpers/gallery'
import { ZoomControls } from 'shared/components/ZoomControls'
import { disclaimerText } from 'shared/components/Disclaimer'

function RoomPreviewSlider({
  zoomed,
  onZoomChange,
  showFavorites,
  pickedAsset,
  hoveredAsset,
  apartment = {},
  pickedDesignLayout,
  onSlideChange,
  pickedImage,
  designItemsMobileShown,
  wishlistPreview,
  items,
  logClickEvent,
  logZoomEvent,
  carouselInstance,
  previewMode,
  onRawImageDisplayToggled,
  rawImageDisplay,
  lineMode,
  activeSlide,
  ...props
}) {
  const [infoShown, setInfoShown] = useState(null)
  const [dragIndicatorShown, setDragIndicatorShown] = useState(false)
  const [scale, setScale] = useState(1)
  const isMobile = useMediaQuery('(max-width: 1023px)')

  function onSwiper(instance) {
    props.onSwiper && props.onSwiper(instance)
  }

  useEffect(() => {
    let timeout
    if (infoShown) {
      timeout = setTimeout(() => {
        setInfoShown(false)
      }, 5000)
    }
    return function () {
      clearTimeout(timeout)
    }
  }, [infoShown])

  useEffect(() => {
    setInfoShown(null)
  }, [pickedImage])

  function onPointPick(asset, isPopoverAction, event) {
    if (asset) {
      props.onPointPick(asset, isPopoverAction, event)
    } else {
      props.onPointPick(null)
    }
  }

  function onPointHover(asset) {
    if (asset) {
      props.onPointHover(asset)
    } else {
      props.onPointHover(null)
    }
  }

  useEffect(() => {
    if (zoomed) {
      setDragIndicatorShown(true)
    }
  }, [zoomed])

  const config = { attributes: true, childList: false, subtree: false }

  const callback = (mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'attributes') {
        const scale = mutation.target.style.transform?.split('scale(')[1]?.split(')')[0]
        setScale(+scale)
      }
    }
  }

  const observer = new MutationObserver(callback)

  useEffect(() => {
    const targetNode = document.getElementById(`zoomContainer_${pickedImage.id}`)
    if (targetNode) {
      observer.observe(targetNode, config)
    }
  })

  function renderWrap(children) {
    return children
  }

  return renderWrap(
    <div
      className={`relative main-slider h-full flex w-full lg:h-full justify-center items-center bg-[#fafafa] lg:bg-gradient-to-b from-[rgba(75,76,86,.15)] to-[rgba(250,250,250,0)]`}
    >
      {dragIndicatorShown && zoomed && (
        <div className="absolute flex justify-center h-[100px] w-auto items-center z-40">
          <div className="flex items-center flex-col">
            <DragIcon />
            <div className="uppercase text-white font-bold tracking-wider mt-[15px]">drag</div>
          </div>
        </div>
      )}
      <Carousel
        className="h-full w-full"
        onImagesReady={() => {
          props.onImagesReady && props.onImagesReady()
        }}
        keyboard={{
          enabled: true,
          onlyInViewport: false,
        }}
        loop={true}
        navigation={!zoomed}
        initialSlide={0}
        zoom={true}
        onSlideChange={swiper=>{
          if(activeSlide !== swiper.realIndex){
            onSlideChange()
          }
        }}
        onBeforeSlideChangeStart={(_swiper) => {
          _swiper.zoom.out()
        }}
        onSwiper={onSwiper}
        items={items.map((image, i) => (
          <div className="swiper-zoom-container h-full w-screen" key={i}>
            <div className="swiper-zoom-target h-full w-full" id={`zoomContainer_${image.id}`}>
              <ImagePointMap
                rawImageDisplay={rawImageDisplay}
                carouselInstance={carouselInstance}
                showFavorites={showFavorites}
                pickedPoint={pickedAsset}
                hoveredPoint={hoveredAsset}
                pickedDesignLayout={pickedDesignLayout}
                assets={pickedImage.assets}
                wishlistPreview={wishlistPreview}
                image={image}
                isActive={image.id === pickedImage.id}
                showDots={previewMode === 'assets' && !rawImageDisplay}
                zoom={zoomed}
                onPointClick={onPointPick}
                onPointHover={onPointHover}
                scale={scale}
              />
            </div>
          </div>
        ))}
      />
      <div
        className={`absolute ${
          designItemsMobileShown ? 'bottom-[110px]' : 'bottom-[20px]'
        }  hidden lg:block lg:bottom-[28px] right-[32px] z-40 items-center lg:items-start justify-end`}
      >
        <div className={`lg:flex lg:justify-end ${isQuadraticDisplayMode(apartment) ? 'flex-col' : ''}`}>
          {pickedImage.raw_image && (
            <button
              type="button"
              onClick={() => {
                onRawImageDisplayToggled()
              }}
              className={`bg-white ml-[8px] flex items-center justify-center h-[40px] w-[40px] ${
                isQuadraticDisplayMode(apartment) ? 'lg:h-[36px] lg:w-[36px]' : 'lg:h-[32px] lg:w-[32px]'
              } rounded-full shadow-sm`}
            >
              {rawImageDisplay ? (
                isQuadraticDisplayMode(apartment) ? (
                  <UnfurnishedIcon />
                ) : (
                  <UnfurnishedIconSmall />
                )
              ) : isQuadraticDisplayMode(apartment) ? (
                <FurnishedIcon />
              ) : (
                <FurnishedIconSmall />
              )}
            </button>
          )}
          {/* <button
            type="button"
            disabled
            className="bg-white flex items-center justify-center drop-shadow p-[5px] h-[40px] w-[40px] lg:h-[32px] lg:w-[32px] rounded-full shadow-[0px_2px_6px_rgba(0,0,0,0.15)] lg:shadow-[rgba(0,0,0,0.5)] opacity-40"
          >
            <FurnitureIcon />
          </button> */}
          {(pickedImage.tour_unit || pickedImage.direction || (pickedImage.captured_in && !lineMode)) && (
            <button
              type="button"
              onClick={() => {
                logClickEvent()
                setInfoShown(!infoShown)
              }}
              className={`bg-white ${
                isQuadraticDisplayMode(apartment) ? 'mt-[10px] lg:h-[36px] lg:w-[36px]' : 'lg:h-[32px] lg:w-[32px]'
              } ml-[8px] flex items-center justify-center p-[5px] h-[40px] w-[40px] rounded-full shadow-sm`}
            >
              {isQuadraticDisplayMode(apartment) ? <InfoIcon /> : <InfoIconSmall />}
            </button>
          )}

          <ZoomControls
            className="mt-[10px] ml-[8px] hidden lg:flex"
            zoomIn={() => {
              carouselInstance.zoom.in()
            }}
            zoomOut={() => {
              carouselInstance.zoom.out()
            }}
          />
          {infoShown !== null && (
            <div
              className={`bg-white transition-[opacity] duration-1000 ${
                infoShown ? 'fadeShow' : 'fadeHide'
              } min-h-[64px] h-auto drop-shadow-md rounded-[4px] absolute bottom-[100%] right-0 w-[250px] lg:w-max flex justify-center items-center px-[24px] py-[20px]`}
            >
              <div>
                {pickedImage.captured_in && !lineMode && (
                  <div className="text-[16px] lg:text-[18px] font-semibold leading-[22px]">
                    Captured in apartment #{pickedImage.captured_in?.unit_number}
                  </div>
                )}
                {pickedImage.direction && (
                  <div className="text-[16px] font-medium text-[#5F6787] leading-[21px]">
                    Direction {pickedImage.direction}
                  </div>
                )}
                <div className='text-[14px] lg:w-[377px] mt-[12px] font-normal'>* {disclaimerText.second}</div>
              </div>
          
            </div>
          )}
        </div>
      </div>
    </div>,
  )
}

export default RoomPreviewSlider
