import PropertyChip from 'Resident/components/PropertyChip'
import React from 'react'
import { ReactComponent as QuestionIcon } from 'shared/assets/icons/question-resident.svg'
import { ReactComponent as BackArrow } from 'shared/assets/icons/back-arrow-resident.svg'
import { useNavigate } from 'react-router-dom'

function AuthLayout({ children, help, back }) {
  const navigate = useNavigate()

  return (
    <div className="bg-[#F3F4F6] h-full flex flex-col items-center justify-center relative">
      <div className="h-full w-full lg:h-auto lg:w-[500px] lg:min-h-[630px] bg-[white] p-[32px] pb-[0] lg:py-[50px] lg:px-[80px] flex flex-col items-center lg:justify-center">
        <div className="flex justify-between w-full lg:hidden">
          {back !== false && (
            <div>
              <button type="button" onClick={() => navigate(-1)}>
                <BackArrow />
              </button>
            </div>
          )}
          {help !== false && (
            <a href="mailto:info@sparx.ai" className="underline">
              <QuestionIcon />
            </a>
          )}
        </div>
        <div className="mt-[46px] lg:mt-0">
          <PropertyChip />
        </div>
        {children}
      </div>
      <div className="mt-[42px] text-[#5F6787] hidden lg:block">
        If you’re having a problem,{' '}
        <a href="mailto:info@sparx.ai" className="underline">
          Contact Us
        </a>
      </div>
    </div>
  )
}

export default AuthLayout
