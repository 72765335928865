import FloorPlansUpload from 'MediaManager/components/FloorPlansUpload'
import ScreenLayout from 'MediaManager/layouts/ScreenLayout'
import React from 'react'

function FloorPlansScreen(props) {
  return (
    <ScreenLayout title="Upload Floor Plan">
       <FloorPlansUpload></FloorPlansUpload>
    </ScreenLayout>
    
  )
}

export default FloorPlansScreen
