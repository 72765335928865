import { Navigate } from '@tanstack/react-location'
import { events, iframe } from 'shared/analytics'
import { useStore } from 'IFrameApp/store'
// Screens
import NotFoundScreen from 'IFrameApp/screens/NotFound'
import GalleryScreen from 'IFrameApp/screens/Gallery'
import VideosScreen from 'IFrameApp/screens/Videos'
import FloorPlansScreen from 'IFrameApp/screens/FloorPlans'
import TourGuard from 'IFrameApp/components/TourGuard'
import FeaturedVideosIcon from 'TourApp/components/Sidebar/icons/videos'
import DesignGalleryIcon from 'TourApp/components/Sidebar/icons/design-gallery'
import FloorPlansIcon from 'TourApp/components/Sidebar/icons/floor-plans'
import { getDefaultRoute, getHiddenScreensList } from './helpers'

export const pageTitles = {
  DESIGN_GALLERY: 'Design Gallery',
  FLOOR_PLANS: 'Floor Plans',
  VIDEOS: 'Video Walkthrough',
}

const definedRoutes = [
  {
    path: '/videos',
    title: pageTitles.VIDEOS,
    icon: (active) => <FeaturedVideosIcon stroke={active ? 'black' : ''} />,
    onMatch: () => {
      const { setTitle } = useStore.getState()
      iframe(events.fvp)
      setTitle(pageTitles.VIDEOS)
    },
    element: (
      <TourGuard>
        <VideosScreen />
      </TourGuard>
    ),
  },
  {
    path: '/gallery',
    title: pageTitles.DESIGN_GALLERY,
    icon: (active) => <DesignGalleryIcon stroke={active ? 'black' : ''} />,
    onMatch: () => {
      const { setTitle } = useStore.getState()
      iframe(events.dgp)
      setTitle(pageTitles.DESIGN_GALLERY)
    },
    element: (
      <TourGuard>
        <GalleryScreen />
      </TourGuard>
    ),
  },
  {
    path: '/floorplans',
    title: pageTitles.FLOOR_PLANS,
    icon: (active) => <FloorPlansIcon stroke={active ? 'black' : ''} />,
    onMatch: () => {
      const { setTitle } = useStore.getState()
      iframe(events.fpp)
      setTitle(pageTitles.FLOOR_PLANS)
    },
    element: (
      <TourGuard>
        <FloorPlansScreen />
      </TourGuard>
    ),
  },
  {
    path: '/*',
    element: <NotFoundScreen />,
  },
]

export function generateRoutes() {
  const hiddenScreensList = getHiddenScreensList()
  const routes = definedRoutes.filter(
    (route) => !route.path || !hiddenScreensList.includes(route.path.replace('/', '')),
  )

  routes.push({
    element: <Navigate to={getDefaultRoute(routes)} />,
  })
  return routes
}
