import logo from 'shared/assets/images/sparx-logo.png'

export default function NotFoundScreen() {
  return (
    <div className="h-full w-screen flex justify-center items-center">
      <div className="flex flex-col items-center">
        <img src={logo} className="h-[32px]" alt="sparx-logo" />
        <div className="text-3xl uppercase font-bold mt-5">Page not found</div>
      </div>
    </div>
  )
}