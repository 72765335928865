import { useEffect } from 'react'
import { useStore } from 'Resident/store'

export function useScreen(screen) {
  const {setCurrentScreen} = useStore()
  useEffect(() => {
    setCurrentScreen(screen)
    return () => {
      setCurrentScreen(null)
    }
  }, [screen, setCurrentScreen])
}
