import React from 'react'
import { ReactComponent as ErrorIcon } from 'shared/assets/icons/resident-error-reload.svg'
import Button from 'shared/components/Button'

function DataLoadError({onRetry}) {
  return (
    <div className="flex flex-col items-center">
      <ErrorIcon />
      <div className="mt-[24px] font-semibold text-[20px]">Failed to receive data</div>
      <div className="leading-[21px] text-[#5F6787] mt-[16px] text-center">
        We can’t reach SparX servers at
        <br /> the moment
      </div>
      <Button rounded className="w-[206px] mt-[42px]" onClick={()=>{onRetry ? onRetry() : window.location.reload()}}>
        Reload
      </Button>
    </div>
  )
}

export default DataLoadError
