import { getApartmentsForAgent } from 'CRM/services/apartments'
import { getDummySystemUserToken, getUser } from 'CRM/services/user'
import { useStore } from 'CRM/store'
import React, { useEffect, useMemo, useState } from 'react'
import { startHotJarRecording } from 'shared/analytics/hotjar'
import Loader from 'shared/components/Loader'
import AccessSplash from '../AccessSplash'
import { isAccessErrorResponse } from 'shared/helpers/api'

function ContentGuard({ children }) {
  const { setProperties, setSelectedProperty, selectedProperty, setUser, properties, user, setFakeToken, fakeToken } =
    useStore()
  const [loading, setLoading] = useState(true)
  const [accessError, setAccessError] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (!fakeToken) {
        try {
          const { data } = await getDummySystemUserToken()
          setFakeToken(data.token)
        } catch (error) {
          if (isAccessErrorResponse(error.response.status)) {
            setAccessError(true)
            setLoading(false)
          }
        }
      }
    })()
  }, [fakeToken, setFakeToken])

  useEffect(() => {
    ;(async () => {
      if (!user) {
        const { data: userData } = await getUser()
        setUser(userData)
      }
    })()
  }, [setUser, user])

  useEffect(() => {
    ;(async () => {
      if (user && selectedProperty === null) {
        let { data: propertiesData } = await getApartmentsForAgent(user.id)
        const proper = propertiesData.properties_by_user_id.map((p) => ({
          ...p,
          unit_types: p.unit_types.map((t) => ({
            ...t,
            unit_types_configuration_options: t.unit_types_configuration_options || {},
          })),
        }))

        const properties = proper.filter(
          ({ unit_types, properties_configuration_options, agents }) =>
            (unit_types.some(
              ({ unit_types_configuration_options }) =>
                unit_types_configuration_options && unit_types_configuration_options.ready_for_publish,
            ) &&
              properties_configuration_options?.contract_is_valid === true &&
              agents.some((a) => a.user?.id === user.id)) ||
            user.is_admin,
        )

        if (properties.length > 0) {
          const storedSelectedProperty = sessionStorage.getItem('selectedPropertyId')
          setSelectedProperty(properties.find((p) => p.id === Number(storedSelectedProperty)) || properties[0])
        } else {
          setSelectedProperty({})
        }
        setProperties(properties)
      }
    })()
  }, [user, selectedProperty, setProperties, setSelectedProperty, properties])

  useEffect(() => {
    if (selectedProperty !== null && user && fakeToken) {
      setLoading(false)
      startHotJarRecording()
    }
  }, [user, fakeToken, selectedProperty])

  const hasAccess = useMemo(() => {
    if (accessError) {
      return false
    }
    return properties?.length > 0
  }, [properties, accessError])

  return !loading ? (
    hasAccess ? (
      children
    ) : (
      <AccessSplash />
    )
  ) : (
    <div className="h-full w-full min-h-[inherit] flex items-center justify-center overflow-hidden">
      <Loader />
    </div>
  )
}

export default ContentGuard
