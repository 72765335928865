import { useStore } from 'TourApp/store'
import { useMemo } from 'react'

export function useApartment() {
  const { tourData, selectedApartmentId } = useStore()

  const { units } = tourData

  const apartment = useMemo(() => {
    return units.find(({ unit }) => unit.id === selectedApartmentId) || null
  }, [selectedApartmentId, units])

  return apartment
}
