import React, { useState } from 'react'
import { events, posttour } from 'shared/analytics'
import { feedbackUrl, sparxUrl } from 'shared/settings'
import { ReactComponent as AppStoreIcon } from 'shared/assets/icons/appstore.svg'
import { ReactComponent as SparxLogoBlack } from './icons/sparx-black.svg'
// Images
import logo from 'shared/assets/images/sparx-logo-white.png'
import TermsModal from '../TermsModal'

function Footer({ className, eventsSpace, poweredByText, minimal = false, appStoreLink, disableLink }) {
  const [termsModalShown, setTermsModalShown] = useState(false)
  const goToUrl = (name, url) => {
    if (eventsSpace === 'posttour') posttour(name, () => ({ url }))
  }

  function openTermsModal(e) {
    e.preventDefault()
    setTermsModalShown(true)
  }

  const poweredBy = (
    <span className={`font-normal text-[14px] mr-[8px] ${!disableLink ? 'underline' : 'select-none cursor-default'}`}>
      {poweredByText || 'Virtually Staged by'}
    </span>
  )

  return (
    <footer
      className={`lg:bg-black md:font-normal lg:font-medium md:text-black md:h-[48px] lg:h-[30px] md:bg-[#FAFAFA] md:border-t-[1px] md:border-[#C7CCD9] md:border-solid lg:border-none w-full flex items-center lg:text-white px-[20px] flex-wrap ${className}`}
    >
      <div className="w-1/2 flex items-center">
        {!minimal && (
          <>
            {!disableLink ? (
              <a href="!#" onClick={openTermsModal}>
                {poweredBy}
              </a>
            ) : (
              poweredBy
            )}
          </>
        )}
        <a href={sparxUrl} target="_blank" rel="noreferrer" onClick={() => goToUrl(events.fll, sparxUrl)}>
          <span className='lg:inline-block hidden'><img src={logo} className="h-[13px]" alt="sparx-logo" /></span>
          <span className="lg:hidden">
            <SparxLogoBlack />
          </span>
        </a>
        {appStoreLink && (
          <a href={appStoreLink} target="_blank" className="ml-[16px] hidden lg:inline" rel="noreferrer">
            <AppStoreIcon />
          </a>
        )}
      </div>
      <div className="w-1/2 flex justify-end text-[14px]">
        © All Rights Reserved, SparX Inc. {new Date().getFullYear()}
      </div>
      {termsModalShown && <TermsModal onClose={setTermsModalShown} />}
    </footer>
  )
}

export default Footer
