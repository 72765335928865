import * as React from "react"
const SettingsIcon = (props) => (
  <svg
    width={18}
    height={19}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.51111 2.67067C7.88978 1.10978 10.1102 1.10978 10.4889 2.67067C10.5457 2.90515 10.6571 3.12291 10.814 3.30622C10.9708 3.48953 11.1688 3.6332 11.3917 3.72555C11.6146 3.8179 11.8561 3.85631 12.0967 3.83766C12.3372 3.81901 12.57 3.74382 12.776 3.61822C14.1476 2.78267 15.7182 4.35244 14.8827 5.72489C14.7572 5.9308 14.6822 6.16341 14.6636 6.4038C14.645 6.64419 14.6833 6.88557 14.7756 7.10834C14.8678 7.3311 15.0113 7.52896 15.1944 7.68582C15.3775 7.84269 15.5951 7.95414 15.8293 8.01111C17.3902 8.38978 17.3902 10.6102 15.8293 10.9889C15.5948 11.0457 15.3771 11.1571 15.1938 11.314C15.0105 11.4708 14.8668 11.6688 14.7744 11.8917C14.6821 12.1146 14.6437 12.3561 14.6623 12.5967C14.681 12.8372 14.7562 13.07 14.8818 13.276C15.7173 14.6476 14.1476 16.2182 12.7751 15.3827C12.5692 15.2572 12.3366 15.1822 12.0962 15.1636C11.8558 15.145 11.6144 15.1833 11.3917 15.2756C11.1689 15.3678 10.971 15.5113 10.8142 15.6944C10.6573 15.8775 10.5459 16.0951 10.4889 16.3293C10.1102 17.8902 7.88978 17.8902 7.51111 16.3293C7.4543 16.0948 7.34291 15.8771 7.18604 15.6938C7.02916 15.5105 6.83121 15.3668 6.60831 15.2744C6.38541 15.1821 6.14385 15.1437 5.9033 15.1623C5.66275 15.181 5.43 15.2562 5.224 15.3818C3.85244 16.2173 2.28178 14.6476 3.11733 13.2751C3.24276 13.0692 3.31782 12.8366 3.33643 12.5962C3.35504 12.3558 3.31666 12.1144 3.22442 11.8917C3.13218 11.6689 2.98868 11.471 2.80558 11.3142C2.62248 11.1573 2.40495 11.0459 2.17067 10.9889C0.609778 10.6102 0.609778 8.38978 2.17067 8.01111C2.40515 7.9543 2.62291 7.84291 2.80622 7.68604C2.98953 7.52916 3.1332 7.33121 3.22555 7.10831C3.3179 6.88541 3.35631 6.64385 3.33766 6.4033C3.31901 6.16275 3.24382 5.93 3.11822 5.724C2.28267 4.35244 3.85244 2.78178 5.22489 3.61733C6.11378 4.15778 7.26578 3.67956 7.51111 2.67067Z"
      stroke={props.stroke || "black"}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.33203 9.50016C6.33203 10.2074 6.61298 10.8857 7.11308 11.3858C7.61318 11.8859 8.29145 12.1668 8.9987 12.1668C9.70594 12.1668 10.3842 11.8859 10.8843 11.3858C11.3844 10.8857 11.6654 10.2074 11.6654 9.50016C11.6654 8.79292 11.3844 8.11464 10.8843 7.61454C10.3842 7.11445 9.70594 6.8335 8.9987 6.8335C8.29145 6.8335 7.61318 7.11445 7.11308 7.61454C6.61298 8.11464 6.33203 8.79292 6.33203 9.50016Z"
      stroke={props.stroke || "black"}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SettingsIcon
