import { useState } from "react"
import Loader from "shared/components/Loader"

export function Image({ className, ...props }) {
  const [loading, setLoading] = useState(true)
  return (
    <>
      <img
        alt=""
        className={`${className} ${loading ? 'hidden' : ''}`}
        {...props}
        onLoad={() => {
          setLoading(false)
        }}
      />
      {loading && (
        <div className="h-full w-full absolute left-0 flex justify-center items-center">
          <Loader />
        </div>
      )}
    </>
  )
}
