import React from 'react'
import cortBadge from 'shared/assets/images/cort-badge.png'

function CortBadge({className = ''}) {
  return (
    <img
      src={cortBadge}
      alt="cort"
      className={`absolute top-[72px] lg:top-0 right-[26px] z-[200] h-[60px] ${className}`}
    />
  )
}

export default CortBadge
