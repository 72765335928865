import { objectCopy } from 'shared/helpers/general'

export const generalStoreCreator = function (setter) {
  return {
    title: 'CRM',
    setTitle: (title) => {
      document.title = `${title} - SparX - CRM`
      setter(() => ({ title }))
    },
    selectedProperty: null,
    setSelectedProperty: (selectedProperty) => {
      if(selectedProperty === null){
        sessionStorage.removeItem('selectedPropertyId')
        return
      }
      sessionStorage.setItem('selectedPropertyId', selectedProperty.id)
      setter(() => ({ selectedProperty }))
    },
    properties: [],
    setProperties: (properties) => {
      setter(() => ({ properties }))
    },
    user: null,
    setUser: (user) => {
      setter(() => ({ user }))
    },
    lastCopiedUnit: [],
    setLastCopiedUnit: (lastCopiedUnit) => {
      setter(() => ({ lastCopiedUnit }))
    },
    fakeToken: null,
    setFakeToken: (fakeToken) => {
      setter(() => ({ fakeToken }))
    },
    setUnitVacant: (property, unitTypeId, unitId, vacant) => {
      setter(({ properties }) => {
        const propertiesCopy = objectCopy(properties)
        const propertyIndex = propertiesCopy.findIndex(({ id }) => property.id === id)
        const unitTypeIndex = propertiesCopy[propertyIndex].unit_types.findIndex(({ id }) => id === unitTypeId)
        const unitIndex = propertiesCopy[propertyIndex].unit_types[unitTypeIndex].units.findIndex(
          ({ id }) => id === unitId,
        )
        propertiesCopy[propertyIndex].unit_types[unitTypeIndex].units[unitIndex].vacant = vacant
        return {
          properties: propertiesCopy,
        }
      })
    },
  }
}
