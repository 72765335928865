import React from 'react'

import { Link, useLocation } from '@tanstack/react-location'
import { ROUTES } from '../../common/constants'
import logo from 'shared/assets/images/sparx-logo-white.png'
import { ReactComponent as BackButtonIcon } from 'shared/assets/icons/arrowLeft.svg'

function Header({ title }) {
  const location = useLocation()

  return (
    <nav className="h-[50px] px-[33px] flex items-center border-solid border-[#C7CCD9] border-b-[1px] box-border bg-[#231260] text-white">
      {
        location.current.pathname !== ROUTES.HOME && 
        <button className="z-10" onClick={() => { location.history.back() }}>
          <BackButtonIcon className='text-white' />
        </button>
      }
      {title && <div className="absolute mx-auto left-0 right-0 text-center">{title}</div>}
      <Link to="/actions" className="flex items-center justify-end ml-auto z-10">
        <img src={logo} className="h-[20px] inline-flex mr-[5px]" alt="logo" />
        <span className="text-2xl font-light uppercase opacity-70">CMS</span>
      </Link>
    </nav>
  )
}

export default Header
