import { isAgentApp } from 'Resident/helpers/app'
import { useStore } from 'Resident/store'
import { ReactComponent as ArrowRightDotsPopupIcon } from 'shared/assets/icons/arrowRightDotsPopup.svg'

function Divider({ className = '' }) {
  return <div className={`bg-[#E5E7ED] h-[1px] ml-[-17px] mr-[-17px] ${className}`}></div>
}

function PackageAssetPopover({
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  layout,
  vendor,
  dimensions,
  rent,
  asset = {},
  ...props
}) {
  const { appType } = useStore()

  function showPrices() {
    return true
  }

  function isRent() {
    return rent
  }

  function purchaseType() {
    return 'rent'
  }

  const name = isAgentApp(appType)
    ? (asset.asset || {}).asset_name
    : (asset.category || {}).category_name || (asset.asset || {}).asset_name

  function isCore() {
    return asset.type === 'core'
  }

  return (
    <div className="pl-[22px]" onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
      <div
        className="w-[237px] rounded-[8px] border-[1px] border-[#E5E7ED] border-solid bg-white p-[16px] pt-[13px] pb-0 relative hidden md:block"
        onMouseLeave={onMouseLeave}
      >
        <div className="h-full flex flex-col justify-between ">
          <div className="mb-[12px]">
            <div className="flex justify-between items-center text-[16px] leading-[20px] mb-[5px]">
              <div
                data-name="assetname"
                className="font-semibold text-left hover:underline"
                // onMouseEnter={onAssetNameHover}
              >
                {name}
              </div>
              {/* <div>
              <ArrowRightDotsPopupIcon />
            </div> */}
            </div>
            <div className="text-left text-[#5F6787] text-[14px] leading-[14px] font-medium">
              By <span className="uppercase">{vendor}</span>
            </div>
            <div className={`flex items-end ${showPrices() ? 'justify-between' : 'justify-end'}`}>
              {dimensions && (
                <div className="text-[12px] text-[#5F6787] font-medium mt-[26px] leading-[12px]">
                  W{dimensions.width} × D{dimensions.depth} × H{dimensions.height}
                </div>
              )}
            </div>
          </div>
          {(!props.emptyImage || (props.emptyImage && showPrices())) && !isAgentApp(appType) && <Divider />}
          {!showPrices() && (
            <div
              className={`text-[12px]  text-[#5F6787] font-medium ${
                props.emptyImage ? 'mt-[10px]' : 'my-[10px]'
              } leading-[10px]`}
            >
              W{dimensions.width} × D{dimensions.depth} × H{dimensions.height}
            </div>
          )}
          {showPrices() && !isAgentApp(appType) &&  (
            <div className={`flex items-center my-[12px] ${props.emptyImage ? 'mb-0' : ''}`}>
              <div
                className={`uppercase ${
                  isRent() ? 'bg-[#A93DCB]' : 'bg-[#387EE5]'
                } text-white font-semibold rounded-full leading-[10px] w-[42px] h-[18px] text-[10px] flex items-center justify-center`}
              >
                {purchaseType(rent)}
              </div>
              {!isCore() && (
                <div className="ml-[10px] font-medium text-[14px]">
                  {!asset.added && !isCore() && <span>+</span>}${asset.price.toLocaleString()}
                  {isRent() && <>{rent ? <span>/mo</span> : ''}</>}
                </div>
              )}
              {isCore() && <div className="text-[12px] ml-[8px] font-medium text-[#A93DCB]">Base Package</div>}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PackageAssetPopover
