import { ApolloClient, gql, InMemoryCache } from '@apollo/client'
import { getAuth, validateToken } from 'CRM/helpers/auth'
import { properties_configuration_options, unit_types_configuration_options } from 'shared/gql'
import { apiHost } from 'shared/helpers/api'
import { mutate, query } from 'shared/helpers/graphql'

export async function getInvitesForAgent(agentId) {
  return await query({
    variables: {
      agentId,
    },
    query: gql`
      query Prospects_invitations_by_agent_id($agentId: Int) {
        prospects_invitations_by_agent_id(agent_id: $agentId) {
          user {
            first_name
            last_name
            email
            id
          }
          unit {
            name
            id
            unit_number
            unit_type {
              id
              name
              property {
                id
                ${properties_configuration_options}
                building_number
              }
              ${unit_types_configuration_options}
            }
            building_name
          }
          invitation_link_web
          invitation_link_ios
          invitation_text
          last_sent_date
          invitation_status
        }
      }
    `,
  })
}

export async function upsertInvite(payload) {
  return mutate(
    {
      prospects: [payload],
    },
    gql`
      mutation Upsert_prospect_invitations($prospects: [prospects_invitation_input]) {
        upsert_prospect_invitations(prospects: $prospects) {
          id
          invitation_status
          last_sent_date
          invitation_text
        }
      }
    `,
  )
}

export async function deleteInvite(unitId, userId) {
  return mutate(
    {
      unitId,
      userId,
    },
    gql`
      mutation Delete_prospects_invitation($unitId: Int!, $userId: Int!) {
        delete_prospects_invitation(unit_id: $unitId, user_id: $userId) {
          last_sent_date
        }
      }
    `,
  )
}

export async function upsertApartmentPageInvitation(invitation_id, link, status = 'pending') {
  return mutate(
    {
      apartmentPageInvitations: [
        {
          id: invitation_id,
          invitation_status: status,
          ...(link ? { invitation: link } : {}),
        },
      ],
    },
    gql`
      mutation Mutation($apartmentPageInvitations: [apartment_page_invitations_input]) {
        upsert_apartment_page_invitations(apartment_page_invitations: $apartmentPageInvitations) {
          id
          invitation
          invitation_status
        }
      }
    `,
    {anonymous: true}
  )
}
