import AuthLayout from 'Resident/layouts/AuthLayout'
import FooterLayout from 'Resident/layouts/FooterLayout'
import React, { useCallback, useEffect, useState } from 'react'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
// Icons
import { ReactComponent as SuccessIcon } from 'shared/assets/icons/resident-password-set-success.svg'
import { useSetPasswordForm } from 'shared/hooks/forms/useSetPasswordForm'
import ErrorMessage from 'shared/components/ErrorMessage'
import { setPassword } from 'CRM/services/user'
import { useStore } from 'Resident/store'
import { upsertProspect } from 'Resident/services/user'
import { setAuth } from 'CRM/helpers/auth'
import { useSplashError } from 'shared/components/ErrorSplash'
import { useNavigate } from 'react-router-dom'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { group3 } from 'shared/analytics/resident'

const ESignupStages = {
  SET_PASSWORD: 'SET_PASSWORD',
  PASSWORD_SET_DONE: 'PASSWORD_SET_DONE',
}

function SetPasswordScreen(props) {
  const [stage, setStage] = useState(ESignupStages.SET_PASSWORD)
  const [loading, setLoading] = useState(false)
  const { appData } = useStore()
  const navigate = useNavigate()
  const splashError = useSplashError()

  useEffect(()=>{
    if(appData && appData.user && appData.user.prospects[0].invitation_status !== 'accepted'){
      rpevent('signup_launched', group3)
    }
  }, [appData])

  const onSubmit = useCallback(
    async (values) => {
      setLoading(true)
      try {
        const { data } = await setPassword(values.password, appData.code)
       
        setAuth(data)
        await upsertProspect({
          user_id: appData.user.id,
          unit_id: appData.unit.id,
          invitation_status: 'accepted',
        })
        if (appData.user.prospects[0].invitation_status !== 'accepted') {
          rpevent(eventDictionary.signup_password_set, group3)
          navigate('/review-profile')
        } else {
          rpevent(eventDictionary.forgot_password_password_set)
          setStage(ESignupStages.PASSWORD_SET_DONE)
        }
      } catch (error) {
        splashError.show()
      } finally {
        setLoading(false)
      }
    },
    [setLoading, appData, navigate, splashError],
  )

  const { form, validateForm } = useSetPasswordForm(onSubmit)

  function isStage(target) {
    return stage === target
  }

  function onContinue(){
    navigate('/packages')
  }

  return (
    <FooterLayout>
      <AuthLayout>
        {isStage(ESignupStages.SET_PASSWORD) && (
          <div className="h-full flex flex-col items-center w-full">
            <div className="font-medium text-center w-[280px] leading-[21px] my-[30px]">
              Your email has been verified successfully! Please set a password:
            </div>
            <form className="w-full flex flex-col justify-between flex-1" onSubmit={form.handleSubmit}>
              <div>
                <div>
                  <Input
                    onChange={form.handleChange}
                    onBlur={() => form.validateField('password')}
                    value={form.values.password}
                    required
                    error={form.errors.password}
                    name="password"
                    type="password"
                    placeholder="New Password"
                  />
                  <ErrorMessage text={form.errors.password} />
                </div>
                <div className="mt-[20px]">
                  <Input
                    onChange={form.handleChange}
                    onBlur={() => form.validateField('repeatPassword')}
                    value={form.values.repeatPassword}
                    required
                    error={form.errors.repeatPassword}
                    name="repeatPassword"
                    type="password"
                    placeholder="Re-Enter New Password"
                  />
                  <ErrorMessage text={form.errors.repeatPassword} />
                </div>
              </div>
              <div className="lg:mt-[110px]">
                <Button
                  loading={loading}
                  disabled={!validateForm() || loading}
                  rounded
                  type="submit"
                  className="w-full mt-[16px]"
                >
                  Continue
                </Button>
              </div>
            </form>
          </div>
        )}
        {isStage(ESignupStages.PASSWORD_SET_DONE) && (
          <div className="h-full flex flex-col items-center justify-between w-full text-center">
            <div></div>
            <div className="flex flex-col justify-center items-center">
              <SuccessIcon />
              <div className="mt-[28px] text-[18px] font-semibold">All set!</div>
              <div className="text-[18px]">You are signed in.</div>
            </div>
            <div className="w-full">
              <Button className="w-full mt-[16px]" rounded onClick={onContinue}>
                Continue
              </Button>
            </div>
          </div>
        )}
      </AuthLayout>
    </FooterLayout>
  )
}

export default SetPasswordScreen
