import { useEffect } from 'react'
import { Link, useSearch, useLocation } from '@tanstack/react-location'

import { useStore } from 'IFrameApp/store'
import { navLinks } from 'IFrameApp/navigation'

import { events, iframe } from 'shared/analytics'
import { getUnitByUnitId, getVideosByUnitTypeId } from 'IFrameApp/services/tour.service'

import Loader from 'shared/components/Loader'
import Sidebar from '../Sidebar'
import BookButton from '../BookButton'

import './styles.scss'

function TourGuard({ children }) {
  const { tourData, setTourData, setSelectedApartmentId, setParentQueryParams, sidebar } = useStore()
  const VIDEOS = tourData?.videos?.filter((v) => v.is_official && v.is_verified)
  const search = useSearch()
  const location = useLocation()
  const portraitistClassName =
    location.current.pathname === '/gallery'
      ? 'portrait:max-h-[calc(100%-48px-156px)] mt-[-22px] lg:mt-0'
      : 'portrait:max-h-[calc(100%-48px-144px)]'

  useEffect(() => {
    setParentQueryParams(search)
  }, [setParentQueryParams])

  useEffect(() => {
    async function fetchData() {
      const searchUnitId =
        process.env.NODE_ENV === 'development'
          ? search.unitId || +localStorage.getItem('devUnitId') || 1
          : search.unitId
      if (!tourData) {
        const unitResponse = await getUnitByUnitId(searchUnitId)
        const unit = unitResponse.data.units_by_id
        const videosResponse = await getVideosByUnitTypeId(unit.unit_type.id)
        const videos = videosResponse.data.videos_by_unit_type_id

        setTourData(
          JSON.parse(
            JSON.stringify({
              unit,
              videos,
            }),
          ),
        )
        setSelectedApartmentId(searchUnitId)
      }
    }
    fetchData()
  }, [setTourData, tourData, search, setSelectedApartmentId])

  function onMenuLinkClick(key) {
    const eventNames = {
      gallery: events.mdgc,
      floorplans: events.mfpc,
      videos: events.mfvc,
    }
    iframe(eventNames[key.replace("/", '')])
  }

  function menuRenderer() {
    const links = navLinks.filter((l) => {
      const unit = tourData?.unit
      switch (l.path) {
        case 'gallery':
          return unit.unit_type.unit_type_levels[0].rooms.length > 0
        case 'floorplans':
          return unit.unit_type.unit_type_levels[0].unit_plans && unit.unit_type.unit_type_levels[0].unit_plans.length > 0
        case 'videos':
          return VIDEOS?.length > 0
        default:
          return true
      }
    })

    return (
      <>
        <nav className="nav h-[48px] flex lg:hidden bg-white border-b-[#E5E7ED] border-b-[1px] border-solid relative z-10">
          {links.map((l) => (
            <Link
              to={`/${l.path}`}
              key={l.path}
              className="screen-link w-1/3 h-full text-[12px] font-semibold flex items-center justify-center"
              data-test-id={l.path}
              onClick={() => onMenuLinkClick(l.path)}
            >
              {l.title}
            </Link>
          ))}
        </nav>
        <Sidebar links={links} unit={tourData?.unit} />
      </>
    )
  }

  return tourData ? (
    <div className="w-full portrait:h-full lg:h-full flex flex-col lg:flex-row">
      {menuRenderer()}
      <div
        className={`w-full h-full lg:landscape:h-full landscape:h-[calc(100vh-48px)] ${portraitistClassName} transition-all duration-300
ease-out lg:max-h-full ${sidebar.minimized ? 'lg:w-[calc(100%-56px)]' : 'lg:w-[calc(100%-213px)]'} bg-[#FAFAFA]`}
      >
        {children}
      </div>
      <BookButton withPhone className="lg:hidden bg-white px-[24px] py-[30px] rounded-t-[16px]" />
    </div>
  ) : (
    <div className="w-full h-full flex justify-center items-center">
      <Loader />
    </div>
  )
}

export default TourGuard
