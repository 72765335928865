import { useState } from 'react'
import BottomDrawer from 'shared/components/BottomDrawer'

export function Dropdown({
  items = [],
  disabled,
  placeholder = 'Select ...',
  className,
  value,
  onChange,
  name,
  drawerMode,
}) {
  const [drawerShown, setDrawerShown] = useState(false)

  return (
    <>
      <select
        name={name || `select-${Date.now()}`}
        disabled={disabled}
        className={`min-w-[140px] px-[16px] py-[10px] rounded-[3px] border-[1px] border-[#C7CCD9] border-solid bg-white ${
          disabled ? 'opacity-50 pointer-events-none' : ''
        } ${className || ''}`}
        value={value}
        onMouseDown={(e) => {
          if (drawerMode) {
            e.preventDefault()
            setDrawerShown(!drawerShown)
          }
        }}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="" className="text-[#5F6787] text-[14px] font-medium">
          {!value || value.length === 0 ? placeholder : `All`}
        </option>
        {items.map((item, index) => (
          <option
            key={index}
            value={item.value !== undefined ? item.value : item}
            className="text-[#5F6787] text-[14px] font-medium"
          >
            {item.label !== undefined ? item.label : item}
          </option>
        ))}
      </select>
      <BottomDrawer
        onClose={() => {
          setDrawerShown(false)
        }}
        isOpen={drawerShown}
      >
        <div className="uppercase font-semibold text-[14px] text-[#C7CCD9] mb-[22px]">choose</div>
        <div className="font-semibold text-black">{placeholder}</div>
        <div className="max-h-[270px] overflow-y-auto mt-[6px]">
          {items.map((item, index) => (
            <>
              <button
                type="button"
                onClick={() => {
                  setDrawerShown(!drawerShown)
                  item.value ? onChange(item.value) : onChange(item)
                }}
                className="block w-full"
              >
                <div
                  className={`${item.value === value ? 'text-[#A93DCB]' : 'text-black'}  text-medium h-[54px] ${
                    index > 0 ? 'border-t-[1px]' : ''
                  } border-[#C7CCD9] border-solid flex items-center`}
                >
                  {item.label || item}
                </div>
              </button>
            </>
          ))}
        </div>
      </BottomDrawer>
    </>
  )
}
