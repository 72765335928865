import { generateRoutes } from './routes'

export const routes = generateRoutes()

export const navLinks = routes
  .filter(({ title }) => !!title)
  .map(({ path, title, icon }) => ({
    path,
    title,
    icon,
  }))
