import { useMemo, useState } from 'react'
import { useStore } from 'CRM/store'
import { PreviewModal } from '../PreviewModal'
import { ReactComponent as EyeMedViolet } from 'shared/assets/icons/eye-med-violet.svg'
import { LineCopyButton } from '../LineCopy'
import { ReactComponent as CopyMedViolet } from 'shared/assets/icons/copy-med-violet.svg'
import { ReactComponent as ArrowDownViolet } from 'shared/assets/icons/arrow-violet-down.svg'
import { ReactComponent as ArrowDownMobileViolet } from 'shared/assets/icons/mobile-arrow-crm-violet.svg'
import { ReactComponent as CrmCopyCode } from 'shared/assets/icons/crm-code-copy.svg'
import { ReactComponent as CrmSofaBig } from 'shared/assets/icons/crm-sofa-big.svg'
import { ReactComponent as EyeMobileViolet } from 'shared/assets/icons/eye-crm-mobile-violet.svg'
import { objectCopy, safeConfigurationOptions } from 'shared/helpers/general'
import { UnitItem } from '../Unit'
import { apartmentPageHost } from '../../helpers'
import { LineHtmlCopyButton } from '../LineHtmlCopy'
import DownloadContentButton from '../DownloadContentButton'
import { InstantAppLinkCopy } from '../InstantAppLinkCopy'

export function LineSection({ unitType, searchFilterValue, buildingFilterValue, property }) {
  const [previewTarget, setPreviewTarget] = useState(false)
  const [toggled, setToggled] = useState(true)
  const { user } = useStore()

  function getHumanLabelBathrooms() {
    const { number_of_bathrooms } = unitType
    return number_of_bathrooms > 1 ? `${number_of_bathrooms} bath` : `${number_of_bathrooms} bath`
  }

  function getHumanLabelBedrooms() {
    const { number_of_bedrooms } = unitType
    if (number_of_bedrooms === 0) {
      return 'Studio'
    }
    return number_of_bedrooms > 1 ? `${number_of_bedrooms} bedrooms` : `${number_of_bedrooms} bedroom`
  }

  const units = useMemo(() => {
    return unitType.units
      .filter((unit) =>
        searchFilterValue
          ? String(unit.unit_number.toLowerCase()).includes(String(searchFilterValue.replace('#', '').toLowerCase()))
          : true,
      )
      .filter(({ building_name }) => building_name === buildingFilterValue)
  }, [unitType, searchFilterValue, buildingFilterValue])

  function hasUnits() {
    return units.length > 0
  }

  function openPreview(unit, lineApp) {
    if (lineApp) {
      const target = `https://${apartmentPageHost()}.sparx.ai/?unitId=${btoa(unit.id)}&skipAnalytics=1&agent_id=${
        user.id
      }&line_app=1&skip_cache=1`
      console.log(`Opening preview : ${target}`)
      setPreviewTarget({
        url: target,
        unit,
        lineApp,
      })
    } else {
      const target = `https://${apartmentPageHost()}.sparx.ai/?unitId=${btoa(unit.id)}&skipAnalytics=1&agent_id=${
        user.id
      }&skip_cache=1`
      console.log(`Opening preview : ${target}`)
      setPreviewTarget({
        url: target,
        unit,
        lineApp,
      })
    }
  }

  const { lp_link_as_html_support, supports_instant_resident_page } = property.properties_configuration_options

  const fourButtonsLayout = !!supports_instant_resident_page

  return (
    hasUnits() && (
      <div className="mt-[24px] lg:mt-[56px] bg-white border-[#C7CCD9] border-solid border-[1px] lg:border-[0] lg:bg-[#E5E7ED] pt-[18px] pb-0 lg:pb-[18px] lg:px-[24px] rounded-[3px] relative">
        {previewTarget && (
          <PreviewModal
            target={previewTarget}
            onClose={() => {
              setPreviewTarget(null)
            }}
          />
        )}
        <div
          className={`${
            toggled ? 'absolute top-[24px] right-[24px]' : 'absolute top-[50%] right-[24px] mt-[-8px] '
          } items-center h-[16px] hidden lg:flex`}
        >
          <button
            className="group"
            onClick={() => {
              openPreview(units[0], true)
            }}
          >
            <EyeMedViolet />
          </button>
          <div className="ml-[15px] w-[30px]">
            <LineCopyButton
              unit={units[0]}
              copyTooltip={true}
              icon={<CopyMedViolet />}
              className="!m-0 "
              extra={`line_app=1`}
              line={unitType.name}
            />
          </div>
          {lp_link_as_html_support && (
            <div className="ml-[10px] w-[30px]">
              <LineHtmlCopyButton
                unit={units[0]}
                copyTooltip={true}
                icon={<CrmCopyCode />}
                className="!m-0 "
                extra={`line_app=1`}
                line={unitType.name}
              />
            </div>
          )}

          <DownloadContentButton unit={units[0]} isLine={true} />
          {supports_instant_resident_page && (
            <InstantAppLinkCopy
              unit={units[0]}
              copyTooltip={true}
              icon={<CrmSofaBig />}
              extra={`line_app=1`}
              line={unitType.name}
            />
          )}
        </div>

        <div className="text-[#A93DCB] px-[24px] lg:px-[0]">
          <div>
            <button
              type="button"
              className={`flex items-center text-[18px] font-medium w-full justify-between lg:w-auto lg:justify-start`}
              onClick={() => {
                setToggled(!toggled)
              }}
            >
              Line {unitType.name} {unitType.nick_name ? `- ${unitType.nick_name} ` : ''}
              {unitType.unit_types_configuration_options.is_mirrored ? '(mirrored)' : ''}
              <div className={`ml-[8px]  ${toggled ? '' : 'rotate-[90deg] lg:rotate-[-90deg]'}`}>
                <span className="hidden lg:inline-block">
                  {' '}
                  <ArrowDownViolet />
                </span>
                <span className="lg:hidden">
                  <ArrowDownMobileViolet />
                </span>
              </div>
            </button>
          </div>
          <div className="opacity-80">
            {getHumanLabelBedrooms()} · {getHumanLabelBathrooms()}
            {safeConfigurationOptions(property.properties_configuration_options).show_unit_size && <span>{unitType.size ? ` · ${unitType.size} ft sq` : ''}</span>}
          </div>
        </div>
        <div className={`flex items-center ${fourButtonsLayout ? 'justify-between' : ''} mt-[24px] px-[24px] mb-[24px] lg:hidden`}>
          <button
            type="button"
            className={`bg-[rgba(229,231,237,.4)] px-[16px] h-[48px]  ${fourButtonsLayout ? ' w-[48px]' : 'w-full '}  rounded-full flex items-center justify-center text-[#A93DCB]`}
            onClick={() => {
              openPreview(units[0], true)
            }}
          >
            <EyeMobileViolet />
            {!fourButtonsLayout && <span className='ml-[6px]'>View</span>}
          </button>
          <div className={`bg-[rgba(229,231,237,.4)] ml-[10px] ${!fourButtonsLayout ? 'w-full' : ''} rounded-full overflow-hidden flex items-center justify-center text-[#A93DCB] relative`}>
            <LineCopyButton
              unit={units[0]}
              icon={<CopyMedViolet />}
              className={`!m-0 items-center flex justify-center !h-[48px] ${fourButtonsLayout ? '!w-[48px]' :'px-[16px]'}`}
              extra={`line_app=1`}
              copyTooltip
              label={!fourButtonsLayout ? `Link` : ''}
              line={unitType.name}
            />
          </div>

          <DownloadContentButton className="!h-[48px] !w-[48px]" unit={units[0]} isLine={true} />
          {supports_instant_resident_page ? (
            <InstantAppLinkCopy
              unit={units[0]}
              className="!h-[48px] !w-[48px]"
              copyTooltip={true}
              icon={<CrmSofaBig />}
              extra={`line_app=1`}
              line={unitType.name}
            />
          ) : null}
        </div>
        {toggled && (
          <>
            <div className="lg:grid-cols-4 lg:gap-[20px] lg:grid mt-[24px]  lg:mt-[32px]">
              {objectCopy(units)
                .sort((a, b) =>
                  a.unit_number.localeCompare(b.unit_number, undefined, { numeric: true, sensitivity: 'base' }),
                )
                .map((unit, index) => (
                  <UnitItem key={index} property={property} openPreview={openPreview} unitType={unitType} unit={unit} />
                ))}
            </div>
          </>
        )}
        {/* {!toggled && <div className="bg-[#C7CCD9] h-[1px] my-[48px]"></div>} */}
      </div>
    )
  )
}
