import React, { useState } from 'react'
import Input from 'shared/components/Input'
import ImageUploaders from '../../components/ImageUploaders'
import ActionSuccessModal from 'MediaManager/components/ActionSuccessModal'
import Button from 'shared/components/Button'
import { deletePhotos, upsertPhotos } from 'MediaManager/services'
import { uploadFileToS3 } from 'MediaManager/helpers/s3'
import { whitespacesToUnderscore } from 'shared/helpers/general'

function AmenitiesEditor({ formValues, onChange, setFormValues, properties, photo }) {
  const [rawImage, setRawImage] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [resultValue, setResultValue] = useState()

  function resetForm() {
    setRawImage(null)
    setFormValues(({ type, property }) => ({
      type,
      property,
    }))
    setResultValue(null)
  }

  function isFormValid() {
    return (rawImage || photo?.src) && formValues.displayName
  }

  function generateImageObjectKey(image) {
    const property = properties.find((p) => p.id === formValues.property)
    const [unit] = property.unit_types
    const { real_estate_company } = unit.property
    return `real_estate_companies/${real_estate_company.company_name || real_estate_company.id}/properties/${
      property.property_name || property.id
    }/amenities/${image.name}`
  }

  async function onSubmit() {
    try {
      setSubmitted(true)
      const { property, displayName } = formValues
      // Uploading of RAW image
      let rawImageUrl = photo?.src

      if (rawImage) {
        // Uploading of RAW image
        const rawImageKey = whitespacesToUnderscore(generateImageObjectKey(rawImage))
        console.log({rawImageKey})
        rawImageUrl = await uploadFileToS3(rawImage, rawImageKey)
      }

      if(photo){
        await deletePhotos(photo.id)
      }

      await upsertPhotos([
        {
          ...(photo ? {id: photo.id} : {}),
          src: rawImageUrl,
          thumbnail: rawImageUrl,
          is_verified: true,
          is_official: true,
          display_name: displayName,
          property_id: property,
        },
      ])
      setResultValue('success')
      setSubmitted(false)
    } catch (error) {
      setResultValue('error')
    } finally {
      setSubmitted(false)
    }
  }

  console.log({rawImage})

  return (
    <div>
      <div className="mt-3">
        <div className="text-sm mb-2 opacity-50">
          Display name <span className="text-[red]">*</span>
        </div>
        <Input
          placeholder="Display name"
          value={formValues.displayName || ''}
          onChange={(e) => onChange('displayName', e.target)}
        ></Input>
      </div>
      <div className="mt-5">
        <ImageUploaders onRawImagePicked={setRawImage} photo={{
            ...photo,
            raw_image: photo?.src
          }} rawImage={rawImage} />
      </div>
      <div className="flex justify-end my-[32px]">
        <div className="flex justify-end">
          <Button disabled={!isFormValid() || submitted} rounded loading={submitted} size="small" onClick={onSubmit}>
            Upload
          </Button>
        </div>
      </div>
      {resultValue && (
        <ActionSuccessModal
          action="Image"
          state={resultValue}
          skipConfirm={photo}
          onClose={() => {
            setResultValue(null)
          }}
          onConfirm={resetForm}
        />
      )}
    </div>
  )
}

export default AmenitiesEditor
