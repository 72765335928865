import { getUnitByUnitId } from 'IFrameApp/services/tour.service'
import JSZip from 'jszip'
import { getCdnAssetUrl } from 'shared/helpers/assets'

async function generateContentList(data) {
  const unit = data.units_by_id
  const { unit_type } = unit
  const videos = unit_type.videos.map((v) => ({ url: getCdnAssetUrl(v.src), folder: 'Videos' }))
  const photos = unit_type.unit_type_levels
    .map((l) => l.rooms)
    .flat()
    .map((r) => r.room_designs)
    .flat()
    .map((d) => d.photos)
    .flat()
    .map((p) => ({
      url: getCdnAssetUrl(p.src),
      folder: 'Photos',
    }))
  const amenitiesPhotos = unit_type.property.photos.map((p) => ({
    url: getCdnAssetUrl(p.src),
    folder: 'Photos',
  }))
  const floorPlans = unit_type.unit_type_levels
    .map((l) => l.unit_plans)
    .flat()
    .map((u) => ({
      url: getCdnAssetUrl(u.src),
      folder: 'Floor Plans',
    }))
    .flat()

  return [...videos, ...photos, ...amenitiesPhotos, ...floorPlans]
}

export async function downloadContentAsZip(unit, isLine) {
  const zip = new JSZip(); // Instance of JSZip
  const { data } = await getUnitByUnitId(unit.id, true);
  const unitData = data.units_by_id;
  const { unit_type } = unitData;
  const zipName = `SparX Gallery - ${isLine ? `Line ${unit_type.name}` : `${unit.unit_number}`}`;

  // Helper functions
  const sanitizeFilename = (filename) => filename.replace(/[:*?"<>|\\]/g, '_'); // Remove invalid characters
  const truncatePath = (path, maxLength = 255) => (path.length > maxLength ? path.substring(0, maxLength) : path); // Ensure path length is within Windows limit

  const content = await generateContentList(data);
  const res = await Promise.all(content.map((i) => fetch(i.url)));
  const items = await Promise.all(res.map((i) => i.blob()));

  items.forEach((item, index) => {
    const folderPath = truncatePath(sanitizeFilename(content[index].folder)); // Sanitize and truncate folder name
    const originalFilename = decodeURIComponent(content[index].url).split('/').pop();
    const sanitizedFilename = sanitizeFilename(originalFilename); // Sanitize filename
    zip.folder(folderPath).file(sanitizedFilename, item); // Add file to ZIP
  });

  const zipData = await zip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE', // Use DEFLATE compression for better compatibility
    streamFiles: true,
  });

  // Create a download link for the zip file
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(zipData);
  link.download = `${zipName}.zip`;
  link.click();
}