export const properties_configuration_options = `
    properties_configuration_options {
        support_selling_mode_in_ap
        show_design_details
        show_furniture_prices
        supports_virtual_tour
        quadratic_design_gallery
        show_prices
        is_active
        id
        show_unit_size
        building_as_property
        is_rental
        manage_vacant_units
        lp_link_as_html_support
        approves_prospect_app
        supports_furniture_as_amenity
        supports_instant_resident_page
        contract_is_valid
    }
`

export const unit_types_configuration_options = `
    unit_types_configuration_options {
        is_mirrored
        ready_for_publish
        include_in_sparx_staging
        supports_tour_page_emails
        furniture_as_amenity_ready
        id
    }
`