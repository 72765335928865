import { useEffect, useState } from 'react'
import { ReactComponent as ArrowDown } from 'shared/assets/icons/arrowDownSmall.svg'
import Carousel from 'shared/components/Carousel'

function RoomItem({ room, active, onDesignPicked, onDropdownToggled, hideDesignNames, forceCloseDropdowns, ...props }) {
  const [toggled, setToggled] = useState(false)

  function hasDesigns() {
    return room.room_designs.length > 1
  }

  useEffect(() => {
    if (!active) {
      setToggled(false)
    }
  }, [active])

  useEffect(() => {
    if (forceCloseDropdowns) {
      setToggled(false)
    }
  }, [forceCloseDropdowns])

  useEffect(() => {
    onDropdownToggled(toggled)
  }, [toggled, onDropdownToggled])

  useEffect(() => {
    const handleClick = (event) => {
      if (toggled && !event.target.closest('.room-item')) {
        setToggled(false)
      }
    }
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [toggled])

  function renderLabel(layout, index) {
    if (!layout.is_more) {
      return (
        <>
          {hideDesignNames && <>Design {index + 1}</>}
          {!hideDesignNames && (
            <div>
              {layout.design_name} by {layout.main_vendor.full_name}
            </div>
          )}
        </>
      )
    } else {
      return <>Other Images</>
    }
  }

  function getFloorLabel(level){
return level.show ? ` (${level.display_name || (level.floor_number === 0 ? 'Ground floor' : `Floor ${level.floor_number}`)})` : ''
  }

  return (
    <div className={`relative room-item z-20 ${hasDesigns() ? 'mr-[15px]' : 'mr-[35px]'}`} {...props}>
      <div
        className={`rounded-full px-[16px] ${active ? 'bg-white text-black' : 'text-white'} ${
          active ? 'justify-between' : 'justify-center'
        } ${!hasDesigns() ? '!justify-center' : ''} leading-[19px] font-medium text-[14px] h-[32px] flex items-center ${
          hasDesigns() ? 'cursor-pointer' : 'cursor-default'
        }`}
        onClick={() => {
          hasDesigns() && active && setToggled(!toggled)
        }}
      >
        {room.name || room.classification}{room.level ? getFloorLabel(room.level) : ''}
        <div className={`${hasDesigns() ? 'ml-[10px]' : 'hidden'} ${active ? 'opacity-1' : 'opacity-0'}`}>
          <ArrowDown />
        </div>
      </div>
      <div
        className={`absolute top-[40px] left-[0px] w-full ${
          toggled ? '' : 'hidden'
        } px-[16px] bg-[white] rounded-[16px] py-[12px] w-max min-w-full`}
      >
        {room.room_designs.map((layout, index) => (
          <div
            key={index}
            className={`${
              index > 0 ? 'mt-[24px]' : 'mt-0'
            } leading-[19px] font-medium text-[14px] text-black cursor-pointer hover:opacity-75`}
            onClick={(e) => {
              e.stopPropagation()
              onDesignPicked(layout)
              setToggled(false)
            }}
          >
            {renderLabel(layout, index)}
          </div>
        ))}
      </div>
    </div>
  )
}

export function RoomDesignPicker({
  rooms,
  pickedRoom,
  onDropdownToggled,
  onRoomPicked,
  hideDesignNames,
  onDesignPicked,
  onInit,
  onGalleryDim = () => {},
}) {
  const [carouselInstance, setCarouselInstance] = useState(null)
  const [dropdownToggled, setDropdownToggled] = useState()
  const [forceCloseDropdowns, setForceCloseDropdowns] = useState(false)
  useEffect(() => {
    setForceCloseDropdowns(false)
    onDropdownToggled && onDropdownToggled(dropdownToggled)
  }, [dropdownToggled, onDropdownToggled])

  function onDesignPick(design) {
    onDesignPicked(design)
    if (onGalleryDim) {
      onGalleryDim(false)
    }
  }

  useEffect(() => {
    onInit &&
      onInit({
        closeDropdowns: function () {
          setForceCloseDropdowns(true)
        },
      })
  }, [])

  function renderRooms() {
    return rooms.map((room) => (
      <RoomItem
        onClick={() => pickedRoom.id !== room.id && onRoomPicked(room)}
        active={pickedRoom.id === room.id}
        room={room}
        forceCloseDropdowns={forceCloseDropdowns}
        hideDesignNames={hideDesignNames}
        onDropdownToggled={setDropdownToggled}
        onDesignPicked={onDesignPick}
      />
    ))
  }

  useEffect(() => {
    if (carouselInstance && pickedRoom) {
      const roomIndex = rooms.findIndex((room) => room.id === pickedRoom.id)
      carouselInstance.slideTo(roomIndex, 400)
    }
  }, [pickedRoom, carouselInstance, rooms])

  return (
    <div className="absolute lg:w-full top-[26px] lg:left-0 lg:pl-[24px] left-[40px] w-[calc(100%-40px)] lg:w-initial h-[32px]">
      <Carousel
        onSwiper={setCarouselInstance}
        id="rooms-slider"
        className="!overflow-visible !z-10"
        items={renderRooms()}
        slidesPerView="auto"
        spaceBetween={0}
        slideClassName="!w-max"
      />
    </div>
  )
}
