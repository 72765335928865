import { useStore } from 'Resident/store'
import React, { useMemo } from 'react'
import { IconButton, SidebarToggleButton } from 'TourApp/components/Sidebar'
import PropertyChip from '../PropertyChip'

import { useMediaQuery } from '@react-hook/media-query'
import { usePropertyLogo } from 'Resident/hooks/usePropertyLogo'

function Sidebar({ children }) {
  const { sidebar, setSidebar } = useStore()
  const isMobile = useMediaQuery('(max-width: 1023px)')

  function toggleSidebar() {
    setSidebar({ minimized: !sidebar.minimized })
  }

  const widthStyles = useMemo(() => {
    if (isMobile) {
      return 'w-full px-[32px]'
    } else {
      return sidebar.minimized ? 'w-[56px]' : 'lg:w-[336px] px-[32px]'
    }
  }, [isMobile, sidebar])


  const { logo } = usePropertyLogo()

  return (
    <div
      className={`iframe-sidebar lg:flex h-full w-screen ${widthStyles} lg:bg-white pb-[21px] group transition-all duration-300 
  ease-out flex-col fixed z-[250] lg:relative shrink-0 overflow-x-hidden overflow-y-auto border-solid lg:border-r-[1px] border-[#C7CCD9] pt-[33px] lg:pt-0`}
    >
      <div className={`flex items-start justify-between mt-[26px] ${sidebar.minimized ? 'mb-[28px]' : 'mb-[40px]'}`}>
        {!sidebar.minimized && (
          <div className="w-[85%]">
            {' '}
            <PropertyChip pillClassName="!w-full" />
          </div>
        )}
        <SidebarToggleButton
          onToggle={toggleSidebar}
          className={`${logo ? '' : 'mt-[8px]'} relative h-[24px] hidden  lg:flex ${
            sidebar.minimized ? `justify-center px-[18px]` : `justify-end`
          }`}
          minimized={sidebar.minimized}
        />
      </div>
      {children}
    </div>
  )
}

export default Sidebar
