import React, { useState } from 'react'

import { ReactComponent as InfoSidebarIconResident } from 'shared/assets/icons/info-sidebar-icon-resident.svg'
import { ReactComponent as ArrowsHelp } from './icons/arrows-help.svg'
import { ReactComponent as Package } from './icons/package.svg'
import { ReactComponent as Count } from './icons/count.svg'
import { ReactComponent as Plus } from './icons/plus.svg'
import { ReactComponent as Trash } from './icons/trash.svg'
import Button from 'shared/components/Button'
import { Tooltip } from 'react-tooltip'
import TinyModal from 'shared/components/TinyModal'
import { useMediaQuery } from '@react-hook/media-query'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { group3 } from 'shared/analytics/resident'

function HelpContent({onClose}) {
  return (
    <div className="lg:px-[20px] z-[500] lg:py-[32px] bg-white lg:border-[1px] border-solid border-[rgba(199,204,217,0.4)] relative rounded-[8px] lg:w-[570px]">
    
      <div className="text-[18px] mb-[22px] lg:mb-[24px] text-center">Actions guide</div>
      <div className="text-[#3D4256]">
        <div className="mb-[22px] lg:mb-[24px] flex items-center">
          <Button
            className="text-[10px] lg:text-[14px] h-[22px] lg:h-[37px] !min-w-[75px] !lg:min-w-[114px] font-normal"
            rounded
            variant="secondary"
          >
            View Gallery
          </Button>
          <span className="ml-[24px] text-[14px] lg:text-[16px]">
            Explore your apartment virtually staged with package items.
          </span>
        </div>
        <div className="mb-[22px] lg:mb-[24px] flex items-center">
          <Button
            className="text-[10px] lg:text-[14px] h-[22px] lg:h-[37px] !min-w-[75px] !lg:min-w-[114px] font-normal"
            rounded
          >
            Review Cart
          </Button>
          <span className="ml-[24px] text-[14px] lg:text-[16px]">
          Review and send cart to CORT,<br className='hidden lg:inline'></br> a representative will contact you.
          </span>
        </div>
        <div className="mb-[22px] lg:mb-[24px] flex items-center">
          <div className="flex-shrink-0">
            <ArrowsHelp />
          </div>
          <span className="ml-[24px] text-[14px] lg:text-[16px]">
            Expand/Collapse to personalize your package items selection.
          </span>
        </div>
        <div className="mb-[22px] lg:mb-[24px] flex items-center">
          <Package />
          <span className="ml-[24px] text-[14px] lg:text-[16px]">Base package item (quantity fixed).</span>
        </div>
        <div className="mb-[22px] lg:mb-[24px] flex items-center">
          <Count />
          <span className="ml-[16px] text-[14px] lg:text-[16px]">Item quantity, included in price. Tap to edit.</span>
        </div>
        <div className="mb-[22px] lg:mb-[24px] flex items-center">
          <Plus />
          <span className="ml-[24px] text-[14px] lg:text-[16px]">Add Item to package.</span>
        </div>
        <div className="flex items-center">
          <Trash />
          <span className="ml-[24px] text-[14px] lg:text-[16px]">Remove Item from package.</span>
        </div>
      </div>
    </div>
  )
}

function Help({ minimal }) {
  const [modalShown, setModalShown] = useState(false)
  const isMobile = useMediaQuery('(max-width: 1023px)')
  return (
    <div className={`${!minimal ? 'lg:mb-[24px] mb-[22px]' : ''} flex  items-center relative`}>
      {modalShown && isMobile && (
        <TinyModal className="!px-[30px]">
          <HelpContent />
          <Button
            rounded
            className="w-[233px] mt-[48px]"
            onClick={() => {
              setModalShown(false)
            }}
          >
            Close
          </Button>
        </TinyModal>
      )}
      <Tooltip
        // isOpen={!isMobile && modalShown}
        id="sidebar-help-modal"
        clickable
        className="default-tooltip z-[500] fadeShow !bg-transparent !shadow-none lg:mt-[-20px] "
        positionStrategy="fixed"
        disableStyleInjection={true}
      >
        <HelpContent onClose={()=>{
          setModalShown(false)
        }} />
      </Tooltip>
      <button
        onMouseEnter={() => {
          if (!isMobile) {
            rpevent(eventDictionary.menu_help_tapped, group3)
            setModalShown(true)
          }
        }}
        type="button"
        className="cursor-default flex items-center"
        data-tooltip-place="right-start"
        data-tooltip-id="sidebar-help-modal"
        data-tooltip-position-strategy='fixed'
        onClick={() => {
          
          if (isMobile) {
            rpevent(eventDictionary.menu_help_tapped, group3)
            setModalShown(true)
          }
        }}
      >
        <InfoSidebarIconResident /> {!minimal && <span className="ml-[16px] text-[#3D4256] cursor-pointer">Help</span>}
      </button>
    </div>
  )
}

export default Help
