import React, { useEffect, useState } from 'react'
import Button from 'shared/components/Button'
import { getCdnAssetUrl } from 'shared/helpers/assets'

function LogoUpload({ logo, label, ...props }) {
  const [picked, setPicked] = useState(null)
  const [logoLocal, setLogoLocal] = useState(logo)
  function onPicked(file) {
    setPicked(file)
    props.onPicked && props.onPicked(file)
  }

  useEffect(() => {
    if (!picked) {
      setLogoLocal(logo ? getCdnAssetUrl(logo) : null)
    } else {
      setLogoLocal(URL.createObjectURL(picked))
    }
  }, [logo, picked])

  function onRemove() {
    props.onPicked(null)
    setPicked(null)
    setLogoLocal(null)
  }

  return (
    <div>
      <div className="text-sm mb-2 opacity-50">{label}</div>
      <div
        className={`w-full aspect-square p-2 rounde-lg overflow-hidden group relative rounded-xl file-upload-bg flex items-center justify-center ${!logoLocal ? 'cursor-pointer' : ''}`}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault()
          const file = e.dataTransfer.files[0]
          if (file) {
            const reader = new FileReader()
            reader.onloadend = () => {
              onPicked(file)
            }
            reader.readAsDataURL(file)
          }
        }}
        onClick={(e) => {
          if (!picked) {
            e.target.querySelectorAll('.upload-trigger')[0].click()
          }
        }}
      >
        {logoLocal && (
          <>
            <img src={logoLocal} alt="logo" className="max-w-full max-h-full object-contain" />
            <div className="absolute top-0 left-0 group-hover:flex hidden justify-center mt-5 w-full">
              <Button
                rounded
                onClick={() => {
                  onRemove()
                }}
              >
                Remove
              </Button>
            </div>
          </>
        )}

        {!logoLocal && (
          <div className="px-5">
            <span>Drag {label} here or </span>
            <label className="cursor-pointer">
              <span className="underline">select file</span>
              <input
                type="file"
                accept="image/*"
                className="hidden upload-trigger"
                onChange={(event) => {
                  const file = event.currentTarget.files[0]
                  if (file) {
                    const reader = new FileReader()
                    reader.onloadend = () => {
                      onPicked(file)
                    }
                    reader.readAsDataURL(file)
                  }
                }}
              />
            </label>
          </div>
        )}
      </div>
    </div>
  )
}

export default LogoUpload
