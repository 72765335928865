import { getAuth, setAuth } from 'CRM/helpers/auth'
import { getUser, signin } from 'CRM/services/user'
import { useCallback, useState } from 'react'
import Button from 'shared/components/Button'
import ErrorMessage from 'shared/components/ErrorMessage'
import { useSplashError } from 'shared/components/ErrorSplash'
import Input from 'shared/components/Input'
import { useSigninForm } from 'shared/hooks/forms/useSigninForm'
import { NavLink, Navigate, useNavigate } from 'react-router-dom'
import FooterLayout from 'Resident/layouts/FooterLayout'
import AuthLayout from 'Resident/layouts/AuthLayout'
import { useAnalytics } from 'shared/analytics/hooks/useAnalytics'
import { rpevent } from 'shared/analytics'
import { useStore } from 'Resident/store'

function LoginScreen(props) {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const splashError = useSplashError()
  const { setLoginFlowPassedMarker } = useStore()

  const auth = getAuth()

  const { sendOnLoad } = useAnalytics()

  sendOnLoad(()=>{
    rpevent('signin_launched')
  })

  const onSubmit = useCallback(async (values) => {
    setLoading(true)
    try {
      const { data } = await signin(values.email, values.password)
      setAuth(data)
      const user = await getUser()
      setLoginFlowPassedMarker(true)
      if (!user.data.profile_confirmed) {
        navigate('/review-profile')
      } else {
        navigate('/packages')
      }
    } catch ({ response, ...error }) {
      if (response && response.data.error) {
        form.setFieldError(
          'email',
          `Looks like we cannot log you in. Please ensure the credentials are valid and that your email is registered at SparX.`,
        )
      } else {
        splashError.show()
      }
    } finally {
      setLoading(false)
    }
  }, [])

  const { form } = useSigninForm(onSubmit)

  return (
    !auth ? <FooterLayout>
      <AuthLayout>
        <div className="h-full flex flex-col items-center w-full">
          <div className="font-medium text-center w-[280px] leading-[21px] my-[30px]">
            Please enter your name and password to sign in
          </div>
          <form className="w-full flex flex-col justify-between flex-1" onSubmit={form.handleSubmit}>
            <div className="w-full">
              <div className="w-full">
                <Input
                  name="email"
                  type="email"
                  onChange={form.handleChange}
                  onBlur={() => form.validateField('email')}
                  value={form.values.email}
                  required
                  placeholder="Email"
                  error={form.errors.email}
                />
                <ErrorMessage text={form.errors.email} />
              </div>
              <div className="w-full mt-[16px]">
                <Input
                  name="password"
                  type="password"
                  onChange={form.handleChange}
                  onBlur={() => form.validateField('password')}
                  value={form.values.password}
                  required
                  placeholder="Password"
                  error={form.errors.password}
                />
                <ErrorMessage text={form.errors.password} />
              </div>
              <div className="flex justify-end">
                <NavLink className="text-[#5F6787] text-[14px] mt-[12px] underline" to="/forgot-password">
                  Forgot Password?
                </NavLink>
              </div>
            </div>
            <div className="lg:mt-[110px]">
              {/* {appData && !isSignedUp(appData.user) && (
                <Link to="/signup">
                  <Button className="w-full" rounded variant="secondary">
                    Sign up
                  </Button>
                </Link>
              )} */}
              <Button loading={loading} type="submit" disabled={loading} className="w-full mt-[16px]" rounded>
                Sign In
              </Button>
            </div>
          </form>
        </div>
      </AuthLayout>
    </FooterLayout> : <Navigate to="/packages" replace/>
  )
}

export default LoginScreen
