import Checkbox from 'shared/components/Checkbox'
import { upsertUnit } from 'CRM/services/apartments'
import { useStore } from 'CRM/store'
import { useEffect, useState } from 'react'

export function UnitVacantToggle({ vacant, unit, unitType, property, disabled,  ...props }) {
  const { setUnitVacant } = useStore()
  const [mobileTooltipShown, setMobileTooltipShown] = useState(false)
  const [tooltipTimeout, setTooltipTimeout] = useState()
  async function toggleUnitVacant(vacant) {
    await upsertUnit(unit.id, { vacant })
    setUnitVacant(property, unitType.id, unit.id, vacant)
  }

  function onToggle(e) {
    toggleUnitVacant(e.target.checked)
    setMobileTooltipShown(e.target.checked)

    props.onToggle && props.onToggle(e.target.checked)
  }

  useEffect(() => {
    tooltipTimeout && clearTimeout(tooltipTimeout)
    if (mobileTooltipShown) {
      const timeout = setTimeout(() => {
        setMobileTooltipShown(false)
      }, 3000)
      setTooltipTimeout(timeout)
    }
  }, [mobileTooltipShown])

  return (
    <div className="relative group">
      {mobileTooltipShown && (
        <div className="z-[200] fixed bottom-[32px] left-[24px] w-[calc(100%-48px)] h-[48px] rounded-full bg-[#16C270] text-white flex justify-center items-center">
          Unit marked as vacant
        </div>
      )}
      {!vacant && (
        <div className="bg-[#16C270] top-[-42px]  absolute hidden lg:group-hover:flex items-center justify-center left-[50%] translate-x-[-50%] px-[12px] py-[8px] w-max text-[14px] rounded-[3px] text-white">
          Mark unit as vacant
        </div>
      )}
      <div className="mr-[8px]">
        <Checkbox checked={vacant} disabled={disabled} className="big green" onChange={onToggle} />
      </div>
    </div>
  )
}
