import { RouterProvider } from 'react-router-dom'
import ErrorSplashWrapper from 'shared/components/ErrorSplash'
import { router } from './routes'
import WelcomeBottomDrawer from './components/WelcomeBottomDrawer'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { useEffect } from 'react'
import HotJar from 'shared/analytics/hotjar'

function App() {
  useEffect(()=>{
    rpevent(eventDictionary.pl)
  }, [])
  return (
    <>
      <HotJar delayed={true}/>
      <ErrorSplashWrapper>
        <RouterProvider router={router} />
        <WelcomeBottomDrawer />
      </ErrorSplashWrapper>
    </>
  )
}

export default App
