import { resetPassword } from 'CRM/services/user'
import AuthLayout from 'Resident/layouts/AuthLayout'
import FooterLayout from 'Resident/layouts/FooterLayout'
import { useCallback, useState } from 'react'
import Button from 'shared/components/Button'
import ErrorMessage from 'shared/components/ErrorMessage'
import Input from 'shared/components/Input'
import { useResetPasswordForm } from 'shared/hooks/forms/useResetPasswordForm'
// Icons
import { ReactComponent as CodeSent } from 'shared/assets/icons/resident-code-sent.svg'
import { useSplashError } from 'shared/components/ErrorSplash'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { useAnalytics } from 'shared/analytics/hooks/useAnalytics'

const EStages = {
  EMAIL_ENTER: 'EMAIL_ENTER',
  LINK_SENT: 'LINK_SENT',
}

function ForgotPasswordScreen(props) {
  const [loading, setLoading] = useState(false)
  const [stage, setStage] = useState(EStages.EMAIL_ENTER)

  const splashError = useSplashError()

  const { sendOnLoad } = useAnalytics()

  sendOnLoad(()=>{
    rpevent(eventDictionary.forgot_password_launched, null)
  })

  const onSubmit = useCallback(
    async (values) => {
      setLoading(true)
      try {
        await resetPassword(values.email)
        rpevent('forgot_password_email_entered')
        setStage(EStages.LINK_SENT)
      } catch ({response}) {
        if (response && response.data.error) {
          form.setFieldError('email', response.data.error)
        } else {
          splashError.show()
        }
      } finally {
        setLoading(false)
      }
    },
    [splashError],
  )

  const { form, validateForm } = useResetPasswordForm(onSubmit)

  return (
    <FooterLayout>
      <AuthLayout>
        <div className="h-full flex flex-col items-center w-full">
          {stage === EStages.EMAIL_ENTER && (
            <>
              <div className="font-medium text-center w-[280px] leading-[21px] my-[30px]">
                To reset your password, please enter your email address
              </div>
              <form className="w-full flex flex-col justify-between flex-1" onSubmit={form.handleSubmit}>
                <div>
                  <Input
                    name="email"
                    type="email"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.email}
                    required
                    placeholder="Email"
                    error={form.errors.email}
                    className={`mt-[32px]`}
                  />
                  <ErrorMessage text={form.errors.email} />
                </div>
                <div className="lg:mt-[110px]">
                  <Button
                    loading={loading}
                    disabled={loading || !validateForm()}
                    type="submit"
                    className="w-full mt-[16px]"
                    rounded
                  >
                    Continue
                  </Button>
                </div>
              </form>
            </>
          )}
          {stage === EStages.LINK_SENT && (
            <>
              <div className="h-full flex items-center justify-center flex-col">
                <CodeSent />
                <div className="text-[18px] text-normal mt-[28px] text-center px-[16px]">
                  A verification email has been sent to your email, please check your inbox in the next few minutes to
                  continue.
                </div>
                <div className="mt-[40px]">
                  <button type="button" onClick={()=>{setStage(EStages.EMAIL_ENTER)}} className="font-semibold text-[18px] underline ">
                    I didn’t get an email
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </AuthLayout>
    </FooterLayout>
  )
}

export default ForgotPasswordScreen
