import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Select from 'shared/components/Select'
import ImageUploaders from '../../components/ImageUploaders'

import Button from 'shared/components/Button'
import { getPropertyById, getUnitType } from 'MediaManager/services/property'
import Loader from 'shared/components/Loader'
import { uploadFileToS3 } from 'MediaManager/helpers/s3'
import { deletePhotos, upsertPhotos } from 'MediaManager/services'
import ActionSuccessModal from 'MediaManager/components/ActionSuccessModal'
import ImageAssetsEditor from './components/ImageAssetsEditor'
import { arrayUniqueByKey, objectCopy, whitespacesToUnderscore } from 'shared/helpers/general'
import Input from 'shared/components/Input'
import { smartSort } from 'shared/helpers/arrays'
import { getFloorLabel } from 'shared/helpers/levels'

function WithDesignEditor({ line, formValues, onChange, property, setFormValues, editMode, photo }) {
  const [unitType, setUnitType] = useState()
  const [assetsEditorToggled, setAssetsEditorToggled] = useState(false)
  const [designAssets, setDesignAssets] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [resultValue, setResultValue] = useState()

  const resetForm = useCallback(() => {
    setRawImage(null)
    setArImage(null)
    setFormValues(({ type, property, line, unit, room }) => ({
      type,
      property,
      line,
      unit,
      room,
    }))
    setResultValue(null)
  }, [setFormValues])

  useEffect(() => {
    ;(async () => {
      if (!unitType || unitType.id !== line) {
        setUnitType(null)
        const { data } = await getUnitType(Number(line))
        setUnitType(data.unit_types[0])
        if (!editMode || !formValues.level) {
          resetForm()
        }
      }
    })()
  }, [line, unitType, formValues, onChange, setFormValues, resetForm, editMode])

  useEffect(()=>{
   if(photo){
    if(photo.src){
      setArImageURL(photo.src)
    }
    if(photo.raw_image){
      setRawImageURL(photo.raw_image)
    }
   }
  }, [photo])

  const level = useMemo(() => {
    if (unitType) {
      return unitType.unit_type_levels.find(({ id }) => formValues.level === id)
    } else {
      return null
    }
  }, [formValues.level, unitType])

  useEffect(() => {
    if (unitType && !formValues.level && unitType.unit_type_levels.length === 1) {
      onChange('level', { value: unitType.unit_type_levels[0].id })
    }
  }, [unitType, onChange, formValues])

  const unitOptions = useMemo(() => {
    if (unitType) {
      return objectCopy(unitType.units)
        .sort(smartSort('unit_number'))
        .map(({ id, unit_number }) => ({ value: id, label: unit_number }))
    }
    return []
  }, [unitType])

  const unit = useMemo(() => {
    if (unitType && formValues.unit) {
      return unitType.units.find(({ id }) => id === formValues.unit)
    }
  }, [formValues, unitType])

  const roomOptions = useMemo(() => {
    if (level && formValues.level) {
      return level.rooms
        .filter(
          (room) =>
            room.room_designs.length > 0 && room.room_designs.find((d) => d.main_vendor.id === formValues.vendor),
        )
        .sort(smartSort('name'))
        .map(({ id, name }) => ({ value: id, label: name }))
    }
    return []
  }, [formValues, level])

  const designOptions = useMemo(() => {
    if (level && formValues.level && formValues.room && formValues.vendor) {
      const room = level.rooms.find(({ id }) => id === formValues.room)
      if (room) {
        return room.room_designs
          .filter(({ main_vendor }) => main_vendor.id === formValues.vendor)
          .map(({ id, design_layout }) => ({ value: id, label: design_layout.name || `ID: ${design_layout.id}` }))
          .sort(smartSort('label'))
      }
    }
    return []
  }, [formValues, level])

  function toggleAssetsEditor() {
    setAssetsEditorToggled(!assetsEditorToggled)
  }

  // const [arImage, setArImage] = useState(null)
  // const [rawImage, setRawImage] = useState(null)
  // const [arImagePreviewURL, setArImagePreviewURL] = useState(null)
  // const [rawImagePreviewURL, setRawImagePreviewURL] = useState(null)

  function isFormValid() {
    const { unit, design } = formValues

    if (unit && design) {
      if (arImage && arAssets.length > 0) {
        return true
      }
    }
    return false
  }

  function generateImageObjectKey(image) {
    const room = level.rooms.find((r) => r.id === formValues.room)
    const design = room.room_designs.find((d) => d.id === formValues.design)
    const { real_estate_company } = unitType.property
    return `real_estate_companies/${real_estate_company.company_name || real_estate_company.id}/properties/${
      property.property_name || property.id
    }/${unitType.name || unitType.unit_type || unit.id}${unitType.unit_types_configuration_options.is_mirrored ? '_mirrored' : ''}/rooms/${
      room.name || room.id
    }/${design.main_vendor.full_name}/${image.name}`
  }

  async function onSubmit() {
    try {
      setSubmitted(true)
      const { unit } = formValues
      let arImageUrl = arImageURL
      // Uploading of AR image
      if (arImage) {
        const arImageKey = whitespacesToUnderscore(generateImageObjectKey(arImage))
        arImageUrl = await uploadFileToS3(arImage, arImageKey)
      }

      let rawImageUrl = rawImageURL

      if (rawImage) {
        // Uploading of RAW image
        const rawImageKey = whitespacesToUnderscore(generateImageObjectKey(rawImage))
        rawImageUrl = await uploadFileToS3(rawImage, rawImageKey)
      }

      //==========================================================================
      // Assets mapping
      //==========================================================================
      const notMappedAssets = designAssets
        .filter(({ id }) => !arAssets.find((asset) => asset.id === id))
        .map((asset) => ({
          asset_id: asset.id,
          // name: asset.asset_name || asset.name,
          x_axis: 110,
          y_axis: 110,
          is_core_item: asset.is_core_item || false,
        }))

      const mappedAssets = arAssets.map((asset) => ({
        asset_id: asset.id,
        // editable: asset.editable,
        // name: asset.asset_name || asset.name,
        x_axis: asset.current.leftPercent,
        y_axis: asset.current.topPercent,
        is_core_item: asset.is_core_item || false,
      }))

      const assetsPayload = [...notMappedAssets, ...mappedAssets].map(({editable, name, ...asset}) => {
        return {
          ...asset,
          // id: photo && editable ? (photo.assets.find((a) => a.asset.id === asset.asset_id) || {}).id : undefined,
        }
      })

      if(photo){
        await deletePhotos(photo.id)
      }
      // return

      await upsertPhotos([
        {
          // ...(photo ? { id: photo.id } : {}),
          src: arImageUrl,
          thumbnail: arImageUrl,
          raw_image: rawImageUrl || undefined,
          is_verified: true,
          is_official: true,
          unit_id: unit,
          direction: formValues.direction || undefined,
          room_design_id: formValues.design,
          assets: assetsPayload,
        },
      ])
      setResultValue('success')
      setSubmitted(false)
    } catch (error) {
      console.log(error)
      setResultValue('error')
    } finally {
      setSubmitted(false)
    }
  }

  const [arImage, setArImage] = useState(null)
  const [rawImage, setRawImage] = useState(null)
  const [arImageURL, setArImageURL] = useState(null)
  const [rawImageURL, setRawImageURL] = useState(null)
  const [arAssets, setArAssets] = useState([])

  function onAssetsEditSave(assets) {
    setArAssets(assets)
    setAssetsEditorToggled(false)
  }

  function onArImagePicked(file) {
    setArImage(file)
    onAssetsEditSave([])
  }

  useEffect(() => {
    if (!formValues.design) {
      onAssetsEditSave([])
      setDesignAssets([])
    }
  }, [formValues.design])

  const levelOptions = useMemo(() => {
    if (unitType) {
      return unitType.unit_type_levels.map((level) => ({
        value: level.id,
        label: getFloorLabel(level),
      }))
    }
    return []
  }, [unitType])

  const vendorsOptions = useMemo(() => {
    if (level && formValues.level) {
      const vendors = []
      console.log({level})
      const designLayouts = arrayUniqueByKey(
        level.rooms
          .map((room) => room.room_designs)
          .flat()
          .map(({ main_vendor }) => main_vendor),
        'id',
      )
      designLayouts.forEach((main_vendor) => {
        const vendor = vendors.find((vendor) => vendor && vendor.id === main_vendor.id)
        if (!vendor) {
          vendors.push(main_vendor)
        }
      })
      const result = vendors
        .sort(smartSort('display_name'))
        .map(({ id, full_name }) => ({ value: id, label: full_name }))
      return result
    } else {
      return []
    }
  }, [level, formValues.level])

  useEffect(() => {
    if (level && formValues && formValues.vendor && formValues.level) {
      const vendor = formValues.vendor
      const assets = []

      if (formValues.design && formValues.room) {
        const room = level.rooms.find(({ id }) => id === formValues.room)
        const d = room.room_designs
          .filter((d) => d.main_vendor.id === vendor)
          .find(({ id }) => id === formValues.design)

        d.design_components.forEach((c) => {
          c.assets
            .filter((a) => a.asset.vendor && a.asset.vendor.id === d.main_vendor.id)
            .forEach((a) => {
              assets.push({
                ...a.asset,
                count: a.count,
                name: a.asset.asset_name,
                room_classification: room.room_classification.id,
                room_design: d.id,
              })
            })
        })
      }

      setDesignAssets(arrayUniqueByKey(assets, 'id'))
    } else {
      setDesignAssets([])
    }
  }, [formValues, level, unit])

  const packageContainingDesigns = useMemo(() => {
    if (formValues.design && level && formValues.vendor) {
      const packs = arrayUniqueByKey(
        level.rooms
          .map(({ room_designs }) => room_designs)
          .flat()
          .map((d) => d.packages)
          .flat()
          .map((p) => p.package),
        'id',
      ).filter((p) => p.vendor.id === formValues.vendor)
      const match = packs.filter((pack) => pack.room_designs.find((d) => d.room_design.id === formValues.design))
      return match
    }
    return []
  }, [formValues, level])

  useEffect(() => {
    if (photo) {
      setArAssets(
        photo.assets.filter(a=>a.x_axis <= 100 && a.y_axis <= 100).map((a) => ({
          current: {
            leftPercent: a.x_axis,
            topPercent: a.y_axis,
          },
          name: a.asset.name || a.asset.asset_name,
          isNew: true,
          is_core_item: a.is_core_item,
          editable: true,
          ...a.asset,
        })),
      )
    }
  }, [photo])

  const hasArImage = arImage || (photo && photo.src)

  async function onDelete() {
    if (window.confirm('Do you really want to delete this photo?')) {
      await deletePhotos(photo.id)
      window.history.back()
    }
  }


  function onRawImagePicked(image){
    setRawImage(image)
    setRawImageURL(null)
  }

  console.log({levelOptions})


  return (
    <>
      {unitType ? (
        <>
          <div>
            <div className="mt-3">
              <div className="text-sm mb-2 opacity-50 mr-[16px]">
                Level <span className="text-[red]">*</span>
              </div>
              <Select
                onChange={(val) => onChange('level', val, { vendor: undefined })}
                options={levelOptions}
                isDisabled={levelOptions.length === 1}
                value={levelOptions.find((vendor) => vendor.value === formValues.level) || ''}
                placeholder="Level"
              />
            </div>
            <div className="mt-3">
              <div className="text-sm mb-2 opacity-50">
                Vendor <span className="text-[red]">*</span>
              </div>
              <Select
                onChange={(val) => onChange('vendor', val, { design: undefined })}
                options={vendorsOptions}
                isDisabled={!formValues.line}
                value={vendorsOptions.find((vendor) => vendor.value === formValues.vendor) || ''}
                placeholder="Vendor name"
              />
            </div>

            <div className="mt-3">
              <div className="text-sm mb-2 opacity-50">
                Captured in Unit <span className="text-[red]">*</span>
              </div>
              <Select
                onChange={(val) => onChange('unit', val)}
                value={unitOptions.find((unit) => unit.value === formValues.unit) || ''}
                options={unitOptions}
                placeholder="Unit"
                isDisabled={!formValues.line}
              />
            </div>
            <div className="mt-3">
              <div className="text-sm mb-2 opacity-50">
                Physical room name <span className="text-[red]">*</span>
              </div>
              <Select
                isDisabled={!formValues.line}
                onChange={(val) => onChange('room', val, { design: undefined })}
                value={roomOptions.find((room) => room.value === formValues.room) || ''}
                options={roomOptions}
                placeholder="Room"
              />
            </div>
            <div className="mt-3 flex items-end">
              {!formValues.package && (
                <div className="flex-1">
                  <div className="text-sm mb-2 opacity-50 mr-[16px]">
                    Design name <span className="text-[red]">*</span>
                  </div>
                  <Select
                    isDisabled={!formValues.room}
                    onChange={(val) => {
                      onChange('design', val)
                      setArAssets([])
                    }}
                    value={designOptions.find((design) => design.value === formValues.design) || ''}
                    options={designOptions}
                    placeholder="Design name"
                  />
                </div>
              )}

              <Button
                className="ml-5"
                disabled={!designAssets.length || !hasArImage}
                rounded
                onClick={toggleAssetsEditor}
              >
                {arAssets.length > 0 ? 'Manage items' : 'Add new item'}
              </Button>
            </div>
            {packageContainingDesigns.length > 0 && (
              <div className="text-[14px] opacity-50 mt-3 text-[red]">
                The photo is going to be attached to package{' '}
                <strong>{packageContainingDesigns.map((p) => p.name).join(', ')}</strong> and presented on RP, FAA, TP
                and AP
              </div>
            )}
            <div className="mt-3">
              <div className="text-sm mb-2 opacity-50">Captured direction</div>
              <Input
                placeholder="Direction"
                value={formValues.direction || ''}
                onChange={(e) => onChange('direction', e.target)}
              ></Input>
            </div>
            <ImageUploaders
              arPicker
              photo={photo}
              rawImage={rawImage}
              arImage={arImage}
              onRawImagePicked={onRawImagePicked}
              onArImagePicked={onArImagePicked}
              positionedAssets={arAssets}
            />
            {assetsEditorToggled && hasArImage && (
              <ImageAssetsEditor
                onSave={onAssetsEditSave}
                image={arImage}
                photo={photo}
                coreAddonModeSupport={packageContainingDesigns.length > 0}
                assets={designAssets}
                onClose={() => {
                  setAssetsEditorToggled(false)
                }}
                addedAssets={arAssets}
              />
            )}
            <div className="flex justify-end my-[32px]">
              {photo && (
                <div className="flex justify-end mr-[32px]">
                  <Button
                    // disabled={true || !isFormValid() || submitted}
                    rounded
                    size="small"
                    onClick={onDelete}
                  >
                    Delete
                  </Button>
                </div>
              )}
              <div className="flex justify-end">
                <Button
                  // disabled={true || !isFormValid() || submitted}
                  rounded
                  loading={submitted}
                  size="small"
                  onClick={onSubmit}
                >
                  Upload
                </Button>
              </div>
            </div>
          </div>
          {resultValue && (
            <ActionSuccessModal
              action="Image"
              state={resultValue}
              skipConfirm={photo}
              onClose={() => {
                setResultValue(null)
              }}
              onConfirm={resetForm}
            />
          )}
        </>
      ) : (
        <div className="flex justify-center">
          <Loader />
        </div>
      )}
    </>
  )
}

export default WithDesignEditor
