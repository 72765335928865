import React from 'react'
import Select from 'react-select'
function SearchDropdown({options = [], onChange, ...props}) {
  return (
    <Select
      options={options}
      menuPosition='fixed'
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          height: '46px',
          border: '1px solid #c7ccd9',
          paddingLeft: '10px',
        }),
      }}
      onChange={onChange}
      {...(props || {})}
    />
  )
}

export default SearchDropdown
