export const tourStoreCreator = function (setter) {
  return {
    tourData: sessionStorage.getItem("tourData") ? JSON.parse(sessionStorage.getItem("tourData")) : undefined,
    setTourData: (tourData, skip_cache) => {
      setter(() => ({ tourData }))
      if(!skip_cache){
        sessionStorage.setItem("tourData", JSON.stringify(tourData))
      }
    },
    selectedApartmentId: sessionStorage.getItem("selectedApartmentId") ? Number(sessionStorage.getItem("selectedApartmentId")) : "1",
    setSelectedApartmentId: (selectedApartmentId, skip_cache) => {
      setter(() => ({ selectedApartmentId }))
      if(!skip_cache){
        sessionStorage.setItem("selectedApartmentId", String(selectedApartmentId))
      }
    },
    wishlistItemId: undefined,
    setWishlistItemId: (wishlistItemId) => {
      setter(() => ({ wishlistItemId }))
    },
    wipeTourData: () => {
      setter(() => ({
        tourData: undefined,
        selectedApartmentId: '1',
        wishlistItem: undefined,
      }))
      sessionStorage.removeItem("tourData")
      sessionStorage.removeItem("selectedApartmentId")
    },
  }
}
