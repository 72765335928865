import { useApartment } from 'TourApp/hooks/useApartment'
import { useProperty } from 'TourApp/hooks/useProperty'
import AssetPopover from 'TourApp/screens/Gallery/components/ImagePointMap/components/AssetPopover'
import React, { useEffect, useState } from 'react'
import { Popover } from 'react-tiny-popover'
// Icons
import { ReactComponent as FavoriteSmallIcon } from 'shared/assets/icons/favoriteHeartSmall.svg'
import { ReactComponent as ImgIcon } from 'shared/assets/icons/img.svg'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import { showPrices } from 'shared/helpers/gallery'

export default function AssetItem({
  asset,
  blurred,
  className = '',
  showFavorite,
  separator,
  purchaseType,
  hidePrice,
  pickedLayout,
  hasPopup = false,
  presentsOnOtherImage,
  onAssetFindClick,
  ...props
}) {
  const property = useProperty()
  const [popupShown, setPopupShown] = useState(false)
  const [timeoutId, setTimeoutId] = useState(null)
  const [isLoaded, setLoaded] = useState(false)
  const load = () => setLoaded(true)
  const [imageLoadError, setImageLoadError] = useState(false)

  const separatorClassList =
    "after:content-[''] after:block after:h-full after:w-[1px] after:bg-[#C7CCD9] after:absolute after:top-0 after:right-[-16px] lg:after:right-[-16px]"

  useEffect(() => {
    if (props.popupShown) {
      setPopupShown(true)
    }
  }, [props])

  function hidePopup() {
    setTimeoutId(
      setTimeout(() => {
        setPopupShown(false)
      }, 100),
    )
  }

  const [internalHasPopup, setInternalHasPopup] = useState(false)

  useEffect(() => {
    setInternalHasPopup(hasPopup)
  }, [hasPopup])

  const assetTitle = asset.text || asset.asset_name

  return (
    <div
      onMouseLeave={hidePopup}
      className={`shrink-0 transition-opacity duration-150 block cursor-pointer group asset
  ${blurred !== false && blurred !== undefined ? 'opacity-50' : ''}
  ${className}`}
      {...props}
    >
      <div
        className={`relative shadow-lg rounded-[4px] flex items-center group ${
          separator ? separatorClassList : 'overflow-hidden'
        } ${props.innerWrapClassName}`}
      >
        {/* TODO: Check if it's needed */}
        {asset.icon && (
          <div className="h-full backdrop-blur-[2px] bg-[rgba(255,255,255,.7)] w-full absolute top-0 left-0 flex justify-center items-center">
            {asset.icon}
          </div>
        )}
        {internalHasPopup ? (
          <Popover
            isOpen={popupShown}
            containerStyle={{ zIndex: 50 }}
            padding={17}
            align="start"
            positions={'top'}
            content={
              <AssetPopover
                onMouseEnter={() => {
                  clearTimeout(timeoutId)
                }}
                asset={{
                  presentsOnOtherImage: presentsOnOtherImage,
                  onFindClick: () => {
                    setInternalHasPopup(false)
                    onAssetFindClick()
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation()
                }}
                emptyImage
                point={asset}
                layout={pickedLayout}
              />
            }
          >
            <img
              className={isLoaded && !imageLoadError ? 'w-full' : 'h-[0] hidden'}
              src={getCdnAssetUrl(asset.thumbnail)}
              alt={asset.text || asset.asset_name}
              onLoad={load}
              onError={() => {
                setImageLoadError(true)
              }}
            />
          </Popover>
        ) : (
          <img
            className={isLoaded && !imageLoadError ? 'w-full' : 'h-[0] hidden'}
            src={getCdnAssetUrl(asset.thumbnail)}
            alt={asset.text || asset.asset_name}
            onLoad={load}
            onError={() => {
              setImageLoadError(true)
            }}
          />
        )}

        {(!isLoaded || imageLoadError) && (
          <div className="bg-white aspect-square w-full flex justify-center items-center">
            <ImgIcon />
          </div>
        )}
        {asset.is_favourite && showFavorite && (
          <div className="absolute top-[10px] right-[10px]">
            <FavoriteSmallIcon />
          </div>
        )}
      </div>
      <div className="flex flex-col font-medium">
        <div
          className={`title text-[14px] leading-[18px] mt-[8px] font-medium md:leading-[22px] group-hover:underline ${
            asset.text
              ? 'max-h-[54px] md:max-h-[66px] three-line-truncate'
              : 'max-h-[36px] md:max-h-[44px] two-line-truncate'
          }`}
          data-name="assetname"
        >
          <a
            href={asset.affiliation_link || asset.web_page}
            target="_blank"
            rel="noreferrer"
            className='hidden lg:inline'
            onClick={(e) => e.stopPropagation()}
          >
            {assetTitle}
          </a>

          <span className="lg:hidden">{assetTitle}</span>
        </div>
        {asset.count > 1 && (
          <div className="absolute top-[10px] left-[8px] text-[14px] h-[20px] px-[7px] bg-white rounded-full font-medium leading-[20px]">
            x{asset.count}
          </div>
        )}
        {asset.listings?.length > 0 && asset.listings[0]?.price > 0 && !hidePrice && showPrices(property) && (
          <div className="price text-[#5F6787] text-[12px] leading-[15px] mt-[8px] md:text-[14px] md:leading-[17px]">
            ${(asset.listings[0]?.price * (asset.count || 1)).toLocaleString()}
            {purchaseType === 'rent' && <span className="opacity-60"> mo</span>}
          </div>
        )}
      </div>
    </div>
  )
}
