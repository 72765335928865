import { ESidebarState } from 'TourApp/components/Sidebar'

export const layoutStoreCreator = function (setter) {
  return {
    // Navbar toggling
    navbarShown: false,
    toggleNavbar: (_, v) => {
      setter(({ navbarShown }) => {
        if (!navbarShown) {
          document.body.classList.add('overflow-hidden')
        } else {
          document.body.classList.remove('overflow-hidden')
        }
        return { navbarShown: v === undefined ? !navbarShown : v }
      })
    },
    // Sidebar toggling
    sidebarShown: false,
    toggleSidebar: (isShown) => {
      setter(({ sidebarShown }) => ({ sidebarShown: isShown !== undefined ? isShown : !sidebarShown }))
    },
    // Orientation locking
    orientationLocked: true,
    setOrientationLocked: (lock = true) => {
      setter(() => ({ orientationLocked: lock }))
    },
    // Sidebar state
    sidebar: localStorage.getItem('sidebar')
      ? JSON.parse(localStorage.getItem('sidebar'))
      : {
          minimized: false,
        },
    setSidebar: (sidebar) => {
      localStorage.setItem('sidebar', JSON.stringify(sidebar))
      setter(() => ({ sidebar }))
    },
  }
}
