// Icons
import { ReactComponent as PriceIcon } from 'shared/assets/icons/pricetag.svg'
import { ReactComponent as BedroomIcon } from 'shared/assets/icons/bedroom.svg'
import { ReactComponent as BathroomIcon } from 'shared/assets/icons/bathroom.svg'
import { ReactComponent as MeasuretoolIcon } from 'shared/assets/icons/measuretool.svg'

import { pluralEnds, safeConfigurationOptions } from "shared/helpers/general"
import { useStore } from 'IFrameApp/store'

function PropertyDescriptionDetails({ details = {}, className = '' }) {
  const { unit_type } = details
  const { dynamicApartmentPrice } = useStore()

  const items = [
    {
      icon: <BedroomIcon className="w-[20px]"/>,
      text: unit_type.number_of_bedrooms ? (`${unit_type.number_of_bedrooms} Bedroom${pluralEnds(unit_type.number_of_bedrooms)}`) : 'Studio'
    },
    {
      icon: <BathroomIcon className="w-[20px]"/>,
      text: unit_type.number_of_bathrooms && `${unit_type.number_of_bathrooms} Bathroom${pluralEnds(unit_type.number_of_bathrooms)}`
    },
    {
      icon: <PriceIcon className="w-[20px]"/>,
      text: (dynamicApartmentPrice || details.price) &&`$${(dynamicApartmentPrice || details.price).toLocaleString('en-US')}/Month`
    },
    (safeConfigurationOptions(details.unit_type.property.properties_configuration_options).show_unit_size ? {
      icon: <MeasuretoolIcon className="w-[20px]"/>,
      text: unit_type.size && `${unit_type.size} ft sq`
    } : null),
  ]

  const options = [items.filter(i=>i).filter(({ text }) => text).map(({ text, icon }, i) => (
    <div className={`flex items-center w-full h-[22px] mt-[8px] first:mt-0`} key={i}>
      <span>{icon}</span>
      <span className="ml-2 text-[12px] leading-[21px] font-normal">{text}</span>
    </div>
  ))]

  return (
    <div className={className}>
      <span className="text-[14px] font-medium">Unit {details.name}</span>
      <div className="mt-[16px]">
        {options}
      </div>
    </div>
  )
}

export default PropertyDescriptionDetails
