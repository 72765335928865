import signup from 'shared/assets/images/resident-signup-flow.jpg'
import signupMobile from 'shared/assets/images/resident-mobile-signup-flow.png'

import Button from 'shared/components/Button'
import { useStore } from 'Resident/store'
import { isSignedUp } from 'Resident/helpers/user'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { group3 } from 'shared/analytics/resident'

function WelcomeScreen(props) {
  const { appData } = useStore()

  const navigate = useNavigate()

  function onContinue() {
    if (appData && !isSignedUp(appData.user)) {
      navigate('/set-password')
    } else {
      navigate('/signin')
    }
  }

  useEffect(()=>{
    if(appData && appData.user){
      rpevent(eventDictionary.welcome_screen_from_deep_link, group3)
    }
  }, [appData])

  function getWelcomeText() {
    if (appData) {
      return (
        <>
          {appData.user.first_name},
          <br className="hidden lg:inline" /> Reveal the full potential
          <br className="hidden lg:inline" /> of unit #{appData.unit.name}
        </>
      )
    }
    if (!appData) {
      return (
        <>
          Reveal Your Space’s
          <br /> Full Potential!
        </>
      )
    }
  }

  return (
    <div
      className="h-full w-full bg-cover lg:flex items-center bg-center"
      style={{ backgroundImage: `url(${signup})` }}
    >
      <div className="h-full lg:h-auto lg:w-[500px] bg-white  lg:ml-[128px] flex flex-col lg:items-center">
        <div className="w-full flex justify-end lg:hidden ">
          <img src={signupMobile} />
        </div>
        <div className="p-[32px] lg:p-[64px] flex flex-col lg:items-center flex-1">
          <>
            {/* <div>
                <PropertyChip property={appData && appData.unit.unit_type.property} />
              </div> */}
            <>
              <div className="mt-[24px] lg:mt-[58px]">
                <div className="text-[22px] lg:text-[32px] font-semibold lg:text-center leading-[29px] lg:leading-[38px]">
                  {getWelcomeText()}
                </div>
              </div>
            </>
            <div className="lg:text-[18px] font-normal text-[#5F6787] lg:text-center mt-[16px]">
              Explore tailor-made interior designs <br />
              with SparX Visualization Platform
            </div>
            <div className="w-full mt-[49px] lg:mt-[180px]">
              <Button rounded className="w-full" onClick={onContinue}>
                Continue
              </Button>
            </div>
          </>
        </div>
      </div>
    </div>
  )
}

export default WelcomeScreen
