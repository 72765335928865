import React, { useEffect, useMemo, useState } from 'react'
import { getDesignLayoutPackagePrice, isQuadraticDisplayMode, showFurnitureDetails, showPrices } from 'shared/helpers/gallery'
import { isApartmentApp, purchaseType } from 'shared/helpers/general'
import AssetsGallerySwitch from '../AssetsGallerySwitch'

import ExpandCollapseHandle from './components/ExpandCollapseHandle'
import { useProperty } from 'TourApp/hooks/useProperty'

function DesignLayoutNav({
  onOptionsToggled,
  onPreviewModeChanged,
  className,
  onAddToCardClick,
  room,
  onLayoutPicked,
  wishlistPreview,
  previewMode,
  apartment,
  pickedImage,
  bgImage,
  hideDesignNames,
  onPreviewSizeChanged,
  previewMinimized,
  ...props
}) {
  const [pickedLayout, setPickedLayout] = useState(room.room_designs[0])

  const packagePrice = useMemo(() => {
    return getDesignLayoutPackagePrice(pickedLayout)
  }, [pickedLayout])

  useEffect(() => {
    setPickedLayout(room.room_designs[0])
  }, [room])

  useEffect(() => {
    if (props.pickedLayout) {
      setPickedLayout(props.pickedLayout)
    }
  }, [props.pickedLayout])

  function hasMultipleDesigns() {
    if (!isApartmentApp()) {
      return true
    }
    return room.room_designs.length > 1
  }

  function getTitle() {
    if (hideDesignNames) {
      if (!pickedImage.is_more) {
        return `${pickedImage.roomName}${hasMultipleDesigns() ? ` · Design ${pickedImage.design_index}` : ''}`
      }
    }
    if (!pickedImage.assets.length) {
      return pickedImage.display_name || room.name || room.classification
    }
    if (pickedLayout.short_description) {
      return `${pickedLayout.design_name} (${pickedLayout.short_description}) by ${pickedLayout.main_vendor.full_name}`
    }
    return `${pickedLayout.design_name} by ${pickedLayout.main_vendor.full_name}`
  }

  function getMoreTitle() {
    if (hideDesignNames) {
      if (!pickedImage.display_name) {
        return `${pickedImage.roomName} · Other Images`
      }
      if (pickedImage.display_name) {
        return `${pickedImage.roomName} · ${pickedImage.display_name}`
      }
    }
    if (pickedImage.display_name) {
      return `Other Images · ${pickedImage.display_name}`
    }
    return `Other Images`
  }

  function generateAssetsForEversetLink() {
    return pickedImage.assets.map((asset) => ({
      quantity: asset.quantity,
      variant_id: asset.asset.variant_id,
    }))
  }

  const [previewSizeTooltipShown, setPreviewSizeTooltipShown] = useState(false)

  function getBorderStyle() {
    if (previewSizeTooltipShown) {
      if (isQuadraticDisplayMode(apartment)) {
        return `border-t-[2px] border-[#5F6787]`
      } else {
        return 'border-b-[2px] border-[#5F6787]'
      }
    }
   
    if (isQuadraticDisplayMode(apartment)) {
      return `border-t-[1px] border-[#C7CCD9]`
    } else {
      return 'border-b-[1px] border-[#C7CCD9]'
    }
  }
  
  const property = useProperty()

  return (
    <div className={`w-full relative ${className} bg-white ${getBorderStyle()} border-solid`}>
      <ExpandCollapseHandle
        onHoverChange={(val) => setPreviewSizeTooltipShown(val)}
        previewMode={previewMode}
        previewState={previewMinimized}
        onChange={onPreviewSizeChanged}
      />
      <div
        className={`relative flex flex-col lg:flex-row items-center justify-between px-[16px] ${
          pickedImage.assets.length > 0 ? 'pb-[26px] pt-[20px]' : 'pb-[10px] pt-[0]'
        } lg:py-0 lg:pr-[32px] lg:pl-[24px] z-10 lg:h-[58px] ${previewSizeTooltipShown && previewMinimized ? 'mt-[-1px]' : ''}`}
      >
        <div
          className={`items-center ${
            wishlistPreview || room.room_designs.length === 1 ? 'cursor-default' : 'cursor-pointer'
          } select-none hidden lg:flex`}
        >
          <div className="text-[24px] lg:text-[18px] font-medium">
            {room.room_designs.length > 0 &&
              !wishlistPreview &&
              !pickedLayout.is_more &&
              !pickedLayout.is_amenities &&
              getTitle()}
            {pickedLayout.is_more && getMoreTitle()}
            {pickedLayout.is_amenities
              ? `Amenities${pickedImage.display_name ? ` · ${pickedImage.display_name}` : ''}`
              : null}
          </div>
        </div>

        <div className="flex flex-col lg:flex-row items-center w-full lg:w-auto select-none">
          {previewMode === 'assets' && !pickedLayout.main_vendor.is_sparx && showPrices(property) && (
            <>
              {packagePrice > 0 && (
                <div className="flex mb-[18px] mt-[10px] lg:my-0 flex-shrink-0 items-center">
                  <div className="leading-[22px] lg:text-[18px] text-black font-medium">
                    <span className="capitalize">{purchaseType(pickedLayout.main_vendor.is_rental)}</span>
                    {' room package for '}
                    <span>${packagePrice.toLocaleString()}</span>
                    {purchaseType(pickedLayout.main_vendor.is_rental).toLowerCase() === 'rent' && (
                      <span className="ml-[5px]">mo</span>
                    )}
                  </div>
                </div>
              )}
            </>
          )}

          <AssetsGallerySwitch
            mode={isQuadraticDisplayMode(apartment) ? 'separate' : 'default'}
            showFurnitureMode={showFurnitureDetails(apartment.unit_type.property)}
            onChange={onPreviewModeChanged}
            pickedState={previewMode}
            className="ml-[20px]"
          />
        </div>
      </div>
    </div>
  )
}

export default DesignLayoutNav
