import moment from 'moment'
import { objectCopy } from 'shared/helpers/general'

export function addTimestampToPath(fileName) {
  const timestamp = moment().format('DD.MM.YY_HH:mm:ss') // Get current timestamp in milliseconds
  const extensionIndex = fileName.lastIndexOf('.')

  if (extensionIndex !== -1) {
    // If there's an extension in the fileName
    const fileNameWithoutExtension = fileName.slice(0, extensionIndex)
    const fileExtension = fileName.slice(extensionIndex)

    // Combine the timestamp, fileName without extension, and extension
    const newFileName = `${fileNameWithoutExtension}_${timestamp}${fileExtension}`

    return newFileName
  } else {
    // If there's no extension, simply append the timestamp to the fileName
    return `${fileName}_${timestamp}`
  }
}

//==========================================================================
// This filtering is applied because of potentially unavailable unit_types_configuration_options for unit_type AT-941
//==========================================================================

export function filterPropertiesData(properties) {
  return objectCopy(properties).map((p) => ({
    ...p,
    unit_types: p.unit_types.filter((t) => !!t.unit_types_configuration_options),
  }))
}
