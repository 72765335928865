import React from 'react'
import { useStore } from 'TourApp/store'

function VirtualTourScreen(props) {
  const { tourData, selectedApartmentId, isLineApp } = useStore()

  const { unit } = tourData.units.find(({ unit }) => unit.id === selectedApartmentId)

  function getFrameUrl() {
    if (!isLineApp && unit && unit.virtual_tour) {
      return unit.virtual_tour.tour_path
    }
    if (unit && unit.unit_type.unit_type_levels[0].virtual_tour) {
      return unit.unit_type.unit_type_levels[0].virtual_tour.tour_path
    }
  }

  return (
    <iframe
      title={getFrameUrl()}
      src={getFrameUrl()}
      sandbox="allow-forms | allow-modals | 
  allow-orientation-lock | allow-pointer-lock | 
  allow-popups | allow-popups-to-escape-sandbox |
  allow-presentation | allow-same-origin | allow-scripts
  "
      className="h-full w-full"
    />
  )
}

export default VirtualTourScreen
