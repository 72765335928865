import { useEffect, useMemo, useState } from 'react'

import { ReactComponent as InfoIcon } from './icons/info.svg'

import { useNavigate } from 'react-router-dom'
import { objectCopy, priceSum } from 'shared/helpers/general'
import { produceAllRoom } from 'Resident/helpers/general'
import { usePackages } from 'Resident/hooks/usePackages'
import { ViewButton } from '../components/ViewButton'
import { RoomsSlider } from '../components/RoomsSlider'
import SelectPackage from 'Resident/components/SelectPackage'
import { sortByCategoryWeight, sortByWeight } from 'shared/helpers/arrays'
import { PackageAssetsSlider } from '../components/AssetsSlider'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { group4 } from 'shared/analytics/resident'

function AgentPackageRow({ checkout, opened, pack, minimal = false, ...props }) {
  const [infoToggled, setInfoToggled] = useState(false)
  const [pickedRoom, setPickedRoom] = useState(null)
  useEffect(() => {
    if (!pickedRoom) {
      setPickedRoom(-1)
    }
  }, [pack, pickedRoom])

  const navigate = useNavigate()

  function onPreview() {
    rpevent(eventDictionary.dp_screen_gallery_button_tapped, group4(pack))
    navigate('/preview', { state: { packageId: pack.id } })
  }

  const rooms = useMemo(() => {
    return [produceAllRoom(pack), ...pack.room_classifications]
  }, [pack])

  const assets = useMemo(() => {
    if (pickedRoom) {
      return rooms.find((item) => item.room_classification.id === pickedRoom).package_assets
    } else {
      return []
    }
  }, [pickedRoom, rooms])

  const { updatePackageAsset } = usePackages()

  const pickedAssets = useMemo(() => {
    return rooms
      .filter((room) => room.room_classification.id > 0)
      .map((room) => room.package_assets.filter((asset) => asset.type === 'core' || asset.added))
      .flat()
  }, [rooms])

  const packagePriceLabel = useMemo(() => {
    if (pickedAssets.length === 0) {
      return <span className="text-[#5F6787]">No items selected yet</span>
    }
    if (pickedAssets.every((asset) => asset.type === 'core') && !checkout) {
      return `$${pack.price.toLocaleString('en-US')}${pack.vendor.is_rental ? '/mo' : ''}`
    } else {
      return `${pickedAssets.reduce((acc, val) => (val.selectedCount || val.count) + acc, 0)} items for $${(
        pack.price +
        priceSum(pickedAssets.filter((asset) => asset.added).map((asset) => asset.price * asset.selectedCount))
      ).toLocaleString('en-US')}${pack.vendor.is_rental ? '/mo' : ''}`
    }
  }, [pickedAssets, pack, checkout])

  function generateBasePackageContents() {
    return objectCopy(pack.room_classifications)
      .sort(sortByWeight)
      .map((r) => (
        <div>
          <span className="font-semibold">{r.room_classification.classification_name}</span>:{' '}
          {objectCopy(r.package_assets).sort(sortByCategoryWeight).reverse().map((a) => a.category.category_name).join(', ')}
        </div>
      ))
  }

  function renderRow() {
    return (
      <div className=" bg-white border-[2px] border-solid overflow-hidden border-[#C7CCD9] mt-[20px] first:mt-0">
        <div className="h-[68px] pr-[32px]">
          <div className="flex justify-between items-center h-full">
            <div className="h-full flex items-center">
              <div className="ml-[24px] min-w-max">
                <div className="flex items-center">
                  <div className="text-[20px] font-semibold max-w-[250px] truncate">
                    {pack.display_name || pack.name}
                  </div>
                  {!checkout && (
                    <div className="ml-[12px] flex items-center">
                      <button
                        type="button"
                        onClick={() => {
                          rpevent(eventDictionary.dp_screen_info_button_engaged, group4(pack))
                          setInfoToggled(!infoToggled)
                        }}
                      >
                        <InfoIcon />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="min-w-max">{packagePriceLabel}</div>
              {!checkout && (
                <>
                  <ViewButton className="h-[37px] !min-w-[128px] w-[128px]" onClick={onPreview} />
                  <SelectPackage pack={pack} />
                </>
              )}
            </div>
          </div>
        </div>
        {infoToggled && (
          <div
            className={`p-[24px] ease-in overflow-hidden text-[#3D4256] border-t-[2px] border-solid border-[#C7CCD9]`}
          >
            {pack.description && <div className="mb-[24px]">{pack.description}</div>}
            <div>
              <div>Base Packages Includes:</div>
              <div>{generateBasePackageContents()}</div>
            </div>
          </div>
        )}
        <div className="border-t-[2px] flex items-start border-solid border-[#C7CCD9] w-full p-[25px]">
          <RoomsSlider pack={pack} onRoomPicked={setPickedRoom} pickedRoom={pickedRoom} rooms={rooms} />
          <div className="ml-[46px] w-[calc(100%-174px)]">
            <PackageAssetsSlider
              pack={pack}
              summaryMode={pickedRoom === -1}
              checkout={checkout}
              onAssetAdd={(asset) => updatePackageAsset(pack, pickedRoom, asset)}
              onAssetRemove={(asset) => updatePackageAsset(pack, pickedRoom, asset)}
              onAssetUpdate={(asset, fields) => updatePackageAsset(pack, pickedRoom, asset, fields)}
              assets={assets}
            />
          </div>
        </div>
      </div>
    )
  }

  return renderRow()
}

export default AgentPackageRow
