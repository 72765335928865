import React, { useState } from 'react'
import { ReactComponent as DownloadIcon } from 'shared/assets/icons/crm-download-violet.svg'
import { ReactComponent as CheckmarkIcon } from './icons/checkmark.svg'
import DownloadLoader from './components/Loader'
import { downloadContentAsZip } from 'CRM/helpers/contentDownload'
import Button from 'shared/components/Button'

const EDownloadStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
}

function DownloadContentButton({ className, unit, isLine }) {
  const [downloadState, setDownloadState] = useState(null)

  async function onDownload() {
    setDownloadState(EDownloadStatus.IN_PROGRESS)
    await downloadContentAsZip(unit, isLine)
    setDownloadState(EDownloadStatus.SUCCESS)
  }

  return (
    <div>
      {downloadState && (
        <div className="fixed  h-screen w-screen left-0 top-0 z-[200] flex justify-center items-center">
          <div className="absolute h-full w-full bg-[rgba(0,0,0,.4)] left-0 top-0 z-[201] backdrop-blur-sm"></div>
          <div className="z-[202] px-[46px] lg:px-[0]">
            {downloadState === EDownloadStatus.IN_PROGRESS && (
              <div className=" flex flex-col items-center justify-center">
                <DownloadLoader />
                <div className="text-[18px] text-white font-medium text-center lg:w-[282px] mt-[32px]">
                  Please wait while we compress and download your files. This may take some time...
                </div>
              </div>
            )}
            {downloadState === EDownloadStatus.SUCCESS && (
              <div className=" flex flex-col items-center justify-center">
                <CheckmarkIcon />
                <div className="text-[22px] text-white font-medium text-center lg:w-[282px] mt-[22px]">
                  Download completed!
                </div>
                <Button rounded variant='secondary' className="mt-[50px] !border-[white] text-white !min-w-auto !w-[282px]" onClick={()=>{setDownloadState(null)}}>OK</Button>
              </div>
            )}
          </div>
        </div>
      )}
      <button
        type="button"
        className={` p-[5px] mr-[-5px] lg:mr-[0] border-none ml-[10px] group relative bg-[rgba(229,231,237,.4)] lg:bg-transparent h-[44px] w-[44px] lg:w-auto rounded-full flex items-center justify-center text-[#A93DCB] ${className}`}
        onClick={onDownload}
      >
        <DownloadIcon />
      </button>

     
    </div>
  )
}

export default DownloadContentButton
