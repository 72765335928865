import { useCallback, useEffect } from 'react'

export function useTitle(title) {
  const set = useCallback((title) => {
    document.title = `${title} - SparX`
  }, [])

  useEffect(()=>{
    set(title)
  }, [title, set])

  return {
    set
  }
}
