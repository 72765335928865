import { useFormik } from 'formik'
import { useCallback } from 'react'
import { EMAIL_REGX } from 'shared/constants'
import { errorMessages } from 'shared/settings'
import * as Yup from 'yup'

export function useResetPasswordForm(onSubmit) {
  const validationSchema = Yup.object().shape({
    email: Yup.string().matches(EMAIL_REGX, 'Invalid email address').required(errorMessages.required),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema,
    // validateOnMount:true,
    onSubmit: values=>{
        validateForm(formik)
        onSubmit(values)
    },
  })

  const validateForm = useCallback(() => {
    if (!formik.values.email) {
      return false
    }
    if (formik.errors.email) {
      return false
    }
    return true
  }, [formik])

  return {
    form: formik,
    validateForm,
  }
}
