import React, { useState } from 'react'
import { useStore } from 'IFrameApp/store'
import VideosLayout from "shared/components/FrameVideoLayout"
import { events, iframe } from 'shared/analytics'

export default function VideosScreen() {
  const { tourData } = useStore()
  const videos = tourData.videos.filter(v => v.is_official && v.is_verified)
  const [videoId, setVideoId] = useState((videos[0] || {}).id)
  const [currentVideoDuration, setCurrentVideoDuration] = useState(null)
  const [videoPlayedOver50PercentEventSent, setVideoPlayedOver50PercentEventSent] = useState(false)

  function getVideoProps() {
    const { id, src, name } = videos.find((video) => video.id === videoId)

    return {
      video_id: id,
      video_file_name: src,
      video_display_name: name,
    }
  }

  function onVideoPlay(e) {
    setCurrentVideoDuration(e.currentTarget.duration);
    setVideoPlayedOver50PercentEventSent(false)
    iframe(events.fvvs, getVideoProps)
  }

  function onVideoEnd() {
    iframe(events.fvve, getVideoProps )
  }

  function onVideoPlayProgress({ currentTime }) {
    const currentlyPlayedPercentage = Math.round((currentTime * 100) / currentVideoDuration)

    if (currentlyPlayedPercentage > 50 && !videoPlayedOver50PercentEventSent) {
      iframe(events.fvvo5p, getVideoProps)
      setVideoPlayedOver50PercentEventSent(true)
    }
  }

  return (
    <VideosLayout
      {...{
        videoId,
        setVideoId,
        background: 'lg:bg-[#242528]',
        videos,
        videoEventProps: {
          onVideoPlay,
          onVideoEnd,
          onVideoPlayProgress,
          setCurrentVideoDuration
        }
      }}
    />
  )
}
