export const ROUTES = {
  HOME: '/actions',
  FLOOR_PLANS: '/floorplans',
  FEATURED_VIDEOS: '/featuredvideos',
  PROPERTY: {
    MANAGE: '/property/manage',
    ADD: '/property/add',
    EDIT: '/property/edit',
  },
  REAL_ESTATE_COMPANIES: '/real-estate-companies',
  MANAGE_LINE_IMAGES: '/manage-line-images',
  IMAGES: '/images',
  SIGNIN: '/signin',
}
