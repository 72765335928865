import React from 'react'
import ReactSelect from 'react-select'
import ErrorMessage from 'shared/components/ErrorMessage'

const customStyles = {
  control: (base, { isDisabled }) => ({
    ...base,
    height: 46,
    minHeight: 46,
  }),
}

function Select({ style = {}, error, ...props }) {
  return (
    <div className='w-full'>
      {props.label && (
        <div className="text-sm mb-2 opacity-50">
          {props.label} {props.required && <span className="text-red-500">*</span>}
        </div>
      )}
      <ReactSelect styles={{ ...customStyles, ...style }} {...props} />
      {error && <ErrorMessage text={error} />}
    </div>
  )
}

export default Select
