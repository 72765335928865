import React from 'react'
import ErrorMessage from 'shared/components/ErrorMessage'
import SharedInput from 'shared/components/Input'

function Input({ required = false, number = false, label, error, wrapClassName = '', ...props }) {
  function onNumberChange(e) {
    const value = e.target.value
    if (value === '' || /^[0-9]+$/.test(value)) {
      props.onChange(e)
    }
  }
  return (
    <div className={`${wrapClassName}`}>
      {label && (
        <div className="text-sm mb-2 opacity-50">
          {label} {required && <span className="text-red-500">*</span>}
        </div>
      )}
      <SharedInput {...props} onChange={!number ? props.onChange : onNumberChange} required={required} />
      {error && <ErrorMessage text={error} />}
    </div>
  )
}

export default Input
