import { Navigate, ReactLocation } from '@tanstack/react-location'
import { events, posttour } from 'shared/analytics'
import { useStore } from './store'
// Screens
import GalleryScreen from './screens/Gallery'
import IntroScreen from './screens/Intro'
import NotFoundScreen from './screens/NotFound'
import WishlistScreen from './screens/Wishlist'
import FloorPlansScreen from './screens/FloorPlans'
import PhotosScreen from './screens/Photos'
import VideosScreen from './screens/Videos'
import TourGuard from './components/TourGuard'
import { isApartmentApp, isGalleryAvailable, isPostTourApp } from 'shared/helpers/general'
import ApartmentFetcher from './components/ApartmentFetcher'
import VirtualTourScreen from './screens/VirtualTour'
// Icons
import VirtualTourIcon from './components/Sidebar/icons/3dtour'
import DesignGalleryIcon from './components/Sidebar/icons/design-gallery'
import FeaturedVideosIcon from './components/Sidebar/icons/videos'
import FloorPlansIcon from './components/Sidebar/icons/floor-plans'
import WishlistIcon from './components/Sidebar/icons/wishlist'
import PhotosIcon from './components/Sidebar/icons/photos'

import VirtualTourIconBig from './components/Sidebar/icons/3dtour-big'
import DesignGalleryIconBig from './components/Sidebar/icons/design-gallery-big'
import FeaturedVideosIconBig from './components/Sidebar/icons/videos-big'
import FloorPlansIconBig from './components/Sidebar/icons/floor-plans-big'
import WishlistIconBig from './components/Sidebar/icons/wishlist-big'
import PhotosIconBig from './components/Sidebar/icons/photos-big'

const PostTourRoutes = [
  {
    path: '/wishlist',
    onMatch: () => {
      const { setTitle } = useStore.getState()
      posttour(events.wip)
      setTitle(pageTitles.WISHLIST)
    },
    element: (
      <TourGuard>
        <WishlistScreen />
      </TourGuard>
    ),
  },
  {
    path: '/photos',
    onMatch: () => {
      const { setTitle } = useStore.getState()
      posttour(events.pgp)
      setTitle(pageTitles.MEDIA_GALLERY)
    },
    element: (
      <TourGuard>
        <PhotosScreen />
      </TourGuard>
    ),
  },
]

export function renderIndexRouteComponent() {
  if (isPostTourApp()) {
    return <IntroScreen />
  }
  if (isApartmentApp()) {
    return <ApartmentFetcher />
  }
  return <>Invalid configuration !</>
}

export const routes = [
  {
    path: '/',
    onMatch: ({ search }) => {
      const { setTitle } = useStore.getState()
      const tourCode = search?.tour
      if (!tourCode) {
        posttour(events.tcp)
      }
      setTitle(pageTitles.WELCOME)
    },
    element: renderIndexRouteComponent(),
  },
  {
    path: '/gallery',
    onMatch: () => {
      const { setTitle } = useStore.getState()
      posttour(events.dgp)
      setTitle(pageTitles.DESIGN_GALLERY)
    },
    element: (
      <TourGuard>
        <GalleryScreen />
      </TourGuard>
    ),
  },
  {
    path: '/floorplans',
    onMatch: () => {
      const { setTitle } = useStore.getState()
      posttour(events.fpp)
      setTitle(pageTitles.FLOOR_PLANS)
    },
    element: (
      <TourGuard>
        <FloorPlansScreen />
      </TourGuard>
    ),
  },
  {
    path: '/videos',
    onMatch: () => {
      const { setTitle } = useStore.getState()
      posttour(events.fvp)
      setTitle(pageTitles.VIDEOS)
    },
    element: (
      <TourGuard>
        <VideosScreen />
      </TourGuard>
    ),
  },
  {
    path: '/virtual-tour',
    onMatch: () => {
      const { setTitle } = useStore.getState()
      setTitle(pageTitles.VIRTUAL_TOUR)
    },
    element: (
      <TourGuard>
        <VirtualTourScreen />
      </TourGuard>
    ),
  },
  ...(isPostTourApp() ? PostTourRoutes : []),
  {
    path: '/*',
    onMatch: () => {
      const { setTitle } = useStore.getState()
      setTitle('Page not found')
    },
    element: <NotFoundScreen />,
  },
  {
    element: <Navigate to="/" />,
  },
]

export const location = new ReactLocation()

export const pageTitles = {
  DESIGN_GALLERY: 'Design Gallery',
  WISHLIST: 'Wishlist',
  FLOOR_PLANS: 'Floor Plans',
  VIDEOS: 'Video Walkthrough',
  WELCOME: 'Welcome',
  MEDIA_GALLERY: 'My Photos',
  VIRTUAL_TOUR: '3D Virtual Tour',
}

export const shortPageTitles = {
  DESIGN_GALLERY: 'Gallery',
  VIDEOS: 'Video',
  FLOOR_PLANS: 'Floor Plans',
  WELCOME: 'Welcome',
  MEDIA_GALLERY: 'My Photos',
  VIRTUAL_TOUR: '3D Tour',
}

function getIconStroke(active, { activeColor, defaultColor } = {}) {
  return {
    stroke: active ? activeColor || 'black' : defaultColor || '',
  }
}

const PostTourNavLinks = [
  {
    path: 'wishlist',
    title: pageTitles.WISHLIST,
    shortTitle: shortPageTitles.WISHLIST,
    icon: (active, options) => <WishlistIcon {...getIconStroke(active, options)} />,
    iconBig: (active, options) => <WishlistIconBig {...getIconStroke(active, options)} />,
  },
  {
    path: 'photos',
    shortTitle: shortPageTitles.MEDIA_GALLERY,
    title: pageTitles.MEDIA_GALLERY,
    icon: (active, options) => <PhotosIcon {...getIconStroke(active, options)} />,
    iconBig: (active, options) => <PhotosIconBig {...getIconStroke(active, options)} />,
  },
]

export const navLinks = [
  {
    path: 'gallery',
    shortTitle: shortPageTitles.DESIGN_GALLERY,
    title: pageTitles.DESIGN_GALLERY,
    icon: (active, options) => <DesignGalleryIcon {...getIconStroke(active, options)} />,
    iconBig: (active, options) => <DesignGalleryIconBig {...getIconStroke(active, options)} />,
  },
  {
    path: 'videos',
    shortTitle: shortPageTitles.VIDEOS,
    title: pageTitles.VIDEOS,
    icon: (active, options) => <FeaturedVideosIcon {...getIconStroke(active, options)} />,
    iconBig: (active, options) => <FeaturedVideosIconBig {...getIconStroke(active, options)} />,
  },
  {
    path: 'floorplans',
    shortTitle: shortPageTitles.FLOOR_PLANS,
    title: pageTitles.FLOOR_PLANS,
    icon: (active, options) => <FloorPlansIcon {...getIconStroke(active, options)} />,
    iconBig: (active, options) => <FloorPlansIconBig {...getIconStroke(active, options)} />,
  },
  {
    path: 'virtual-tour',
    shortTitle: shortPageTitles.VIRTUAL_TOUR,
    title: pageTitles.VIRTUAL_TOUR,
    icon: (active, options) => <VirtualTourIcon {...getIconStroke(active, options)} />,
    iconBig: (active, options) => <VirtualTourIconBig {...getIconStroke(active, options)} />,
    separated: true,
  },
  ...(isPostTourApp() ? PostTourNavLinks : []),
]

export function getAvailableMenuItems(tourData, selectedApartmentId, videos, photos, isLineApp) {
  return navLinks.filter((l) => {
    const { unit } = tourData.units.find(({ unit }) => unit.id === selectedApartmentId)
    switch (l.path) {
      case 'videos':
        return videos.length > 0
      case 'photos':
        return photos.length > 0
      case 'gallery':
        return isGalleryAvailable(tourData)
      case 'floorplans':
        return unit.unit_type.unit_type_levels.some(l=>l.unit_plans) && unit.unit_type.unit_type_levels.some(l=>l.unit_plans.length > 0)
      case 'virtual-tour':
        return (
          unit.unit_type.property.properties_configuration_options.supports_virtual_tour &&
          (isLineApp ? unit.unit_type.unit_type_levels[0].virtual_tour : !!(unit.virtual_tour || unit.unit_type.unit_type_levels[0].virtual_tour))
        )
      default:
        return true
    }
  })
}
