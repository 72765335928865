import axios from 'axios'
import { apiHost } from 'shared/helpers/api'

const api = axios.create({
  baseURL: `${apiHost}/api`,
  timeout: 30000,
})

export async function fetchConfig() {
  const { data } = await api.get(`/app/config`)
  return data
}
