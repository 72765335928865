import { validateToken } from 'CRM/helpers/auth'
import { api, configWithAuth } from 'CRM/services/user'

export function checkout(payload, vendor) {
  return validateToken(async (token) => {
    const { data } = await api.post(`/checkout/${vendor}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data
  })
}

export function agentCheckout(payload, vendor) {
  return validateToken(async () => {
    const { data } = await api.post(`/checkout/faa/${vendor}`, payload, configWithAuth())
    return data
  })
}
