import * as React from 'react'
const SvgComponent = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 1L23 6.5V17.5L12 23L1 17.5V6.5L12 1Z"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 12L23 6.5"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 12V23"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 12L1 6.5"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
export default SvgComponent
