import React, { useState, useEffect } from 'react'
// Icons
import { ReactComponent as ArrowTopBig } from './icons/arrow-top-big.svg'
import { ReactComponent as ArrowTop } from './icons/arrow-top.svg'
import './styles.scss'

function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false)

  const handleScrollToTop = () => {
    document.getElementById('wrapper').scrollTo({
      top: 0,
      behavior: 'smooth', // Enables smooth scrolling
    })
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = document.getElementById('wrapper').scrollTop
      setIsVisible(scrollPosition >= window.innerHeight * 2)
    }

    const wrapper = document.getElementById('wrapper')
    wrapper.addEventListener('scroll', handleScroll)
    return () => {
      wrapper.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    isVisible && (
      <div
        onClick={handleScrollToTop}
        className="scroll-to-top shadow bg-white border-[rgba(199,204,217,.4)] border-r-[0] border-[1px] lg:border-none
        border-solid h-[68px] w-[90px] lg:h-[52px] lg:w-[52px] fixed right-[0] bottom-[120px] 
        lg:bottom-[95px] lg:right-[50px] flex items-center justify-center cursor-pointer rounded-l-[8px] lg:rounded-full"
        title="Scroll to Top"
      >
        <span className="hidden lg:inline-block">
          <ArrowTopBig />
        </span>
        <div className="lg:hidden flex items-center justify-center flex-col">
          <ArrowTop />
          <span className="text-[10px] text-center tracking-[1px] uppercase font-bold leading-[13px] mt-[8px] w-[80%]">
            Back to Top
          </span>
        </div>
      </div>
    )
  )
}

export default ScrollToTop
