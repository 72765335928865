import { setCurrentScreen } from "firebase/analytics"

export const layoutStoreCreator = function (setter) {
  return {
    sidebar: localStorage.getItem('sidebar')
      ? JSON.parse(localStorage.getItem('sidebar'))
      : {
          minimized: false,
        },
    setSidebar: (sidebar) => {
      localStorage.setItem('sidebar', JSON.stringify(sidebar))
      setter(() => ({ sidebar }))
    },
    welcomeDrawer: { opened: false },
    setWelcomeDrawer: (welcomeDrawer) => {
      setter(() => ({ welcomeDrawer }))
    },
    currentScreen: undefined,
    setCurrentScreen: (currentScreen) => {
      setter(() => ({ currentScreen }))
    }
  }
}
