import React, { useMemo, useState, useEffect, useCallback } from 'react'
import Input from 'shared/components/Input'
import Select from 'shared/components/Select'
import ImageUploaders from '../../components/ImageUploaders'
import ActionSuccessModal from 'MediaManager/components/ActionSuccessModal'
import Button from 'shared/components/Button'
import { getUnitType } from 'MediaManager/services/property'
import Loader from 'shared/components/Loader'
import { deletePhotos, upsertPhotos } from 'MediaManager/services'
import { uploadFileToS3 } from 'MediaManager/helpers/s3'
import { objectCopy, safeConfigurationOptions, whitespacesToUnderscore } from 'shared/helpers/general'
import { smartSort } from 'shared/helpers/arrays'
import { getFloorLabel } from 'shared/helpers/levels'

function WithoutDesignEditor({ photo, line, formValues, onChange, property, setFormValues }) {
  const [rawImage, setRawImage] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [resultValue, setResultValue] = useState()
  const [unitType, setUnitType] = useState()

  const resetForm = useCallback(() => {
    setRawImage(null)
    setFormValues(({ type, property, line, unit, room }) => ({
      type,
      property,
      line,
      unit,
      room,
    }))
    setResultValue(null)
  }, [setFormValues])

  useEffect(() => {
    ;(async () => {
      if (line) {
        const { data } = await getUnitType(Number(line))
        setUnitType(data.unit_types[0])
       if(!photo){
        resetForm()
       } else {
        if(photo.captured_in.unit_type.id !== Number(line)){
          resetForm()
        }
       }
      }
    })()
  }, [line, resetForm, photo])

  const level = useMemo(() => {
    if (unitType) {
      return unitType.unit_type_levels.find(({ id }) => formValues.level === id)
    } else {
      return null
    }
  }, [formValues.level, unitType])

  useEffect(() => {
    if (unitType && !formValues.level && unitType.unit_type_levels.length === 1) {
      onChange('level', { value: unitType.unit_type_levels[0].id })
    }
  }, [unitType, onChange, formValues])

  const unitOptions = useMemo(() => {
    if (unitType) {
      return objectCopy(unitType).units
        .sort(smartSort('unit_number'))
        .map(({ id, unit_number }) => ({ value: id, label: unit_number }))
    }
    return []
  }, [unitType])

  const roomOptions = useMemo(() => {
    if (level && formValues.level) {
      return level.rooms
        .filter((room) => room.room_designs.length > 0)
        .sort(smartSort('name'))
        .map(({ id, name }) => ({ value: id, label: name }))
    }
    return []
  }, [level, formValues])

  function isFormValid() {
    const { unit, line, room, displayName } = formValues
    if (unit && line && room && (rawImage || photo?.src) && displayName) {
      return true
    }
    return false
  }

  function getDesign() {
    if (unitType) {
      const room = level.rooms.find(({ id }) => id === formValues.room)
      if (room) {
        return room.room_designs[0]
      }
    }
  }

  function generateImageObjectKey(image) {
    const room = level.rooms.find((r) => r.id === formValues.room)
    const { real_estate_company } = unitType.property
    return `real_estate_companies/${real_estate_company.company_name || real_estate_company.id}/properties/${
      property.property_name || property.id
    }/${unitType.name || unitType.id}${
      safeConfigurationOptions(unitType.unit_types_configuration_options).is_mirrored ? '_mirrored' : ''
    }/rooms/${room.name || room.id}/general_photos/${image.name}`
  }

  async function onSubmit() {
    try {
      setSubmitted(true)
      const { displayName } = formValues
      const design = getDesign().id
      // // Uploading of RAW image
      // const rawImageKey = whitespacesToUnderscore(generateImageObjectKey(rawImage))
      // const rawImageUrl = await uploadFileToS3(rawImage, rawImageKey)

      let rawImageUrl = photo?.src

      if (rawImage) {
        // Uploading of RAW image
        const rawImageKey = whitespacesToUnderscore(generateImageObjectKey(rawImage))
        console.log({rawImageKey})
        rawImageUrl = await uploadFileToS3(rawImage, rawImageKey)
      }

      if(photo){
        await deletePhotos(photo.id)
      }

      await upsertPhotos([
        {
          ...(photo ? {id: photo.id} : {}),
          src: rawImageUrl,
          thumbnail: rawImageUrl,
          is_verified: true,
          is_official: true,
          room_design_id: design,
          display_name: displayName,
          direction: formValues.direction || undefined,
          unit_id: formValues.unit,
          assets: [],
        },
      ])
      setSubmitted(false)
      setResultValue('success')
    } catch (error) {
      console.log(error)
      setResultValue('error')
    } finally {
      setSubmitted(false)
    }
  }

  const levelOptions = useMemo(() => {
    if (unitType) {
      return unitType.unit_type_levels.map((level) => ({
        value: level.id,
        label: getFloorLabel(level),
      }))
    }
    return []
  }, [unitType])

  return unitType ? (
    <>
      <div>
        <div className="mt-3">
          <div className="text-sm mb-2 opacity-50 mr-[16px]">
            Level <span className="text-[red]">*</span>
          </div>
          <Select
            onChange={(val) => onChange('level', val, { vendor: undefined })}
            options={levelOptions}
            isDisabled={levelOptions.length === 1}
            value={levelOptions.find((vendor) => vendor.value === formValues.level) || ''}
            placeholder="Level"
          />
        </div>
        <div className="mt-3">
          <div className="text-sm mb-2 opacity-50">
            Captured in Unit <span className="text-[red]">*</span>
          </div>
          <Select
            onChange={(val) => onChange('unit', val)}
            value={unitOptions.find((unit) => unit.value === formValues.unit) || ''}
            options={unitOptions}
            placeholder="Unit"
            isDisabled={!formValues.line}
          />
        </div>
        <div className="mt-3">
          <div className="text-sm mb-2 opacity-50">
            Physical room name <span className="text-[red]">*</span>
          </div>
          <Select
            onChange={(val) => onChange('room', val)}
            value={roomOptions.find((room) => room.value === formValues.room) || ''}
            options={roomOptions}
            isDisabled={!formValues.unit}
            placeholder="Room"
          />
        </div>
        <div className="mt-3">
          <div className="text-sm mb-2 opacity-50">
            Display name <span className="text-[red]">*</span>
          </div>
          <Input
            placeholder="Display name"
            value={formValues.displayName || ''}
            disabled={!formValues.room}
            onChange={(e) => onChange('displayName', e.target)}
          ></Input>
        </div>
        <div className="mt-3">
          <div className="text-sm mb-2 opacity-50">Captured direction</div>
          <Input
            placeholder="Direction"
            value={formValues.direction || ''}
            disabled={!formValues.room}
            onChange={(e) => onChange('direction', e.target)}
          ></Input>
        </div>
        <div className="mt-5">
          <ImageUploaders photo={{
            ...photo,
            raw_image: photo?.src
          }} onRawImagePicked={setRawImage} rawImage={rawImage} />
        </div>
        <div className="flex justify-end my-[32px]">
          <div className="flex justify-end">
            <Button disabled={!isFormValid() || submitted} rounded loading={submitted} size="small" onClick={onSubmit}>
              Upload
            </Button>
          </div>
        </div>
        {resultValue && (
          <ActionSuccessModal
            state={resultValue}
            onClose={() => {
              setResultValue(null)
            }}
            skipConfirm={photo}
            action="Image"
            onConfirm={resetForm}
          />
        )}
      </div>
    </>
  ) : (
    <div className="flex justify-center">
      <Loader />
    </div>
  )
}

export default WithoutDesignEditor
