import { EAppType } from 'Resident/constants/appType'

export function isStandartApp(appType) {
  //==========================================================================
  // Standard resident app
  //==========================================================================
  return !appType || appType === EAppType.STANDART
}

export function isMinimalApp(appType) {
  return appType === EAppType.MINIMAL
}

export function isAgentApp(appType) {
  return appType === EAppType.AGENT
}

export function isInstantApp(appType) {
  return appType === EAppType.INSTANT
}
