import React, { useMemo } from 'react'
import { useDropzone } from 'react-dropzone'

const baseStyle = {
  flex: 1,
  height: '400px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',

  borderRadius: 2,

  backgroundColor: '#D9D9D9',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
  border: '2px solid #304FFE',
  backgroundColor: 'rgba(48,79,254,0.28)',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

function DragFileUpload({ onPicked, type = 'image', hintText, required = false }) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, isDragActive } = useDropzone({
    accept: { [`${type}/*`]: [] },
    onDrop: onPicked,
  })
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  )

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p className="text-xl text-[#304FFE]">Drop files here ...</p>
      ) : (
        <p className="text-xl text-[#304FFE]">
          {hintText || 'Drop files'} here or <span className="underline">select file</span>{' '}
          {required && <span className="text-[#FE3030]">*</span>}
        </p>
      )}
    </div>
  )
}

export default DragFileUpload
