import React from 'react'
import './styles.scss'

function Checkbox({onChange, checked, className = ''}) {
  return (
    <label className={`checkbox-container-empty ${className}`} data-test-id="terms-check">
      <input type="checkbox" onChange={onChange} checked={checked} />
      <span className="checkmark"></span>
    </label>
  )
}

export default Checkbox
