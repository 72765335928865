import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import Input from 'shared/components/Input'
import Select from 'shared/components/Select'

function AgentForm({ pack, onChange }) {
  const unitOptions = pack.unit_type.units.map(({ unit_number, id }) => ({ label: `#${unit_number}`, value: id }))

  const formik = useFormik({
    initialValues: {
      unitId: unitOptions[0].value,
      residentName: ``,
    },
    validateOnChange: false,
    validateOnBlur: true
  })

  useEffect(() => {
    onChange({...formik.values, form: {isValid: formik.isValid}})
  }, [formik.values, formik.isValid, onChange])

  return (
    <div className="w-full lg:w-1/2 pr-[68px]">
      <div className="text-[#C7CCD9]">Order Information</div>
      <div className="grid gap-[16px] lg:gap-[24px] grid-cols-2 mt-[12px] lg:mt-[24px]">
        <div className="flex">
          <div className="w-full">
            <div className="hidden lg:block font-semibold text-[rgba(95,103,135,0.3)] uppercase text-[12px] mt-[8px]">
              Unit Number
            </div>
            <div className="mt-[8px]">
              <Select
                onChange={async (val) => {
                  formik.setFieldValue('unitId', val.value)
                }}
                value={unitOptions.find(({ value }) => value === formik.values.unitId) || ''}
                options={unitOptions}
                placeholder="Unit number"
              />
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="w-full">
            <div className="hidden lg:block font-semibold text-[rgba(95,103,135,0.3)] uppercase text-[12px] mt-[8px]">
              Resident name
            </div>
            <div className="mt-[8px]">
              <Input
                placeholder="Resident name"
                name="residentName"
                value={formik.values.residentName}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgentForm
