import React, { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from '@react-hook/media-query'
import AssetItem from 'shared/components/AssetItem'
import Carousel from 'shared/components/Carousel'

function AssetsSlider({
  showFavorites = true,
  pickedAsset,
  hoveredAsset,
  onAssetPick,
  onAssetHover,
  items,
  designImages,
  pickedImage,
  purchaseType,
  scrollToAsset,
  pickedLayout,
}) {
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const [carouselInstance, setCarouselInstance] = useState()
  const slidesRefs = useRef([])

  const [skipSliding, setSkipSliding] = useState(false)

  useEffect(() => {
    if (carouselInstance && pickedAsset) {
      const index = items.findIndex(({ id }) => id === pickedAsset.id)
      if (index > -1 && !skipSliding) {
        if (!Boolean(Number(slidesRefs.current[index].getAttribute('data-visible')))) {
          carouselInstance.slideTo(index, 400)
        }
      }
    }
    if (carouselInstance && hoveredAsset) {
      const index = items.findIndex(({ id }) => id === hoveredAsset.id)
      if (index > -1 && !skipSliding) {
        if (!Boolean(Number(slidesRefs.current[index].getAttribute('data-visible')))) {
          carouselInstance.slideTo(index, 400)
        }
      }
    }
  }, [pickedAsset, carouselInstance, items, hoveredAsset, skipSliding])

  function sortByFavorite(items) {
    return items.sort((x, y) => (x.is_favourite === y.is_favourite ? 0 : x.is_favourite ? -1 : 1))
  }

  function lastFavoriteIndex() {
    return sortByFavorite(items).filter(({ is_favourite }) => is_favourite).length - 1
  }

  function getBlurredState(asset) {
    if (!pickedAsset && hoveredAsset && hoveredAsset.id !== asset.id) {
      return true
    }
    if (pickedAsset && pickedAsset.id !== asset.id) {
      return true
    }
    return false
  }

  function showAssetPopup(asset) {
    if (!pickedImage || !hoveredAsset) {
      return
    }

    return hoveredAsset.id === asset.id && (asset.x_axis > 100 || asset.y_axis > 100)
  }

  function assetpresentsOnOtherImage(asset) {
   return designImages && designImages.find(image=>image.assets.find(imageAsset=>imageAsset.asset.id === asset.id && imageAsset.x_axis <= 100 && imageAsset.y_axis <= 100))
  }

  return (
    <div onMouseEnter={() => setSkipSliding(true)} onMouseLeave={() => setSkipSliding(false)}>
      <Carousel
        className="sparx-slider"
        slidesPerView="auto"
        navigation={isDesktop}
        onSwiper={setCarouselInstance}
        separatorIndex={showFavorites && lastFavoriteIndex()}
        separatorClassList="!mr-[32px]"
        spaceBetween={16}
        watchSlidesProgress={true}
        slidesRefs={slidesRefs}
        items={sortByFavorite(items)
          .filter((a) => (isDesktop ? a : !a?.isVendorSite))
          .map((asset, index, assets) => ({
            ...asset,
            separator:
              showFavorites &&
              assets.filter(({ is_favourite }) => is_favourite).length - 1 === index &&
              !assets.every(({ is_favourite }) => is_favourite),
          }))
          .map((asset, index) =>
            asset.id ? (
              <div
              key={`${pickedImage ? pickedImage.id : null}${asset.id}`}
                onClick={(e) => !isDesktop ? onAssetPick(asset, e) : null}
                onMouseEnter={() => {
                  isDesktop && onAssetHover(asset)
                }}
                onMouseLeave={() => {
                  isDesktop && onAssetHover(null)
                }}
              >
                <AssetItem
                  className={`${pickedAsset && pickedAsset.id === asset.id ? 'cursor-pointer' : ''}`}
                  key={index}
                  pickedLayout={pickedLayout}
                  popupShown={showAssetPopup(asset)}
                  onAssetFindClick={()=>scrollToAsset(asset)}
                  presentsOnOtherImage={assetpresentsOnOtherImage(asset)}
                  showFavorite={showFavorites}
                  blurred={getBlurredState(asset)}
                  hidePrice={pickedLayout && pickedLayout.main_vendor.is_sparx}
                  asset={asset}
                  hasPopup
                  separator={asset.separator}
                  purchaseType={purchaseType}
                />
              </div>
            ) : (
              <AssetItem
                className={pickedAsset || hoveredAsset ? 'opacity-40' : ''}
                key={index}
                showFavorite={showFavorites}
                asset={asset}
                separator={asset.separator}
                purchaseType={purchaseType}
                onClick={() => onAssetPick(asset)}
              />
            ),
          )}
      />
    </div>
  )
}

export default AssetsSlider
