export const ONE_MEGABYTE_IN_BYTES = 1000000

export const REGEX_PATTERNS = {
  ABSOLUTE_URL: /^(?:[a-z+]+:)?\/\//,
}

export const EMAIL_REGX =
/^(([^<>()[\]\\.,;:\s@"+"]+(\.[^<>()[\]\\.,;:\s@"+"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const APP_STORE_LINK = 'https://apps.apple.com/il/app/sparx-ai/id1632413626'
