import * as React from 'react'
const WishlistIcon = (props) => (
  <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.8383 10.6495L11.5093 19L3.18024 10.6495C2.63086 10.1083 2.19813 9.45782 1.90928 8.73903C1.62044 8.02024 1.48174 7.2487 1.50193 6.47298C1.52211 5.69726 1.70074 4.93418 2.02656 4.23178C2.35239 3.52938 2.81835 2.90289 3.39511 2.39174C3.97187 1.8806 4.64693 1.49588 5.37778 1.26181C6.10863 1.02775 6.87944 0.949401 7.64167 1.03171C8.4039 1.11402 9.14104 1.35521 9.80666 1.74008C10.4723 2.12494 11.052 2.64516 11.5093 3.26797C11.9685 2.64968 12.5489 2.13401 13.214 1.75323C13.8792 1.37244 14.6149 1.13474 15.375 1.055C16.135 0.975265 16.9032 1.0552 17.6314 1.28982C18.3596 1.52443 19.0321 1.90867 19.6068 2.41848C20.1815 2.92829 20.6461 3.55271 20.9715 4.25264C21.2969 4.95258 21.476 5.71297 21.4978 6.48622C21.5195 7.25947 21.3833 8.02894 21.0978 8.74647C20.8122 9.464 20.3834 10.1141 19.8383 10.6562"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
export default WishlistIcon
