import React from 'react'
import { getCdnAssetUrl } from 'shared/helpers/assets'

function SliderVideo({ onOpenVideo, video }) {
  return (
    <div className={`relative w-[221px] h-[103px] cursor-pointer`} onClick={onOpenVideo}>
      <img src={getCdnAssetUrl(video.thumbnail)} alt={video.id} className="w-full object-cover" />
    </div>
  )
}

export default SliderVideo
