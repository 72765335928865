import Photo from '../../Photo'

export function renderPhotoSlide(photo, pack, dots, zoomed, onDotHovered) {
  return (
    <div className={`swiper-zoom-container h-full`}>
      <div className="h-full flex items-center justify-center swiper-zoom-target relative">
        {' '}
        <Photo
          zoomed={zoomed}
          dots={!pack.generated && dots}
          photo={photo}
          pack={pack}
          onDotHovered={onDotHovered}
        />
      </div>
    </div>
  )
}
