import React, { useEffect, useState } from 'react'
import Button from 'shared/components/Button'
import { analyticsDebugEnabled } from 'shared/helpers/analytics'

const fullMode = localStorage.getItem('analyticsDebug') === 'full'

function EventLogBuffer(props) {
  const [events, setEvents] = useState([])
  const [toggled, setToggled] = useState(false)

  function renderBufferToggle() {
    return analyticsDebugEnabled()
  }

 

  return (
    <>
      {renderBufferToggle() && (
        <div className="fixed top-[20px] right-[20px] z-[900]">
          <Button
            className="px-[15px]"
            rounded
            onClick={() => {
              if (!toggled) {
                setEvents(JSON.parse(JSON.stringify(window.eventsBuffer.events)).reverse())
              }
              setToggled(!toggled)
            }}
          >
            Toggle event log buffer
          </Button>
        </div>
      )}
      {toggled && (
        <div className="fixed h-screen w-screen overflow-y-auto bg-[#fafafa] top-0 left-0 z-[500] p-5">
          <div className="text-3xl">Event log buffer</div>
          <table class="table-auto border-separate border-spacing-6">
            <thead>
              <tr>
                <th>Name</th>
                <th>Data</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event) => (
                <tr>
                  <td>
                    <div>
                      {event.name}
                      {event.data.is_engaged && <div className="bg-[#e9a527] text-center text-black rounded-full text-sm opacity-50 mt-2 p-1">Engaged</div>}
                    </div>
                  </td>
                  <td>{fullMode ? <pre>{JSON.stringify(event.data, null, 2)}</pre> : JSON.stringify(event.data) }</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default EventLogBuffer
