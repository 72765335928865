import Button from '../Button'
import TinyModal from '../TinyModal'
import { ReactComponent as ErrorFilled } from 'shared/assets/icons/error-filled.svg'

import { ReactComponent as SuccessIcon } from 'shared/assets/icons/resident-password-set-success.svg'

export function ResultPopup({ onClose, text, type, actions = [], className = '', btnClassName = '' }) {
  return (
    <>
      {type === 'success' && (
        <TinyModal className={className} light>
          <>
            <SuccessIcon />
            <div className="text-[18px] text-center leading-[22px] mt-[24px]">{text}</div>
          </>
          {onClose && (
            <div className="px-[24px] w-full flex justify-center">
              <Button rounded className={`w-full mt-[24px] ${btnClassName}`} onClick={onClose}>
                OK
              </Button>
            </div>
          )}
        </TinyModal>
      )}
      {type === 'error' && (
        <div className="bg-[rgba(0,0,0,.3)] z-[300] p-[16px] fixed top-0 left-0 h-screen w-screen flex items-center justify-center">
          <div
            className={`bg-white w-full lg:w-[343px] rounded-[10px] px-[56px] py-[40px] flex flex-col items-center justify-center ${className}`}
          >
            <ErrorFilled />
            <div className="mt-[26px] text-[18px]">{text || `Something went wrong...`}</div>
            <div className="mt-[80px] w-full">
              {actions
                ? actions.map((item) => (
                    <Button
                      rounded
                      type="button"
                      variant={item.variant || 'secondary'}
                      className="w-full mb-[10px]"
                      onClick={item.action}
                    >
                      {item.label}
                    </Button>
                  ))
                : null}
              {onClose && (
                <Button rounded type="button" className={`w-full ${btnClassName}`} onClick={onClose}>
                  OK
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
