import moment from 'moment'
// Icons
import { ReactComponent as LocationIcon } from 'shared/assets/icons/location.svg'
import { ReactComponent as CalendarIcon } from 'shared/assets/icons/calendar.svg'
import { ReactComponent as UserHomeIcon } from 'shared/assets/icons/userHome.svg'
import { ReactComponent as PropertyIcon } from 'shared/assets/icons/property.svg'
import { ReactComponent as EnvelopeIcon } from 'shared/assets/icons/envelope.svg'
import { ReactComponent as PhoneIcon } from 'shared/assets/icons/phoneLine.svg'
import { ReactComponent as EnvelopeSmallIcon } from 'shared/assets/icons/envelopeSmall.svg'
import { ReactComponent as PhoneSmallIcon } from 'shared/assets/icons/phoneLineSmall.svg'

import DetailsDropdown from 'shared/components/DetailsDropdown'
import { isApartmentApp, isPostTourApp } from 'shared/helpers/general'

function PropertyTourDetailsDropdown({ plain, active, onToggle, name, details, className = '' }) {
  function renderAgentInfo() {
    if (details.agent) {
      return {
        icon: <UserHomeIcon className="w-[20px]" />,
        content: <span>{[details.agent.first_name, details.agent.last_name].join(' ')}</span>,
      }
    }
    return {}
  }

  function renderContactInfo() {
    const elements = []
    const { leasing_office_main_email, phone_number: leasing_office_main_phone_number } =
      details.unit.unit_type.property
    if (details.agent && Object.values(details.agent).some((val) => !!val)) {
      const { email, phone_number } = details.agent
      // Emails display
      if (email) {
        elements.push({
          icon: <EnvelopeIcon className="w-[20px]" />,
          content: (
            <a href={`mailto:${email}`} className="underline">
              {email}
            </a>
          ),
        })
      }
      if (!email && leasing_office_main_email) {
        elements.push({
          icon: <EnvelopeIcon className="w-[20px]" />,
          content: (
            <a href={`mailto:${leasing_office_main_email}`} className="underline">
              {leasing_office_main_email}
            </a>
          ),
        })
      }
      // Phones display
      if (phone_number) {
        elements.push({
          icon: <PhoneIcon className="w-[20px]" />,
          content: (
            <a href={`tel:${phone_number}`} className="underline">
              {phone_number}
            </a>
          ),
        })
      }
      if (!phone_number && leasing_office_main_phone_number) {
        elements.push({
          icon: <PhoneIcon className="w-[20px]" />,
          content: (
            <a href={`tel:${leasing_office_main_phone_number}`} className="underline">
              {leasing_office_main_phone_number}
            </a>
          ),
        })
      }
    } else {
      if (leasing_office_main_email) {
        elements.push({
          icon: <EnvelopeIcon className="w-[20px]" />,
          content: (
            <a href={`mailto:${leasing_office_main_email}`} className="underline">
              {leasing_office_main_email}
            </a>
          ),
        })
      }
      if (leasing_office_main_phone_number) {
        elements.push({
          icon: <PhoneIcon className="w-[20px]" />,
          content: (
            <a href={`tel:${leasing_office_main_phone_number}`} className="underline">
              {leasing_office_main_phone_number}
            </a>
          ),
        })
      }
    }

    return elements
  }

  const { street, city } = details.unit.unit_type.property

  const items = [
    {
      icon: <LocationIcon className="w-[20px]" />,
      content: `${street}${city ? `, ${city}` : ''}`,
    },
    {
      icon: <CalendarIcon className="w-[20px]" />,
      content: details.startTime && moment(details.startTime).format('L, LT'),
    },
    {
      icon: <PropertyIcon className="w-[20px]" />,
      content: details.unit.unit_type.property.real_estate_company.company_name,
    },
    renderAgentInfo(),
    ...(isPostTourApp() ? renderContactInfo() : []),
  ]

  const options = [
    items
      .filter(({ content }) => content)
      .map(({ content, icon }, i) => (
        <div className={`flex items-center w-full h-[22px] mt-[13px]`} key={i}>
          <span>{icon}</span>
          <div className="ml-[15px] text-[14px] leading-[21px] font-normal w-full truncate">{content}</div>
        </div>
      )),
  ]

  return (
    <DetailsDropdown
      className={className}
      name={name}
      tooltip={details.title}
      highlighted
      plain={plain}
      title={details.title}
      options={options}
      active={active}
      onToggle={onToggle}
    />
  )
}

export default PropertyTourDetailsDropdown
