import { useFormik } from 'formik'
import { useCallback } from 'react'
import * as Yup from 'yup'

export function useSigninForm(onSubmit) {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(),
    password: Yup.string().required(),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema,
    // validateOnMount:true,
    onSubmit: (values) => {
      validateForm(formik)
      onSubmit(values)
    },
  })

  const validateForm = useCallback(() => {
    if (!formik.values.email) {
      return false
    }
    if (formik.errors.email) {
      return false
    }
    if (!formik.values.password) {
      return false
    }
    if (formik.errors.password) {
      return false
    }
    return true
  }, [formik])

  return {
    form: formik,
    validateForm,
  }
}
