import React, { useState, useEffect, useRef } from 'react'

import Chip from 'shared/components/Chip'
import Carousel from 'shared/components/Carousel'

export default function   ChipPicker({
  columnView = false,
  items = [],
  picked,
  classNames = '',
  itemClassNames = '',
  activeItemClassNames = '',
  ...props
}) {
  const [pickedIndex, setPickedIndex] = useState(0)
  const chipRefs = useRef([])

  useEffect(() => {
    setPickedIndex(items.findIndex((item) => item === picked))
  }, [picked, items])

  function onPicked(item, index) {
    setPickedIndex(index)
    props.onPicked && props.onPicked(item, index)
  }

  useEffect(() => {
    if (chipRefs && chipRefs.current[pickedIndex]) {
      chipRefs.current[pickedIndex].scrollIntoView(false)
    }
  }, [pickedIndex])

  const buttons = items.map((item, index) => (
    <div key={index} ref={(el) => (chipRefs.current[index] = el)}>
      <Chip
        onClick={() => onPicked(item, index)}
        className={`border-solid border-[1px] border-black transition duration-6000 capitalize mr-[8px] lg:mr-[16px]
        ${pickedIndex === index ? 'bg-black text-white cursor-default !opacity-100' : 'cursor-pointer'}
        ${typeof itemClassNames === 'function' ? itemClassNames(index) : itemClassNames}
        ${pickedIndex === index ? activeItemClassNames : ''}
      `}
      >
        {item}
      </Chip>
    </div>
  ))

  return columnView ? (
    <div className={`flex flex-wrap content-start ${classNames}`} {...props}>{buttons}</div>
  ) : (
    <div className={`w-full ${classNames}`} {...props}>
      <Carousel slideClassName="!w-max" items={buttons} slidesPerView="auto" spaceBetween={0} />
    </div>
  )
}
