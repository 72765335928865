import PackageAssetPopover from 'Resident/components/AssetPopover'
import React, { useMemo, useState } from 'react'
import { Popover } from 'react-tiny-popover'
import { getCdnAssetUrl } from 'shared/helpers/assets'

function PackagePhotoItem({ item, hoveredAsset, onHover, assetPopup, pack }) {
  const name = (item.asset || {}).asset_name
  const image = (item.asset || {}).thumbnail
  const [hovered, setHovered] = useState()
  const [timeoutId, setTimeoutId] = useState(null)

  const opacity = useMemo(() => {
    if (!hoveredAsset) {
      return ''
    }
    if (hoveredAsset && hoveredAsset.identifier === item.identifier && item.type === hoveredAsset.type) {
      return ''
    } else {
      return 'opacity-50'
    }
  }, [hoveredAsset, item])

  function onAssetPick(asset) {
    if (asset) {
      setHovered(true)
      clearTimeout(timeoutId)
    } else {
      const interval = setTimeout(() => {
        setHovered(false)
      }, 100)
      setTimeoutId(interval)
    }

    onHover && onHover(asset ? item : null)
  }

  const dimensions = useMemo(() => {
    if (!item.category) {
      return
    }
    const asset = item.category.assets[0] || null
    if (asset) {
      const { height, width, depth } = asset
      return { height, width, depth }
    } else {
      return null
    }
  }, [item])

  return (
    <Popover
      isOpen={hovered && assetPopup && assetPopup === item.id}
      containerClassName="z-[200]"
      padding={17}
      positions={['top']} // preferred positions by priority
      content={
        pack &&
        item && (
          <PackageAssetPopover
            dimensions={dimensions}
            rent={pack.vendor.is_rental}
            vendor={pack.vendor.full_name}
            asset={item}
            onMouseEnter={() => {
              onAssetPick(item)
            }}
            onMouseLeave={() => {
              onAssetPick(null)
            }}
          />
        )
      }
    >
      <div
        onMouseEnter={() => {
          onAssetPick(true)
        }}
        onMouseLeave={() => {
          onAssetPick(null)
        }}
      >
        <div className={`flex justify-center ${opacity}`}>
          {item.count > 1 && (
            <div
              className={`absolute top-[8px] left-[8px] bg-[#3D4256] pointer-events-none text-white w-max min-w-[27px]
            text-[14px] rounded-full h-[24px] leading-[14px] px-[8px] flex items-center justify-center font-medium`}
            >
              ×{item.count}
            </div>
          )}
          <img src={getCdnAssetUrl(image)} className="h-[124px]" alt={getCdnAssetUrl(image)} />
        </div>
        <div className="mt-[12px] text-center text-[14px] w-[150px]">{name}</div>
      </div>
    </Popover>
  )
}

export default PackagePhotoItem
