import { getVendors } from 'MediaManager/services'
import React, { useEffect, useState } from 'react'
import Button from 'shared/components/Button'
import Input from 'MediaManager/components/Form/Input'
import Modal from 'shared/components/Modal'
import TinyModal from 'shared/components/TinyModal'
import Select from 'shared/components/Select'
import { useFormik } from 'formik'
import { createExternalMapping, deleteExternalMapping } from 'MediaManager/services/property'

function ExternalPropertyMappingsEditor({ propertyId, ...props }) {
  const [mappings, setMappings] = useState([])
  const [vendors, setVendors] = useState([])
  const [editItem, setEditItem] = useState(null)
  const [isAdding, setIsAdding] = useState(false)

  useEffect(() => {
    ;(async () => {
      const vendors = await getVendors()
      setVendors(vendors.data.vendors)
    })()
  }, [])

  useEffect(() => {
    formik.setFieldValue('internal_property_id', propertyId)
  }, [propertyId])

  useEffect(() => {
    if (props.mappings) {
      setMappings(props.mappings)
    }
  }, [props.mappings])

  const formik = useFormik({
    initialValues: {
      internal_property_id: propertyId,
    },
    // validateOnChange: false,
    // validateOnBlur: true,
    // validationSchema,
    // validateOnMount:true,
    onSubmit: async () => {
      if (!editItem) {
        await createExternalMapping(formik.values)
        setMappings([...mappings, formik.values])
        setIsAdding(false)
      } else {
        const { internal_property_id, external_property_id, vendor_id } = editItem
        await deleteExternalMapping({
          internalPropertyId: internal_property_id,
          externalPropertyId: external_property_id,
          vendorId: vendor_id,
        })
        await createExternalMapping(formik.values)
        const newMappings = [...mappings]
        newMappings[editItem.index] = formik.values
        setMappings(newMappings)
        setEditItem(null)
      }
    },
  })

  useEffect(() => {
    if (editItem) {
      const { internal_property_id, external_property_id, account_id, vendor_id } = editItem
      formik.resetForm()
      formik.setValues({ internal_property_id, external_property_id, account_id, vendor_id })
    }
  }, [editItem])

  async function onDelete(index) {
    if (window.confirm('Are you sure you want to delete this mapping?')) {
      const { internal_property_id, external_property_id, vendor_id } = mappings[index]
      await deleteExternalMapping({
        internalPropertyId: internal_property_id,
        externalPropertyId: external_property_id,
        vendorId: vendor_id,
      })
      const newMappings = [...mappings]
      newMappings.splice(index, 1)
      setMappings(newMappings)
    }
  }

  const vendorOptions = vendors.map((v) => ({ label: v.full_name, value: v.id }))

  const isValid =
    formik.values.internal_property_id &&
    formik.values.external_property_id &&
    formik.values.account_id &&
    formik.values.vendor_id

  return (
    <>
      {(isAdding || editItem) && (
        <TinyModal
          onClose={() => {
            setIsAdding(false)
            setEditItem(null)
          }}
        >
          <div className="text-xl text-left w-full font-normal mb-[16px]">
            {editItem ? 'Edit' : 'Add'} external mapping
          </div>
          <Input
            name="internal_property_id"
            label="Internal Property ID"
            placeholder="Enter value"
            value={formik.values.internal_property_id}
            onChange={formik.handleChange}
            wrapClassName="w-full"
            disabled
          />
          <Input
            name="external_property_id"
            label="External Property ID"
            placeholder="Enter value"
            value={formik.values.external_property_id}
            onChange={formik.handleChange}
            wrapClassName="w-full"
          />
          <Input
            name="account_id"
            label="Account ID"
            placeholder="Enter value"
            value={formik.values.account_id}
            onChange={formik.handleChange}
            wrapClassName="w-full"
          />
          <Select
            label="Vendor"
            options={vendorOptions}
            value={vendorOptions.find((v) => v.value === formik.values.vendor_id)}
            onChange={(e) => formik.setFieldValue('vendor_id', e.value)}
          />
          <Button
            className="mt-3 w-full"
            onClick={() => {
              formik.handleSubmit()
            }}
            disabled={!isValid}
            rounded
          >
            {editItem ? 'Edit' : 'Add'}
          </Button>
        </TinyModal>
      )}
      <div className="my-[32px]">
        <div className="flex justify-between items-center mb-[16px]">
          <div className="text-sm mb-2 opacity-50">External property mappings</div>
          <Button
            size="small"
            variant="secondary"
            rounded
            onClick={() => {
              setIsAdding(true)
            }}
          >
            Add
          </Button>
        </div>
        <div className="data-table">
          <table className="table">
            <thead>
              <tr>
                <th>Internal Property ID</th>
                <th>External Property ID</th>
                <th>Account ID</th>
                <th>Vendor</th>
                <th className="opacity-50 text-center">
                  <div className="text-center">Actions</div>
                </th>
              </tr>
            </thead>

            <tbody>
              {mappings.map((p, index) => (
                <tr>
                  <td>{p.internal_property_id}</td>
                  <td>{p.external_property_id}</td>
                  <td>{p.account_id}</td>
                  <td>{vendors.find((v) => v.id === p.vendor_id)?.full_name}</td>
                  <td className="flex justify-center items-center">
                    <div className="flex">
                      <Button size="small" variant="secondary" rounded onClick={() => setEditItem({ ...p, index })}>
                        Edit
                      </Button>
                      <Button
                        size="small"
                        variant="secondary"
                        rounded
                        onClick={() => {
                          onDelete(index)
                        }}
                        className="ml-2"
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default ExternalPropertyMappingsEditor
