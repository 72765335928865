import { useFormik } from 'formik'
import * as Yup from 'yup'

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/

export function useSetPasswordForm(onSubmit) {
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required()
      .matches(
        passwordRules,
        'Passwords should contain a minimum of 8 characters, using a mix of uppercase and lowercase letters and numbers, not matching username',
      ),
    repeatPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('password'), null], 'Passwords do not match'),
  })

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema,
    // validateOnMount:true,
    onSubmit,
  })

  function validateForm() {
    if (!formik.values.password || !formik.values.repeatPassword) {
      return false
    }
    if (Object.values(formik.errors).length > 0) {
      return false
    }
    return true
  }

  return {
    form: formik,
    validateForm
  }
}
