import NotFoundScreen from 'TourApp/screens/NotFound'
import LoginScreen from './screens/Login'
import SetPasswordScreen from './screens/SetPassword'
import SignupScreen from './screens/Signup'
import WelcomeScreen from './screens/Welcome'
import RootScreen from './screens/Root'
import ForgotPasswordScreen from './screens/ForgotPassword'
import ReviewProfileScreen from './screens/ReviewProfile'
import ContentGuard from './components/ContentGuard'
import DesignPackagesScreen from './screens/DesignPackages'
import CheckoutScreen from './screens/Checkout'
import PreviewScreen from './screens/Preview'

import { Outlet, createBrowserRouter, useLocation } from 'react-router-dom'
import SettingsScreen from './screens/Settings'
import PackagesContentGuard from './components/PackagesContentGuard'
import RouteStateGuard from './components/RouteStateGuard'
import SidebarLayout from './layouts/SidebarLayout'
import Page from 'shared/components/Page'

function App() {
  const location = useLocation()
  const hideSidebar = [`welcome`, `signin`, `signup`, `set-password`, `forgot-password`, `review-profile`].includes(
    location.pathname.replace('/', ''),
  ) || location.pathname === '/'
  return (
    <>
      {hideSidebar ? (
        <Outlet />
      ) : (
        <ContentGuard>
          <SidebarLayout>
            <Outlet />
          </SidebarLayout>
        </ContentGuard>
      )}
    </>
  )
}

export const routes = [
  {
    element: <App />,
    children: [
      {
        path: '/welcome',
        element: (
          <Page title="Welcome">
            <WelcomeScreen />
          </Page>
        ),
      },
      {
        path: '/signin',
        element: (
          <Page title="Sign in">
            <LoginScreen />
          </Page>
        ),
      },
      {
        path: '/signup',
        element: (
          <Page title="Sign up">
            <SignupScreen />
          </Page>
        ),
      },
      {
        path: '/set-password',
        element: (
          <Page title="Set password">
            <SetPasswordScreen />
          </Page>
        ),
      },
      {
        path: '/forgot-password',
        element: (
          <Page title="Forgot password">
            <ForgotPasswordScreen />
          </Page>
        ),
      },
      {
        path: '/review-profile',
        element: (
          <Page title="Review profile">
            <ReviewProfileScreen />
          </Page>
        ),
      },
      {
        path: '/packages',
        element: (
          <Page title="Packages">
            <PackagesContentGuard>
              <DesignPackagesScreen />
            </PackagesContentGuard>
          </Page>
        ),
      },
      // {
      //   path: '/',
      //   element: <RootScreen />,
      // },
      {
        path: '/checkout',
        element: (
          <Page title="Review cart">
            <PackagesContentGuard>
              <CheckoutScreen />
            </PackagesContentGuard>
          </Page>
        ),
      },
      {
        path: '/preview',
        element: (
          <Page title="View">
            <RouteStateGuard redirect="/packages">
              <PackagesContentGuard>
                <PreviewScreen />
              </PackagesContentGuard>
            </RouteStateGuard>
          </Page>
        ),
      },
      {
        path: '/settings',
        element: (
          <Page title="Settings">
            <SettingsScreen />
          </Page>
        ),
      },
      // {
      //   path: '/*',
      //   element: <NotFoundScreen />,
      // },
      {
        path: '/',
        element: <RootScreen />,
      },
    ],
  },
]

export const router = createBrowserRouter(routes)
