import React, { useEffect, useRef, useState } from 'react'

const slidersAnimationDuration = 500

function ExpandCollapseHandle({ previewMode, previewState, ...props }) {
  const ref = useRef()
  const [position, setPosition] = useState({ x: 0 })
  const [freeze, setFreeze] = useState(false)

  const [previewSizeTooltipShown, setPreviewSizeTooltipShown] = useState(false)

  function showTooltip() {
    if (freeze) {
      return
    }
    setPosition((val) => ({ ...val, y: ref.current.getBoundingClientRect().top }))
    if (!previewMode) {
      return
    }
    setPreviewSizeTooltipShown(true)
  }

  useEffect(() => {
    props.onHoverChange && props.onHoverChange(previewSizeTooltipShown)
  }, [previewSizeTooltipShown, props])

  function onChange() {
    setFreeze(true)
    setPreviewSizeTooltipShown(false)
    props.onChange()
    setTimeout(() => {
      setFreeze(false)
    }, slidersAnimationDuration)
  }

  function calculateXPosition(positionX) {
    const sidebarWidth = document.getElementById('sidebar').clientWidth
    const offset = 100
    if (positionX + offset > window.innerWidth) {
      return { right: `0px` }
    }
    if (positionX - offset > sidebarWidth) {
      return { left: `${positionX - offset}px` }
    }
    if (positionX - offset < sidebarWidth) {
      return { left: `${sidebarWidth}px` }
    }
  }

  return (
    <>
      <div
        className={`absolute h-[3px] z-[100] w-full left-0 `}
        onMouseEnter={(e) => {
          setPosition({ x: e.clientX })
          showTooltip()
        }}
        ref={ref}
        onMouseLeave={(e) => {
          setPreviewSizeTooltipShown(false)
        }}
      ></div>
      {previewSizeTooltipShown && previewMode && (
        <div
          className="fixed w-max h-[70px] transition-all ease-out duration-200 flex  items-start z-[100]"
          style={{ top: `${position.y - 60}px`, ...calculateXPosition(position.x) }}
          onMouseEnter={() => {
            setPreviewSizeTooltipShown(true)
          }}
          onMouseLeave={() => {
            setPreviewSizeTooltipShown(false)
          }}
        >
          <div
            onMouseLeave={() => {
              setPreviewSizeTooltipShown(false)
            }}
            className="bg-white w-max shadow-[0px_1px_1px_0px_rgba(0,0,0,0.1)] px-[16px] py-[8px] rounded-[4px] relative z-[100] "
          >
            <button onClick={onChange}>
              Click <span className="text-[#5F6787]">to {previewState === true ? 'minimize' : 'maximize'}</span>
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default ExpandCollapseHandle
