import jwt_decode from 'jwt-decode'
import moment from 'moment'
import { refreshToken } from '../services/user'

const AUTH_KEY = 'auth'

export const storage = {
  getItem: (key) => {
    return sessionStorage.getItem(key) || localStorage.getItem(key)
  },
  setItem: (key, value, storage = localStorage) => {
    return storage.setItem(key, value)
  },
  removeItem: (key, storage = localStorage) => {
    return storage.removeItem(key)
  },
}

export function isAuthenticated() {
  return !!storage.getItem(AUTH_KEY)
}

export function getAuth() {
  return storage.getItem(AUTH_KEY)
}

export function getAuthRefresh() {
  return storage.getItem(`${AUTH_KEY}_refresh`)
}

export function setAuth({ token, refreshToken, storage = localStorage }) {
  storage.setItem(AUTH_KEY, token, storage)
  storage.setItem(`${AUTH_KEY}_refresh`, refreshToken, storage)
}

export function clearAuth() {
  storage.removeItem(AUTH_KEY)
  storage.removeItem(`${AUTH_KEY}_refresh`)
}

export function logout() {
  clearAuth()
  window.location.reload()
}

export async function validateToken(cb) {
  function logout() {
    // clearAuth()
    // window.location.href = '/signin';
  }
  try {
    const { exp } = jwt_decode(getAuth())
    // Check if token is expired
    if (moment(exp * 1000).isBefore(moment())) {
      const { exp } = jwt_decode(getAuthRefresh())
      // Check if refresh token is expired
      if (moment(exp * 1000).isBefore(moment())) {
        // If refresh token expired, session should ended
        return logout()
      } else {
        // Try to refresh token
        try {
          const { data } = await refreshToken(getAuthRefresh())
          setAuth(data)
        } catch (error) {
          return logout()
        }
      }
    }
  } catch (error) {
    return logout()
  }

  return await cb(getAuth())
}
