import { Tooltip } from 'react-tooltip'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { group4 } from 'shared/analytics/resident'
import { ReactComponent as PackageInfoIcon } from 'shared/assets/icons/info-package-resident.svg'
import { useMediaQuery } from '@react-hook/media-query'

export function PackageInfo({ text, extra, pack, ...props }) {


  const isMobile = useMediaQuery('(max-width: 1023px)')

  const id = Math.random()

  function logAnalyticsEvent(){
    rpevent(eventDictionary.dp_screen_info_button_engaged, group4(pack))
  }

  function onClick(e) {
    logAnalyticsEvent()
    e.stopPropagation()
    props.onClick && props.onClick()
  }

  return (
    <>
      <div onClick={onClick} onMouseEnter={!isMobile ? logAnalyticsEvent : ()=>{}} className='inline-block'>
        <Tooltip
          id={`id_${id.toString()}`}
          noArrow
          className="default-tooltip !w-[372px] border-[1px] border-solid bg-[rgba(199,204,217,0.4)] !px-[24px] !py-[16px] z-[10]"
        >
          <div>
            <div>{text}</div>
            {extra && <div className='mt-[20px]'>* {extra.disclaimer}</div>}
          </div>
        </Tooltip>
        <div
          data-tooltip-id={`id_${id.toString()}`}
          data-tooltip-position-strategy="fixed"
          data-tooltip-offset="16"
          data-tooltip-place="bottom-start"
          className='inline-block'
        >
          <PackageInfoIcon />
        </div>
      </div>
    </>
  )
}
