import { Router, Outlet } from '@tanstack/react-location'
import { location, routes } from 'CRM/routes'
import './styles.scss'
import Footer from './components/Footer'
import ErrorSplashWrapper from 'shared/components/ErrorSplash'
import HotJar from 'shared/analytics/hotjar'
import ScrollToTop from './components/ScrollToTop'

function App() {
  return (
    <div id="wrapper" className="h-full bg-[#FAFAFA] lg:bg-[#F3F4F6] w-full overflow-x-hidden">
      <HotJar delayed={true}/>
      <Router location={location} routes={routes}>
        <ErrorSplashWrapper>
          <Outlet />
        </ErrorSplashWrapper>
      </Router>
      <ScrollToTop/>
    </div>
  )
}

export default App
