import React from 'react'
import Carousel from 'shared/components/Carousel'
import { Chip } from '../Chip'

function MobileFilterChips({ invites, statusFilter, onChange }) {
  function renderItems() {
    return [
      <Chip
        active={!statusFilter}
        variant="black"
        className="mr-[10px]"
        outline
        onClick={() => {
          onChange(null)
        }}
      >
        All
      </Chip>,
      invites.find(({ invitation_status }) => invitation_status === 'pending') && (
        <Chip
          active={statusFilter === 'pending'}
          variant="grey"
          outline
          className="mr-[10px]"
          onClick={() => {
            onChange('pending')
          }}
        >
          Pending
        </Chip>
      ),
      invites.find(({ invitation_status }) => invitation_status === 'accepted') && (
        <Chip
          active={statusFilter === 'accepted'}
          variant="green"
          outline
          className="mr-[10px]"
          onClick={() => {
            onChange('accepted')
          }}
        >
          Accepted
        </Chip>
      ),
    ]
  }

  return (
    <Carousel
      id="rooms-slider"
      className="!overflow-visible !z-10 my-[24px]"
      items={renderItems()}
      slidesPerView="auto"
      spaceBetween={0}
      slideClassName="!w-max"
    />
  )
}

export default MobileFilterChips
