import React from 'react'
import './styles.scss'

function Checkbox({onChange, checked, className = '', disabled = false}) {
  return (
    <label className={`checkbox-container ${disabled ? 'opacity-40 !cursor-default' : ''} ${className}`} data-test-id="terms-check">
      <input type="checkbox" onChange={onChange} checked={checked} disabled={disabled} />
      <span className="checkmark"></span>
    </label>
  )
}

export default Checkbox
