import { useEffect, useState } from 'react'

import { useStore } from 'CRM/store'

import { errorMessages } from 'shared/settings'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import ErrorMessage from 'shared/components/ErrorMessage'

import FormWrapper from './components/FormWrapper'
import { useFormik, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { validate } from 'graphql'
import { Link, useSearch } from '@tanstack/react-location'
import { setPassword } from 'CRM/services/user'

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/

export default function SetPasswordScreen() {
  const { code } = useSearch()
  const [loading, setLoading] = useState(false)
  const { setTitle } = useStore()
  const [passwordSet, setPasswordSet] = useState(false)
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required()
      .matches(
        passwordRules,
        'Passwords should contain a minimum of 8 characters, using a mix of uppercase and lowercase letters and numbers, not matching username',
      ),
    repeatPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('password'), null], 'Passwords do not match'),
  })

  useEffect(() => {
    setTitle('Set password')
  }, [setTitle])

  async function onSubmit(values) {
    setLoading(true)
    try {
      await setPassword(values.password, code)
      setPasswordSet(true)
    } catch (error) {
      if (error.response.data.error) {
        formik.setFieldError('password', error.response.data.error)
      }
    } finally {
      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema,
    // validateOnMount:true,
    onSubmit,
  })

  function validateForm() {
    if (!formik.values.password || !formik.values.repeatPassword) {
      return false
    }
    if (Object.values(formik.errors).length > 0) {
      return false
    }
    return true
  }

  return (
    <>
      {!passwordSet && (
        <FormWrapper
          text={
            <>
              Your email has been verified
              <br /> succesfully! Please set a new password:
            </>
          }
        >
          <form className="w-full flex flex-col items-center mt-[20px]" onSubmit={formik.handleSubmit}>
            <div className="w-full">
              <Input
                onChange={formik.handleChange}
                onBlur={() => formik.validateField('password')}
                value={formik.values.password}
                required
                error={formik.errors.password}
                name="password"
                type="password"
                placeholder="New Password"
              />
              <ErrorMessage text={formik.errors.password} />
            </div>
            <div className="w-full">
              <Input
                onChange={formik.handleChange}
                onBlur={() => formik.validateField('repeatPassword')}
                value={formik.values.repeatPassword}
                required
                error={formik.errors.repeatPassword}
                name="repeatPassword"
                type="password"
                className="mt-[16px]"
                placeholder="Re-Enter New Password"
              />
              <ErrorMessage text={formik.errors.repeatPassword} />
            </div>
            <Button
              loading={loading}
              disabled={!validateForm()}
              rounded
              type="submit"
              className="w-full lg:w-[172px] mt-[48px]"
            >
              Continue
            </Button>
          </form>
        </FormWrapper>
      )}
      {passwordSet && (
        <FormWrapper>
          <div>
            <div className="text-center">
              <div className="text-[24px] font-semibold">All set!</div>
              <div className="text-[18px] font-normal">You are logged in.</div>
            </div>
            <Link to="/apartments">
              <Button rounded type="submit" className="w-full lg:w-[172px] mt-[48px]">
                Let’s Get Started
              </Button>
            </Link>
          </div>
        </FormWrapper>
      )}
    </>
  )
}
