import React from 'react'
import './styles.scss'

function Loader() {
  return (
    <div className="sk-fading-circle bounce-in">
      {new Array(12).fill().map((_item, index) => (
        <div key={index} className={`sk-circle${index + 1} sk-circle`}></div>
      ))}
    </div>
  )
}

export default Loader
