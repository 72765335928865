function Greeting({ firstName, lastName, isVirtualTour = false, personalized = false }) {
  function getPersonName() {
    return [firstName, lastName].join(' ').trim()
  }

  function renderGreeting() {
    if (firstName && lastName) {
      return (
        <>
          Hey {getPersonName()},<br /> thanks for touring at:
        </>
      )
    } else {
      return <>Thanks for touring at:</>
    }
  }

  return (
    <div className="mt-[24px] mb-[20px] leading-[21px] font-medium">
      {isVirtualTour ? (
        firstName ? (
          <>{personalized ? 'Dear' : 'Hey'} {getPersonName()}, welcome to your virtual tour at:</>
        ) : (
          <>Welcome to your virtual tour at:</>
        )
      ) : (
        renderGreeting()
      )}
    </div>
  )
}

export default Greeting
