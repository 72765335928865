import Button from 'shared/components/Button'
import { bytesToMegabytes } from 'shared/helpers/general'
import { Modal } from 'MediaManager/components/Modal'
import { ONE_MEGABYTE_IN_BYTES } from 'shared/constants'
import { getCdnAssetUrl } from 'shared/helpers/assets'

export async function validateFloorPlanImage({ floorPlan, existingUnitPlans }) {
  const errorMessages = {
    title: '',
    list: [],
  }

  errorMessages.title = `Floor plan's image size is ${bytesToMegabytes(floorPlan.size).toFixed(1)} Mb`

  // 1) checking size of uploaded image
  if (floorPlan.size > ONE_MEGABYTE_IN_BYTES) {
    errorMessages.list.push('Image size should not exceed 1Mb')
  }

  // validating floor plan dimension ONLY in case we already have floor plans uploaded for selected unit_type
  if (existingUnitPlans.length) {
    // 2) checking dimensions of uploaded image
    const floorPlanImg = new Image()
    floorPlanImg.src = URL.createObjectURL(floorPlan)
    await floorPlanImg.decode()

    const existingFloorPlanImg = new Image()
    // appending base url in case floor plan path is relative
    existingFloorPlanImg.src = getCdnAssetUrl(existingUnitPlans[0].src)
    await existingFloorPlanImg.decode()

    errorMessages.title += ` and its dimensions is ${floorPlanImg.width} x ${floorPlanImg.height}.`

    if (floorPlanImg.width != existingFloorPlanImg.width && floorPlanImg.height !== existingFloorPlanImg.height) {
        errorMessages.list.push(`Dimensions should be ${existingFloorPlanImg.width} X ${existingFloorPlanImg.height}, matching already uploaded floor plan`)
    }
  }

  return errorMessages
}

export const InvalidFloorPlanPopup = ({ closePopup, errors }) => (
  <Modal title='Floor Plan Validation Error' isWarning={true} className='!max-w-[367px] pt-[5px] !h-fit'>
    <div className='text-sm m-[8px 10px 8px 0] flex flex-col'>
      <span>{errors.title}</span>
      <ul role="list" className="text-[#F13C3C] list-disc m-[20px]">
        {errors.list.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <span>Floor plan image can not be updated.</span>
    </div>
    <Button
      className="mx-auto mt-[24px] rounded bg-[#536DFE] shadow-[0_1px_5px_0_rgba(0,0,0,0.12),0_2px_2px_0_rgba(0,0,0,0.14),0_3px_1px_-2px_rgba(0,0,0,0.20)]"
      onClick={() => closePopup()}
    >
      OK
    </Button>
  </Modal>
)