import React from 'react'
import { ReactComponent as ModalCloseSmallIcon } from 'shared/assets/icons/modalCloseSmall.svg'
import Modal from '../Modal'
import DisclaimerContent, { disclaimerText } from '../Disclaimer'

function TermsModal({ onClose }) {
  const disclaimerContent = [
    disclaimerText.first,
    'Most photos and videos have been virtually staged to help showcase potential of spaces in the residences',
    disclaimerText.second,
  ]

  return (
    <Modal className="bg-[rgba(255,255,255,.8)] w-screen h-screen" plain>
      <div className="w-[calc(100%-48px)] lg:w-[500px] bg-white py-[20px] pl-[25px] pr-[35px] lg:py-[30px] lg:px-[33px] relative lg:mb-[250px] shadow-[0px_0px_12px_rgba(0,0,0,0.05)]">
        <button className="absolute right-[14px] top-[14px]" onClick={() => onClose(false)}>
          <ModalCloseSmallIcon />
        </button>
        <div>
          <DisclaimerContent content={disclaimerContent} className="mt-0" />
        </div>
      </div>
    </Modal>
  )
}

export default TermsModal
