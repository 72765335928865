import { useEffect, useMemo, useState } from 'react'
import Button from 'shared/components/Button'
import wayfairLogo from 'shared/assets/images/wayfair-logo.png'
import cortLogo from 'shared/assets/images/cort-logo.png'
import { ReactComponent as ModalClose } from 'shared/assets/icons/resident-modal-close.svg'
import { ReactComponent as AppStore } from 'shared/assets/icons/resident-welcome-appstore.svg'
import { ReactComponent as AppStoreBlack } from 'shared/assets/icons/appstore-black-filled.svg'
import { ReactComponent as Tick } from 'shared/assets/icons/resident-welcome-tick.svg'
import QRCode from 'react-qr-code'
import { useStore } from 'Resident/store'
import Video from '../../screens/Preview/components/Video'
import { upsertProspect } from 'Resident/services/user'
import { APP_STORE_LINK } from 'shared/constants'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { group3 } from 'shared/analytics/resident'

function WelcomeModal({ opened, ...props }) {
  const { appData, setAppData } = useStore()
  const [shown, setShown] = useState(false)

  useEffect(() => {
    if (opened === undefined) {
      if (appData.invite.instruction_screen_visible) {
        setShown(true)
      }
    }
  }, [appData, opened])

  async function onClose() {
    if (opened) {
      props.onClose()
      return
    }
    setShown(false)
    await upsertProspect({
      user_id: appData.user.id,
      unit_id: appData.unit.id,
      instruction_screen_visible: false,
    })
    setAppData({
      ...appData,
      invite: {
        ...appData.invite,
        instruction_screen_visible: false,
      },
    })
  }

  const video = appData.config ? (
    <Video
      className="rounded-[4px] overflow-hidden h-full"
      video={{
        src: appData.config.residents_instruction_video,
        thumbnail: appData.config.resident_welcome_video_thumbnail
      }}
    />
  ) : null

  const link = useMemo(()=>{
    if(appData && appData.invite){
      const { invitation_link_short_ios } = appData.invite
      return invitation_link_short_ios || APP_STORE_LINK
    } else {
      return APP_STORE_LINK
    }
  }, [appData])

  return (
    (shown || opened) && (
      <div
        className="bg-[rgba(229,231,237,.7)] fixed top-[0px] left-[0px] h-full w-full z-[500] flex items-center justify-center"
        onClick={opened && onClose}
      >
        <div
          className="bg-white rounded-[10px] lg:w-[1218px] px-[40px] py-[30px] lg:p-[52px] relative h-full lg:h-max"
          onClick={(e) => e.stopPropagation()}
        >
          <>
            <div className="flex items-center justify-between">
              <div className="lg:hidden"></div>
              <div className="text-[18px] lg:text-[28px] lg:leading-[28px]">
                {!opened ? 'Welcome to SparX!' : 'Upgrade to an Immersive Experience!'}
              </div>
              <button type="button" onClick={onClose}>
                <ModalClose />
              </button>
            </div>
            {!opened && (
              <>
                <div className="font-light text-[16px] lg:text-[18px] text-center lg:text-left mt-[16px] lg:mt-[32px]">
                  Explore interior design packages specifically tailored for your unit, available for purchase and rent.
                </div>
                <div className="flex justify-between items-center flex-col lg:flex-row lg:mt-[40px]">
                  <Button rounded className="lg:mt-[0] order-2 lg:order-1" onClick={
                    ()=>{
                      onClose()
                      rpevent(eventDictionary.landing_page_continue_web, group3)
                    }
                  }>
                    Continue to Web App
                  </Button>
                  <div className="flex items-center justify-center lg:justify-start my-[21px] lg:my-[0] order-1 lg:order-2">
                    <img className="h-[20px] lg:h-[26px]" src={cortLogo} alt="cort" />
                    {!appData.user.email.includes("@cort.com") && <img className="h-[20px] lg:h-[26px] ml-[24px] lg:ml-[24px]" src={wayfairLogo} alt="cort" />}
                  </div>
                </div>

                <div className="bg-[#C7CCD9] h-[1px] my-[32px] lg:my-[48px]"></div>
              </>
            )}
          </>

          <div className="lg:hidden">
            <div className="text-[17px] text-center">Upgrade to an Immersive Experience!</div>
            <div className="mt-[12px]">{video}</div>
            <div className="mt-[22px]">
              <div className="flex items-center">
                <Tick /> <span className="ml-[16px] lg:text-[18px] font-light">Compatible with Pro devices</span>
              </div>
              <div className="flex mt-[8px] items-center">
                <Tick /> <span className="ml-[16px] lg:text-[18px] font-light">Applicable when in-unit</span>
              </div>
            </div>
            <div className="flex justify-center items-center mt-[32px]">
              <a href="apple.com" target="_blank" className="ml-[18px]">
                <AppStoreBlack />
              </a>
            </div>
          </div>

          <div className="justify-between hidden lg:flex mt-[32px]">
            <div className="mr-[50px]">
              {!opened && <div className="text-[18px] mb-[18px]">Upgrade to an Immersive Experience!</div>}
              <div className="text-[18px] font-light">
                Immerse yourself in your future furnished home using SparX Augmented Reality App.
              </div>
              <div className="mt-[22px]">
                <div className="flex items-center">
                  <Tick />{' '}
                  <span className="ml-[16px] lg:text-[18px] font-light">Compatible with iPhone Pro or iPad Pro</span>
                </div>
                <div className="flex mt-[8px] items-center">
                  <Tick />{' '}
                  <span className="ml-[16px] lg:text-[18px] font-light">Works only when you’re in the unit</span>
                </div>
              </div>
              <div className="mt-[40px] flex">
                  <QRCode
                    style={{ maxWidth: '144px', maxHeight: '144px' }}
                    value={link}
                  />
                  <div className="ml-[30px]">
                    <div className="uppercase font-bold tracking-[1px] text-[14px]">Scan Now to Download</div>
                    <div className="mt-[15px]">
                      <a href={link} target="_blank" rel="noreferrer" onClick={()=>{
                        rpevent(eventDictionary.landing_page_app_store, group3)
                      }}>
                        <AppStore />
                      </a>
                    </div>
                  </div>
                </div>
            </div>
            <div className="shrink-0 w-[622px]">{video}</div>
          </div>
        </div>
      </div>
    )
  )
}

export default WelcomeModal
