import React, { useState } from 'react'

function DevHelper(props) {
  const actions = [
    {
      name: 'Close',
      fn: () => {
        setOpen(false)
      },
    },
    {
      name: 'Switch to Post Tour App',
      fn: () => {
        localStorage.setItem('reactApp', 'posttour')
        window.location.reload()
      },
    },
    
    {
      name: 'Switch to Apartment App',
      fn: () => {
        localStorage.setItem('reactApp', 'apartment')
        window.location.reload()
      },
    },
    {
      name: 'Switch to iFrame App',
      fn: () => {
        localStorage.setItem('reactApp', 'iframeapp')
        window.location.reload()
      },
    },
    
    {
      name: 'Switch to CRM App',
      fn: () => {
        localStorage.setItem('reactApp', 'crm')
        window.location.reload()
      },
    },
    {
      name: 'Switch to Media Manager App',
      fn: () => {
        localStorage.setItem('reactApp', 'mediamanager')
        window.location.reload()
      },
    },
    {
      name: 'Switch to Resident App',
      fn: () => {
        localStorage.setItem('reactApp', 'resident')
        window.location.reload()
      },
    },
    {
      name: 'Set Tour code',
      fn: () => {
        const result = window.prompt('Tour code')
        if (!result) {
          return
        }
        localStorage.setItem('devTourCode', result)
        window.location.reload()
      },
    },
    {
      name: 'Set unit id',
      fn: () => {
        const result = window.prompt('Unit ID')
        if (!result) {
          return
        }
        localStorage.setItem('devUnitId', result)
        window.location.reload()
      },
    },
    {
      name: 'Set PROD API',
      fn: () => {
        localStorage.setItem('apiHost', 'https://api.sparx.ai')
        window.location.reload()
      },
    },
    {
      name: 'Set STAGE API',
      fn: () => {
        localStorage.setItem('apiHost', 'https://stage.sparx.ai')
        window.location.reload()
      },
    },
    {
      name: 'Set DEV API',
      fn: () => {
        localStorage.setItem('apiHost', 'https://dev.sparx.ai')
        window.location.reload()
      },
    },
    {
      name: !localStorage.getItem('analyticsDebug') ? 'Enable analytics debug' : 'Disable analytics debug',
      fn: () => {
        if (localStorage.getItem('analyticsDebug')) {
          localStorage.removeItem('analyticsDebug')
        } else {
          localStorage.setItem('analyticsDebug', '1')
        }
        window.location.reload()
      },
    },
  ]

  const [open, setOpen] = useState(false)

  function renderActionsList() {
    return actions.map((action) => (
      <div>
        <button
          className="bg-[rgba(255,255,255,.2)] text-white my-2 py-1 px-3 w-full rounded-md hover:bg-[rgba(255,255,255,.4)]"
          onClick={action.fn}
        >
          {action.name}
        </button>
      </div>
    ))
  }

  return (
    <div>
      {!open && (
        <button
          type="button"
          className="fixed text-[10px] w-full lg:w-auto bottom-[0px] left-[0px] bg-green-500 hover:bg-green-700 p-1 px-4 z-[1000] opacity-60"
          onClick={() => {
            setOpen(true)
          }}
        >
          Dev tools
        </button>
      )}
      {open && <div className="fixed bottom-0 left-0 w-full lg:w-auto p-4 bg-black border-solid border-green-500 border-[1px] z-[1000]">{renderActionsList()}</div>}
    </div>
  )
}

export default DevHelper
