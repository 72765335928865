//==========================================================================
// Smart sorting for alphanumeric property of array objects
//==========================================================================
export function smartSort(sortBy, order = 'asc') {
  return (a, b) => {
    const keys = sortBy.split('.')

    // Get nested property values
    const valueA = keys.reduce((obj, key) => (obj && obj[key] !== undefined ? obj[key] : undefined), a)
    const valueB = keys.reduce((obj, key) => (obj && obj[key] !== undefined ? obj[key] : undefined), b)

    let comparison = 0

    const isNumericString = (str) => typeof str === 'string' && /^\d+$/.test(str)
    const naturalCompare = (a, b) => {
      const aParts = a.match(/\d+|\D+/g) || []
      const bParts = b.match(/\d+|\D+/g) || []

      for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
        const aPart = aParts[i] || ''
        const bPart = bParts[i] || ''

        if (isNumericString(aPart) && isNumericString(bPart)) {
          const diff = parseInt(aPart, 10) - parseInt(bPart, 10)
          if (diff !== 0) return diff
        } else {
          const diff = aPart.localeCompare(bPart, undefined, { sensitivity: 'base' })
          if (diff !== 0) return diff
        }
      }
      return 0
    }

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      // Natural string comparison
      comparison = naturalCompare(valueA, valueB)
    } else if ((typeof valueA === 'string' && !isNaN(valueA)) || (typeof valueB === 'string' && !isNaN(valueB))) {
      // Numeric string comparison
      comparison = Number(valueA) - Number(valueB)
    } else if (typeof valueA === 'number' || typeof valueB === 'number') {
      // Numeric comparison
      comparison = valueA - valueB
    } else if (valueA instanceof Date && valueB instanceof Date) {
      // Date comparison
      comparison = valueA - valueB
    } else {
      // Fallback to string comparison if types are not recognized
      comparison = String(valueA).localeCompare(String(valueB), undefined, { sensitivity: 'base' })
    }

    return order === 'desc' ? comparison * -1 : comparison
  }
}

//==========================================================================
// Sort by room_classification weight
//==========================================================================

export function sortByWeight(a, b) {
  return b.room_classification.weight - a.room_classification.weight
}


export function sortByCategoryWeight(a, b) {
  return b.category.weight - a.category.weight
}