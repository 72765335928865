import DataLoadError from 'Resident/components/DataLoadError'
import { upsertUser } from 'Resident/services/user'
import { useStore } from 'Resident/store'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useBlocker } from 'react-router-dom'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { group3 } from 'shared/analytics/resident'
import Button from 'shared/components/Button'
import { useSplashError } from 'shared/components/ErrorSplash'
import Input from 'shared/components/Input'
import { ResultPopup } from 'shared/components/ResultPopup'
import { compareObjects } from 'shared/helpers/general'

function Label({ children }) {
  return <div className="text-[#5F6787] opacity-30 uppercase text-[12px] font-semibold">{children}</div>
}

function SettingsScreen(props) {
  const [error, setError] = useState(false)
  const { appData, setAppData } = useStore()
  const [result, setResult] = useState(null)

  const splashError = useSplashError()

  async function onSubmit(values) {
    try {
      const { firstName, lastName } = values
      const update = {
        first_name: firstName,
        last_name: lastName,
      }
      await upsertUser({
        id: appData.user.id,
        ...update,
      })
      setAppData({
        ...appData,
        user: {
          ...appData.user,
          ...update,
        },
      })
      rpevent(eventDictionary.settings_update_successful, group3)
      setResult(true)
      if (blocker.state === 'blocked') {
        blocker.proceed()
      }
    } catch (error) {
      splashError.show()
    }
  }

  const formik = useFormik({
    initialValues: {
      firstName: appData.user.first_name,
      lastName: appData.user.last_name,
    },
    validateOnChange: false,
    validateOnBlur: true,
    // validationSchema,
    // validateOnMount:true,
    onSubmit,
  })

  const designProfiles = [
    {
      label: 'Young Professional',
    },
    {
      label: 'Family',
    },
    {
      label: 'Roommates',
    },
    {
      label: 'Couple',
    },
    {
      label: 'Student',
    },
  ]

  const pickedDesignProfile = designProfiles[0].label

  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const changed =
      formik.values.firstName !== appData.user.first_name || formik.values.lastName !== appData.user.last_name
    return changed && currentLocation.pathname !== nextLocation.pathname
  })

  function isDisabled() {
    return compareObjects(formik.values, {
      firstName: appData.user.first_name,
      lastName: appData.user.last_name,
    })
  }

  return (
    <div className="h-full px-[25px] py-[25px] flex flex-col">
    <form onSubmit={formik.handleSubmit}>
      <div className="flex justify-between items-center">
        <div className="text-[18px] lg:text-[24px] font-semibold lg:mb-[36px] shrink-0">Settings</div>
        <Button rounded type="submit" disabled={isDisabled()}>
          Save
        </Button>
      </div>
      {result && (
        <ResultPopup
          type="success"
          text="Your changes has been saved successfuly"
          onClose={() => {
            setResult(null)
          }}
        />
      )}
      {/* {blocker.state === 'blocked' && <div>asd</div>} */}
      {!error && (
        <div className="w-full lg:w-1/2 mt-5 lg:mt-[0]">
          <div className="grid grid-cols-2 gap-[30px]">
            <div>
              <Label>unit</Label>
              <div className="mt-[24px]">{appData.unit.unit_number}</div>
            </div>
            <div>
              <Label>email</Label>
              <div className="mt-[24px]">
                <div className="mt-[24px]">{appData.user.email}</div>
              </div>
            </div>
            <div>
              <Label>first name</Label>
              <div className="mt-[12px]">
                <Input
                  placeholder="First name"
                  name="firstName"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                />
              </div>
            </div>
            <div>
              <Label>last name</Label>
              <div className="mt-[12px]">
                <Input
                  placeholder="Last name"
                  name="lastName"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                />
              </div>
            </div>
            <button type="submit" hidden></button>
          </div>

          {/* Commented due to https://sparx-app.atlassian.net/browse/AT-789, do not remove */}
          {/* <div className="mt-[56px]">
            <div>
              <Label>Default Design profile</Label>
            </div>
            <div className="grid grid-cols-2 gap-x-[32px] gap-y-[16px] mt-[12px] cursor-pointer">
              {designProfiles.map((item) => (
                <div
                  className={` px-[26px] items-center flex h-[46px] ${
                    pickedDesignProfile === item.label
                      ? 'bg-[#A93DCB] text-white'
                      : 'border-[1px] border-solid border-[#C7CCD9] text-[#5F6787]'
                  }`}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div> */}
          {/* Commented due to https://sparx-app.atlassian.net/browse/AT-789, do not remove */}
        </div>
      )}
      {error && (
        <div className="flex-1  w-full flex justify-center items-center">
          <DataLoadError />
        </div>
      )}
      {blocker.state === 'blocked' && !result && (
        <ResultPopup
          type="error"
          text={
            <div className="text-center">Your Settings have unsaved changes. How would you like to proceed?</div>
          }
          actions={[
            {
              label: 'Discard',
              action: () => {
                rpevent(eventDictionary.settings_update_cancelled, group3)
                blocker.proceed()
              },
            },
            {
              label: 'Save',
              variant: 'primary',
              action: () => {
                formik.handleSubmit()
              },
            },
          ]}
        />
      )}
    </form>
  </div>
  )
}

export default SettingsScreen
