import Page from 'CRM/components/Page'

import React, { useState, useEffect, useMemo } from 'react'

import { useStore } from 'CRM/store'
import { smartSort } from 'shared/helpers/arrays'
import { LineSection } from './components/Line'
import { SearchInput } from './components/Search'
import { Dropdown } from './components/Dropdown'
import MobileFilter from './components/MobileFilter'

function Tabs({ items, ...props }) {
  const [pickedIndex, setPickedIndex] = useState(0)

  function onPicked(index) {
    setPickedIndex(index)
    props.onPicked && props.onPicked(items[index], index)
  }

  return (
    <div className="border-b-[1px] border-solid border-[#C7CCD9]">
      {items.map((item, index) => (
        <button
          key={index}
          onClick={() => {
            onPicked(index)
          }}
          type="button"
          className={`h-full p-[8px] font-semibold truncate border-b-[2px] border-solid ${
            pickedIndex === index ? 'border-[#A93DCB] text-[#A93DCB]' : 'border-transparent'
          } ${index > 0 ? 'ml-[20px]' : ''}`}
        >
          {item}
        </button>
      ))}
    </div>
  )
}

function ApartmentsScreen(props) {
  const { selectedProperty: property, setTitle } = useStore()
  const [searchFilterValue, setSearchFilterValue] = useState()
  const [lineFilter, setLineFilter] = useState([])
  const [bedroomsFilter, setBedroomsFilter] = useState([])
  const [buildingFilterValue, setBuildingFilter] = useState()

  function onSearchUpdate(val) {
    setSearchFilterValue(val)
  }

  function getLines() {
    let lines = property.unit_types
    if (lineFilter && lineFilter.length > 0) {
      lines = lines.filter(({ name }) => lineFilter.find((el) => el.toLowerCase() === name.toLowerCase()))
    }
    if (bedroomsFilter && bedroomsFilter.length > 0) {
      lines = lines.filter(({ number_of_bedrooms }) => bedroomsFilter.find((num) => Number(num) === number_of_bedrooms))
    }
    return lines
      .filter(({ unit_types_configuration_options }) => unit_types_configuration_options.ready_for_publish && unit_types_configuration_options.include_in_sparx_staging)
      .map((unitType) => ({
        ...unitType,
        full_name: `${unitType.name}${' '}${unitType.nick_name ? `- ${unitType.nick_name} ` : ''}${
          unitType.unit_types_configuration_options.is_mirrored ? '(mirrored)' : ''
        }`,
      }))
  }

  useEffect(() => {
    setBuildingFilter(propertyBuildings()[0])
    setLineFilter(undefined)
    setBedroomsFilter(undefined)
    setTitle(`${property.property_name} - Apartments`)
  }, [property])

  function propertyBuildings() {
    const buildings = []
    property.unit_types
      .filter(({ unit_types_configuration_options }) => unit_types_configuration_options.ready_for_publish)
      .forEach(({ units }) =>
        units.forEach(({ building_name }) => !buildings.includes(building_name) && buildings.push(building_name)),
      )
    return buildings
  }

  const bedroomsDropdownOptions = useMemo(() => {
    const unique = [...new Set(property.unit_types.map(({ number_of_bedrooms }) => number_of_bedrooms))]
    return unique.sort().map((number_of_bedrooms) => ({
      label: number_of_bedrooms === 0 ? 'Studio' : number_of_bedrooms,
      value: number_of_bedrooms,
    }))
  }, [property])

  const linesDropdownOptions = useMemo(() => {
    return property.unit_types
      .filter(({ unit_types_configuration_options }) => unit_types_configuration_options.ready_for_publish)
      .filter(({ units }) => units.length > 0)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ name }) => name.toUpperCase())
  }, [property])

  function onLineFilterChange(line) {
    if (line) {
      setLineFilter([line])
      return
    } else {
      setLineFilter([])
    }
  }

  function onBedroomsFilterChange(value) {
    if (value) {
      setBedroomsFilter([value])
      return
    } else {
      setBedroomsFilter([])
    }
  }

  function currentPropertyAsBuiding() {
    return property.unit_types
      .filter(({ unit_types_configuration_options }) => unit_types_configuration_options.ready_for_publish)
      .some(({ property }) => property.properties_configuration_options.building_as_property)
  }

  useEffect(() => {
    setSearchFilterValue('')
  }, [property, buildingFilterValue])

  return (
    <Page>
      <span className="text-[24px] lg:leading-[24px] font-semibold">Apartments</span>
      {(propertyBuildings().length > 1 || currentPropertyAsBuiding()) && (
        <div className="mt-[26px]">
          <Tabs items={propertyBuildings()} onPicked={setBuildingFilter} />
        </div>
      )}
      <div className="my-[32px] text-medium text-[16px] text-[#5F6787]">
        View or share link to apartments in {property.property_name} with your prospects.
      </div>
      <div className="flex items-center justify-between">
        <div className="w-full flex items-center mb-[8px] lg:mb-[0]">
          <SearchInput value={searchFilterValue} onChange={onSearchUpdate} />
          <MobileFilter
            lineFilter={lineFilter || []}
            bedroomsFilter={bedroomsFilter || []}
            bedroomsOptions={bedroomsDropdownOptions}
            linesOptions={linesDropdownOptions}
            setBedroomsFilter={setBedroomsFilter}
            setLineFilter={setLineFilter}
          />
        </div>
        <div className="hidden lg:flex">
          <div>
            <Dropdown
              value={bedroomsFilter}
              placeholder="Bedrooms #"
              onChange={onBedroomsFilterChange}
              items={bedroomsDropdownOptions}
            />
          </div>
          <div className="ml-[16px]">
            <Dropdown
              value={lineFilter}
              placeholder="Line"
              onChange={onLineFilterChange}
              items={linesDropdownOptions}
            />
          </div>
        </div>
      </div>
      {getLines()
        .sort(smartSort('full_name'))
        .map((item, index) => (
          <LineSection
            key={index}
            property={property}
            unitType={item}
            searchFilterValue={searchFilterValue}
            buildingFilterValue={buildingFilterValue}
          />
        ))}
    </Page>
  )
}

export default ApartmentsScreen
