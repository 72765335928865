import React from 'react'

export const disclaimerText = {
  first:
    '  SparX is not the owner or the seller of any products shown on the website and has no control over the inventory,quality, price, safety, delivery, or any other aspect of the items shown.',
  second: (
    <span>
      {' '}
      The photos and videos shown are representative of the floor plan <strong>but may not be of the exact unit</strong>
      . Differences may include views from the window or ceiling heights.
    </span>
  ),
}

function DisclaimerContent({content = [], className = ''}) {
  return content.map((t, i) => (
    <div className={`text-left relative pl-[15px] font-normal mt-[20px] first:mt-[0] text-black ${className}`}>
      <div className=" absolute top-[10px] left-0 bg-black rounded-full h-[5px] w-[5px]"></div>
      {t}
    </div>
  ))
}

export default DisclaimerContent
