import React, { useEffect, useRef, useState } from 'react'
// Icons
import { ReactComponent as PlusIcon } from 'shared/assets/icons/plus.svg'
import { ReactComponent as PlusIconInactive } from 'shared/assets/icons/plusInactive.svg'
import { ReactComponent as MinusIcon } from 'shared/assets/icons/minus.svg'

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { randomID } from 'shared/helpers/general'

export const useIsOverflow = (ref, targetRef, callback) => {
  const [isOverflow, setIsOverflow] = React.useState(undefined)

  React.useLayoutEffect(() => {
    const trigger = (ref, targetRef) => {

      setIsOverflow(ref.offsetWidth > targetRef.offsetWidth)
      // const hasOverflow = current.scrollHeight > current.clientHeight;

      // setIsOverflow(hasOverflow);

      // if (callback) callback(hasOverflow);
    }

    if (ref && targetRef && ref.current && targetRef.current) {
      trigger(ref.current, targetRef.current)
    }
  }, [callback, ref, targetRef])

  return isOverflow
}

function DetailsDropdown({
  title,
  options,
  disabled,
  onToggle,
  name,
  titleClassNames = '',
  className = '',
  highlighted = false,
  tooltip = '',
  plain = false,
  icon = null,
  ...props
}) {
  const [active, setActive] = useState(false)

  function toggle() {
    if (!onToggle) {
      return
    }
    setActive(!active)
    onToggle(name)
  }

  useEffect(() => {
    setActive(props.active)
  }, [props.active])

  const id = randomID()

  const ref = useRef()

  const parent = useRef()

  const isOverflow = useIsOverflow(ref, parent)

  return (
    <div
      className={`text-[14px] lg:text-base ${className}`}
      data-tooltip-id={`tooltip-${id}`}
      data-tooltip-content={tooltip}
      data-tooltip-offset="64"
      data-tooltip-place="right-start"
      data-tooltip-position-strategy="fixed"
    >
      {tooltip && isOverflow && (
        <Tooltip id={`tooltip-${id}`} positionStrategy="fixed" noArrow className="default-tooltip fadeShow" />
      )}
      <div
        className={`select-none ${
          onToggle ? 'cursor-pointer' : ''
        } h-[40px] w-full flex justify-between items-center px-[24px] ${
          plain ? '!px-0' : ''
        } border-[1px] border-solid relative z-10
        ${
          active || highlighted
            ? `text-black border-[rgba(199,204,217,.4)] bg-[#E5E7ED66] ${
                plain ? '!bg-transparent !border-transparent' : ''
              }`
            : `text-[#5F6787] border-[#C7CCD933] bg-[#E5E7ED33] ${plain ? '!bg-transparent !border-transparent' : ''}`
        }
        ${active ? 'rounded-t-[8px]' : 'rounded-full'}`}
        onClick={disabled ? null : toggle}
      >
        {icon || null}
        <div ref={parent} className={`truncate relative w-[90%] text-[16px] font-medium ${titleClassNames}`}>
          {title}
        </div>
        <div ref={ref} className="absolute top-0 left-0 w-max bg-[red] opacity-0 h-[0] overflow-hidden">
          {title}
        </div>
        <div>
          {onToggle ? (
            <>{active ? <MinusIcon /> : active || highlighted ? <PlusIcon /> : <PlusIconInactive />}</>
          ) : null}
        </div>
      </div>
      {active && (
        <div
          className={`w-full border-solid border-[rgba(199,204,217,.4)] border-[1px] border-t-0 rounded-b-[8px] px-[28px] pt-[3px] pb-[14px] bg-[#E5E7ED66] ${
            plain && '!bg-transparent !border-transparent !px-0 pb-0'
          }`}
        >
          {options}
        </div>
      )}
    </div>
  )
}

export default DetailsDropdown
