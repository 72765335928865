export const EUserType = {
  ADMIN: 'Admin',
  API_USER: 'Api User',
  END_USER: 'End User',
  PROPERTY_MANAGER: 'Property Manager',
  REAL_ESTATE_COMPANY_MANAGER: 'Real Estate Company Manager',
  PROPERTY_VIEWER: 'Property Viewer',
  RE_COMPANY_MANAGER_VIEWER: 'RE Company Manager Viewer',
  DESIGNER: 'Designer',
  AGENT: 'Agent',
}
