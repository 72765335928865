import { useEffect, useState } from 'react'
import { logDevelopment } from 'shared/helpers/log'
import { ReactComponent as CheckmarkViolet } from 'shared/assets/icons/checkmark-violet-small.svg'
import { ReactComponent as CheckmarkVioletMobile } from 'shared/assets/icons/checkmark-violet-mobile.svg'
import { ReactComponent as CopySmall } from 'shared/assets/icons/copy-small.svg'
import { ReactComponent as CopySmallViolet } from 'shared/assets/icons/copy-small-violet.svg'
import { ReactComponent as CopyMobile } from 'shared/assets/icons/copy-icon-mobile-crm-gray.svg'
import { useStore } from 'CRM/store'
import { apartmentPageHost } from '../../helpers'
import { useMediaQuery } from '@react-hook/media-query'

export function LineHtmlCopyButton({ unit, icon, extra = '', className = '', copyTooltip = false, label, line }) {
  const { setLastCopiedUnit, lastCopiedUnit, selectedProperty, user } = useStore()
  const [hideTimeout, setHideTimeout] = useState(null)
  const [tooltipShown, setTooltipShown] = useState(false)
  const isMobile = useMediaQuery('(max-width: 1023px)')
  function onCopy(prospectName) {
    if (!prospectName) {
      setTooltipShown(true)
      setLastCopiedUnit(unit)
      hideTooltipWithTimeout()
    }
    const uri = encodeURI(
      `https://${apartmentPageHost()}.sparx.ai/?property_name=${selectedProperty.property_name}&unit_number=${
        unit.unit_number
      }&building_name=${unit.building_name}&agent_id=${user.id}${extra ? `&${extra}` : ''}${
        prospectName ? `&person=${prospectName}` : ''
      }`,
    )
    const content = `<a href="${uri}" rel="noopener" target="_blank">View apartment gallery</a>`
    try {
      logDevelopment(content)
      navigator.clipboard.writeText(content)
    } catch (error) {
      console.log(error)
      console.log(content)
    }
  }

  function hideTooltipWithTimeout() {
    if (hideTimeout) {
      clearTimeout(hideTimeout)
    }
    const timeout = setTimeout(() => {
      setTooltipShown(false)
    }, 3000)
    setHideTimeout(timeout)
  }

  useEffect(() => {
    if (lastCopiedUnit.id !== unit.id) {
      setTooltipShown(false)
    }
  }, [lastCopiedUnit, unit])

  const isCopied = lastCopiedUnit.id === unit.id

  return (
    <>
      <button
        type="button"
        className={`p-[5px] mr-[-5px] lg:mr-[0] border-none ml-[16px] lg:ml-[10px] group relative bg-[rgba(229,231,237,.4)] lg:bg-transparent h-[44px] w-[44px] lg:w-auto rounded-full flex items-center justify-center text-[#A93DCB] ${className}`}
        onClick={() => onCopy()}
      >
        {copyTooltip && (
          <div
            className={`bg-[#16C270] w-[calc(100vw-48px)] lg:w-max px-[16px] overflow-hidden left-[24px] bottom-[40px] z-[100] lg:bottom-0 lg:top-[-42px] lg:translate-x-[-50%] fixed lg:absolute flex items-center justify-center h-[48px] lg:h-[31px] text-[16px] lg:text-[14px] rounded-full lg:rounded-[3px]  font-medium lg:font-normal text-white ${
              tooltipShown ? 'fadeIn' : 'fadeOut'
            }`}
            onMouseEnter={() => {
              clearTimeout(hideTimeout)
            }}
            onMouseLeave={() => {
              hideTooltipWithTimeout()
            }}
          >
            HTML Copied
          </div>
        )}
        <div className="lg:h-[20px] overflow-hidden  flex items-center">
          {icon}
        </div>
        {label && <span className="ml-[7px] font-medium">{label}</span>}
      </button>
    </>
  )
}
