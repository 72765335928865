import LogInScreen from 'CRM/screens/Auth/LogIn'
import React from 'react'

function SigninScreen() {
  return (
    <div className="h-full w-full flex items-center justify-center">
      <LogInScreen minimal redirect="/actions" />
    </div>
  )
}

export default SigninScreen
