import { useNavigate } from '@tanstack/react-location'
import { isAuthenticated } from 'CRM/helpers/auth'
import { useEffect, useState } from 'react'

function RouteGuard({ children, loggedIn, loggedOut }) {
  const navigate = useNavigate()
  const [renderChildren, setRenderChildren] = useState(true)

  useEffect(() => {
    if (loggedIn) {
      if (isAuthenticated()) {
        navigate({ to: '/apartments' })
      } else {
        setRenderChildren(true)
      }
    }
  }, [navigate, loggedIn])

  useEffect(() => {
    if (loggedOut) {
      if (!isAuthenticated()) {
        navigate({ to: '/signin' })
      } else {
        setRenderChildren(true)
      }
    }
  }, [navigate, loggedOut])

  return renderChildren ? children : null
}

export default RouteGuard
