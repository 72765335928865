import React from 'react'
import sparxLogo from 'shared/assets/images/sparx-logo-white.png'
function Page({ children, title, className = '', ...props }) {
  return (
    <div className={`py-[32px] lg:py-[40px] px-[24px] lg:px-[120px] ${className}`}>
      <div className="text-[24px] font-semibold">{title}</div>
      <div className="lg:mt-[40px]">{children}</div>
    </div>
  )
}

export default Page
