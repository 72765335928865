import React from 'react'
import { ReactComponent as Property } from 'shared/assets/icons/property-violet.svg'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import sparxLogo from 'shared/assets/images/sparx-logo.png'
import { usePropertyLogo } from 'Resident/hooks/usePropertyLogo'

function PropertyChip({ pillClassName = '', ...props }) {
  const { logo, property, unitData } = usePropertyLogo(props)

  return (
    <>
      {!logo && unitData && (
        <div
          className={`rounded-full bg-[rgba(229,231,237,0.4)] p-[5px] pr-[16px] w-max flex items-center ${pillClassName}`}
        >
          <div className="h-[34px] shrink-0 w-[34px] lg:h-[40px] lg:w-[40px] rounded-full flex items-center justify-center bg-[rgba(169,61,203,0.15)]">
            <Property />
          </div>
          <span className="lg:text-[18px] font-medium ml-[10px] text-[#A93DCB] truncate w-full">
            {property.property_name}
          </span>
        </div>
      )}
      {!logo && !unitData && (
        <div>
          <img src={sparxLogo} className="h-[33px]" alt="sparx-logo" />
        </div>
      )}
      {logo && (
        <img src={getCdnAssetUrl(logo)} alt={property.property_name} className="h-[33px] w-auto object-contain" />
      )}
    </>
  )
}

export default PropertyChip
