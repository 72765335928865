import React, { useState } from 'react'
import Button from 'shared/components/Button'
import { Modal } from '../Modal'
import { useNavigate } from '@tanstack/react-location'
import { ReactComponent as IconSuccess } from 'shared/assets/icons/success-icon.svg'
import { ReactComponent as IconError } from 'shared/assets/icons/error-icon.svg'
function ActionSuccessModal({
  action,
  skipBack = false,
  onConfirm,
  skipConfirm,
  onClose,
  error,
  state = 'error',
  successText,
  errorText,
}) {
  const [confirmed, setConfirmed] = useState(false)
  const navigate = useNavigate()

  function onOk() {
    if (skipBack) {
      onClose()
      return
    }
    if (skipConfirm) {
      window.history.back()
      return
    }
    setConfirmed(true)
  }

  return (
    <>
      {!confirmed && (
        <Modal className="!h-auto" title={null}>
          {state === 'success' && (
            <div className="flex flex-col items-center">
              <div>
                <IconSuccess />
              </div>
              <div className="mt-[22px]">
                {action} {successText || 'has been successfully uploaded.'}
              </div>
            </div>
          )}
          {state === 'error' && (
            <div className="flex flex-col items-center">
              <div>
                <IconError />
              </div>
              <div className="mt-[22px]">
                {action} {errorText || 'uploading failed. Please try again.'}
              </div>
              {error && <div className='text-xs mt-5 opacity-50 w-2/3 text-center'>
                {error}
              </div>}
            </div>
          )}
          <div className="flex justify-end mt-[32px]">
            <Button
              rounded
              variant="secondary"
              size="small"
              className="ml-2"
              onClick={() => {
                state === 'success' ? onOk() : onClose()
              }}
            >
              OK
            </Button>
          </div>
        </Modal>
      )}
      {confirmed && (
        <Modal className="!h-auto" title={`Upload another ${action} ?`}>
          <div>Do you wish to upload another {action} ?</div>
          <div className="flex justify-end mt-[32px]">
            <Button
              variant="secondary"
              rounded
              size="small"
              className="text-sm"
              onClick={() => {
                window.history.back()
              }}
            >
              No
            </Button>
            <Button rounded size="small" className="ml-2" onClick={onConfirm}>
              Yes
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ActionSuccessModal
