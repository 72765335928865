import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import { useMediaQuery } from '@react-hook/media-query'

import { useStore } from 'TourApp/store'
import { events, posttour } from 'shared/analytics'
// Components
import DesignLayoutNav from './components/DesignLayoutPicker'

// Icons
import { ReactComponent as AssetsMoreIcon } from 'shared/assets/icons/assetMoreIcon.svg'
import { ReactComponent as NoAssetsIcon } from 'shared/assets/icons/noDesignItems.svg'
import AssetsSlider from './components/AssetsSlider'
import PreviewSlider from './components/PreviewSlider'
// Styles
import './styles.scss'
import { RoomDesignPicker } from 'IFrameApp/screens/Gallery/RoomPicker'
import MobileBottomSheet from './components/MobileBottomSheet'
import Carousel from 'shared/components/Carousel'
import { getDesignTitle, isApartmentApp, isPostTourApp, purchaseType } from 'shared/helpers/general'
import {
  addAmenitiesRoom,
  addMoreRoomCategory,
  divideRoomsByWeight,
  isQuadraticDisplayMode,
  showDesignNames,
  showFurnitureDetails,
} from 'shared/helpers/gallery'
import { StagingImage } from './components/StagingImage'
import Loader from 'shared/components/Loader'

export default function GalleryScreen() {
  const {
    selectedApartmentId,
    wishlistItemId,
    setWishlistItemId,
    tourData,
    setGalleryState,
    setOverlayShown,
    overlayShown,
    setGalleryPreviewMode,
    isLineApp,
  } = useStore()
  const [designLayoutOptionsToggled, setDesignLayoutOptionsToggled] = useState()
  const [rooms, setRooms] = useState([])
  const [apartment, setApartment] = useState()
  const [pickedRoom, setPickedRoom] = useState()
  const [zoomed, setZoomed] = useState(false)
  const [carouselInstance, setCarouselInstance] = useState(null)
  const [stagingCarouselInstance, setStagingCarouselInstance] = useState(null)
  const [pickedAsset, setPickedAsset] = useState()
  const [previewMode, setPreviewMode] = useState(null)
  const [hoveredAsset, setHoveredAsset] = useState()
  const [showDesignItemsMobile, setShowDesignItemsMobile] = useState()
  const [pickedDesignLayout, setPickedDesignLayout] = useState()
  const [activeSlide, setActiveSlide] = useState(0)
  const [roomPickerInstance, setRoomPickerInstance] = useState(null)
  const bottomContentRef = useRef()
  const [sliderWrapRef, setSliderWrapRef] = useState()
  const [roomPickerDropdownActive, setRoomPickerDropdownActive] = useState(false)
  const [rawImageDisplay, setRawImageDisplay] = useState(false)
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const [previewMinimized, setPreviewMinimized] = useState(true)
  const checkIfIsFavourite = (asset) => {
    return Boolean(tourData.liked_assets.find((liked) => liked.asset.id === asset.id))
  }

  const designImages = useMemo(() => {
    const images = []
    if (apartment) {
      const rooms = apartment.unit_type.unit_type_levels.map(l=>l.rooms).flat()
      rooms.forEach((room) => {
        const layouts = room.room_designs
        layouts.forEach((design, index) => {
          let assetsWithCounts = []
          design.design_components.forEach(({ assets }) => assetsWithCounts.push(assets))
          assetsWithCounts = assetsWithCounts.flat()
          design.photos.forEach((image) => {
            images.push({
              ...{
                ...image,
                assets: image.assets.map((asset) => ({
                  ...asset,
                  is_favourite: checkIfIsFavourite(asset.asset),
                  quantity: (assetsWithCounts.find((a) => a.asset.id === asset.asset.id) || { count: 0 }).count,
                })),
              },
              design_index: index + 1,
              roomId: room.id,
              display_name: image.display_name,
              roomName: room.name,
              designLayoutId: design.id,
              designLayoutName: design.design_name,
              design,
            })
          })
        })
      })
    }
    return images
  }, [apartment, tourData])

  useEffect(() => {
    if (apartment) {
      if (!isQuadraticDisplayMode(apartment) && isDesktop) {
        setPreviewMode('staging')
      }
    }
  }, [apartment, isDesktop])

  const getPickedImage = useCallback(() => {
    if (carouselInstance) {
      return designImages[activeSlide]
    } else {
      return designImages[0]
    }
  }, [designImages, activeSlide, carouselInstance])

  useEffect(() => {
    setGalleryState({ pickedDesignLayout, pickedRoom, pickedAsset, pickedImage: getPickedImage() })
  }, [pickedDesignLayout, pickedRoom, pickedAsset, setGalleryState, getPickedImage])

  useEffect(() => {
    // When ever room pill is changed or selected
    // (on first entering, after room pill clicked, after navigation between images etc.)
    if (pickedRoom && !wishlistItemId) {
      posttour(events.dgrpch, getGalleryEventProperties)
    }
  }, [pickedRoom, activeSlide])

  useEffect(() => {
    // When ever design is changed or selected
    // (on first entering, after room pill clicked, after selecting new design, after navigation between images etc.)
    if (!wishlistItemId && pickedDesignLayout) {
      posttour(events.dgdc, getGalleryEventProperties)
    }
  }, [pickedDesignLayout])

  useEffect(() => {
    if (carouselInstance && !carouselInstance.destroyed) {
      carouselInstance.slideToLoop(0, 0)
    }
  }, [designImages, carouselInstance])

  useEffect(() => {
    if (getPickedImage()) {
      posttour(events.dgiv, getGalleryEventProperties)
    }
  }, [activeSlide])

  useEffect(() => {
    if (carouselInstance && activeSlide === 0) {
      // carouselInstance.slideTo(0, 0)
    }
  }, [activeSlide, carouselInstance])

  useEffect(() => {
    const { unit } = tourData.units.find(({ unit }) => unit.id === selectedApartmentId)
    const apartment = JSON.parse(JSON.stringify(unit))

    // Reset on apartment change / initial state
    setActiveSlide(0)
    setPickedDesignLayout(null)

    apartment.unit_type.unit_type_levels = apartment.unit_type.unit_type_levels.map(level=>({
      ...level,
      rooms: level.rooms.filter((room) => room.room_designs.length > 0)
      .filter((room) => room.room_designs.some((layout) => layout.photos.length > 0))
    }))


    

    apartment.unit_type.unit_type_levels = apartment.unit_type.unit_type_levels.map(({rooms, ...level}) => ({
      ...level,
      rooms: rooms.map(room=>({
        ...room,
        level: {
          ...level,
          show: apartment.unit_type.unit_type_levels.length > 1
        },
        room_designs: room.room_designs.filter((design) => design.photos.length > 0),
      }))
    }))

    apartment.unit_type.unit_type_levels = apartment.unit_type.unit_type_levels.map(level=>{
       // Rooms with designs. Sorted by weight, in case if weight is same, sorted according to alphabetical order.
      const [groupOne, groupTwo] = divideRoomsByWeight(level)
      return {
        ...level,
        rooms: [...groupOne, ...groupTwo]
      }
    })

    //==========================================================================
    // More category for room
    //==========================================================================

    addMoreRoomCategory(apartment)

    //==========================================================================
    // Amenities virtual room
    //==========================================================================

    addAmenitiesRoom(apartment)

    setApartment(apartment)

    const rooms = apartment.unit_type.unit_type_levels.map(({rooms})=>rooms).flat()

    if (apartment && !wishlistItemId) {
     
      setRooms(rooms)
      setPickedRoom(rooms[0])
      const designLayout = rooms[0].room_designs[0]
      setPickedDesignLayout(designLayout)
    }
    if (apartment && wishlistItemId) {
      setRooms(rooms)
      let designLayout = null
      const room = rooms.find(({ room_designs }) => {
        designLayout = room_designs.find(({ id }) => id === wishlistItemId)
        return designLayout
      })
      setPickedRoom(room)
      setPickedDesignLayout(designLayout)
    }
  }, [selectedApartmentId, tourData, wishlistItemId])

  useEffect(() => {
    if (pickedRoom && pickedDesignLayout && wishlistItemId && carouselInstance && designImages) {
      const slideIndex = designImages.findIndex(
        (image) => image.roomId === pickedRoom.id && image.designLayoutId === pickedDesignLayout.id,
      )
      carouselInstance.slideToLoop(slideIndex, 0, false)
      setActiveSlide(slideIndex)
    }
  }, [pickedRoom, pickedDesignLayout, wishlistItemId, carouselInstance, designImages])

  function getGalleryEventProperties() {
    const pickedImage = getPickedImage()
    // Group_5
    const properties = {
      physical_room_id: pickedRoom?.id,
      physical_room_name: pickedRoom?.classification,
      design_id: pickedImage?.designLayoutId,
      design_name: pickedImage?.designLayoutName,
      vendor_id: pickedDesignLayout?.main_vendor?.id,
      vendor_name: pickedDesignLayout?.main_vendor?.full_name,
      image_id: pickedImage?.id, // Group_6
    }
    return properties
  }

  function onRoomPicked(room, index) {
    setPickedRoom(room)
    const slideIndex = designImages.findIndex(({ roomId }) => roomId === room.id)
    carouselInstance.slideToLoop(slideIndex, 400)
    setShowDesignItemsMobile(false)

    posttour(events.dgrpc, () => ({
      ...getGalleryEventProperties(),
      new_physical_room_id: room.id,
      new_physical_room_name: room.name || room.classification,
    }))
  }

  function onDesignLayoutOptionsToggled() {
    setDesignLayoutOptionsToggled(!designLayoutOptionsToggled)
  }

  useEffect(() => {
    if (carouselInstance && !carouselInstance.destroyed) {
      if (zoomed) {
        carouselInstance.zoom.in()
      } else {
        carouselInstance.zoom.out()
      }
    }
  }, [zoomed, carouselInstance])

  function onCarouselSlideChange() {
    if(!carouselInstance){
      return
    }

    setRawImageDisplay(false)
    if (roomPickerInstance) {
      roomPickerInstance.closeDropdowns()
    }

    const image = designImages[carouselInstance.realIndex]

    setZoomed(false)

    const room = apartment.unit_type.unit_type_levels.map(({rooms})=>rooms).flat().find(({ id }) => id === image.roomId)
    setPickedRoom(room)

    setPickedDesignLayout(room.room_designs.find((design) => design.id === image.designLayoutId))

    const diff = carouselInstance.realIndex - activeSlide
    if (diff === 1) {
      posttour(events.dginc, getGalleryEventProperties)
      wishlistItemId && setWishlistItemId(null)
    }
    if (diff === -1) {
      posttour(events.dgibc, getGalleryEventProperties)
      wishlistItemId && setWishlistItemId(null)
    }
    setActiveSlide(carouselInstance.realIndex)
    if (isStagingPreviewMode()) {
      stagingCarouselInstance.slideToLoop(carouselInstance.realIndex, 400)
      stagingCarouselInstance.updateSlides()
    }
    onAssetPick(null)
  }

  function onStagingCarouselSlideChange(index, image) {
    posttour(events.dgiric, () => ({
      ...getGalleryEventProperties(),
      clicked_image_id: image.id,
      clicked_image_pyhiscal_room_id: image.roomId,
      clicked_image_pyhiscal_room_name: image.roomName,
      clicked_image_design_id: image.designLayoutId,
      clicked_image_design_name: image.designLayoutName,
    }))
    setActiveSlide(carouselInstance.realIndex)
    stagingCarouselInstance.slideToLoop(index, 400)
    carouselInstance.slideToLoop(index, 400)
  }

  function onPreviewModeChanged(mode, isClickAction = false) {
    if (!previewMinimized && !mode) {
      setPreviewMinimized(true)
      return
    }
    setPreviewMode(mode)
    setGalleryPreviewMode(mode)
    isClickAction && posttour(mode === 'assets' ? events.dgvitc : events.dgvimc, getGalleryEventProperties)
    stagingCarouselInstance.slideToLoop(carouselInstance.activeIndex, 0)
    setPreviewMinimized(true)
  }

  function onAssetPick(asset, isPopoverAction = false, isAssetAction = false, event = null) {
    setHoveredAsset(null)
    if (asset) {
      const assetProps = () => ({
        ...getGalleryEventProperties(),
        asset_id: asset.id, // Group_7
        asset_name: asset.asset_name, // Group_7
        product_link: asset.web_page,
        affiliation_link: asset.affiliation_link,
      })
      if ((pickedAsset && asset.id === pickedAsset.id) || isDesktop) {
        if (isAssetAction) {
          posttour(isDesktop ? events.dgacl : events.dgsal, assetProps)
        } else {
          if (asset.is_favourite) {
            if (wishlistItemId) {
              posttour(events.dgwacl, assetProps)
            } else {
              posttour(isPopoverAction ? events.dghdpl : events.dgihl, assetProps)
            }
          } else {
            posttour(isPopoverAction ? events.dgidpl : events.dgidl, assetProps)
          }
        }
        // Desktop - Only tapping on asset name should lead to the affiliation link
        if ((isDesktop && event?.target.dataset?.name === 'assetname') || !isDesktop) {
          window.open(asset.affiliation_link || asset.web_page, '_blank').focus()
        }
        return
      }
      scrollSliderToAsset(asset)
      setPickedAsset(asset)
      setShowDesignItemsMobile(!!asset)
    } else {
      setPickedAsset(null)
      setShowDesignItemsMobile(null)
    }
  }

  function scrollSliderToAsset(asset) {
    if (asset && (asset.x_axis > 100 || asset.y_axis > 100)) {
      const nearestImageIndex = designImages.findIndex((image) =>
        image.assets.find(
          (imageAsset) =>
            asset.id === imageAsset.asset.id &&
            imageAsset.x_axis <= 100 &&
            imageAsset.y_axis <= 100 &&
            image.designLayoutId === pickedDesignLayout.id,
        ),
      )
      if (nearestImageIndex > -1) {
        carouselInstance.slideToLoop(nearestImageIndex, 400)
      }
    }
  }

  function onAssetHover(asset) {
    setPickedAsset(null)
    if (asset) {
      setHoveredAsset(asset)
    } else {
      setHoveredAsset(null)
    }
  }

  function getAssetsList() {
    const assets = designImages[activeSlide].assets
    const result = [
      ...assets.map(({ asset, quantity, y_axis, x_axis }) => ({
        ...asset,
        x_axis,
        y_axis,
        count: quantity,
        is_favourite: checkIfIsFavourite(asset),
      })),
      ...[
        {
          icon: (
            <div>
              <AssetsMoreIcon />
            </div>
          ),
          text: `More products by ${pickedDesignLayout.main_vendor.full_name}`,
          thumbnail: assets && assets.length ? assets.at(-1).asset.thumbnail : null,
          blurred: false,
          web_page: pickedDesignLayout.main_vendor.website_link,
          isVendorSite: true,
        },
      ],
    ].filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i) // Unique array
    return result
  }

  function getActiveDesignImage() {
    if (carouselInstance) {
      return designImages[activeSlide]
    }
  }

  function hasDesignItems() {
    return pickedRoom && getPickedImage().assets.length > 0
  }

  function isAssetsPreviewMode() {
    return previewMode === 'assets'
  }

  function isStagingPreviewMode() {
    return previewMode === 'staging'
  }

  //==========================================================================
  // Logic for handling resize for sticky image on Desktop gallery page
  // https://sparx-app.atlassian.net/browse/AT-132
  //==========================================================================

  const onResize = useCallback(() => {
    function updateAssetItemSizes(width, title, price) {
      root.style.setProperty('--asset-item-width', `${width}px`)
      root.style.setProperty('--asset-title-font-size', `${title}px`)
      root.style.setProperty('--asset-price-font-size', `${price}px`)
    }

    const image = document.getElementsByClassName('slide-image')[activeSlide]
    const windowHeight = window.innerHeight > 770 ? window.innerHeight : 770
    const h = windowHeight - image.clientHeight - 30
    const root = document.documentElement

    if (h >= 286 && h <= 340) {
      updateAssetItemSizes(100, 14, 12)
    }
    if (h >= 341 && h <= 396) {
      updateAssetItemSizes(150, 14, 12)
    }
    if (h >= 397) {
      updateAssetItemSizes(200, 16, 14)
    }
  }, [activeSlide])

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (isDesktop) {
  //       onResize()
  //       window.addEventListener('resize', onResize)
  //     }
  //   }, 5000)
  //   return () => window.removeEventListener('resize', onResize)
  // }, [sliderWrapRef, isDesktop, activeSlide, onResize])

  function onWrapRef(node) {
    setSliderWrapRef(node)
  }

  function onDesignLayoutPicked(layout) {
    setPickedDesignLayout(layout)
    posttour(events.dgcdc, getGalleryEventProperties)
    if (carouselInstance) {
      const slideIndex = designImages.findIndex((image) => image.designLayoutId === layout.id)

      if (slideIndex > -1) {
        carouselInstance.slideToLoop(slideIndex, 400)
      }
    }
  }

  function onStagingCarouselInit(swiper) {
    setStagingCarouselInstance(swiper)
    if (carouselInstance) {
      swiper.slideToLoop(carouselInstance.activeIndex, 0)
    }
  }

  function renderStagingSliderItems() {
    let activeDesign = null
    let activeRoom = null

    const hideDesignNames = !showDesignNames(apartment.unit_type.property)

    if (!designImages || !carouselInstance) {
      return []
    }

    function getBorder(image) {
      if (activeDesign && image.designLayoutId === activeDesign) {
        return 'border-[transparent] !pl-0'
      }
      if (activeDesign && activeRoom && image.designLayoutId !== activeDesign && image.roomId === activeRoom) {
        return 'border-[#C7CCD9]'
      }
      if (activeDesign && activeRoom && image.designLayoutId !== activeDesign && image.roomId !== activeRoom) {
        return 'border-[black]'
      }
    }

    return designImages.map((image, index) => {
      const item = (
        <div
          onClick={() => onStagingCarouselSlideChange(index, image)}
          className={`group h-full relative px-[6px] lg:px-[24px] ${
            index > 0 ? `border-l-[1px] ${getBorder(image)}  border-solid` : ''
          } ${index === 0 ? '!pl-0' : ''}`}
        >
          <div
            className={`bg-[rgba(255,255,255,.8)] box-border hidden ${
              index !== carouselInstance.realIndex ? 'lg:group-hover:flex' : ''
            } flex-col justify-end backdrop-blur-sm absolute h-full w-full left-0 top-0 p-[15px]`}
          >
            <div className="w-full select-none px-[15px]">
              <div className="text-[14px] font-medium text-[#5F6787]">
                {image.roomId > 0 ? rooms.find(({ id }) => id === image.roomId).name : 'Amenities'}
              </div>
              <div className="text-[18px] font-medium leading-[20px] mt-[8px]">
                {image.roomId > 0 ? getDesignTitle(image, hideDesignNames) : image.display_name || ''}
              </div>
            </div>
          </div>
          <StagingImage carouselInstance={carouselInstance} index={index} image={image} />
        </div>
      )

      activeDesign = image.designLayoutId
      activeRoom = image.roomId
      return item
    })
  }

  function onAddToCardClick() {
    posttour(events.dgcc, () => ({
      ...getGalleryEventProperties(),
      url: `#${pickedDesignLayout.main_vendor.full_name}`,
    }))
  }

  function onImageDisplayToggle() {
    if (!rawImageDisplay) {
      posttour(events.dgsuc, () => ({
        ...getGalleryEventProperties(),
      }))
    } else {
      posttour(events.dgsfc, () => ({
        ...getGalleryEventProperties(),
      }))
    }
    setRawImageDisplay(!rawImageDisplay)
  }

  function showDesignLayoutNavModeSwitcher() {
    return showFurnitureDetails(apartment.unit_type.property)
  }

  function onGalleryMouseMove(event) {
    if (event.clientY <= 80) {
      if (!overlayShown) {
        setOverlayShown(true)
      }
    } else {
      if (overlayShown) {
        setOverlayShown(false)
      }
    }
  }

  function onGalleryMouseOut(event) {
    const gallery = document.getElementsByClassName('desktop-gallery')[0]
    if (gallery.contains(event.relatedTarget)) {
      return
    }
    if (overlayShown) {
      setOverlayShown(false)
    }
  }

  function onPreviewSizeChanged() {
    setPreviewMinimized(!previewMinimized)
  }

  return (
    apartment ? 
      <div
        onMouseMove={onGalleryMouseMove}
        onMouseOut={onGalleryMouseOut}
        className={`w-full h-full lg:h-full overflow-y-auto flex relative justify-center   ${
          isDesktop ? 'desktop-gallery' : 'mobile-gallery'
        }`}
      >
        {/* <div className='h-full w-full justify-center items-center flex absolute left-0 right-0'><Loader/></div> */}
        <div className="w-full h-full flex flex-col landscape:min-h-[calc(500px-30px)] ">
          <div
            ref={onWrapRef}
            className="relative h-full bg-while w-full overflow-x-hidden justify-between my-[-22px] lg:my-0 "
          >
            <div className="absolute top-[16px] lg:top-0 lg:left-0 z-30 w-full lg:pr-[80px] hidden lg:block">
              {pickedRoom && (
                <RoomDesignPicker
                  rooms={rooms}
                  onDrop
                  onInit={(instance) => setRoomPickerInstance(instance)}
                  hideDesignNames={!showDesignNames(apartment.unit_type.property)}
                  onDropdownToggled={setRoomPickerDropdownActive}
                  pickedRoom={pickedRoom}
                  onRoomPicked={onRoomPicked}
                  onDesignPicked={(design) => onDesignLayoutPicked(design)}
                />
              )}
            </div>
            {pickedDesignLayout && (
              <PreviewSlider
                lineMode={isLineApp}
                previewMode={previewMode}
                onImagesReady={() => {
                  // onResize()
                }}
                activeSlide={activeSlide}
                onRawImageDisplayToggled={() => onImageDisplayToggle()}
                rawImageDisplay={rawImageDisplay}
                // zoomed={zoomed}
                showFavorites={!!wishlistItemId}
                // onZoomChange={setZoomed}
                pickedAsset={pickedAsset}
                hoveredAsset={hoveredAsset}
                apartment={apartment}
                onSlideChange={onCarouselSlideChange}
                pickedDesignLayout={pickedDesignLayout}
                pickedImage={getPickedImage()}
                carouselInstance={carouselInstance}
                onSwiper={setCarouselInstance}
                onPointPick={(asset, isPopoverAction, event) => {
                  if (asset && !isPopoverAction) {
                    posttour(asset.is_favourite ? events.dgihc : events.dgidc, () => ({
                      ...getGalleryEventProperties(),
                      asset_id: asset.id,
                      asset_name: asset.asset_name,
                    }))
                  }
                  onAssetPick(asset, isPopoverAction, null, event)
                }}
                onPointHover={(asset) => {
                  if (asset) {
                    posttour(asset.is_favourite ? events.dgihh : events.dgidh, () => ({
                      ...getGalleryEventProperties(),
                      asset_id: asset.id,
                      asset_name: asset.asset_name,
                    }))
                  }
                  onAssetHover(asset)
                }}
                designItemsMobileShown={showDesignItemsMobile}
                items={designImages}
                wishlistPreview={!!wishlistItemId}
                logClickEvent={() => {
                  posttour(events.dgic, getGalleryEventProperties)
                }}
                logZoomEvent={(zoomed) => {
                  posttour(zoomed ? events.dgizi : events.dgizo, getGalleryEventProperties)
                }}
              />
            )}
          </div>

          {!!getPickedImage() && (
            <div
              className={`flex-shrink-0  z-[40] ${
                isQuadraticDisplayMode(apartment) ? 'bg-white' : ''
              } hidden lg:block bottom-container ${previewMode ? 'active' : ''} ${
                previewMinimized ? 'maximized' : 'minimized'
              }`}
              ref={bottomContentRef}
              id="bottom-container"
            >
              {pickedRoom && (
                <DesignLayoutNav
                  previewMinimized={previewMinimized}
                  showModeSwitch={showDesignLayoutNavModeSwitcher()}
                  apartment={apartment}
                  onLayoutPicked={onDesignLayoutPicked}
                  pickedLayout={pickedDesignLayout}
                  hideDesignNames={!showDesignNames(apartment.unit_type.property)}
                  pickedImage={getPickedImage()}
                  onPreviewSizeChanged={onPreviewSizeChanged}
                  room={pickedRoom}
                  previewMode={previewMode}
                  bgImage={getActiveDesignImage()}
                  onPreviewModeChanged={onPreviewModeChanged}
                  onAddToCardClick={onAddToCardClick}
                  onOptionsToggled={onDesignLayoutOptionsToggled}
                  className="relative"
                />
              )}

              <div
                className={`relative hidden lg:block ${
                  isQuadraticDisplayMode(apartment) ? 'lg:bg-[white]' : ''
                } design-layout-nav ${
                  previewMode && pickedRoom && (isStagingPreviewMode() || getPickedImage().assets.length > 0)
                    ? 'active'
                    : ''
                }`}
                id="assets-ruler"
              >
                {designLayoutOptionsToggled && (
                  <div className="bg-[rgba(255,255,255,.8)] h-full w-full absolute z-10"></div>
                )}
                <div
                  className={`assets-slider display-mode-content ${
                    isAssetsPreviewMode() && hasDesignItems() ? 'active pl-[24px] pr-[32px] py-[24px]' : 'hidden'
                  }`}
                >
                  {pickedDesignLayout && (
                    <AssetsSlider
                      items={getAssetsList()}
                      pickedAsset={pickedAsset}
                      hoveredAsset={hoveredAsset}
                      pickedLayout={pickedDesignLayout}
                      showFavorites={true}
                      scrollToAsset={(asset) => {
                        scrollSliderToAsset(asset)
                        setPickedAsset(asset)
                      }}
                      designImages={designImages}
                      pickedImage={carouselInstance ? designImages[carouselInstance.realIndex] : null}
                      purchaseType={purchaseType(pickedDesignLayout.main_vendor.is_rental)}
                      onAssetPick={(asset, event) => {
                        if (asset?.isVendorSite) {
                          // Asset (furniture) link clicked (link to vendor website)
                          posttour(events.dgacl, () => ({
                            ...getGalleryEventProperties(),
                            product_link: asset.web_page,
                          }))
                          window.open(asset.web_page, '_blank').focus()
                        } else if (asset) {
                          posttour(asset.is_favourite && wishlistItemId ? events.dgwacc : events.dgacc, () => ({
                            ...getGalleryEventProperties(),
                            asset_id: asset.id,
                            asset_name: asset.asset_name,
                          }))
                          onAssetPick(asset, false, true, event)
                        }
                      }}
                      onAssetHover={(asset) => {
                        if (asset) {
                          posttour(asset.is_favourite && wishlistItemId ? events.dgwach : events.dgach, () => ({
                            ...getGalleryEventProperties(),
                            asset_id: asset.id,
                            asset_name: asset.asset_name,
                          }))
                        }
                        onAssetHover(asset)
                      }}
                    />
                  )}
                </div>
                {isDesktop && carouselInstance && (
                  <div
                    className={`display-mode-content ${
                      isStagingPreviewMode() ? 'active py-[24px] pr-[32px] pl-[24px]' : ''
                    }`}
                  >
                    <Carousel
                      navigation={isDesktop}
                      slidesPerView="auto"
                      className="h-[143px] custom-navigation-slider"
                      spaceBetween={0}
                      loop={true}
                      slideClassName="!w-max"
                      onSwiper={(swiper) => {
                        setStagingCarouselInstance(swiper)
                        onStagingCarouselInit(swiper)
                      }}
                      items={renderStagingSliderItems()}
                    />
                  </div>
                )}
              </div>
              {!hasDesignItems() && !isStagingPreviewMode() && (
                <div className="flex flex-col items-center justify-center lg:py-[45px]">
                  <div>
                    <NoAssetsIcon />
                  </div>
                  <div className="text-[#5F6787] font-semibold mt-[16px]">No Design Items</div>
                </div>
              )}
            </div>
          )}
          {isMobile && (
            <MobileBottomSheet
              className="lg:hidden"
              assets={getAssetsList()}
              rooms={rooms}
              lineMode={isLineApp}
              pickedAsset={pickedAsset}
              rawImageDisplay={rawImageDisplay}
              onAddToCardClick={onAddToCardClick}
              onRawImageDisplayToggled={() => onImageDisplayToggle()}
              onAssetPick={(...p) => {
                posttour(p[0].is_favourite && wishlistItemId ? events.dgwacc : events.dgacc, () => ({
                  ...getGalleryEventProperties(),
                  asset_id: p[0].id, // Group_7
                  asset_name: p[0].asset_name, // Group_7
                }))
                onAssetPick(...p)
              }}
              getGalleryEventProperties={getGalleryEventProperties}
              onPreviewModeChanged={onPreviewModeChanged}
              onLayoutPicked={onDesignLayoutPicked}
              hideDesignNames={!showDesignNames(apartment.unit_type.property)}
              previewMode={previewMode}
              pickedRoom={pickedRoom}
              pickedImage={getPickedImage()}
              onStagingCarouselInit={onStagingCarouselInit}
              setPickedAsset={setPickedAsset}
              onRoomPicked={onRoomPicked}
              pickedLayout={pickedDesignLayout}
              apartment={apartment}
              onStagingSlider={setStagingCarouselInstance}
              renderStagingSliderItems={renderStagingSliderItems}
              goToVendorWebsite={() => {
                // TODO: Set Vendor URL
                posttour(events.dgvwc, () => ({
                  ...getGalleryEventProperties(),
                  url: `#${pickedDesignLayout.main_vendor.full_name}`,
                }))
              }}
            />
          )}
        </div>
      </div>  : <div className='h-full w-full justify-center items-center flex'><Loader/></div>
    
  )
}
