import React from 'react'

export function Chip({ children, variant, outline, onClick, active, className = '' }) {
  function getVariantStyles(variant) {
    if (active) {
      switch (variant) {
        case 'green':
          return 'bg-[#0D8F51] text-white'
        case 'black':
          return 'bg-black text-white'
        case 'grey':
          return 'bg-[#5F6787] text-white'
        default:
          return ''
      }
    } else {
      switch (variant) {
        case 'green':
          return outline ? 'border-[#0D8F51] text-[#0D8F51]' : 'bg-[rgba(22,194,112,0.15)] text-[rgba(13,143,81,1)]'
        case 'black':
          return outline ? 'border-black text-black' : 'bg-black text-white'
        case 'grey':
          return outline ? 'border-[#5F6787] text-[#5F6787]' : 'bg-[rgba(95,103,135,0.15)] text-[#5F6787]'
        default:
          return 'bg-[rgba(95,103,135,0.15)] text-[rgba(95,103,135,1)]'
      }
    }
  }

  return (
    <div
      onClick={onClick}
      className={`${getVariantStyles(variant)} ${
        outline ? 'border-[1px] border-solid' : ''
      } px-[16px] h-[32px] flex w-max select-none cursor-default items-center rounded-full  ${className}`}
    >
      {children}
    </div>
  )
}
