import React from 'react'
import { useEffect, useState } from 'react'
// Default
import { ReactComponent as AssetsSwitchGalleryIcon } from 'shared/assets/icons/assetsSwitchGallery.svg'
import { ReactComponent as AssetsSwitchPriceIcon } from 'shared/assets/icons/assetsSwitchPrice.svg'

function AssetsGallerySwitch({ className = '', onChange, pickedState, mode, showFurnitureMode }) {
  const [activeOption, setActiveOption] = useState()

  useEffect(() => {
    setActiveOption(pickedState)
  }, [pickedState])

  function isActive(option) {
    return activeOption === option
  }

  function onOptionPicked(option) {
    onChange(option)
  }

  const data =
    mode === 'separate'
      ? [
          showFurnitureMode
            ? {
                name: 'assets',
                label: 'Furniture',
                className: '',
              }
            : null,
          {
            name: 'staging',
            label: 'Images',
            className: 'ml-[10px]',
          },
        ]
      : [
          {
            name: 'staging',
            icon: <AssetsSwitchGalleryIcon className="h-[16px] w-[16px]" />,
            className: 'right-0',
          },
          {
            name: 'assets',
            icon: <AssetsSwitchPriceIcon className="h-[16px] w-[16px]" />,
            className: 'left-0',
          },
        ]

  return (
    <>
      {mode === 'separate' && (
        <div className="flex items-center ml-[20px]">
          {data
            .filter((item) => item)
            .map((item) => (
              <button
                onClick={() => {
                  isActive(item.name) ? onOptionPicked(null) : onOptionPicked(item.name)
                }}
                className={`h-[33px] px-[16px] transition-all ease-in duration-150 border-[1px] border-[#C7CCD9] border-solid font-medium ${isActive(item.name) ? 'bg-black border-black text-white' : 'hover:bg-[rgba(229,231,237,0.5)] hover:border-[rgba(229,231,237,0.5)]'}  rounded-full ${item.className}`}
              >
                {item.label}
              </button>
            ))}
        </div>
      )}
      {(mode === undefined || mode === 'default') && (
        <div
          className={`h-[32px] w-[75px] border-solid border-[1px] border-[#C7CCD9] rounded-full relative ${
            !showFurnitureMode ? 'hidden' : ''
          } ${className}`}
        >
          {data
            .filter((item) => item)
            .map(({ icon, name, className }) => (
              <div
                key={name}
                onClick={() => {
                  onOptionPicked(name)
                }}
                className={`absolute flex items-center justify-center top-0 h-full w-[40px] rounded-full border-solid border-[1px] ${className} ${
                  isActive(name) ? 'border-black cursor-default' : 'border-transparent cursor-pointer opacity-50'
                }`}
              >
                {icon}
              </div>
            ))}
        </div>
      )}
    </>
  )
}

export default AssetsGallerySwitch
