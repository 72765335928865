import { useMediaQuery } from '@react-hook/media-query'
import ContentDownload from 'CRM/components/ContentDownload'
import { ReactComponent as CrossCloseWhite } from 'shared/assets/icons/cross-close-white.svg'
const { useState } = require('react')

export function PreviewModal({ target, onClose, unit }) {
  const [loaded, setLoaded] = useState(false)

  const belowThreshold900 = useMediaQuery('(max-height: 900px)')


  return (
    <div className="bg-[rgba(0,0,0,.5)] z-[200] h-full w-screen fixed top-0 left-0 flex items-center justify-center backdrop-blur-sm">
      <div className={`h-full w-screen ${belowThreshold900 ? 'lg:h-[calc(100%-60px)] lg:w-[calc(100%-60px)]' : 'lg:h-[calc(100%-130px)] lg:w-[calc(100%-130px)]'}`}>
        <div className="h-[42px] p-[16px] bg-[black] w-full flex items-center justify-end">
          <ContentDownload unit={target.unit} isLine={target.lineApp}/>
          <button type="button" onClick={onClose}>
            <CrossCloseWhite />
          </button>
        </div>
        <iframe
          title="Frame"
          src={target.url}
          className={`w-full h-[calc(100%-42px)] ${!loaded ? 'hidden' : ''}`}
          onLoad={() => {
            setLoaded(true)
          }}
        />
        <div
          className={`text-white py-5 text-2xl font-semibold h-[calc(100%-42px)] bg-black flex items-center justify-center ${
            !loaded ? 'flex' : 'hidden'
          }`}
        >
          Preview loading ...
        </div>
      </div>
    </div>
  )
}
