import { useNavigate } from '@tanstack/react-location'
import { useEffect } from 'react'
import { useStore } from 'TourApp/store'

function TourGuard({ children }) {
  const navigate = useNavigate()
  const { tourData, toggleSidebar, sidebarShown } = useStore()

  useEffect(() => {
    if (!tourData) {
      navigate({ to: '/' })
    } else {
      if (!sidebarShown) {
        toggleSidebar(true)
      }
    }
  }, [navigate, tourData, sidebarShown, toggleSidebar])
  return tourData ? children : null
}

export default TourGuard
