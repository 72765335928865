import Button from '../Button'
import React, { createContext, useContext, useState } from 'react'
import { ReactComponent as ErrorFilled } from 'shared/assets/icons/error-filled.svg'
import { ResultPopup } from '../ResultPopup'

const ErrorContext = createContext()

export function useSplashError() {
  const ctx = useContext(ErrorContext)
  return ctx
}

function ErrorSplashWrapper({ children }) {
  const [shown, setShown] = useState(false)
  const [text, setText] = useState('Something went wrong...')
  const [actions, setActions] = useState(null)
  const value = {
    shown,
    text,
    show: (cb, opts = {}) => {
      if (opts.text) {
        setText(opts.text)
      }
      if (opts.actions) {
        setActions(opts.actions)
      } else {
        setActions(null)
      }
      setShown(true)
      cb && cb()
    },
    hide: (cb, opts = {}) => {
      setShown(false)
      cb && cb()
    },
  }

  return (
    <ErrorContext.Provider
      value={{
        shown,
        show: value.show,
      }}
    >
      {shown && <ResultPopup text={text} actions={actions} type="error" onClose={!actions ? value.hide : null} />}
      {children}
    </ErrorContext.Provider>
  )
}

export default ErrorSplashWrapper
