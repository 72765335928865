import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'
import { getApartments } from 'CRM/services/apartments'
import { upsertFeaturedVideos } from 'MediaManager/services'
import React, { useEffect, useMemo, useState } from 'react'
import Button from 'shared/components/Button'
import DragFileUpload from 'shared/components/DragFileUpload'

import Input from 'shared/components/Input'
import Loader from 'shared/components/Loader'
import Select from 'shared/components/Select'
import ActionSuccessModal from '../ActionSuccessModal'
import { usePrompt, useSearch } from '@tanstack/react-location'
import { getBucketDetails, uploadFileToS3 } from 'MediaManager/helpers/s3'
import { objectCopy, whitespacesToUnderscore } from 'shared/helpers/general'
import { smartSort } from 'shared/helpers/arrays'


const customStyles = {
  control: (base) => ({
    ...base,
    height: 46,
    minHeight: 46,
  }),
}

function FeaturedVideosUpload(props) {
  const [thumbImage, setThumbImage] = useState()
  const [thumbImagePreviewURL, setThumbImagePreviewURL] = useState()
  const [video, setVideo] = useState()
  const [videoPreviewURL, setVideoPreviewURL] = useState()
  const [properties, setProperties] = useState([])
  const [loading, setLoading] = useState(true)
  const [formValues, setFormValues] = useState({})
  const [submitted, setSubmitted] = useState()
  const [resultValue, setResultValue] = useState()

  const search = useSearch()


  useEffect(() => {
    if (search.state) {
      const state = JSON.parse(atob(search.state))
      setFormValues({
        ...state,
      })
    }
  }, [search])


  useEffect(() => {
    ;(async () => {
      const { data: propertiesData } = await getApartments()

      setProperties(propertiesData.properties)

      setLoading(false)
    })()
  }, [])

  function onThumbPicked(files) {
    setThumbImage(files[0])
  }

  function onVideoPicked(files) {
    setVideo(files[0])
  }

  useEffect(() => {
    if (thumbImage) {
      // create the preview
      const objectUrl = URL.createObjectURL(thumbImage)
      setThumbImagePreviewURL(objectUrl)

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [thumbImage])

  useEffect(() => {
    if (video) {
      // create the preview
      const objectUrl = URL.createObjectURL(video)
      setVideoPreviewURL(objectUrl)

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [video])

  const propertiesOptions = useMemo(() => {
    return objectCopy(properties).sort(smartSort('property_name')).map(({ id, property_name }) => ({ value: id, label: property_name }))
  }, [properties])

  const lineOptions = useMemo(() => {
    if (properties.length && formValues.property) {
      return objectCopy(properties)
        .find(({ id }) => id === formValues.property)
        .unit_types.filter(({unit_types_configuration_options})=>!!unit_types_configuration_options).sort(smartSort('name')).map(({ id, name, unit_types_configuration_options }) => ({ value: id, label:`${name}${unit_types_configuration_options.is_mirrored ? ' (mirrored)' : ''}` }))
    }
    return []
  }, [formValues, properties])

  const unitOptions = useMemo(() => {
    if (properties.length && formValues.property && formValues.line) {
      return objectCopy(properties)
        .find(({ id }) => id === formValues.property)
        .unit_types.find(({ id }) => id === formValues.line)
        .units.sort(smartSort('unit_number')).map(({ id, unit_number }) => ({ value: id, label: unit_number }))
    }
    return []
  }, [formValues, properties])

  function handleInputChange(name, { value }, extra = {}) {
    setFormValues((values) => ({
      ...values,
      [name]: value,
      ...extra,
    }))
  }

  function generateVideoObjectKey(file) {
    const property = properties.find((val) => val.id === formValues.property)
    const unit = property.unit_types.find((t) => t.id === formValues.line)
    const { real_estate_company } = unit.property
    return `real_estate_companies/${real_estate_company.company_name || real_estate_company.id}/properties/${property.property_name || property.id}/${unit.name || unit.id}${unit.unit_types_configuration_options.is_mirrored ? '_mirrored' : ''}/videos/${file.name}`
  }

  const handleSubmit = async () => {
    try {
      setSubmitted(true)
      const { line, unit, displayName } = formValues

      //==========================================================================
      // Upload video to S3
      //==========================================================================

      const videoKey = whitespacesToUnderscore(generateVideoObjectKey(video))

      const videoUrl = await uploadFileToS3(video, videoKey)

      //==========================================================================
      // Upload thumbnail to S3
      //==========================================================================

      const thumbKey = whitespacesToUnderscore(
        generateVideoObjectKey(thumbImage),
      )

      const thumbUrl = await uploadFileToS3(thumbImage, thumbKey)

      await upsertFeaturedVideos({
        display_name: displayName,
        is_default: true,
        is_official: true,
        is_verified: true,
        name: video.name,
        src: videoUrl,
        thumbnail: thumbUrl,
        unit_type_id: line,
        unit_id: unit
      })
      setResultValue('success')
    } catch (error) {
      setResultValue('error')
    } finally {
      setSubmitted(false)
    }
  }

  function isFormValid() {
    const { line, displayName } = formValues
    if (line && thumbImage && video && displayName) {
      return true
    }
    return false
  }

  function resetForm() {
    setVideo(null)
    setVideoPreviewURL()
    setThumbImage(null)
    setThumbImagePreviewURL()
    const { property, line } = formValues
    setFormValues({ property, line })
    setLoading(false)
    setSubmitted(false)
    setResultValue(null)
  }

  usePrompt('There is upload in progress, are you sure you want to leave?', submitted)

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="grid-cols-2 gap-[32px] grid">
            <div className="flex flex-col justify-between">
              <div>
                <div className="text-sm mb-2 opacity-50">
                  Property <span className="text-[red]">*</span>
                </div>
                <Select
                  onChange={(val) => handleInputChange('property', val, { line: undefined, unit: undefined })}
                  value={propertiesOptions.find((property) => property.value === formValues.property) || ''}
                  options={propertiesOptions}
                  placeholder="Property name"
                  styles={customStyles}
                />
              </div>
              <div className="mt-3">
                <div className="text-sm mb-2 opacity-50">
                  Line <span className="text-[red]">*</span>
                </div>
                <Select
                  isDisabled={!formValues.property}
                  onChange={(val) => handleInputChange('line', val)}
                  value={lineOptions.find((line) => line.value === formValues.line) || ''}
                  options={lineOptions}
                  placeholder="Line name"
                />
              </div>
              <div className="mt-3">
                <div className="text-sm mb-2 opacity-50">
                  Captured in Unit <span className="text-[red]">*</span>
                </div>
                <Select
                  isDisabled={!formValues.line}
                  onChange={(val) => handleInputChange('unit', val)}
                  value={unitOptions.find((unit) => unit.value === formValues.unit) || ''}
                  options={unitOptions}
                  placeholder="Unit number"
                />
              </div>
              <div className="mt-3">
                <div className="text-sm mb-2 opacity-50">
                  Display name <span className="text-[red]">*</span>
                </div>
                <Input
                  placeholder="Display name"
                  disabled={!formValues.unit}
                  value={formValues.displayName}
                  onChange={(e) => handleInputChange('displayName', e.target)}
                ></Input>
              </div>
            </div>
            <div className="">
              {thumbImagePreviewURL && (
                <div className="flex justify-center h-full relative">
                  <img alt="thumb" src={thumbImagePreviewURL} className="h-full max-w-full" />
                  {thumbImagePreviewURL && (
                    <Button
                      rounded
                      size="small"
                      className="absolute right-[10px] top-[10px]"
                      onClick={() => {
                        setThumbImage(null)
                        setThumbImagePreviewURL(null)
                      }}
                    >
                      Upload different thumbnail
                    </Button>
                  )}
                </div>
              )}
              {!thumbImagePreviewURL && <DragFileUpload hintText="Drag thumbnail image" onPicked={onThumbPicked} />}
            </div>
          </div>
          <div className="mt-[32px]">
            {videoPreviewURL && (
              <div className="flex justify-center relative">
                {videoPreviewURL && (
                  <Button
                    rounded
                    size="small"
                    className="absolute right-[0] top-[0]"
                    onClick={() => {
                      setVideo(null)
                      setVideoPreviewURL(null)
                    }}
                  >
                    Upload different video
                  </Button>
                )}
                <video src={videoPreviewURL} controls className="max-h-[600px]" />
              </div>
            )}
            {!videoPreviewURL && <DragFileUpload hintText="Drag video" onPicked={onVideoPicked} type="video" />}
          </div>
          <div className="flex justify-end my-[32px]">
            <Button
              loading={submitted}
              disabled={!isFormValid()}
              rounded
              size="small"
              className="ml-2"
              onClick={handleSubmit}
            >
              Upload
            </Button>
          </div>
        </div>
      )}
      {resultValue && (
        <ActionSuccessModal
          state={resultValue}
          action="Walkthrough Video"
          onClose={() => {
            setResultValue(null)
          }}
          onConfirm={resetForm}
        />
      )}
    </div>
  )
}

export default FeaturedVideosUpload
