import Loader from '../Loader'

function Button({
  children,
  href = '',
  isLink = false,
  disabled = false,
  rounded = false,
  size = 'normal',
  loading = false,
  variant = 'primary',
  ...props
}) {
  function getVariantStyle() {
    switch (variant) {
      case 'secondary':
        return `text-black !font-medium bg-transparent border-[1px] border-black border-solid hover:opacity-80`
      default:
        return `text-white bg-black`
    }
  }

  function getSize() {
    switch (size) {
      case 'small':
        return `p-2 px-4 h-[32px] text-sm`
      default:
        return 'h-[44px]'
    }
  }

  const classNames = `flex items-center justify-center h-[44px] font-semibold ${
    loading ? '!bg-transparent border-[1px] border-solid border-[#0000004d]' : getVariantStyle()
  } ${getSize()} min-w-[160px] px-[16px] ${props.className}`

  return isLink ? (
    <a href={href} {...props} className={classNames}>
      {children}
    </a>
  ) : (
    <button
      type="button"
      {...props}
      disabled={disabled || loading}
      className={`active:scale-[.92] hover:opacity-[.92] transition-all truncate ${classNames} ${rounded ? 'rounded-full' : ''} ${
        disabled ? 'pointer-events-none !bg-[#C7CCD9]' : ''
      }`}
    >
      {loading ? (
        <div className="scale-[.5]">
          <Loader />
        </div>
      ) : (
        children
      )}
    </button>
  )
}

export default Button
