import { useEffect, useState } from 'react'

import { useStore } from 'CRM/store'

import { errorMessages } from 'shared/settings'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import ErrorMessage from 'shared/components/ErrorMessage'

import FormWrapper from './components/FormWrapper'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { resetPassword } from 'CRM/services/user'

export default function ResetPasswordScreen() {
  const { setTitle } = useStore()
  const [loading, setLoading] = useState(false)

  const [requestSent, setRequestSent] = useState(false)

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email address').required(),
  })

  useEffect(() => {
    setTitle('Reset Password')
  }, [setTitle])

  async function onSubmit(values) {
    if (!validateForm()) {
      return
    }
    setLoading(true)
    try {
      await resetPassword(values.email)
      setRequestSent(true)
    } catch (error) {
      if (error.response.data.error) {
        formik.setFieldError('email', error.response.data.error)
      }
    } finally {
      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema,
    // validateOnMount:true,
    onSubmit,
  })

  function validateForm() {
    if (!formik.values.email) {
      return false
    }
    if (formik.errors.email) {
      return false
    }
    return true
  }

  function reset() {
    setRequestSent(false)
    formik.resetForm()
  }

  return (
    <>
      {!requestSent && (
        <FormWrapper
          text={
            <>
              To reset your password, please
              <br /> enter your email address
            </>
          }
        >
          <form className="w-full flex flex-col items-center" onSubmit={formik.handleSubmit}>
            <Input
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              required
              placeholder="Email"
              error={formik.errors.email}
              className={`mt-[32px]`}
            />
            <ErrorMessage text={formik.errors.email} />
            <Button loading={loading} rounded type="submit" className="w-full lg:w-[172px] mt-[48px]">
              Continue
            </Button>
          </form>
        </FormWrapper>
      )}
      {requestSent && (
        <FormWrapper
          text={
            <>
              A verifictation email has been sent
              <br /> to your email, please check your
              <br /> inbox to continue.
            </>
          }
        >
          <a href="#!" onClick={reset} className="text-[18px] font-semibold mt-[20px] underline">
            I didn’t get an email
          </a>
        </FormWrapper>
      )}
    </>
  )
}
