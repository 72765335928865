import React from 'react'
import sparxLogoWhite from 'shared/assets/images/sparx-logo-white.png'
import sparxLogo from 'shared/assets/images/sparx-logo.png'

function Footer({ className = '' }) {
  return (
    <div
      className={`h-[84px] lg:h-[40px] lg:px-[20px] border-t-[1px] border-[#C7CCD9] border-solid lg:border-t-[0px] w-full text-black lg:text-white lg:bg-black flex items-center justify-center ${className}`}
    >
      <div className="flex flex-col lg:flex-row lg:w-full lg:justify-between items-center justify-center ">
        <img src={sparxLogoWhite} className="h-[17px] lg:h-[20px] hidden lg:inline-block" alt="sparx-logo" />
        <img src={sparxLogo} className="h-[17px] lg:h-[20px] lg:hidden" alt="sparx-logo" />
        <div className="mt-[16px] lg:mt-0 text-[10px] lg:text-[14px] font-[400] lg:font-normal">
          © All Rights Reserved SparX Inc. {new Date().getFullYear()}
        </div>
      </div>
    </div>
  )
}

export default Footer
