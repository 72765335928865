import React, { useCallback, useState } from 'react'
import { ReactComponent as SortingArrows } from 'shared/assets/icons/sorting-arrows.svg'
import { InviteRow } from '../InviteRow'

function InvitesTable({ cols, rows, sortingOrder, onDelete, sortingKey, onSortingOrderChange, onSortingKeyChange }) {
  const [currentToggledMenuRow, setCurrentToggledMenuRow] = useState()

  const onMenuToggled = useCallback((rowId) => {
    setCurrentToggledMenuRow(rowId)
  }, [])

  return (
    <>
      {rows.length > 0 && (
        <table class="table-auto w-full lg:mt-[56px] hidden lg:table">
          <thead className="h-[64px]">
            <tr className="text-left uppercase font-semibold text-[12px] text-[#C7CCD9]">
              {cols.map((col) => (
                <th>
                  <div className={`flex items-center group ${col.className || ''}`}>
                    {col.label}
                    {col.sortingKey && (
                      <button
                        onClick={() => {
                          if (col.sortingKey === sortingKey) {
                            onSortingOrderChange(sortingOrder === 'desc' ? 'asc' : 'desc')
                          } else {
                            onSortingKeyChange(col.sortingKey)
                          }
                        }}
                        type="button"
                        className={`ml-[16px] opacity-0 group-hover:opacity-100 ${
                          sortingKey === col.sortingKey ? 'opacity-100' : ''
                        }`}
                      >
                        <SortingArrows />
                      </button>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((invite, index) => (
              <InviteRow
                rowId={index}
                key={index}
                menuToggled={currentToggledMenuRow === index}
                onMenuToggled={onMenuToggled}
                invite={invite}
                onDelete={onDelete}
              />
            ))}
          </tbody>
        </table>
      )}
      {rows.length === 0 && <div className="p-[64px] flex items-center justify-center">No items</div>}
    </>
  )
}

export default InvitesTable
