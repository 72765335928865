import { useNavigate } from '@tanstack/react-location'
import { ROUTES } from 'MediaManager/common/constants'

import HeaderLayout from 'MediaManager/layouts/HeaderLayout'
import React from 'react'
import Button from 'shared/components/Button'

function ActionsScreen(props) {
  const navigate = useNavigate()
  const actions = [
    {
      name: 'Upload floor plan',
      action: () => {
        navigate({ to: '/floorplans' })
      },
    },
    {
      name: 'Upload walkthrough video',
      action: () => {
        navigate({ to: '/featuredvideos' })
      },
    },
    {
      name: 'Upload image',
      action: () => {
        navigate({ to: '/images' })
      },
    },
    {
      name: 'Manage Real Estate Companies',
      action: () => {
        navigate({ to: ROUTES.REAL_ESTATE_COMPANIES })
      },
    },
    {
      name: 'Manage Properties',
      className: 'bg-[#304FFE] text-white',
      action: () => {
        navigate({ to: '/property/manage' })
      },
    },
   
  ]

  return (
    <HeaderLayout title='Home'>
      <div className="h-full w-full flex items-center justify-center">
        <div className="p-[32px] bg-white shadow-lg min-w-[600px]">
          <div className="text-center text-xl mb-5">Which would you like to do?</div>
          {actions.map((action, index) => (
            <Button
              key={index}
              variant='secondary'
              className={`w-full uppercase rounded-md ${index > 0 ? 'mt-3' : ''} ${action.className || ''}`}
              onClick={action.action}
            >
              {action.name}
            </Button>
          ))}
        </div>
      </div>
    </HeaderLayout>
  )
}

export default ActionsScreen
