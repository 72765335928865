import { useState } from 'react'
import { ReactComponent as SearchIcon } from 'shared/assets/icons/search.svg'
import { ReactComponent as SearchMobileIcon } from 'shared/assets/icons/search-mobile.svg'
import { ReactComponent as SearchActiveIcon } from 'shared/assets/icons/search-active.svg'
import { ReactComponent as SearchMobileActiveIcon } from 'shared/assets/icons/search-mobile-active.svg'

export function SearchInput({ onChange, value, className = "" }) {
  const [focused, setFocused] = useState(false)
  return (
    <div
      className={`flex items-center px-[12px] lg:px-[16px] lg:border-[1px] lg:rounded-[3px]  border-solid ${
        focused ? 'border-[#A93DCB]' : 'border-[#5F6787] lg:border-[#C7CCD9]'
      } w-full lg:w-[285px] bg-[#E9EBF0] lg:bg-white lg:h-[40px] rounded-full ${className}`}
    >
      <em className="hidden lg:inline-block">{focused ? <SearchActiveIcon /> : <SearchIcon />}</em>
      <em className="lg:hidden">{focused ? <SearchMobileActiveIcon /> : <SearchMobileIcon />}</em>
      <input
        type="text"
        placeholder="Search..."
        value={value}
        className="bg-transparent ml-[18px] focus:outline-none text-[#5F6787] h-[46px] lg:h-auto w-full font-[400] lg:font-normal"
        onChange={(e) => {
          onChange(e.target.value)
        }}
        onFocus={() => {
          setFocused(true)
        }}
        onBlur={() => {
          setFocused(false)
        }}
      ></input>
    </div>
  )
}
