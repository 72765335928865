import axios from 'axios'
import { getAuth, isAuthenticated, validateToken } from '../helpers/auth'
import { apiHost } from 'shared/helpers/api'

function getAuthHeaders() {
  if (isAuthenticated()) {
    return {
      Authorization: `Bearer ${getAuth()}`,
    }
  }
  return {}
}

export function configWithAuth(config = {}) {
  return {
    headers: {
      ...(config.headers ? config.headers : {}),
      ...getAuthHeaders(),
    },
    ...config,
  }
}

export const api = axios.create({
  baseURL: `${apiHost}/api`,
  timeout: 30000,
})

export async function getUser() {
  return validateToken(async () => {
    const { data } = await api.post(`/auth/me`, { token: getAuth() })
    return data
  })
}

export async function getDummySystemUserToken() {
  return validateToken(async () => {
    const { data } = await api.get(`/auth/token/mock`, configWithAuth())
    return data
  })
}

export async function updatePassword(params) {
  return validateToken(async () => {
    await api.post(`/auth/update-password`, params)
  })
}

export async function signup(email) {
  const { data } = await api.post(`/auth/signup`, { email })
  return data
}

export async function setPassword(password, token) {
  const { data } = await api.post(`/auth/reset-password`, { password, token })
  return data
}

export async function resetPassword(email) {
  const { data } = await api.post(`/auth/reset-password`, { email })
  return data
}

export async function signin(email, password) {
  const { data } = await api.post(`/auth/signin`, { email, password })
  return data
}

export async function refreshToken(token) {
  const { data } = await api.post(`/auth/refresh-token`, { token })
  return data
}
