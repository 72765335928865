import React from 'react'
import { ReactComponent as CloseIcon } from 'shared/assets/icons/cross-small-crm-modal.svg'
import { ReactComponent as CircleErrorIcon } from 'shared/assets/icons/circle-error.svg'
import { ReactComponent as CircleSuccessIcon } from 'shared/assets/icons/circle-success.svg'

// import Button from 'shared/components/Button'
import { InviteCreateStatus } from '../../constants'
import Button from 'shared/components/Button'

function StatusPopup({ type, onClose, actions = '' }) {
  return (
    <div
      className="w-screen h-screen fixed top-0 left-0 bg-transparent flex items-center justify-center"
      // onClick={onClose}
    >
      <div className="bg-[rgba(0,0,0,.3)] h-full w-full left-0 top-0 absolute "></div>
      <div className="w-[calc(100%-42px)] min-h-[160px] lg:w-[430px] lg:h-[245px] border-[1px] border-black border-solid bg-white relative p-[16px] flex items-center justify-center">
        <CloseIcon
          className="absolute right-[16px] top-[16px] cursor-pointer z-10 "
          onClick={(e) => {
            e.stopPropagation()
            onClose()
          }}
        />
        <div className="flex flex-col items-center">
          {type === InviteCreateStatus.ERROR && (
            <>
              <CircleErrorIcon />
              <div className="text-[18px] font-medium mt-[24px]">Something Went Wrong...</div>
            </>
          )}
          {type === InviteCreateStatus.SUCCESS && (
            <>
              <CircleSuccessIcon />
              <div className="text-[18px] font-medium mt-[24px]">Invitation Saved Successfully!</div>
              <div>{actions}</div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default StatusPopup
