import FeaturedVideosUpload from 'MediaManager/components/FeaturedVideosUpload'
import ScreenLayout from 'MediaManager/layouts/ScreenLayout'
import React from 'react'

function FeaturedVideosScreen(props) {
  return (
    <ScreenLayout title="Upload Walkthrough Video">
      <FeaturedVideosUpload />
    </ScreenLayout>
  )
}

export default FeaturedVideosScreen
