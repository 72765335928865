import { clearAuth, isAuthenticated } from 'CRM/helpers/auth'
import { deleteInvite } from 'CRM/services/invites'
import { getUser } from 'CRM/services/user'
import { getUnitByUnitId } from 'IFrameApp/services/tour.service'
import { isAgentApp, isMinimalApp, isStandartApp } from 'Resident/helpers/app'
import { getInvitesForProspect } from 'Resident/services/invites'
import { getPackagesForProperty } from 'Resident/services/packages'
import { useStore } from 'Resident/store'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from 'shared/components/Loader'
import { fetchConfig } from 'shared/services/config.service'

function ContentGuard({ children }) {
  const navigate = useNavigate()
  const { appData, setAppData, appType } = useStore()
  const [loaded, setLoaded] = useState(false)
  
  useEffect(() => {
    if(!isAuthenticated()){
      clearAuth()
      navigate('/welcome')
      return
    }
    ;(async () => {
      if (!appData || !appData.user || !appData.invite || !appData.config) {
        const { data: user } = await getUser()
          const config = await fetchConfig()
          //==========================================================================
          // If app is instant app or agent app, skip invite fetching and fake it
          //==========================================================================
          let invite = {}
          if(isStandartApp(appType)){
            const { data: invites } = await getInvitesForProspect(user.id)
            invite = invites.prospects_invitations[0]
          }
          if (invite) {
            const data = appData ? appData : {}
            const instantUnitId = appData ? appData.instantUnitId : null
            const { data: unit } = await getUnitByUnitId(instantUnitId || invite.unit.id)
            setAppData({
              ...data,
              invite,
              config: config,
              user: {
                ...user,
                prospects: [user.prospect],
              },
              unit: unit.units_by_id,
            })
            setLoaded(true)
          } else {
            clearAuth()
            navigate('/welcome')
          }
      } else {
        setLoaded(true)
      }
    })()
  }, [appData, setAppData, navigate])

  return loaded ? (
    children
  ) : (
    <div className="h-screen w-screen flex items-center justify-center">
      <Loader />
    </div>
  )
}

export default ContentGuard
