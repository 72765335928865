import React, { useEffect, useState } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { ZoomControls } from 'shared/components/ZoomControls'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import { Image } from './components/Image'

function ImageViewer({ unit_plan }) {
  const [zoomInstance, setZoomInstance] = useState()
  useEffect(() => {
    if (zoomInstance) {
      zoomInstance.resetTransform()
    }
  }, [unit_plan, zoomInstance])

  const [zoomed, setZoomed] = useState(false)

  return (
    <div
      className={`h-full transition-all w-full flex items-center  ${
        zoomed ? 'lg:mt-[0] h-full' : 'lg:mt-[calc(32px+10px+28px)] lg:h-[calc(100%-(32px+10px+28px))] pb-[24px]'
      }`}
    >
      <TransformWrapper
        defaultScale={1}
        defaultPositionX={1}
        defaultPositionY={1}
        onInit={setZoomInstance}
        onTransformed={(ref, { scale }) => {
          setZoomed(scale > 1)
        }}
      >
        <TransformComponent>
          <Image
            src={getCdnAssetUrl(unit_plan.src)}
            // alt={`${floorPlans[currentFloor]?.vendor?.name} loading ...`}
            onClick={() => {}}
            className="w-full h-full object-contain absolute top-0"
            data-test-id="floor-second-image"
          />
        </TransformComponent>
      </TransformWrapper>
      {zoomInstance && (
        <ZoomControls
          className="hidden lg:flex absolute bottom-[24px] right-[24px]"
          zoomIn={() => {
            zoomInstance.zoomIn()
          }}
          zoomOut={() => {
            zoomInstance.zoomOut()
          }}
        />
      )}
    </div>
  )
}

export default ImageViewer
