import { useEffect, useRef, useState } from 'react'
import { generatePresignedUrl } from 'shared/helpers/s3'
import use3DViewer from 'shared/hooks/use3DViewer'
import ImageViewer from './components/ImageViewer'
import { getDesignLayoutForUnitPlan } from 'shared/helpers/data'

function ModelViewer({ unit_plan }) {
  useEffect(() => {
    // Listen for messages from the iframe
    window.addEventListener(
      'message',
      (event) => {
        // Access the data sent from the iframe
        const data = event.data
        console.log('Message received from iframe:', data)

        // Handle the event based on its content
        if (data.type === 'eventType') {
          console.log('Handling event type:', data.payload)
        }
      },
      false,
    )
  }, [])

  const iframeRef = useRef(null)

  const [signedModelUrl, setSignedModelUrl] = useState()
  const [modelUrl, setModelUrl] = useState(null)

  const { send } = use3DViewer(iframeRef)

  useEffect(() => {
    if (modelUrl && !iframeRef?.current) {
      ;(async () => {
        const url = await generatePresignedUrl(modelUrl)
        console.log({ url })
        setSignedModelUrl(url)
      })()
    }
  }, [modelUrl, iframeRef])

  useEffect(() => {
    console.log(iframeRef?.current)
    if (modelUrl && iframeRef?.current) {
      ;(async () => {
        const url = await generatePresignedUrl(modelUrl)
        send('url-change', { url, transition: true })
      })()
    }
  }, [modelUrl, iframeRef])

  useEffect(() => {
    const designLayout = getDesignLayoutForUnitPlan(unit_plan)
    if (designLayout?.models[0]) {
      const path = designLayout?.models[0].high_res_model_path
      setModelUrl(path.includes('.glb') ? path : `${path}.glb`)
    } else {
      setModelUrl(null)
      setSignedModelUrl(null)
    }
  }, [unit_plan])

  return (
    <>
      {signedModelUrl !== undefined && (
        <>
          {signedModelUrl ? (
            <>
              {/* <Toolset3DViwer viewer={iframeRef} /> */}
              <iframe
                ref={iframeRef}
                src={`${process.env.REACT_APP_3D_VIEWER_URL || 'https://3d-viewer-dev.sparx.ai'}?url=${btoa(
                  signedModelUrl,
                )}&controls=true`}
                className="h-full w-full"
                alt="3d-viewer"
                title="3d-viewer"
              />
            </>
          ) : (
            <ImageViewer unit_plan={unit_plan} />
          )}
        </>
      )}
    </>
  )
}

export default ModelViewer
