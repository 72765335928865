import { Link, useMatch, useNavigate, useSearch } from '@tanstack/react-location'
import ScreenLayout from 'MediaManager/layouts/ScreenLayout'
import {
  deleteRealEstateCompany,
  getRealEstateCompanyById,
  upsertRealEstateCompany,
} from 'MediaManager/services/realEstateCompanies'
import { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import Button from 'shared/components/Button'
import { uploadFileToS3 } from 'MediaManager/helpers/s3'
import Loader from 'shared/components/Loader'
import Input from 'MediaManager/components/Form/Input'
import './styles.scss'
import ActionSuccessModal from 'MediaManager/components/ActionSuccessModal'
import Select from 'shared/components/Select'
import { USStates } from 'MediaManager/constants/states'
import Toggle from 'shared/components/Toggle'
import { upsertPropertyConfiguration } from 'MediaManager/services/property'
import * as Yup from 'yup'
import { EMAIL_REGX } from 'shared/constants'
import { errorMessages } from 'shared/settings'
import { compareObjects } from 'shared/helpers/general'

const ManageRealEstateCompaniesEditScreen = () => {
  const { id } = useSearch()
  const { state } = useMatch()
  const [data, setData] = useState()
  const [resultValue, setResultValue] = useState()
  useEffect(() => {
    if (id) {
      ;(async () => {
        const data = await getRealEstateCompanyById(id)
        setData(data)
        formik.setValues(data)
      })()
    } else {
      setData({})
    }
  }, [id])

  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required(errorMessages.required),
    state: Yup.string().required(errorMessages.required),
    website: Yup.string().required(errorMessages.required),
    email: Yup.string().matches(EMAIL_REGX, 'Invalid email address').required(errorMessages.required),
  })

  const formik = useFormik({
    initialValues: data || {
      company_name: '',
      state: '',
      website: '',
      email: '',
      phone_number: '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialTouched: false,
    onSubmit: async (values) => {
      try {
        const { company_name, state, website, email, phone_number } = formik.values
        let logoPath
        if (data.logo_local) {
          logoPath = await uploadFileToS3(data.file, `real-estate-companies/${company_name}/${data.file.name}`)
        }
        await upsertRealEstateCompany({
          ...{ company_name, state, website, email, phone_number },
          ...(logoPath ? { company_logo: logoPath } : {}),
          id: data.id,
        })
        navigate({ to: `/real-estate-companies` })
      } catch (error) {
        setResultValue('error')
      }
    },
  })

  const statesOptions = USStates.map((state) => ({ label: state.short, value: state.short }))

  const isValid = useMemo(() => {
    return (data?.logo_local || data?.company_logo) && Object.values(formik.errors).every((v) => !v)
  }, [formik, data])

  async function onPropertyActiveChange(value) {
    await upsertPropertyConfiguration(value)
  }

  const dirty = !compareObjects(formik.values, data)

  function getFieldError(field) {
    return formik.errors[field] && formik.touched[field] ? formik.errors[field] : null
  }

  return (
    <ScreenLayout title={`${id ? 'Edit' : 'Add'} real estate company`}>
      {resultValue && (
        <ActionSuccessModal
          action={`Real Estate Company ${formik.values.company_name} `}
          successText="has been successfully upserted."
          errorText="upserting failed. Please try again."
          state={resultValue}
          error={
            'Issue might be caused by already existing email or other details. If data seems to be good, please try again later.'
          }
          skipConfirm
          skipBack
          onClose={() => {
            setResultValue(null)
          }}
        />
      )}
      {!data && (
        <div className="flex items-center justify-center">
          <Loader />
        </div>
      )}
      {data && (
        <>
          <form onSubmit={formik.handleSubmit}>
            <div className="my-5">
              <div className="flex justify-end">
                {id && (
                  <Button
                    size="small"
                    rounded
                    variant="secondary"
                    className="ml-2"
                    onClick={async () => {
                      if (window.confirm('Are you sure you want to delete this real estate company?')) {
                        await deleteRealEstateCompany(id)
                        navigate({ to: `/real-estate-companies` })
                      }
                    }}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  type="submit"
                  disabled={!isValid || !dirty}
                  size="small"
                  loading={formik.isSubmitting}
                  rounded
                  className="ml-2"
                >
                  {id ? 'Update' : 'Add'}
                </Button>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="w-full grid grid-cols-2 gap-2">
                <Input
                  name="company_name"
                  placeholder="Name"
                  label="Name"
                  required
                  error={getFieldError('company_name')}
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div>
                  <Select
                    required
                    label="State"
                    error={getFieldError('state')}
                    value={statesOptions.find((option) => option.label === formik.values.state)}
                    options={statesOptions}
                    onChange={(val) => formik.setFieldValue('state', val.label)}
                    onBlur={() => {
                      formik.setFieldTouched('state', true)
                    }}
                  />
                </div>
                <Input
                  required
                  name="website"
                  error={getFieldError('website')}
                  placeholder="Website"
                  label="Website"
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Input
                  name="email"
                  placeholder="Email"
                  label="Email"
                  error={getFieldError('email')}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <Input
                  name="phone_number"
                  placeholder="Tel. Number"
                  label="Tel. Number"
                  error={getFieldError('phone_number')}
                  value={formik.values.phone_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="ml-5">
                <div className="text-sm mb-2 opacity-50">
                  Logo <span className="text-red-500">*</span>
                </div>
                <div
                  className="h-[256px] p-2 rounde-lg overflow-hidden group relative rounded-xl file-upload-bg flex items-center justify-center aspect-square"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => {
                    e.preventDefault()
                    const file = e.dataTransfer.files[0]
                    if (file) {
                      const reader = new FileReader()
                      reader.onloadend = () => {
                        setData((prevData) => ({
                          ...prevData,
                          company_logo: reader.result,
                          logo_local: true,
                          file: file,
                        }))
                      }
                      reader.readAsDataURL(file)
                    }
                  }}
                >
                  {data.company_logo && (
                    <>
                      <img
                        src={data.logo_local ? data.company_logo : getCdnAssetUrl(data.company_logo)}
                        alt={data.company_name}
                      />
                      <div className="absolute top-0 left-0 group-hover:flex hidden justify-center mt-5 w-full">
                        <Button
                          rounded
                          onClick={() => {
                            setData((prevData) => ({
                              ...prevData,
                              file: undefined,
                              company_logo: undefined,
                              logo_local: false,
                            }))
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    </>
                  )}

                  {!data.company_logo && (
                    <div>
                      <span>Drag logo here or </span>
                      <label className="cursor-pointer">
                        <span className="underline">select file*</span>
                        <input
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={(event) => {
                            const file = event.currentTarget.files[0]
                            if (file) {
                              const reader = new FileReader()
                              reader.onloadend = () => {
                                setData((prevData) => ({
                                  ...prevData,
                                  company_logo: reader.result,
                                  logo_local: true,
                                  file: file,
                                }))
                              }
                              reader.readAsDataURL(file)
                            }
                          }}
                        />
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
          {data.properties && (
            <>
              <div className="mt-[40px]">
                <div class="flex items-center justify-between  mb-5">
                  <div className="text-xl">Properties</div>
                  <Link to={`/property/add?real_estate_company_id=${data.id}`}>
                    <Button size="small" rounded variant="secondary">
                      Add property
                    </Button>
                  </Link>
                </div>
                <div className="data-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Is Active</th>
                        <th>Class</th>
                        <th>Street</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Country</th>
                        <th>Timezone</th>
                        <th># Buildings</th>
                        <th># Units</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data.properties.map((p) => (
                        <tr
                          className="hover:bg-[#f9f9f9]"
                          onClick={() => {
                            navigate({ to: `/property/edit?id=${p.id}` })
                          }}
                        >
                          <td>{p.id}</td>
                          <td>{p.property_name}</td>
                          <td>
                            <Toggle
                              checked={p.properties_configuration_options?.is_active}
                              onChange={(e) => {
                                onPropertyActiveChange({
                                  id: p.properties_configuration_options?.id,
                                  is_active: !p.properties_configuration_options?.is_active,
                                })
                              }}
                            />
                          </td>
                          <td>{p.class}</td>
                          <td>{p.street}</td>
                          <td>{p.city}</td>
                          <td>{p.state}</td>
                          <td>{p.country}</td>
                          <td>{p.timezone}</td>
                          <td>{p.number_of_buildings}</td>
                          <td>{p.number_of_units}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {data.properties.length === 0 && (
                  <div className="mt-5 text-center opacity-50">No properties available yet</div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </ScreenLayout>
  )
}

export default ManageRealEstateCompaniesEditScreen
