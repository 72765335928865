import { ApolloClient, gql, InMemoryCache } from '@apollo/client'
import { getAuth } from 'CRM/helpers/auth'
import { apiHost } from 'shared/helpers/api'
import { mutate } from 'shared/helpers/graphql'

export async function getRealEstateCompanies() {
  const client = new ApolloClient({
    uri: `${apiHost}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      Authorization: `Bearer ${getAuth()}`,
    },
  })
  return client.query({
    variables: {
      //   propertyId,
    },
    query: gql`
      query Real_estate_companies {
        real_estate_companies {
          id
          company_name
          company_logo
          state
          website
          phone_number
          email
        }
      }
    `,
  })
}

export async function getRealEstateCompaniesWithProperties() {
  const client = new ApolloClient({
    uri: `${apiHost}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      Authorization: `Bearer ${getAuth()}`,
    },
  })
  return client.query({
    variables: {
      //   propertyId,
    },
    query: gql`
      query Real_estate_companies {
        real_estate_companies {
          id
          company_name
          company_logo
          state
          website
          phone_number
          email
          properties {
            id
          }
        }
      }
    `,
  })
}

export async function getRealEstateCompanyById(id) {
  const client = new ApolloClient({
    uri: `${apiHost}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      Authorization: `Bearer ${getAuth()}`,
    },
  })
  const data = await client.query({
    variables: {
      realEstateCompaniesByIdId: id,
    },
    query: gql`
      query Real_estate_companies_by_id($realEstateCompaniesByIdId: Int!) {
        real_estate_companies_by_id(id: $realEstateCompaniesByIdId) {
          id
          company_name
          company_logo
          state
          website
          phone_number
          email
          properties {
            id
            state
            street
            number_of_buildings
            number_of_units
            timezone
            city
            class
            properties_configuration_options {
              is_active
              id
            }
            country
            property_name
          }
        }
      }
    `,
  })
  return data.data.real_estate_companies_by_id
}

export async function upsertRealEstateCompany(values) {
  return mutate(
    {
      realEstateCompanies: [values],
    },
    gql`
      mutation Upsert_real_estate_companies($realEstateCompanies: [real_estate_companies_input]) {
        upsert_real_estate_companies(real_estate_companies: $realEstateCompanies) {
          id
        }
      }
    `,
  )
}

export async function deleteRealEstateCompany(id) {
  return mutate(
    {
      deleteRealEstateCompaniesId: id,
    },
    gql`
      mutation Delete_real_estate_companies($deleteRealEstateCompaniesId: Int!) {
        delete_real_estate_companies(id: $deleteRealEstateCompaniesId) {
          id
        }
      }
    `,
  )
}
