import React, { useEffect } from 'react'

function Page({ title, children }) {
  useEffect(() => {
    document.title = `${title} - SparX`
  }, [title])
  return children
}

export default Page
