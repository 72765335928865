import { deletePhotos, getApartments } from 'MediaManager/services'
import { getPropertyById } from 'MediaManager/services/property'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Loader from 'shared/components/Loader'
import Select from 'shared/components/Select'
import { ReactComponent as LetterSuccessIcon } from 'shared/assets/icons/letter-success.svg'
import { ReactComponent as LetterErrorIcon } from 'shared/assets/icons/letter-error.svg'
import { Link, useNavigate, useSearch } from '@tanstack/react-location'
import samplePhoto from 'shared/assets/images/photo-sample.png'
import { divideRoomsByWeight } from 'shared/helpers/gallery'
import { ETypes } from 'MediaManager/components/ImagesUpload'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import { smartSort } from 'shared/helpers/arrays'
import { objectCopy } from 'shared/helpers/general'
import Button from 'shared/components/Button'

function RoomPhotoContentAdd({ type, room = {}, design = {}, property = {}, line = {}, unit = {} }) {
  const navigate = useNavigate()
  return (
    <div
      onClick={() => {
        navigate({
          to: `/images?state=${btoa(
            JSON.stringify({ level: room.level, type, property: property.id, design: design.id, room: room.id, line, vendor: design?.main_vendor?.id, unit: unit.id }),
          )}`,
        })
      }}
      className="cursor-pointer min-h-[200px] relative w-full flex justify-center items-center text-[#387EE5] text-[36px] bg-cover backdrop-blur mb-[16px] rounded-lg overflow-hidden"
    >
      <img src={samplePhoto} className="w-full  opacity-20" alt={samplePhoto} />
      <div className="z-10 absolute">+</div>
    </div>
  )
}

function PropertyLineImages({ property, line, ...props }) {
  const [propertyDetails, setPropertyDetails] = useState()
  const [loading, setLoading] = useState(true)

  const [formValues, setFormValues] = useState({})
  function handleInputChange(name, { value }, extra = {}) {
    if (formValues[name] === value) {
      return
    }
    setFormValues((values) => ({
      ...values,
      [name]: value,
      ...extra,
    }))
  }

  const loadData = useCallback(async () => {
    setLoading(true)
    const { data } = await getPropertyById(Number(property))
    setPropertyDetails(data.properties_by_id)
    setLoading(false)
    setFormValues({ line })
  }, [property, line])

  useEffect(() => {
    loadData()
  }, [loadData])

  const lineOptions = useMemo(() => {
    if (propertyDetails) {
      return objectCopy(propertyDetails.unit_types)
        .sort(smartSort('name'))
        .map(({ id, name, unit_types_configuration_options }) => ({
          value: id,
          label: `${name}${unit_types_configuration_options.is_mirrored ? ' (mirrored)' : ''}`,
        }))
    }
  }, [propertyDetails])

  const unit = useMemo(() => {
    if (propertyDetails && formValues.line) {
      const unit = propertyDetails.unit_types.find(({ id }) => id === formValues.line)

      return unit
    }
  }, [formValues, propertyDetails])

  function getTotalAssetsCount(design) {
    return design.design_components
      .map(({ assets }) => assets)
      .flat()
      .filter((item) => item.asset.vendor && design.main_vendor && item.asset.vendor.id === design.main_vendor.id)
      .length
  }

  function getTotalAssetsCoveredCount(design) {
    const { photos, design_components } = design
    const availableAssets = design_components
      .map(({ assets }) => assets)
      .flat()
      .map(({ asset }) => asset)
    const photoAssets = photos
      .map(({ assets }) => assets)
      .flat()
      .filter((asset) => asset.x_axis <= 100 && asset.y_axis <= 100)
      .map(({ asset }) => asset)
    const result = availableAssets.filter(({ id }) => !photoAssets.find((asset) => asset.id === id))
    return availableAssets.length - result.length
  }

  function getPhotoCoveredAssets(photo) {
    const items = []
    photo.assets
      .filter((asset) => asset.x_axis <= 100 && asset.y_axis <= 100)
      .forEach((asset) => {
        if (!items.find((i) => i.asset.id === asset.asset.id)) {
          items.push(asset)
        }
      })
    return items
  }

  function isDesignImagesValidCount(design) {
    return getTotalAssetsCoveredCount(design) === getTotalAssetsCount(design)
  }

  function getRooms() {
    const rooms = divideRoomsByWeight({
      unit_type: {
        unit_type_levels: unit.unit_type_levels.filter((l) => l.rooms.some((room) => !!room.room_classification)),
      },
    }).flat()
    return rooms
  }

  function isRoomDesignsValid(room) {
    if (!isEmptyRoom(room)) {
      return room.room_designs.map((design) => isDesignImagesValidCount(design)).every((val) => val)
    } else {
      return room.room_designs.some((design) => design.photos.length > 0)
    }
  }

  function isEmptyRoom(room) {
    return room.room_designs.every((design) =>
      design.design_components.every((component) => component.assets.length === 0),
    )
  }

  async function onPhotoDelete(photo) {
    if (window.confirm('Do you want to delete photo ?')) {
      await deletePhotos(photo.id)
      loadData()
    }
  }

  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className="flex justify-center mt-[32px]">
        <div className="w-1/4">
          <Select
            onChange={(val) => handleInputChange('line', val, { design: undefined, unit: undefined, room: undefined })}
            value={lineOptions.find((line) => line.value === formValues.line) || ''}
            options={lineOptions}
            placeholder="Line"
          />
        </div>
      </div>
      {formValues.line && unit ? (
        <>
          {getRooms()
            .filter((room) => room.room_designs.length > 0)
            .map((room) => (
              <div className="mb-[32px]">
                <div className="flex items-center ml-5">
                  {isRoomDesignsValid(room) && <LetterSuccessIcon />}
                  {!isRoomDesignsValid(room) && <LetterErrorIcon />}
                  <div className="ml-2">{room.classification}</div>
                </div>
                <div className="grid-cols-4 grid gap-[20px] mt-[16px] p-[16px] text-[14px] py-0 rounded-lg border-[1px] border-solid border-[#E0E0E0]">
                  {!isEmptyRoom(room) &&
                    room.room_designs.map((design) => (
                      <div>
                        <div className="h-[56px] flex items-center justify-between ">
                          <div>
                            {design.design_name} by {design.main_vendor.full_name}
                            <span
                              className={`${
                                isDesignImagesValidCount(design) ? 'text-[#2C8258]' : 'text-[#F13C3C]'
                              } ml-1`}
                            >
                              ({getTotalAssetsCoveredCount(design)}/{getTotalAssetsCount(design)})
                            </span>
                          </div>
                          <div className="bg-[#E0E0E0] w-[2px] h-[14px]"></div>
                        </div>
                        {design.photos
                          .filter((photo) => photo.assets.length > 0)
                          .map((photo, index) => (
                            <div
                              className={`relative ${
                                index > 0 ? 'border-t-[2px] pt-[16px]' : ''
                              } border-[#E0E0E0] border-solid`}
                            >
                              <div className="aspect-square w-full rounded-lg overflow-hidden relative group">
                                <img src={getCdnAssetUrl(photo.src)} alt="" className="w-full h-full object-cover" />
                                <div className="bg-[rgba(255,255,255,.5)] rounded-lg aspect-square absolute top-0 left-0 w-full flex-col justify-center items-center group-hover:flex hidden">
                                  <div>
                                    <Button
                                    rounded
                                      type="button"
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        onPhotoDelete(photo)
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                  <div className="mt-[10px]">
                                    <Link to={`/images?photo=${photo.id}`}>
                                      <Button
                                      rounded
                                        type="button"
                                      >
                                        Edit
                                      </Button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="py-2 underline">{getPhotoCoveredAssets(photo).length} items</div>
                            </div>
                          ))}
                        {!isDesignImagesValidCount(design) && (
                          <RoomPhotoContentAdd
                            type={ETypes.WITH_DESIGN}
                            property={propertyDetails}
                            unit={unit}
                            room={room}
                            design={design}
                            line={line}
                          />
                        )}
                      </div>
                    ))}
                  {!isEmptyRoom(room) && (
                    <div className="flex-1">
                      <div className="text-[14px] h-[56px] flex items-center">Other images</div>
                      {room.room_designs
                        .map((design) => design.photos)
                        .flat()
                        .filter((photo) => photo.assets.length === 0)
                        .map((photo, index) => (
                          <div
                            className={`relative ${
                              index > 0 ? 'border-t-[2px] pt-[16px]' : ''
                            } border-[#E0E0E0] border-solid`}
                          >
                            <div className="aspect-square w-full rounded-lg overflow-hidden relative group">
                              <img src={getCdnAssetUrl(photo.src)} alt="" className="w-full h-full object-cover" />
                              <div className="bg-[rgba(255,255,255,.5)] rounded-lg aspect-square absolute top-0 left-0 w-full flex-col justify-center items-center group-hover:flex hidden">
                              <div>
                                  <Button
                                    rounded
                                      type="button"
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        onPhotoDelete(photo)
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                  <div className="mt-[10px]">
                                    <Link to={`/images?photo=${photo.id}`}>
                                      <Button
                                      rounded
                                        type="button"
                                      >
                                        Edit
                                      </Button>
                                    </Link>
                                  </div>
                              </div>
                            </div>

                            <div className="py-2 underline">{photo.display_name}</div>
                          </div>
                        ))}
                      <div className="mt-[16px]">
                        <RoomPhotoContentAdd
                          type={ETypes.WITHOUT_DESIGN}
                          property={propertyDetails}
                          unit={unit}
                          room={room}
                          line={line}
                        />
                      </div>
                    </div>
                  )}
                  {isEmptyRoom(room) && (
                    <>
                      {room.room_designs
                        .map((design) => design.photos)
                        .flat()
                        .filter((photo) => photo.assets.length === 0)
                        .map((photo, index) => (
                          <>
                            <div className={`mt-[16px] border-[#E0E0E0] border-solid relative`}>
                              <div className="aspect-square w-full rounded-lg overflow-hidden relative group">
                                <img
                                  src={getCdnAssetUrl(photo.src)}
                                  alt=""
                                  className="w-full rounded-lg aspect-square object-cover"
                                />
                                <div className="bg-[rgba(255,255,255,.5)] rounded-lg aspect-square absolute flex-col top-0 left-0 w-full justify-center items-center group-hover:flex hidden">
                                <div>
                                  <Button
                                    rounded
                                      type="button"
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        onPhotoDelete(photo)
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                  <div className="mt-[10px]">
                                    <Link to={`/images?photo=${photo.id}`}>
                                      <Button
                                      rounded
                                        type="button"
                                      >
                                        Edit
                                      </Button>
                                    </Link>
                                  </div>
                           
                                </div>
                              </div>

                              <div className="py-2">{photo.display_name}</div>
                            </div>
                          </>
                        ))}
                      <div className="mt-[16px]">
                        <RoomPhotoContentAdd
                          type={ETypes.WITHOUT_DESIGN}
                          property={propertyDetails}
                          unit={unit}
                          room={room}
                          line={line}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
        </>
      ) : null}
    </div>
  )
}

function ManageLineImages(props) {
  const search = useSearch()
  const [properties, setProperties] = useState([])
  const [loading, setLoading] = useState(true)
  const [formValues, setFormValues] = useState({
    property: Number(search.propertyId),
  })

  useEffect(() => {
    ;(async () => {
      const { data: propertiesData } = await getApartments()
      setProperties(propertiesData.properties)
      setLoading(false)
    })()
  }, [])

  const propertiesOptions = useMemo(() => {
    return objectCopy(properties)
      .sort(smartSort('property_name'))
      .map(({ id, property_name }) => ({ value: id, label: property_name }))
  }, [properties])

  function handleInputChange(name, { value }, extra = {}) {
    if (formValues[name] === value) {
      return
    }
    setFormValues((values) => ({
      ...values,
      [name]: value,
      ...extra,
    }))
  }

  return (
    <div>
      {!loading && (
        <>
          <div className="flex justify-center">
            <div className="w-1/4">
              <Select
                onChange={(val) =>
                  handleInputChange('property', val, {
                    line: undefined,
                  })
                }
                isDisabled={formValues.property}
                value={propertiesOptions.find((property) => property.value === formValues.property) || ''}
                options={propertiesOptions}
                placeholder="Property name"
              />
            </div>
          </div>
          {formValues.property && <PropertyLineImages property={formValues.property} line={search.line} />}
        </>
      )}
      {loading && <Loader />}
    </div>
  )
}

export default ManageLineImages
