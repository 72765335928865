import create from 'zustand'

import { generalStoreCreator } from './general.store'
import { tourStoreCreator } from './tour.store'
import { layoutStoreCreator } from './layout.store'
import { galleryStoreCreator } from './gallery.store'

export const useStore = create((set) => ({
  ...generalStoreCreator(set),
  ...tourStoreCreator(set),
  ...layoutStoreCreator(set),
  ...galleryStoreCreator(set)
}))
