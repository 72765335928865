import { sortByWeight } from 'shared/helpers/arrays'
import { objectCopy } from 'shared/helpers/general'

export function getPurchaseType(isRental) {
  if (isRental) {
    return 'rent'
  } else {
    return 'buy'
  }
}

export function produceAllRoom(pack, id = -1) {
  const room = {
    id,
    room_classification: {
      id,
      classification_name: 'Summary',
    },
    package_assets: [],
  }
  pack.room_classifications.sort(sortByWeight).forEach((item) => {
    item.package_assets.forEach((asset) => {
      if (asset.type === 'core' || asset.added) {
        room.package_assets.push({
          ...asset,
          room_classification: item.room_classification
        })
      }
    })
  })
  return room
}

export function isMobileSource(source) {
  return source === 'mobile'
}

export function groupAssets(assets, pack) {
  if (!pack.generated) {
    const grouped = []
    assets
      .filter((asset) => asset.type === 'core' || asset.added)
      .forEach((asset) => {
        const groupedAssetIndex = grouped.findIndex(
          ({ identifier, type }) => identifier === asset.identifier && type === asset.type,
        )

        if (groupedAssetIndex > -1) {
          grouped[groupedAssetIndex].count += asset.count
          if (grouped[groupedAssetIndex].selectedCount) {
            grouped[groupedAssetIndex].selectedCount += asset.selectedCount
            grouped[groupedAssetIndex].groupedPrice += asset.selectedCount * asset.price
          }
        } else {
          asset.groupedPrice = asset.price * asset.selectedCount
          grouped.push(objectCopy(asset))
        }
      })
    return [...grouped, ...assets.filter((asset) => asset.type !== 'core' && !asset.added)]
  } else {
    return assets
  }
}
