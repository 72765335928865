import React, { useState } from 'react'
import { ReactComponent as PersonsPersonalized } from 'shared/assets/icons/persons-personalized.svg'
import { ReactComponent as CloseIcon } from 'shared/assets/icons/modalCloseSmall.svg'

import Button from 'shared/components/Button'
import { ReactComponent as TickIcon } from './icons/tick-copy.svg'

function PersonalizedLinkModal({ unit, line, ...props }) {
  const [prospectName, setProspectName] = useState('')
  const [copied, setCopied] = useState(false)

  function onClose() {
    props.onClose(null)
  }

  function onCopy() {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000);
    props.onCopy(prospectName)
  }

  return (
    <div className="w-screen z-[500] h-screen fixed top-0 left-0 bg-transparent flex items-center justify-center">
      <div className="bg-[rgba(229,231,237,.7)] h-full w-full left-0 top-0 absolute "></div>
      <div className="w-[calc(100%-42px)] min-h-[160px] lg:w-[533px]  border-[1px] border-black border-solid bg-white relative p-[20px] lg:p-[32px] flex items-center justify-center">
        <CloseIcon
          className="absolute right-[16px] top-[16px] cursor-pointer z-10 "
          onClick={(e) => {
            e.stopPropagation()
            onClose()
          }}
        />
        <div className="flex flex-col items-center">
          <div className="flex justify-center">
            <PersonsPersonalized />
          </div>
          <div className="mt-[24px]">
            <div className="font-semibold text-center">
              Send A Personalized Link ({line ? `Line ${line}` : `#${unit.unit_number}`})
            </div>
            <div className="font-light text-[#5F6787] text-center">
              Enter prospect name to send a personalized link{' '}
            </div>
          </div>
          <div className="mt-[40px] flex flex-col items-center">
            <div className="w-full lg:w-[275px]">
              <input
                onChange={(e) => setProspectName(e.target.value)}
                type="text"
                className="w-full h-[42px] block rounded-full border-solid border-[1px] border-[#000] px-[24px] font-normal outline-none text-black text-md"
                placeholder="Prospect Name..."
              />
              <Button
                onClick={onCopy}
                rounded
                className={`w-full !max-h-full mt-[16px] ${copied ? 'bg-[#16C270]' : 'bg-black'}`}
              >
                {!copied && <div className="copied">Copy Link</div>}
                {copied && (
                  <div className="flex items-center">
                    <TickIcon /> <span className="ml-[8px]">Link copied</span>
                  </div>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalizedLinkModal
