import Button from "shared/components/Button"
import { ReactComponent as ResidentLogoSmall } from 'shared/assets/icons/resident-logo-small.svg'
import { useNavigate } from "react-router-dom"

export function ViewButton({ className = '', checkout, ...props }) {
  return (
    <Button
      className={`ml-[16px] !w-max !min-w-[auto] ${className}`}
      rounded
      variant={checkout ? '' : 'secondary'}
      {...props}
    >
      View Gallery
    </Button>
  )
}

export function CheckoutButton({ pack, className = '', onClick, ...props }) {
  const navigate = useNavigate()
  function onCheckout() {
    onClick && onClick()
    navigate('/checkout', { state: { packageId: pack.id } })
  }

  return (
    <Button className={`ml-[16px] !w-max !min-w-[auto] ${className}`} rounded onClick={onCheckout} {...props}>
      Review Cart
    </Button>
  )
}
