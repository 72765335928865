import React from 'react'
import styles from './styles.module.scss'
function Toggle({checked, ...props}) {
  return (
    <label className={`${styles['pure-material-switch']}`}>
      <input type="checkbox" checked={checked} onChange={props.onChange} />
      <span></span>
    </label>
  )
}

export default Toggle
