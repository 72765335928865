import { ApolloClient, gql, InMemoryCache } from '@apollo/client'
import { properties_configuration_options, unit_types_configuration_options } from 'shared/gql'
import { apiHost } from 'shared/helpers/api'
import { mutate } from 'shared/helpers/graphql'

export const unitType = `
  id
  nick_name
  name
  size
  unit_type
  size_to_display
  number_of_bedrooms
  number_of_bathrooms
  ${unit_types_configuration_options}
  videos {
    id
    src
    display_name
    thumbnail
    is_official
    is_verified
    name
    timestamp
    captured_in {
      unit_number
    }
  }
  units {
    id
    unit_number
  }
  property {
    ${properties_configuration_options}
    id
    logo_path
    property_landscape_image
    photos{
      id
      src
      thumbnail
      display_name
    }
    building_number
    street
    city
    leasing_office_main_email
    phone_number
    property_name
    
    real_estate_company {
      id
      company_name
      company_logo
    }
  }
  unit_type_levels {
    design_layouts {
      id
      main_vendor {
        id
        display_name
      }
      name
    }
    floor_number
    id
    display_name
    virtual_tour {
      tour_provider {
        name
        id
      }
      tour_path
      id
    }
    unit_plans {
      suitable_for_unit_model
      display_name
      id
      src
      vendor {
        id
        full_name
      }
      design_layout {
        id
        models {
          high_res_model_path
          id
        }
      }
      unit_type_level {
        id
        is_entrance
        floor_number
        display_name
        unit_type {
          size
          size_to_display
        }
        design_layouts {
          id
          main_vendor {
            id
            full_name
          }
          models {
            id
            low_res_model_path
            low_res_polygons_count
            high_res_model_path
            high_res_polygons_count
            initial_gravity_rotation_angle
          }
          package {
            id
          }
        }
      }
    }
    rooms {
      name
      id
      classification
      area
      room_classification {
        weight
        classification_name
        id
      }
      room_designs {
        id
        design_name
        main_vendor {
          id
          is_sparx
          full_name
          website_link
          is_rental
        }
        design_layout {
          name
          id
        }
        packages {
          package {
            name
            id
            display_name
            vendor {
              full_name
              id
              display_name
            }
            room_designs {
              room_design {
                design_layout {
                  name
                  id
                }
                id
                room {
                  id
                  name
                  classification
                  room_classification {
                    classification_name
                  }
                }
                design_components {
                  assets {
                    asset {
                      id
                      web_page
                      width
                      depth
                      height
                      affiliation_link
                      variant_id
                      asset_name
                      category {
                        id
                        category_name
                        image_path
                      }
                      thumbnail
                      listings {
                        price
                        id
                      }
                    }
                    count
                  }
                }
              }
            }
          }
        }
        design_components {
          assets {
            asset {
              vendor {
                id
                full_name
              }
              id
              web_page
              width
              depth
              height
              affiliation_link
              variant_id
              asset_name
              category {
                id
                category_name
                image_path
              }
              thumbnail
              listings {
                price
                id
              }
            }
            count
          }
        }
        short_description
        photos {
          id
          src
          raw_image
          display_name
          direction
          captured_in {
            unit_number
          }
          tour_unit {
            unit {
              unit_number
            }
          }
          assets {
            x_axis
            y_axis
            asset {
              vendor {
                id
                full_name
              }  
              id
              web_page
              width
              depth
              height
              affiliation_link
              variant_id
              asset_name
              thumbnail
              listings {
                price
                id
              }
            }
          }
        }
      }
    }
  }
`

export async function getTourData(code) {
  const client = new ApolloClient({
    uri: `${apiHost}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      Authorization: process.env.REACT_APP_API_TOKEN || 'xZRuBuykeDuRRei0lw0Oe5WJOXbwuRQK',
    },
  })
  return await client.query({
    variables: {
      code,
      includeInSparxStaging: true
    },
    query: gql`
      query Tours_by_code($code: String!, $includeInSparxStaging: Boolean) {
        tours_by_code(code: $code, include_in_sparx_staging: $includeInSparxStaging) {
          id
          invitation_status
          app_user_email
          app_user_first_name
          app_user_last_name
          uuid
          videos {
            id
            display_name
            src
            thumbnail
            is_official
            is_verified
            name
            timestamp
            captured_in {
              unit_number
            }
          }
          app_user_email
          agent {
            id
            first_name
            last_name
            phone_number
            email
          }
          start_time
          end_time
          units {
            photos {
              id
              src
              thumbnail
              timestamp
            }
            unit {
              virtual_tour {
                tour_provider {
                  name
                  id
                }
                tour_path
                id
              }
              id
              name
              unit_number
              floor
              price
              building_name
              is_available
              unit_type {
                ${unitType}
              }
            }
          }
          liked_assets {
            asset {
              id
              web_page
              affiliation_link
              width
              depth
              height
              asset_name
              variant_id
              thumbnail
              listings {
                id
                price
              }
            }
            room_design {
              id
              short_description
              design_name
              main_vendor {
                id
                full_name
                website_link
                is_sparx
                is_rental
              }
              room {
                id
                name
                classification
                room_classification {
                  weight
                  classification_name
                }
                unit_type {
                  id
                }
              }
              photos {
                id
              }
            }
          }
        }
      }
    `,
  })
}

export async function getUnitByUnitNumber(unitNumber, propertyName) {
  const client = new ApolloClient({
    uri: `${apiHost}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      Authorization: process.env.REACT_APP_API_TOKEN || 'xZRuBuykeDuRRei0lw0Oe5WJOXbwuRQK',
    },
  })
  return await client.query({
    variables: {
      unitNumber,
      propertyName,
      includeInSparxStaging: true
    },
    query: gql`
      query Units_by_unit_number($unitNumber: String!, $propertyName: String, $includeInSparxStaging: Boolean) {
        units_by_unit_number(unit_number: $unitNumber, property_name: $propertyName, include_in_sparx_staging: $includeInSparxStaging) {
          id
          name
          unit_number
          floor
          price
          building_name
          is_available
          virtual_tour {
            tour_provider {
              name
              id
            }
            tour_path
            id
          }
          unit_type {
            ${unitType}
          }
        }
      }
    `,
  })
}

export async function upsertToursByUUID(payload) {
  return mutate(
    {
      ...payload
    },
    gql`
      mutation Upsert_tours(
        $uuid: String!
        $appUserFirstName: String!
        $appUserLastName: String!
        $appUserEmail: String!
        $invitationStatus: invitation_status
      ) {
        upsert_tours(
          uuid: $uuid
          app_user_first_name: $appUserFirstName
          app_user_last_name: $appUserLastName
          app_user_email: $appUserEmail
          invitation_status: $invitationStatus
        ) {
          id
        }
      }
    `,
  )
}
