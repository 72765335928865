import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import { getApartments, getDesignLayouts } from 'CRM/services/apartments'
import Loader from 'shared/components/Loader'
import { deleteUnitPlans, getUnitPlan, getVendors, upsertUnitPlan } from 'MediaManager/services'
import { usePrompt, useSearch } from '@tanstack/react-location'
import ActionSuccessModal from '../ActionSuccessModal'
import Select from 'shared/components/Select'
import DragFileUpload from 'shared/components/DragFileUpload'
import { uploadFileToS3 } from 'MediaManager/helpers/s3'
import { arrayUniqueByKey, isProductionBuild, objectCopy, whitespacesToUnderscore } from 'shared/helpers/general'
import { smartSort } from 'shared/helpers/arrays'
import { validateFloorPlanImage, InvalidFloorPlanPopup } from './InvalidFloorPlanPopup'
import ImageAssetsEditor from '../ImagesUpload/Editors/WithDesign/components/ImageAssetsEditor'
import { getUnitType } from 'MediaManager/services/property'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import { getFloorLabel } from 'shared/helpers/levels'
import Checkbox from 'shared/components/Checkbox'
import Toggle from 'shared/components/Toggle'
import { apiClient, apiHost } from 'shared/helpers/api'




function FloorPlansUpload() {
  const [existingUnitPlans, setExistingUnitPlans] = useState([])
  const [floorPlanValidationPopup, setFloorPlanValidationPopup] = useState(false)
  const [floorPlanValidationErrors, setFloorPlanValidationErrors] = useState(null)
  const [image, setImage] = useState(null)
  const [previewImageURL, setPreviewImageURL] = useState(null)
  const [loading, setLoading] = useState(true)
  const [unitTypeLoading, setUnitTypeLoading] = useState(false)
  const [properties, setProperties] = useState([])
  const [formValues, setFormValues] = useState({})
  const [submitted, setSubmitted] = useState()
  const [resultValue, setResultValue] = useState()
  const [unitType, setUnitType] = useState(null)
  const [editPlan, setEditPlan] = useState(null)
  const search = useSearch()

  useEffect(() => {
    if (search.plan) {
      ;(async () => {
        const { data } = await getUnitPlan(search.plan)
        const [plan] = data.unit_plans
        setEditPlan(plan)
        if (plan) {
          setFormValues({
            property: plan.unit_type_level.unit_type.property.id,
            line: plan.unit_type_level.unit_type.id,
            level: plan.unit_type_level.id,
            vendor: plan.design_layout?.main_vendor.id || null,
            designLayout: plan.design_layout?.id,
            package: plan.package?.id,
            containsAngle: plan.contains_angle,
            suitableForCW: plan.suitable_for_unit_model,
            displayName: plan.display_name,
          })
          setPositionedAssets(
            plan.assets
              .filter((a) => a.x_axis <= 100 && a.y_axis <= 100)
              .map((a) => ({
                current: {
                  leftPercent: a.x_axis,
                  topPercent: a.y_axis,
                },
                name: a.asset.name || a.asset.asset_name,
                isNew: true,
                is_core_item: a.is_core_item,
                editable: true,
                ...a.asset,
              })),
          )
          setPreviewImageURL(getCdnAssetUrl(plan.src))
          onLineChange({ value: plan.unit_type_level.unit_type.id }, true)
        }
      })()
    }
    if (search.state) {
      const state = JSON.parse(atob(search.state))
      setFormValues({
        ...state,
      })
      if (state.line) {
        onLineChange({ value: state.line }, true)
      }
    }
  }, [search])

  async function onDrop(files) {
    const errors = await validateFloorPlanImage({
      floorPlan: files[0],
      existingUnitPlans,
    })

    if (errors.list.length && isProductionBuild()) {
      setFloorPlanValidationErrors(errors)
      setFloorPlanValidationPopup(true)
      resetImage()
    } else {
      setImage(files[0])
    }
  }

  const resetForm = useCallback(() => {
    setImage(null)
    setPreviewImageURL(null)
    setFormValues(({ property, line }) => ({ property, line }))
    setLoading(false)
    setSubmitted(false)
    setResultValue(null)
    setPositionedAssets([])
  }, [])

  const level = useMemo(() => {
    if (unitType) {
      return unitType.unit_type_levels.find(({ id }) => formValues.level === id)
    } else {
      return null
    }
  }, [formValues.level, unitType])

  useEffect(() => {
    if (unitType && !formValues.level && unitType.unit_type_levels.length === 1) {
      setFormValues((values) => ({
        ...values,
        level: unitType.unit_type_levels[0].id,
      }))
    }
  }, [unitType, formValues])

  useEffect(() => {
    if (image) {
      // create the preview
      const objectUrl = URL.createObjectURL(image)
      setPreviewImageURL(objectUrl)

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [image])

  function resetImage() {
    setImage(null)
    setPreviewImageURL(null)
    setPositionedAssets([])
  }

  useEffect(() => {
    ;(async () => {
      const [{ data: propertiesData }] = await Promise.all([await getApartments()])
      setProperties(propertiesData.properties)
      setLoading(false)
    })()
  }, [])

  const propertiesOptions = useMemo(() => {
    return objectCopy(properties)
      .sort(smartSort('property_name'))
      .map(({ id, property_name }) => ({ value: id, label: property_name }))
  }, [properties])

  const vendorsOptions = useMemo(() => {
    if (level && formValues.level) {
      const vendors = []
      
      level.design_layouts.forEach(({main_vendor}) => {
        const vendor = vendors.find((vendor) => vendor && vendor.id === main_vendor.id)
        if (!vendor) {
          vendors.push(main_vendor)
        }
      })
      const result = vendors
        .sort(smartSort('display_name'))
        .map(({ id, display_name }) => ({ value: id, label: display_name }))
      result.unshift({ value: null, label: 'Unfurnished (No Vendor)' })
      return result
    } else {
      return []
    }
  }, [level, formValues.level])

  const lineOptions = useMemo(() => {
    if (properties.length && formValues.property) {
      return objectCopy(properties)
        .find(({ id }) => id === formValues.property)
        .unit_types.sort(smartSort('name'))
        .map(({ id, name, unit_types_configuration_options, unit_plans }) => ({
          value: id,
          label: `${name}${unit_types_configuration_options.is_mirrored ? ' (mirrored)' : ''}`,
          unit_plans,
        }))
    }
    return []
  }, [formValues, properties])

  const levelOptions = useMemo(() => {
    if (unitType) {
      return unitType.unit_type_levels.map((level) => ({
        value: level.id,
        label: getFloorLabel(level),
      }))
    }
    return []
  }, [unitType])

  function handleInputChange(name, { value }, extra = {}) {
    setFormValues((values) => ({
      ...values,
      [name]: value,
      ...extra,
      ...(name === 'vendor' ? { package: null } : {}),
    }))
  }

  function generateImageObjectKey() {
    const property = properties.find((val) => val.id === formValues.property)
    const unit = unitType
    const { real_estate_company } = unit.property
    return `real_estate_companies/${real_estate_company.company_name || real_estate_company.id}/properties/${
      property.property_name || property.id
    }/${unit.name || unit.id}${unit.unit_types_configuration_options.is_mirrored ? '_mirrored' : ''}/floor_plans/${
      image.name
    }`
  }

  const handleUpload = async () => {
    try {
      setSubmitted(true)
      const {
        level,
        vendor,
        displayName,
        package: packageId,
        aptpUploadCheckbox,
        designLayout,
        containsAngle,
        suitableForCW
      } = formValues
      let planImageUrl

      if (editPlan && !image) {
        planImageUrl = editPlan.src
      } else {
        const key = whitespacesToUnderscore(generateImageObjectKey())
        planImageUrl = await uploadFileToS3(image, key)
      }

      if (editPlan) {
        await deleteUnitPlans(editPlan.id)
      }

      const {
        data: { upsert_unit_plans },
      } = await upsertUnitPlan({
        ...(editPlan ? { id: editPlan.id } : {}),
        vendor_id: vendor,
        ...(packageId
          ? { package_id: Number(packageId), unit_type_level_id: aptpUploadCheckbox ? level : undefined }
          : { unit_type_level_id: level }),
        display_name: displayName,
        src: planImageUrl,
        contains_angle: containsAngle,
        suitable_for_unit_model: suitableForCW,
        design_layout_id: vendor ? designLayout : undefined,
        is_verified: true,
        assets: positionedAssets.map(({ id, current, room_classification, room_design, is_core_item }) => ({
          asset_id: id,
          is_core_item: is_core_item || false,
          x_axis: current.leftPercent,
          y_axis: current.topPercent,
          room_classification_id: room_classification,
          room_design_id: room_design,
        })),
      })
      // buffering uploaded floor plan in case of another upload so as to perform validation
      setExistingUnitPlans([...existingUnitPlans, ...upsert_unit_plans])
      setResultValue('success')
    } catch (error) {
      setResultValue('error')
    } finally {
      setSubmitted(false)
    }
  }

  function isFormValid() {
    const { vendor, line, level, designLayout } = formValues
    if (
      level &&
      line &&
      (editPlan ? previewImageURL : image) &&
      (vendor ? designLayout : true) &&
      vendor !== undefined
    ) {
      return true
    }
    return false
  }

  async function onLineChange(line, skipReset) {
    setUnitType(null)
    setUnitTypeLoading(true)
    const { data } = await getUnitType(line.value)
    setUnitType(data.unit_types[0])
    if (!skipReset) {
      setPositionedAssets([])
      setFormValues((val) => ({
        ...val,
        package: null,
        vendor: null,
      }))
      resetForm()
    }
    setUnitTypeLoading(false)
  }

  const packages = useMemo(() => {
    if (level && formValues.level && formValues.vendor) {
      const packs = arrayUniqueByKey(
        level.rooms
          .map(({ room_designs }) => room_designs)
          .flat()
          .map((d) => d.packages)
          .flat()
          .map((p) => p.package),
        'id',
      ).filter((p) => p.vendor.id === formValues.vendor)
      return packs
    } else {
      return []
    }
  }, [formValues, level])

  const packageOptions = useMemo(() => {
    const options = packages.map((pack) => ({
      value: pack.id,
      label: `${pack.name}`,
    }))
    if (options.length > 0) {
      options.unshift({
        value: null,
        label: `No Package`,
      })
    }
    return options
  }, [packages])

  const assets = useMemo(() => {
    if (level && formValues && formValues.vendor && formValues.level && formValues.designLayout) {
      const { vendor, designLayout } = formValues
      const assets = []

      level.rooms.forEach((room) => {
        const designs = room.room_designs.filter(
          (d) => d.main_vendor.id === vendor && d.design_layout.id === designLayout,
        )
        designs.forEach((d) => {
          d.design_components.forEach((c) => {
            c.assets
              .filter((a) => a.asset.vendor && a.asset.vendor.id === d.main_vendor.id)
              .forEach((a) => {
                assets.push({
                  ...a.asset,
                  count: a.count,
                  name: a.asset.asset_name,
                  room_classification: room.room_classification.id,
                  room_classification_name: room.room_classification.classification_name,
                  room_design: d.id,
                })
              })
          })
        })
      })

      return arrayUniqueByKey(assets, 'id')
    } else {
      return []
    }
  }, [level, formValues])

  const [positionedAssets, setPositionedAssets] = useState([])
  const [dotsPopupShown, setDotsPopupShown] = useState(false)

  const designLayoutsOptions = useMemo(() => {
    if (level && formValues.vendor) {
      return level.design_layouts
        .filter((d) => d.main_vendor.id === formValues.vendor)
        .map((d) => ({
          value: d.id,
          label: d.name || `ID: ${d.id}`,
        }))
    }
    return []
  }, [level, formValues])

  usePrompt('There is upload in progress, are you sure you want to leave?', submitted)

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div>
            <div className="text-sm mb-2 opacity-50">
              Property <span className="text-[red]">*</span>
            </div>
            <Select
              onChange={(val) =>
                handleInputChange('property', val, { line: undefined, vendor: undefined, displayName: '' })
              }
              value={propertiesOptions.find((property) => property.value === formValues.property) || ''}
              options={propertiesOptions}
              placeholder="Property name"
            />
          </div>
          <div className="mt-3">
            <div className="text-sm mb-2 opacity-50">
              Line <span className="text-[red]">*</span>
            </div>
            <Select
              onChange={async (val) => {
                setExistingUnitPlans(val.unit_plans)
                handleInputChange('line', val)
                onLineChange(val)
                if (image) {
                  await onDrop([image])
                }
              }}
              isDisabled={!formValues.property}
              value={lineOptions.find((line) => line.value === formValues.line) || ''}
              options={lineOptions}
              placeholder="Line"
            />
          </div>
          {unitTypeLoading && (
            <div className="flex justify-center mt-5">
              <Loader />
            </div>
          )}
          {unitType && !unitTypeLoading && (
            <>
              <div className="mt-3">
                <div className="text-sm mb-2 opacity-50">
                  Level <span className="text-[red]">*</span>
                </div>
                <Select
                  onChange={(val) => {
                    handleInputChange('level', val)
                  }}
                  isDisabled={levelOptions.length === 1}
                  options={levelOptions}
                  value={levelOptions.find((vendor) => vendor.value === formValues.level) || ''}
                  placeholder="Level"
                />
              </div>
              {formValues.level && (
                <div className="mt-3">
                  <div className="text-sm mb-2 opacity-50">
                    Vendor <span className="text-[red]">*</span>
                  </div>
                  <Select
                    onChange={(val) => {
                      handleInputChange('vendor', val, {suitableForCW : false})
                    }}
                    options={vendorsOptions}
                    isDisabled={!formValues.line}
                    value={vendorsOptions.find((vendor) => vendor.value === formValues.vendor) || ''}
                    placeholder="Vendor name"
                  />
                </div>
              )}
              {formValues.vendor && (
                <div className="mt-3">
                  <div className="text-sm mb-2 opacity-50">
                    Design Layout <span className="text-[red]">*</span>
                  </div>
                  <Select
                    onChange={(val) => {
                      handleInputChange('designLayout', val)
                    }}
                    options={designLayoutsOptions}
                    isDisabled={!formValues.line}
                    value={designLayoutsOptions.find((vendor) => vendor.value === formValues.designLayout) || ''}
                    placeholder="Design layout"
                  />
                </div>
              )}
              {formValues.vendor ? (
                <div className="mt-3">
                  <div className="text-sm mb-2 opacity-50">Package</div>
                  <Select
                    onChange={async (val) => {
                      // setExistingUnitPlans(val.unit_plans)
                      handleInputChange('package', val)
                      // onLineChange(val)
                    }}
                    isDisabled={packageOptions.length === 0}
                    value={packageOptions.find((pack) => pack.value === formValues.package) || ''}
                    options={packageOptions}
                    placeholder={packageOptions.length > 0 ? 'Package' : 'No packages available'}
                  />
                </div>
              ) : null}

              {formValues.package && (
                <div className="my-5">
                  <div className="flex items-center">
                    <Checkbox
                      checked={formValues.aptpUploadCheckbox}
                      onChange={(e) => handleInputChange('aptpUploadCheckbox', { value: e.target.checked })}
                    />
                    <div className="ml-5 text-[14px]">Upload this Floor Plan also to Tour Page and Apartment Page</div>
                  </div>
                </div>
              )}
              {formValues.vendor && (
                <div className="mt-3">
                  <div className="text-sm mb-2 opacity-50">Display name</div>
                  <Input
                    placeholder="Display name"
                    value={formValues.displayName}
                    onChange={(e) => handleInputChange('displayName', e.target)}
                  ></Input>
                </div>
              )}
              <div className="my-5">
                <div className="flex items-center">
                  <Checkbox
                    checked={formValues.containsAngle}
                    onChange={(e) => handleInputChange('containsAngle', { value: e.target.checked })}
                  />
                  <div className="ml-5 text-[14px]">Contains an angle</div>
                </div>
              </div>
              {(!formValues.vendor) && <div className="my-5">
                <div className="flex items-center">
                  <Checkbox
                    checked={formValues.suitableForCW}
                    onChange={(e) => handleInputChange('suitableForCW', { value: e.target.checked })}
                  />
                  <div className="ml-5 text-[14px]">Suitable to CW</div>
                </div>
              </div>}
            </>
          )}

          <div className="mt-[32px]">
            {previewImageURL && (
              <div className="relative flex justify-center">
                <div className="absolute right-0 top-0 flex z-[10]">
                  <Button rounded className="p-2 px-4 h-[32px] text-sm" onClick={resetImage}>
                    Upload different image
                  </Button>
                  {assets.length ? (
                    <Button
                      rounded
                      className="p-2 px-4  h-[32px] text-sm ml-[10px]"
                      onClick={() => {
                        setDotsPopupShown(true)
                      }}
                    >
                      Position dots
                    </Button>
                  ) : null}
                </div>
                {previewImageURL && (
                  <div className="relative ">
                    <img className="max-w-full max-h-[600px]" alt="rawImage" src={previewImageURL} />
                    {positionedAssets &&
                      positionedAssets.map(({ current, thumbnail }) => {
                        return (
                          <div
                            className="h-[20px] w-[20px] rounded-full bg-[rgba(0,0,0,.3)] group shadow-[0px_2px_6px_rgba(0,0,0,0.5)] absolute border-solid border-[1px] border-[rgba(255,255,255, .7)] flex items-center justify-center absolute group"
                            style={{
                              top: `${current.topPercent}%`,
                              left: `${current.leftPercent}%`,
                            }}
                          >
                            <div className={`h-[4px] w-[4px] bg-[#fff] rounded-full`}></div>
                            <div className="hidden group-hover:flex justify-center absolute w-max left-[40px] top-[-50px] ">
                              <img
                                className="h-[100px] w-[100px] rounded-xl mt-2 border-[1px] border-solid border-black"
                                alt={thumbnail}
                                src={getCdnAssetUrl(thumbnail)}
                              />
                            </div>
                          </div>
                        )
                      })}
                  </div>
                )}
              </div>
            )}
            {!previewImageURL && <DragFileUpload hintText="Drag floor plan image" onPicked={onDrop} />}
            {floorPlanValidationPopup && (
              <InvalidFloorPlanPopup
                closePopup={() => setFloorPlanValidationPopup(false)}
                errors={floorPlanValidationErrors}
                existingUnitPlans={existingUnitPlans}
              />
            )}
          </div>
          {dotsPopupShown && (
            <ImageAssetsEditor
              onClose={() => {
                setDotsPopupShown(false)
              }}
              showRoomName
              onSave={(assets) => {
                setPositionedAssets(assets)
                setDotsPopupShown(false)
              }}
              showRooms
              coreAddonModeSupport={formValues.package}
              image={image}
              photo={image ? null : { src: previewImageURL }}
              assets={assets}
              addedAssets={positionedAssets}
            />
          )}
          <div className="flex justify-end my-[32px]">
            <Button
              disabled={!isFormValid() || submitted}
              rounded
              loading={submitted}
              size="small"
              onClick={handleUpload}
            >
              {submitted ? 'Uploading ...' : 'Upload'}
            </Button>
          </div>
        </div>
      )}
      {resultValue && (
        <ActionSuccessModal
          action="Floor Plan"
          state={resultValue}
          skipConfirm={editPlan}
          onClose={() => {
            setResultValue(null)
          }}
          onConfirm={resetForm}
        />
      )}
    </div>
  )
}

export default FloorPlansUpload
