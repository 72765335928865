import logo from 'shared/assets/images/sparx-logo.png'

export default function Form({ children, text }) {
  return (
    <div className="flex flex-col items-center w-full h-full lg:h-auto lg:w-[500px] p-[24px] lg:py-[68px] lg:px-[54px] bg-white font-light lg:font-normal">
      <img src={logo} className="mt-[110px] lg:mt-0 h-[21px] lg:h-[29px]" alt="sparx-logo" />
      <div className="text-center text-[18px] leading-[24px] mt-[32px] lg:mt-[48px]">{text}</div>
      {children}
    </div>
  );
}
