import React from 'react'
import { ReactComponent as CloseIcon } from 'shared/assets/icons/cross-small-crm-modal.svg'

function TinyModal({ children, closeIcon, className = '', light, onClose, ...props }) {
  return (
    <div
      onClick={()=>{onClose && onClose()}}
      className={`${
        light ? 'bg-[rgba(229,231,237,.7)]' : 'bg-[rgba(0,0,0,.3)]'
      }  z-[500] p-[16px] fixed top-0 left-0 h-screen w-screen flex items-center justify-center`}
    >
      <div
      onClick={e=>e.stopPropagation()}
        className={`bg-white w-full lg:w-[343px] rounded-[10px] px-[24px] relative  py-[40px] flex flex-col items-center justify-center ${className}`}
      >
        {closeIcon && onClose && <button type="button" onClick={onClose} className='absolute top-[20px] right-[20px] p-[5px]'><CloseIcon/></button>}
        {children}
      </div>
    </div>
  )
}

export default TinyModal
