import React, { useState, useMemo } from 'react'
import { useMediaQuery } from '@react-hook/media-query'

import { useStore } from 'IFrameApp/store'
import { events, iframe } from 'shared/analytics'
import FloorPlansLayout from "shared/components/FloorPlansLayout"

export default function FloorPlansScreen() {
  const { tourData } = useStore()
  const {unit} = tourData
  const [currentFloor, setFloor] = useState(0)
  const [showModal, toggleModal] = useState(false)
  const isMobile = useMediaQuery('(max-width: 1023px)')

  function getFloorPlansProps(_, floorIndex) {
    const { vendor: currentFloorPlanVendor } = floorPlans[currentFloor]
    const props = {
      floorplan_design_id: currentFloorPlanVendor?.id,
      floorplan_design_name: currentFloorPlanVendor?.full_name || 'Unfurnished'
    }
    if (floorIndex !== undefined) {
      const { vendor: newFloorPlanVendor } = floorPlans[floorIndex]
      props.new_floorplan_design_id = newFloorPlanVendor?.id
      props.new_floorplan_design_name = newFloorPlanVendor?.full_name || 'Unfurnished'
    }
    return props
  }


  const openModal = () => {
    if (isMobile) {
      toggleModal(true)
      iframe(events.fpdeo, getFloorPlansProps)
    }
  }

  const closeModal = () => {
    iframe(events.fpdec, getFloorPlansProps)
    toggleModal(false)
  }

  const floorPlans = useMemo(() => {
    return unit ? unit.unit_type.unit_type_levels[0].unit_plans.sort((i) => (!!i.vendor?.full_name ? 1 : -1))
      .map((item, index) => ({
        ...item,
        vendor: {
          full_name: item.vendor ? `Design ${index}` : null,
          id: item?.vendor?.id || null
        }
      })) : []
  }, [unit])

  function onFloorPlanChange(floorIndex) {
    setFloor(floorIndex)
    iframe(events.fpdc, () => getFloorPlansProps('', floorIndex))
  }

  return (
    <FloorPlansLayout
      {...{
        unit,
        floorPlans,
        currentFloor,
        onFloorPlanChange,
        showModal,
        openModal,
        closeModal,
        chipClassNames: '!h-[40px] lg:!h-[30px] !text-[14px] lg:!text-[16px]',
        activeItemClassNames: '!border-[#5F6787] !bg-[#5F6787]',
        isST: true
      }}
    />
  )
}
