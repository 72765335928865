import { ReactComponent as PackageFavouriteIcon } from 'shared/assets/icons/package-favourite.svg'
import { ReactComponent as PackageFavouriteEmptyIcon } from 'shared/assets/icons/package-favourite-empty.svg'
import { ReactComponent as PackageFavouriteEmptyIconBig } from 'shared/assets/icons/package-favourite-empty-big.svg'
import { ReactComponent as AssetAdd } from 'shared/assets/icons/resident-asset-add.svg'
import { ReactComponent as AssetRemove } from 'shared/assets/icons/resident-asset-remove.svg'
import { ReactComponent as AssetRemoveBuy } from 'shared/assets/icons/resident-asset-remove-buy.svg'
import { ReactComponent as AssetSelected } from 'shared/assets/icons/resident-asset-selected.svg'
import { ReactComponent as AssetTrashIcon } from 'shared/assets/icons/asset-trash-icon.svg'
import { ReactComponent as AssetTrashIconBig } from 'shared/assets/icons/asset-trash-icon-big.svg'
import { ReactComponent as QuantityPlus } from 'shared/assets/icons/quantity-plus.svg'
import { ReactComponent as QuantityMinus } from 'shared/assets/icons/quantity-minus.svg'
import { ReactComponent as QuantityPlusBig } from 'shared/assets/icons/quantity-plus-big.svg'
import { ReactComponent as QuantityMinusBig } from 'shared/assets/icons/quantity-minus-big.svg'
import { ReactComponent as ImgIcon } from 'shared/assets/icons/img.svg'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import { Popover } from 'react-tiny-popover'
import PackageAssetPopover from 'Resident/components/AssetPopover'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'shared/hooks/usePrevious'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { group6 } from 'shared/analytics/resident'

export function PackageItem({
  checkout,
  onClick,
  minimal,
  index,
  item,
  onAdd,
  onHover,
  assetPopup,
  summaryMode,
  onUpdate,
  hoveredAsset,
  onRemove,
  pack,
  separatorIndex,
  big,
  rent = false,
  ...props
}) {
  const [quantityEditingMode, setQuantityEditingMode] = useState(false)
  const [quantityEditingModeTimeout, setQuantityEditingModeTimeout] = useState(null)
  const [hovered, setHovered] = useState()
  const [timeoutId, setTimeoutId] = useState(null)
  const separatorClassList =
    "after:content-[''] after:block after:h-full after:w-[2px] after:bg-[#C7CCD9] after:absolute after:top-0 after:right-[-16px] lg:after:right-[-16px]"

  function isCore() {
    return item.type === 'core'
  }

  function isAdded() {
    return item.added
  }

  const name = (item.category || {}).category_name || (item.asset || {}).asset_name
  const image = (item.category || {}).image_path || (item.asset || {}).thumbnail

  const dimensions = useMemo(() => {
    if (!item.category) {
      return
    }
    const asset = item.category.assets[0] || null
    if (asset) {
      const { height, width, depth } = asset
      return { height, width, depth }
    } else {
      return null
    }
  }, [item])

  function onAssetPick(asset) {
    if (asset) {
      setHovered(true)
      clearTimeout(timeoutId)
    } else {
      const interval = setTimeout(() => {
        setHovered(false)
      }, 100)
      setTimeoutId(interval)
    }

    onHover && onHover(asset ? item : null)
  }
  
  useEffect(() => {
    if (!item.selectedCount && onUpdate) {
      item.selectedCount = item.count
      onUpdate({ selectedCount: item.count })
    }
  }, [item, onUpdate])

  const resetEditingModeTimeout = useCallback(()=>{
    if(quantityEditingModeTimeout){
      clearTimeout(quantityEditingModeTimeout)
    }
    setQuantityEditingMode(true)
    setQuantityEditingModeTimeout(setTimeout(() => {
      setQuantityEditingMode(false)
    }, 5000))
  }, [quantityEditingModeTimeout])

  useEffect(()=>{
    if(item.recentlyAdded && !quantityEditingMode){
      resetEditingModeTimeout()
      onUpdate({ recentlyAdded: false })
    }
  }, [item, quantityEditingMode, onUpdate, resetEditingModeTimeout])

  const opacity = useMemo(() => {
    if(!hoveredAsset){
      return ''
    }
    if(hoveredAsset && hoveredAsset.identifier === item.identifier && item.type === hoveredAsset.type){
      return ''
    } else {
      return 'opacity-50'
    }
  }, [hoveredAsset, item])

  return (
    <Popover
      isOpen={hovered && assetPopup && assetPopup === item.id}
      containerClassName="z-[200]"
      padding={17}
      positions={['top']} // preferred positions by priority
      content={
        pack &&
        item && (
          <PackageAssetPopover
            dimensions={dimensions}
            rent={pack.vendor.is_rental}
            vendor={pack.vendor.full_name}
            asset={item}
            onMouseEnter={() => {
              onAssetPick(item)
            }}
            onMouseLeave={() => {
              onAssetPick(null)
            }}
          />
        )
      }
    >
      <div
        className={`${opacity} ${big ? 'w-[150px]' : 'w-[85px] lg:w-[100px]'}  transition-all ${
          separatorIndex + 1 === index + 1 ? 'ml-[16px]' : ''
        }`}
        onMouseEnter={() => {
          onAssetPick(true)
        }}
        onMouseLeave={() => {
          onAssetPick(null)
        }}
        onClick={(e) => {
          onClick && onClick(item)
        }}
      >
        <div
          className={`${big ? 'h-[150px] w-[150px]' : `h-[85px] lg:h-[100px] w-[85px] lg:w-[100px]`}  relative  ${
            separatorIndex === index + 1 && !summaryMode ? separatorClassList : ''
          }`}
        >
          <div
            className={`h-full w-full rounded-[4px] overflow-hidden ${
              isCore(item) || isAdded() ? `bg-[rgba(100,192,179,0.25)]` : `bg-[rgba(199,204,217,0.3)]`
            }`}
          >
            {image && <img src={getCdnAssetUrl(image)} alt="asset" className="h-full object-cover" />}
            {!image && (
              <div className="h-full w-full bg-[#bbbbbb] flex items-center justify-center">
                <ImgIcon />
              </div>
            )}
          </div>
          {isCore() && (
            <div className={`absolute top-[8px] right-[8px] ${big ? 'h-[30px] w-[30px]' : 'h-[20px] w-[20px]'} flex items-center justify-center bg-[#A93DCB] rounded-full`}>
              {big ? <PackageFavouriteEmptyIconBig /> : <PackageFavouriteEmptyIcon />}
            </div>
          )}
          {!summaryMode && !checkout && isAdded() && (
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                onRemove(e)
              }}
              className={`absolute top-[8px] right-[8px] ${big ? 'h-[30px] w-[30px]' : 'h-[20px] w-[20px]'} flex items-center justify-center bg-[#3D4256] rounded-full hover:opacity-90`}
            >
              {big ? <AssetTrashIconBig/> : <AssetTrashIcon />}
              {/* {rent ? <AssetRemove /> : <AssetRemoveBuy />} */}
            </button>
          )}
          {!checkout && !isAdded() && !isCore() && (
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                onAdd(e)
              }}
              className={`absolute top-[8px] right-[8px] ${big ? 'h-[30px] w-[30px]' : 'h-[20px] w-[20px]'} flex items-center justify-center bg-[#3D4256] rounded-full hover:opacity-90`}
            >
               {big ? <QuantityPlusBig /> : <QuantityPlus />}
            </button>
          )}
          {checkout && isAdded() && !isCore() && (
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation()
              
                onRemove(e)
              }}
              className="absolute top-[3px] right-[3px] p-[5px]"
            >
              <AssetSelected />
            </button>
          )}
          {(item.added || isCore()) && <button
              type="button"
              disabled={!item.added}
              onClick={() => {
                resetEditingModeTimeout()
              }}
              className={`absolute top-[8px] left-[8px] ${
                isCore() ? 'bg-[#A93DCB]' : 'bg-[#3D4256]'
              } ${summaryMode ? 'pointer-events-none' : ''} text-white ${big ? 'min-w-[41px]' : 'min-w-[27px]'} w-max ${big ? 'text-[16px]' : 'text-[12px]'} rounded-full ${big ? 'h-[30px]' : 'h-[20px]'} px-[4px] flex items-center justify-center font-medium`}
            >
              ×{item.selectedCount}
            </button>}
          {!isCore() && (
            <div
              onClick={() => {
                setQuantityEditingMode(false)
              }}
              className={`h-full w-full absolute top-0 left-0 overflow-hidden rounded-[6px] max-h-0 ease-in transition-[max-height] ${
                quantityEditingMode ? 'max-h-[150px] duration-[250ms]' : 'duration-[500ms]'
              } `}
            >
              <div
                className={`bg-[rgba(171,177,197,0.3)] backdrop-blur-xl flex justify-between items-center w-full px-[6px] ${big ? ' h-[50px]' : 'h-[32px]'} rounded-t-[6px] overflow-hidden`}
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  type="button"
                  disabled={item.selectedCount === 1}
                  onClick={(e) => {
                    e.stopPropagation()
                    props.onMinusCountAssetClick && props.onMinusCountAssetClick()
                    resetEditingModeTimeout()
                    onUpdate({ selectedCount: item.selectedCount - 1 })
                  }}
                  className={`${big ? 'h-[30px] w-[30px]' : 'h-[20px] w-[20px]'} flex items-center justify-center bg-[#3D4256] rounded-full  ${item.selectedCount === 1 ? 'opacity-50' : 'hover:opacity-90'}`}
                >
                  {big ? <QuantityMinusBig /> : <QuantityMinus />}
                  {/* {rent ? <AssetRemove /> : <AssetRemoveBuy />} */}
                </button>
                <div>{item.selectedCount}</div> 
                <button
                  type="button"
                  disabled={item.selectedCount === 9}
                  onClick={(e) => {
                    e.stopPropagation()
                    props.onPlusCountAssetClick && props.onPlusCountAssetClick()
                    resetEditingModeTimeout()
                    onUpdate({ selectedCount: item.selectedCount + 1 })
                  }}
                  className={`${big ? 'h-[30px] w-[30px]' : 'h-[20px] w-[20px]'} flex items-center justify-center bg-[#3D4256] rounded-full  ${item.selectedCount === 9 ? 'opacity-50' : 'hover:opacity-90'}`}
                >
                  {big ? <QuantityPlusBig /> : <QuantityPlus />}
                  {/* {rent ? <AssetRemove /> : <AssetRemoveBuy />} */}
                </button>
              </div>
            </div>
          )}
        </div>
        {!minimal && (
          <>
            <div className="text-[14px] mt-[10px] font-medium two-line-truncate">{name || 'No name'}</div>
            {isCore() && <div className="text-[12px] mt-[8px] font-medium text-[#A93DCB]">Base Package</div>}
            {!isCore() && item.price ? (
              <div className={`text-[12px] mt-[8px] font-medium text-[#A93DCB]`}>
                <span className={`${isAdded() ? 'text-black' : 'text-[#5F6787]'}`}>
                  {`${!isCore() && isAdded() ? '' : '+'}`} ${(item.groupedPrice || (item.price * item.selectedCount)).toLocaleString('en-US')}
                </span>
                <span className={`${isAdded() ? 'text-black' : 'text-[#5F6787] opacity-70'}`}>{rent ? '/mo' : ''}</span>
              </div>
            ) : null}
          </>
        )}
      </div>
    </Popover>
  )
}
