export const USStates = [
    { short: 'AL', full: 'Alabama' },
    { short: 'AK', full: 'Alaska' },
    { short: 'AZ', full: 'Arizona' },
    { short: 'AR', full: 'Arkansas' },
    { short: 'CA', full: 'California' },
    { short: 'CO', full: 'Colorado' },
    { short: 'CT', full: 'Connecticut' },
    { short: 'DE', full: 'Delaware' },
    { short: 'FL', full: 'Florida' },
    { short: 'GA', full: 'Georgia' },
    { short: 'HI', full: 'Hawaii' },
    { short: 'ID', full: 'Idaho' },
    { short: 'IL', full: 'Illinois' },
    { short: 'IN', full: 'Indiana' },
    { short: 'IA', full: 'Iowa' },
    { short: 'KS', full: 'Kansas' },
    { short: 'KY', full: 'Kentucky' },
    { short: 'LA', full: 'Louisiana' },
    { short: 'ME', full: 'Maine' },
    { short: 'MD', full: 'Maryland' },
    { short: 'MA', full: 'Massachusetts' },
    { short: 'MI', full: 'Michigan' },
    { short: 'MN', full: 'Minnesota' },
    { short: 'MS', full: 'Mississippi' },
    { short: 'MO', full: 'Missouri' },
    { short: 'MT', full: 'Montana' },
    { short: 'NE', full: 'Nebraska' },
    { short: 'NV', full: 'Nevada' },
    { short: 'NH', full: 'New Hampshire' },
    { short: 'NJ', full: 'New Jersey' },
    { short: 'NM', full: 'New Mexico' },
    { short: 'NY', full: 'New York' },
    { short: 'NC', full: 'North Carolina' },
    { short: 'ND', full: 'North Dakota' },
    { short: 'OH', full: 'Ohio' },
    { short: 'OK', full: 'Oklahoma' },
    { short: 'OR', full: 'Oregon' },
    { short: 'PA', full: 'Pennsylvania' },
    { short: 'RI', full: 'Rhode Island' },
    { short: 'SC', full: 'South Carolina' },
    { short: 'SD', full: 'South Dakota' },
    { short: 'TN', full: 'Tennessee' },
    { short: 'TX', full: 'Texas' },
    { short: 'UT', full: 'Utah' },
    { short: 'VT', full: 'Vermont' },
    { short: 'VA', full: 'Virginia' },
    { short: 'WA', full: 'Washington' },
    { short: 'WV', full: 'West Virginia' },
    { short: 'WI', full: 'Wisconsin' },
    { short: 'WY', full: 'Wyoming' }
];