import ImagesUpload from 'MediaManager/components/ImagesUpload'
import ScreenLayout from 'MediaManager/layouts/ScreenLayout'
import React from 'react'

function ImagesScreen(props) {
  return (
    <ScreenLayout title="Upload Image">
      <ImagesUpload />
    </ScreenLayout>
  )
}

export default ImagesScreen
