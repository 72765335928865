import React from 'react'

export const EBedroomClassifications = {
  BEDROOM: 10,
  MASTER_BEDROOM: 7
}

function BedroomFilter({ picked = 'Queen Bed', onChange = () => {} }) {

  const options = [
    {
      label:'Queen Bed',
      value: 'Queen Bed'
    },
    {
      label:'Twin Bed',
      value: 'twin bed'
    },
    {
      label:'Opt Out',
      value: 'out'
    }
  ]

  return (
    <div className=" lg:bg-white lg:border-[1px] border-solid overflow-hidden border-[rgba(199,204,217,0.4)] mt-[20px] first:mt-0">
      <div className="h-[70px] lg:pr-[32px]">
        <div className="flex justify-between items-center h-full">
          <div className="h-full flex items-center">
            <div className="lg:ml-[24px] min-w-max">
              <div className="flex flex-col">
                <div className="text-[18px] lg:text-[20px] font-semibold">2nd Bedroom Configuration</div>
                <div className="text-[#C7CCD9] text-[14px] font-normal lg:hidden">Select an Option</div>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="text-[#C7CCD9] font-normal hidden lg:block">Select an Option</div>
            <div className="ml-[16px]">
              {options.map((i) => (
                <button
                  type="button"
                  onClick={() => {
                    onChange(i.value)
                  }}
                  className={`px-[30px] h-[40px] border-[1px] border-l-[1px] border-r-[0px] last:border-r-[1px] border-solid last:rounded-r-full first:rounded-l-full ${
                    i.value.toLowerCase().includes(picked.toLowerCase())
                      ? 'border-[#C7CCD9] text-[#3D4256] md:bg-[#fff] lg:bg-none'
                      : 'border-[#E5E7ED] text-[rgba(61,66,86,0.3)]'
                  }`}
                >
                  {i.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BedroomFilter
