export const generalStoreCreator = function (setter) {
  return {
    title: 'POST tour App',
    setTitle: (title) => {
      document.title = `${title} - SparX`
      setter(() => ({ title }))
    },
    skipAnalytics: !!sessionStorage.getItem("skipAnalytics"),
    setSkipAnalytics: (skipAnalytics) => {
      setter(() => ({ skipAnalytics }))
      if(skipAnalytics){
        console.warn(`Detected analytics skipping params. Analytics will be disabled for this session`)
        sessionStorage.setItem("skipAnalytics", '1')
      }
    },
    source: !!sessionStorage.getItem("source"),
    setSource: (source, skipCache) => {
      setter(() => ({ source }))
      if(source && !skipCache){
        sessionStorage.setItem("source", '1')
      }
    },
    isLineApp: !!sessionStorage.getItem("isLineApp"),
    setIsLineApp: (isLineApp, skipCache) => {
      setter(() => ({ isLineApp }))
      if(isLineApp && !skipCache){
        sessionStorage.setItem("isLineApp", '1')
      }
    },
    hideUnit: !!sessionStorage.getItem("hideUnit"),
    setHideUnit: (hideUnit) => {
      setter(() => ({ hideUnit }))
      if(hideUnit){
        sessionStorage.setItem("hideUnit", '1')
      }
    },
    isEngaged: false,
    setIsEngaged: (isEngaged) => {
      setter(() => ({ isEngaged }))
    },
  }
}
