export const EAppType = {
  MINIMAL: 'minimal',
  AGENT: 'agent',
  STANDART: 'standart',
  INSTANT: 'instant',
}

export const RPProductMap = {
  FAA: EAppType.AGENT,
  IARP: EAppType.INSTANT,
  RPCO: EAppType.MINIMAL,
  RP: EAppType.STANDART,
}

export const RPScreens = {
  DP: 'dp',
  GALLERY: 'gallery',
  CART: 'cart'
}