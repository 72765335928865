import { gql } from '@apollo/client'
import { query } from 'shared/helpers/graphql'
import { mutate } from 'shared/helpers/graphql'

export async function upsertProspect(payload) {
  return mutate(
    {
      prospects: [payload],
    },
    gql`
      mutation Upsert_prospects($prospects: [prospects_input]) {
        upsert_prospects(prospects: $prospects) {
          invitation_status
        }
      }
    `,
  )
}

export async function upsertUser(payload) {
  return mutate(
    {
      users: [payload],
    },
    gql`
      mutation Upsert_users($users: [users_input]) {
        upsert_users(users: $users) {
          first_name
          last_name
          email
          phone_number
          id
        }
      }
    `,
  )
}

export async function getProspect(userId) {
  return await query({
    variables: {
      userByIdId: userId,
    },
    query: gql`
      query Prospects($userByIdId: Int!) {
        user_by_id(id: $userByIdId) {
          prospects {
            id
            packages {
              id
              addons {
                id
                quantity
                package_asset {
                  id
                }
              }
              package {
                id
              }
            }
          }
        }
      }
    `,
  })
}
