import PackageRow from 'Resident/components/PackageRow'
import { useMemo, useState } from 'react'

// Icons

import { ReactComponent as ArrowDown } from 'shared/assets/icons/arrow-black-packages.svg'
import Button from 'shared/components/Button'
import PurchaseTypeTag from 'shared/components/PurchaseTypeTag'

import { useLocation, useNavigate } from 'react-router-dom'
import { useStore } from 'Resident/store'
import { ResultPopup } from 'shared/components/ResultPopup'

import { checkout, agentCheckout } from 'Resident/services/checkout'
import { useSplashError } from 'shared/components/ErrorSplash'
import { isAgentApp, isInstantApp, isStandartApp } from 'Resident/helpers/app'
import AgentForm from './components/AgentForm'
import InstantForm from './components/InstantForm'
import ResidentForm from './components/ResidentForm'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { group4 } from 'shared/analytics/resident'
import { useScreen } from 'Resident/hooks/useScreen'
import { RPScreens } from 'Resident/constants/appType'
import { useAnalytics } from 'shared/analytics/hooks/useAnalytics'

function CheckoutScreen(props) {
  const { packages, appType } = useStore()
  const location = useLocation()
  const navigate = useNavigate()
  const [cartSentResult, setCartSentResult] = useState(null)
  const { appData } = useStore()
  const splashError = useSplashError()
  const [cartSending, setCartSending] = useState(false)
  const [formValues, setFormValues] = useState({})

  useScreen(RPScreens.CART)  

  const pack = useMemo(() => {
    if (location.state.packageId && packages) {
      return packages.find((pack) => pack.id === location.state.packageId)
    }
  }, [packages, location])


  const { sendOnLoad } = useAnalytics()
  
  sendOnLoad(()=>{
    rpevent(eventDictionary.cart_screen_view, group4(pack))
  })

  function getApiEndpoint() {
    if (isAgentApp(appType)) {
      return agentCheckout
    }
    if (isInstantApp(appType)) {
      return checkout
    }
    if (isStandartApp(appType)) {
      return checkout
    }
  }

  async function onCartSend() {
    rpevent(eventDictionary.cart_screen_send_cart_tapped, group4(pack))
    const addons = pack.room_classifications.map((room) => room.package_assets.filter((asset) => asset.added)).flat()
    setCartSending(true)
    try {
      const api = getApiEndpoint()
      const { residentName, unitId, phoneNumber, email } = formValues
      await api(
        {
          user_id: appData.user.id,
          first_name: appData.user.first_name,
          last_name: appData.user.last_name,
          phone: appData.user.phone_number || phoneNumber,
          email: email,
          selected_package: {
            id: pack.id,
            selected_addons: addons.map((addon) => ({ id: addon.id, quantity: addon.selectedCount })),
          },
          resident_name: residentName,
          unit_id: unitId,
        },
        'cort',
      )
      setCartSentResult(true)
    } catch (error) {
      splashError.show()
    } finally {
      setCartSending(false)
    }
  }

  function onSuccessClose() {
    rpevent(eventDictionary.cart_screen_cart_sent_ok, group4(pack))
    window.history.back()
  }

  const successText = isAgentApp(appType) ? (
    'Order form has been sent successfully'
  ) : (
    <>
      <div className="lg:my-[24px] lg:px-[40px]">
        <div className="text-[18px] lg:text-[22px] lg:font-semibold">Your cart has been successfully sent to CORT</div>
        <br></br>
        <div className="font-normal text-[16px] lg:text-[18px] text-[#3D4256]">
          A representative will contact you within 24 business hours to finalize your product selection and arrange
          payment.
        </div>
      </div>
    </>
  )

  function isCheckoutDisabled() {
    if (cartSending) {
      return true
    }
    return !formValues.form || !formValues.form.isValid
  }

  return (
    <div className="h-full px-[25px] py-[25px] flex flex-col">
      <div className=" d-flex justify-between items-start mb-[36px] hidden lg:flex">
        <div className="text-[18px] lg:text-[24px] font-semibold  shrink-0 flex items-center">
          <button
            type="button"
            onClick={() => {
              rpevent(eventDictionary.cart_screen_back_tapped, group4(pack))
              navigate(-1)
            }}
            className="rotate-[-90deg] mr-[20px]"
          >
            <ArrowDown />
          </button>
          <span>{isAgentApp(appType) ? 'Cart' : 'Review Cart'}</span>
        </div>
        <Button
          rounded
          onClick={onCartSend}
          disabled={isCheckoutDisabled()}
          loading={cartSending}
          className="px-[30px]"
        >
          {isAgentApp(appType) ? 'Send Order Summary' : 'Send to CORT'}
        </Button>
      </div>
      <div className="lg:hidden min-w-max">
        <div className="flex items-center">
          <div className="text-[20px] font-semibold max-w-[80%] leading-[24px]">
            {isAgentApp(appType) ? 'Cart' : 'Review Cart'} - {pack.display_name || pack.name}
          </div>
        </div>
        <div className="flex items-center">
          <PurchaseTypeTag purchaseType="rent" />
          <div className="ml-[8px] font-medium text-[#5F6787]">
            by <span className="uppercase">Cort</span>
          </div>
        </div>
      </div>
      <>
        <div className="hidden lg:block">
          <PackageRow pack={pack} opened checkout />
        </div>
        <div className="lg:hidden my-[40px]">
          <PackageRow pack={pack} opened checkout minimal />
        </div>
        <div className="flex lg:mt-[40px] flex-col lg:flex-row pb-[70px]">
          {isStandartApp(appType) && <ResidentForm onChange={setFormValues} user={appData.user} />}
          {isAgentApp(appType) && <AgentForm onChange={setFormValues} pack={pack} />}
          {isInstantApp(appType) && <InstantForm onChange={setFormValues} />}
          {pack.terms_and_conditions && (
            <div className="mt-[40px] lg:mt-[5px] lg:w-1/2 text-[14px] lg:text-[12px] text-[#3D4256] pr-[16px] mb-[70px] lg:mb-[0] lg:pr-[50px]">
              <div className="lg text-[#C7CCD9] text-[16px] lg:text-[12px] lg:text-[#3D4256]">Terms and Conditions</div>
              <ul className="list-disc lg:ml-[16px] mt-[10px] list-inside lg:list-outside ml-[10px]">
                {pack.terms_and_conditions.tnc.map((item) => (
                  <li className="mt-[8px] first:mt-0 leading-[22px]">{item}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="lg:hidden fixed bottom-[40px] z-[100] w-[calc(100%-48px)]">
            <Button
              rounded
              className="w-full"
              onClick={onCartSend}
              disabled={isCheckoutDisabled()}
              loading={cartSending}
            >
              {isAgentApp(appType) ? 'Send Order Summary' : 'Send to CORT'}
            </Button>
          </div>
          {cartSentResult && (
            <ResultPopup
              type="success"
              text={successText}
              onClose={onSuccessClose}
              className="lg:min-w-[630px]"
              btnClassName="!w-[233px]"
            />
          )}
        </div>
      </>
    </div>
  )
}

export default CheckoutScreen
