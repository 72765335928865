import { useEffect, useState } from 'react'
import { ReactComponent as EyeSmall } from 'shared/assets/icons/eye-small.svg'
import { ReactComponent as EyeSmallViolet } from 'shared/assets/icons/eye-small-violet.svg'
import { ReactComponent as EyeMobile } from 'shared/assets/icons/eye-icon-mobile-crm-gray.svg'
import { ReactComponent as UserInviteIcon } from 'shared/assets/icons/invite-user-icon.svg'

import { ReactComponent as UserInviteIconViolet } from 'shared/assets/icons/invite-user-icon-violet.svg'
import { UnitVacantToggle } from '../UnitVacantToggle'
import { LineCopyButton } from '../LineCopy'
import { useStore } from 'CRM/store'
import { EUserType } from 'shared/enums/roles'
import { Link } from '@tanstack/react-location'
import FeatureGuard, { EFeatures } from 'shared/components/FeatureGuard'

import FurnitureAmenitiesInvite from '../FurnitureAmenitiesInvite'

export function UnitItem({ property, openPreview, unitType, unit }) {
  const [isVacant, setIsVacant] = useState(false)
  const { user } = useStore()
  const manageVacantUnitsAvailable = property.properties_configuration_options.manage_vacant_units

  //==========================================================================
  // Availability of editing vacant units for user types
  //==========================================================================
  const userType = user.user_type.user_type
  const manageVacantUnitsEnabled = ![
    EUserType.PROPERTY_VIEWER,
    EUserType.RE_COMPANY_MANAGER_VIEWER,
    EUserType.REAL_ESTATE_COMPANY_MANAGER,
  ].includes(userType)

  useEffect(() => {
    setIsVacant(unit.vacant)
  }, [unit.vacant])

  const { furniture_as_amenity_ready } = unitType.unit_types_configuration_options

  return (
    <div
      className={`border-t-[1px] lg:border-[1px] border-solid ${
        isVacant && manageVacantUnitsAvailable ? 'lg:border-[#16C270]' : 'lg:border-[#5F6787] '
      } text-[18px] font-medium rounded-[3px] bg-white p-[16px] gap-[20px]`}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {manageVacantUnitsAvailable && (
            <UnitVacantToggle
              onToggle={(vacant) => {
                setIsVacant(vacant)
              }}
              disabled={!manageVacantUnitsEnabled}
              unitType={unitType}
              unit={unit}
              property={property}
              vacant={isVacant}
            />
          )}
          <div>#{unit.unit_number}</div>
        </div>
        <div className="flex items-center h-[24px] lg:h-auto">
          <FeatureGuard feature={EFeatures.INVITES} data={{ property }}>
            <Link
              to="/resident-invites/add"
              search={{ unit: unit.id, building: unit.building_name }}
              className="mr-[16px] group"
            >
              <div className="group-hover:hidden">
                <UserInviteIcon />
              </div>
              <div className="hidden group-hover:block">
                <UserInviteIconViolet />
              </div>
            </Link>
          </FeatureGuard>
          <FeatureGuard feature={EFeatures.FA} data={{ property }}>
            {furniture_as_amenity_ready && <div className="mr-[16px]">
              <FurnitureAmenitiesInvite user={user} unit={unit} />
            </div>}
          </FeatureGuard>
          <button
            className="group"
            onClick={() => {
              openPreview(unit)
            }}
          >
            <div className="hidden lg:group-hover:block">
              <EyeSmallViolet />
            </div>
            <div className="lg:group-hover:hidden">
              <span className="hidden lg:inline-block">
                <EyeSmall />
              </span>
              <span className="lg:hidden">
                <EyeMobile />
              </span>
            </div>
          </button>
          <LineCopyButton unit={unit} copyTooltip />
        </div>
      </div>
    </div>
  )
}
