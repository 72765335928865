import React, { useEffect, useRef, useState } from 'react'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import { ReactComponent as PlayIcon } from 'shared/assets/icons/resident-play-big.svg'
import { ReactComponent as PlayIconSmall } from 'shared/assets/icons/resident-play-small.svg'
import { isSafari } from 'shared/helpers/browser'

function Video({ video, activeSlideId, onStateChange =  () => {} }) {
  const videoRef = useRef()
  const [playing, setPlaying] = useState()
  const [videoRatio, setVideoRatio] = useState({width: 0, height: 0})

  function play() {
    setPlaying(true)
    videoRef.current.play()
  }

  useEffect(()=>{
    videoRef.current.pause()
  }, [activeSlideId])

 
  function calculateAspectRatio(img){
    function aspectRatio(image) {
      const height = image.naturalHeight
      const width = image.naturalWidth
      const gcd = (...arr) => {
        const _gcd = (x, y) => (!y ? x : gcd(y, x % y))
        return [...arr].reduce((a, b) => _gcd(a, b))
      }
  
      const gcdResult = gcd(width, height)
  
      return {
        width: width / gcdResult,
        height: height / gcdResult,
      }
    }
    if(img){
      setVideoRatio(aspectRatio(img))
    }
  }

  return (
    <>
       <div className={`h-full w-full flex items-center justify-center relative ${!playing ? 'z-[2]' : `z-[1] h-0 w-0 overflow-hidden`}`} onClick={play}>
          <button type="button" className="absolute">
            <em className="hidden lg:inline-block">
              <PlayIcon />
            </em>
            <em className="lg:hidden">
              {' '}
              <PlayIconSmall />
            </em>
          </button>
          <img onLoad={e=>calculateAspectRatio(e.target)}  src={getCdnAssetUrl(video.thumbnail)} className="max-h-full object-contain" alt={video.thumbnail} />
        </div>
      <video
        onLoadStart={(e) => {
          e.target.volume = 0.2
        }}
        ref={videoRef}
        className={`max-h-full ${isSafari() ? 'w-full block' : ''} object-contain relative  ${!playing ? 'opacity-0 z-[1] h-0 w-0' : 'z-[2]'}`}
        style={{ aspectRatio: videoRatio.width / videoRatio.height }}
        controls
        poster={`${getCdnAssetUrl(video.thumbnail)}`}
        preload="auto"
        onPlay={()=>{onStateChange({isPlaying: true})}}
        onPause={()=>{onStateChange({isPlaying: false})}}
        src={getCdnAssetUrl(video.src)}
      />
    </>
  )
}

export default Video
