import { Link } from '@tanstack/react-location'
import AuthLayout from 'Resident/layouts/AuthLayout'
import FooterLayout from 'Resident/layouts/FooterLayout'
import React, { useState } from 'react'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
// Icons
import { ReactComponent as CodeSentIcon } from 'shared/assets/icons/resident-code-sent.svg'

const ESignupStages = {
  EMAIL: 'EMAIL',
  CODE_SENT: 'CODE_SENT',
}

function SignupScreen(props) {
  const [stage, setStage] = useState(ESignupStages.CODE_SENT)

  function isStage(target) {
    return stage === target
  }

  return (
    <FooterLayout>
      <AuthLayout>
        {isStage(ESignupStages.EMAIL) && (
          <div className="h-full flex flex-col items-center w-full">
            <div className="font-medium text-center w-[280px] leading-[21px] my-[30px]">
              Please enter your email so we can verify it’s you
            </div>
            <form className="w-full flex flex-col justify-between flex-1">
              <div>
                <Input placeholder="Email" />
              </div>
              <div className="lg:mt-[110px]">
                <Button className="w-full mt-[16px]" rounded>
                  Continue
                </Button>
              </div>
            </form>
          </div>
        )}
        {isStage(ESignupStages.CODE_SENT) && (
          <div className="h-full flex flex-col items-center justify-center w-full text-center">
            <CodeSentIcon />
            <div className="mt-[28px] px-[15px]">
              A verification email has been sent to your email, please check your inbox in the next few minutes to
              continue.
            </div>
            <a className="underline font-semibold mt-[48px]" href="#">
              I didn’t get an email
            </a>
          </div>
        )}
      </AuthLayout>
    </FooterLayout>
  )
}

export default SignupScreen
