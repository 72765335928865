import React from 'react';

function ContentIndicator({activeSlide, dotsLabel, slides, carousel}) {

    function getLevelLabel() {
        if (!activeSlide.level || !activeSlide.level.display_name) {
          return ''
        }
        if (dotsLabel) {
          return `(${activeSlide.level.display_name})`
        } else {
          return activeSlide.level.display_name
        }
      }

    return (
        <div className="absolute bottom-[32px] lg:bottom-[24px] left-0 w-full items-center justify-center z-[100] flex flex-col">
            <div>
              {' '}
              {(dotsLabel || activeSlide.level) && getLevelLabel() && (
                <div className="mb-[8px] text-[14px] lg:text-[12px] text-white bg-[rgba(0,0,0,0.3)] px-[10px] py-[2px] rounded-full">
                  {dotsLabel} {getLevelLabel()}
                </div>
              )}
            </div>
            <div className="flex items-center justify-center bg-[rgba(0,0,0,0.3)] px-[10px] py-[4px] rounded-full">
              {slides.length > 1 &&
                slides.map((item, index) => (
                  <div
                    onClick={() => carousel.slideTo(index, 400)}
                    className={`rounded-full ${
                      activeSlide.index === index ? 'bg-white h-[8px] w-[8px]' : 'bg-[#DDE1EA] h-[4px] w-[4px] cursor-pointer'
                    } ml-[8px] first:ml-[0]`}
                  />
                ))}
            </div>
          </div>
    );
}

export default ContentIndicator;