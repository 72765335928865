import React from 'react'
import { useSwipeable } from 'react-swipeable'
import './styles.scss'
function BottomDrawer({ children, isOpen, onClose, className = '', title, backdrop = true }) {
  const handlers = useSwipeable({
    onSwipedDown: () => {
      onClose && onClose()
    },
    preventScrollOnSwipe: true,
  })

  return (
    <>
      {isOpen && backdrop && (
        <div className="fixed top-0 left-0 h-screen w-screen z-[400] bg-black opacity-30" onClick={onClose}></div>
      )}
      <div
        className={`bottom-drawer fixed bottom-0 left-0 z-[500] w-full ${
          isOpen ? 'max-h-full' : 'max-h-0'
        }  overflow-hidden transition-all ease-out duration-500 shadow`}
      >
        <div className={`bg-[#fff] p-[40px] pt-[0] rounded-t-[16px] ${className}`}>
          <div className="flex items-center justify-center bg-transparent w-full h-[36px] mb-[10px]" {...handlers}>
            <div className="absolute h-[4px] w-[32px] bg-[#E5E7ED] rounded-full"></div>
          </div>
          {title && <div className='px-[24px] truncate pb-[10px] text-[14px] text-[#C7CCD9]'>{title}</div>}
          {children}
        </div>
      </div>
    </>
  )
}

export default BottomDrawer
