import { useEffect, useState } from 'react'
import { Link, useNavigate } from '@tanstack/react-location'

import { useStore } from 'CRM/store'

import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import ErrorMessage from 'shared/components/ErrorMessage'
import FormWrapper from './components/FormWrapper'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { signin } from 'CRM/services/user'
import { setAuth } from 'CRM/helpers/auth'
import LoginLayout from 'CRM/layouts/LoginLayout'

export default function LogInScreen({ minimal = false, redirect }) {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { setTitle } = useStore()

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(),
    password: Yup.string().required(),
  })

  useEffect(() => {
    setTitle('Log In')
  }, [setTitle])

  async function onSubmit(values) {
    if (!validateForm()) {
      return
    }
    setLoading(true)
    try {
      const { data } = await signin(values.email, values.password)
      setAuth(data)
      navigate({ to: redirect || '/apartments' })
    } catch (error) {
      if (error.response.data.error) {
        formik.setFieldError(
          'email',
          `Looks like we cannot log you in. Please ensure the credentials are valid and that your email is registered at SparX.`,
        )
      }
    } finally {
      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema,
    // validateOnMount:true,
    onSubmit,
  })

  function validateForm() {
    if (!formik.values.email) {
      return false
    }
    if (formik.errors.email) {
      return false
    }
    if (!formik.values.password) {
      return false
    }
    if (formik.errors.password) {
      return false
    }
    return true
  }

  return (
    <FormWrapper
      text={
        <>
          Please enter your email address
          <br /> and password to log in
        </>
      }
    >
      <form
        className="w-full flex flex-col items-center justify-between lg:justify-start h-full"
        onSubmit={formik.handleSubmit}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full">
            <Input
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={() => formik.validateField('email')}
              value={formik.values.email}
              required
              placeholder="Email"
              error={formik.errors.email}
              className={`mt-[32px]`}
            />
            <ErrorMessage text={formik.errors.email} />
          </div>
          <div className="w-full mt-[16px]">
            <Input
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={() => formik.validateField('password')}
              value={formik.values.password}
              required
              placeholder="Password"
              error={formik.errors.password}
            />
            <ErrorMessage text={formik.errors.password} />
          </div>
          {!minimal && (
            <Link
              to="/reset-password"
              className={`underline text-[14px] lg:text-[16px] lg:text-[initial] text-[#5F6787] lg:self-end ${
                formik.errors.password ? 'mt-[12px]' : 'mt-[16px]'
              }`}
            >
              Forgot Password?
            </Link>
          )}
        </div>
        <div className="w-full flex flex-col items-center">
          <Button
            loading={loading}
            rounded
            type="submit"
            className={`w-full ${!minimal ? 'lg:w-[172px] mt-[56px]' : 'lg:w-full mt-[24px]'}`}
          >
            Log In
          </Button>
          {!minimal && (
            <div className="text-[14px] lg:text-[18px] leading-[24px] mt-[24px] text-[#5F6787] lg:text-[black] ">
              Not a user yet?{' '}
              <Link to="/signup" className="underline lg:font-semibold">
                Sign Up
              </Link>{' '}
              here
            </div>
          )}
        </div>
      </form>
    </FormWrapper>
  )
}
