import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Popover } from 'react-tiny-popover'
import { useMediaQuery } from '@react-hook/media-query'
import { useStore } from 'TourApp/store'
// Icons
import { ReactComponent as ImgLoader } from 'shared/assets/icons/imgBig.svg'
import { debounce } from 'shared/helpers/general'

// Image icons
import favouriteHeartDesktop from 'shared/assets/icons/favouriteHeartDesktop.png'
import favouriteHeartDesktopSelected from 'shared/assets/icons/favouriteHeartDesktopSelected.png'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import Loader from 'shared/components/Loader'
import AssetPopover from './components/AssetPopover'

function Point({ point, active, activePointIndex, zoom, pickedPoint,  pickedDesignLayout, pointStyle, onPopupShowChange, activePoint, ...props }) {
  const [popupShown, setpopupShown] = useState(false)
  const [timeoutId, setTimeoutId] = useState(null)
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  const shown = useMemo(() => {
    return popupShown || active
  }, [popupShown, active])

  function showPopup() {
    setpopupShown(true)
    onPopupShowChange(true)
    cancelPopupHideInterval()
  }

  function hidePopup() {
    const interval = setTimeout(() => {
      setpopupShown(false)
      onPopupShowChange(false)
    }, 100)
    setTimeoutId(interval)
  }

  function onMouseEnter() {
    showPopup()
    props.onMouseEnter && props.onMouseEnter()
  }
  function onMouseLeave() {
    hidePopup()
    props.onMouseLeave && props.onMouseLeave()
  }

  function cancelPopupHideInterval() {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
  }

  function isActive() {
    if(isDesktop){
      return isDesktop && shown
    } else {
      return active
    }
  }

  function isAssetActive() {
    return activePoint && activePoint.id === point.id
  }

  function isBlurred() {
    if (isDesktop) {
      return !shown && activePointIndex > -1
    } else {
      return !active && pickedPoint && pickedPoint.id !== point.id
    }
  }

  function renderDotContent() {
    return (
      <div
        className={`group cursor-pointer absolute flex justify-center items-center transition-opacity duration-150 ${
          isBlurred() && !isAssetActive() ? 'opacity-20' : ''
        } ${!point.is_favourite ? 'w-[24px] h-[24px]' : ''} ${!isActive() ? 'cursor-pointer' : ''}`}
        style={{
          left: `${point.left}px`,
          top: `${point.top}px`,
          transitionDuration: '300ms',
          ...pointStyle,
        }}
        {...props}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={props.onClick}
      >
        {point.is_favourite ? (
          <div className="h-[23px] w-[23px] flex justify-center items-center">
            {!isActive() ? (
              <img alt="" src={favouriteHeartDesktop} />
            ) : (
              <img alt="" src={favouriteHeartDesktopSelected} />
            )}
          </div>
        ) : (
          <div
            className={`${
              zoom ? 'w-[15px] h-[15px]' : 'w-[20px] h-[20px]'
            } rounded-full bg-[rgba(0,0,0,.3)] group shadow-[0px_2px_6px_rgba(0,0,0,0.5)] absolute border-solid border-[1px] border-[rgba(255,255,255, .7)] flex items-center justify-center`}
          >
            <div
              className={` lg:group-hover:w-[8px] lg:group-hover:h-[8px] ${
                isAssetActive() ? 'h-[8px] w-[8px]' : 'h-[4px] w-[4px]'
              } bg-[#fff] rounded-full`}
            ></div>
          </div>
        )}
      </div>
    )
  }

  return isDesktop ? (
    <Popover
      isOpen={isActive()}
      containerStyle={{ zIndex: 50 }}
      padding={10}
      positions={['right', 'top', 'left', 'bottom']}
      content={
        <AssetPopover
          onMouseEnter={showPopup}
          onMouseLeave={hidePopup}
          onClick={(e) => props.onClick(e, true)}
          layout={pickedDesignLayout}
          point={point || {}}
        />
      }
    >
      {renderDotContent()}
    </Popover>
  ) : (
    renderDotContent()
  )
}

function ImagePointMap({
  zoom,
  image,
  rawImageDisplay,
  pickedPoint,
  assets,
  hoveredPoint,
  showFavorites,
  wishlistPreview,
  pickedDesignLayout,
  showDots,
  isActive = false,
  carouselInstance,
  scale = 1,
  ...props
}) {
  const wrapRef = useRef(null)
  const imageRef = useRef(null)
  const [imageLoaded, setImageLoaded] = useState(false)
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const [points, setPoints] = useState([])

  const { overlayShown } = useStore()
  const calcLeftPosition = useCallback(
    (pos) => {
      const offsetLeft = (wrapRef.current.offsetWidth - imageRef.current.offsetWidth) / 2
      return (imageRef.current.offsetWidth * pos) / 100 + offsetLeft - (zoom ? 5 : 10)
    },
    [zoom],
  )

  const calcTopPosition = useCallback(
    (pos) => {
      return (imageRef.current.offsetHeight * pos) / 100 - (zoom ? 5 : 10) + imageRef.current.offsetTop
    },
    [zoom],
  )

  function onImageLoad(el) {
    setImageLoaded(true)
    debounce(calc, 100)()
  }

  const calc = useCallback(() => {
    if (image) {
      setPoints(() =>
        image.assets
          .filter((point) => point.y_axis <= 100 && point.x_axis <= 100)
          .map(({ asset, ...point }) => ({
            ...asset,
            left: calcLeftPosition(point.x_axis),
            top: calcTopPosition(point.y_axis),
            positioned: true,
            is_favourite: point.is_favourite,
          })),
      )
    }
  }, [calcLeftPosition, calcTopPosition, image])

  useEffect(() => {
    const recalculate = function () {
      calc()
    }
    window.addEventListener('resize', recalculate)
    return () => window.removeEventListener('resize', recalculate)
  }, [calc])

  useEffect(() => {
    if (imageRef) {
      const myObserver = new ResizeObserver((entries) => {
        calc()
      })
      myObserver.observe(wrapRef.current)
    }
  }, [wrapRef, calc])

  useEffect(() => {
    calc()
  }, [zoom, calc])

  function renderHoverOverlay() {
    return (
      <div
        className={`${
          overlayShown ? 'gradient-animate-in' : 'animate-out'
        } absolute top-0 left-0  w-full h-full flex flex-col justify-between`}
      >
        <div className="h-[82px] gradient-tb w-full"></div>
      </div>
    )
  }

  function calculateImageWidth(originalWidth, originalHeight, newHeight) {
    var aspectRatio = originalWidth / originalHeight
    var newWidth = newHeight * aspectRatio
    return newWidth
  }

  function getSizeClasses(image) {
    if (image && image.current && wrapRef && wrapRef.current && !isMobile) {
      const { naturalWidth, naturalHeight } = image.current
      if (
        calculateImageWidth(naturalWidth, naturalHeight, wrapRef.current.clientHeight) > wrapRef.current.clientWidth
      ) {
        return 'max-h-full w-full'
      } else {
        return 'h-full w-auto'
      }
    }
    return 'max-h-full w-auto'
  }

  useEffect(() => {
    if (rawImageDisplay && image.raw_image) {
      setImageLoaded(false)
    }
  }, [rawImageDisplay, image])

  const activePointIndex = useMemo(() => {
    const activePointId = (hoveredPoint || pickedPoint || {}).id
    return points.findIndex((point) => point.id === activePointId)
  }, [pickedPoint, hoveredPoint, points])

  const [pointsPopupShown, setPointsPopupShown] = useState(false)

  function isPointActive(point, pointIndex) {
    if(isMobile){
      return pickedPoint && pickedPoint.id === point.id
    } else {
      return !pointsPopupShown && activePointIndex > -1 ? pointIndex === activePointIndex : false
    }
  }

  return (
    <div
      className="relative h-full w-full z-1"
      ref={wrapRef}
      onClick={() => {
        props.onPointClick(null)
      }}
    >
      {rawImageDisplay && !imageLoaded && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="h-[100px] w-[100px] bg-[rgba(255,255,255,.8)] rounded-xl flex items-center justify-center">
            <Loader />
          </div>
        </div>
      )}
      {renderHoverOverlay()}
      <div className="h-full flex items-center justify-center">
        <img
          src={getCdnAssetUrl(rawImageDisplay && image.raw_image ? image.raw_image : image.src)}
          alt="gallery"
          ref={imageRef}
          onLoad={onImageLoad}
          className={`inline-block object-contain ${getSizeClasses(imageRef)} ${
            !imageLoaded && !rawImageDisplay ? 'opacity-0 w-0' : ''
          }`}
        />
        {!rawImageDisplay && (
          <div
            className={`${
              imageLoaded ? 'hidden' : ''
            } aspect-[2.16/1] max-h-[473px] w-full bg-[#F5F5F8] flex-1 flex items-center justify-center`}
          >
            <ImgLoader />
          </div>
        )}
        {showDots && imageLoaded && isActive && (
          <div className={`absolute top-0 left-0 opacity-0 fadeShow animation-delay-150 `}>
            {points.every((point) => point.positioned) && (
              <div className="absolute top-0 left-0">
                {points
                  .map((point) => ({
                    ...point,
                    favorite: (assets.find(({ id }) => point.assetId === id) || {}).favorite,
                  }))
                  .map((point, index) => (
                    <Point
                      pointStyle={{
                        transform: `scale(${1 / scale})`,
                      }}
                      point={point}
                      key={index}
                      onPopupShowChange={setPointsPopupShown}
                      zoom={zoom}
                      pickedPoint={pickedPoint}
                      hoveredPoint={hoveredPoint}
                      activePoint={pickedPoint || hoveredPoint}
                      pickedDesignLayout={pickedDesignLayout}
                      showPopupTile={hoveredPoint && hoveredPoint.id === point.id}
                      active={isPointActive(point, index)}
                      activePointIndex={activePointIndex}
                      onMouseEnter={() => {
                        props.onPointHover(point, index)
                      }}
                      onMouseLeave={() => {
                        props.onPointHover(null)
                      }}
                      onClick={(e, isPopoverAction) => {
                        e.stopPropagation()
                        props.onPointClick(point, isPopoverAction, e)
                      }}
                    />
                  ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ImagePointMap