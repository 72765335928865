export const galleryStoreCreator = function (setter) {
  return {
    galleryState: {},
    setGalleryState: (payload) => {
      setter(({ galleryState }) => ({ galleryState: { ...galleryState, ...payload } }))
    },
    galleryPreviewMode: {},
    setGalleryPreviewMode: (previewMode) => {
      setter(() => ({ galleryPreviewMode: previewMode }))
    },
    overlayShown: false,
    setOverlayShown: (overlayShown) => {
      setter(() => ({ overlayShown }))
    },
  }
}
