import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'
import { appS3Bucket } from 'shared/helpers/assets'
import { appEnvPrefix } from 'shared/helpers/general'
import { addTimestampToPath } from './general'
import { apiClient } from 'shared/helpers/api'


export async function generatePresignedUrl(objKey){
  const {data} = await apiClient.post(`/api/resource/${objKey}`, {bucket: 'sparx-cdn'})
  return data
}

export function uploadFileToGCP(file, signedUrl){
  return fetch(signedUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'PUT',
      'Access-Control-Allow-Headers': 'Content-Type',
    },
    body: file,
  })
}


export async function uploadFileToS3(file, rawPath) {

  // Automatic timestamp adding, to avoid overwriting already uploaded assets
  const path = addTimestampToPath(rawPath) 
  
  try {

    const key = `${appEnvPrefix()}/${path}`

    const signedUrl = await generatePresignedUrl(key)
    await uploadFileToGCP(file, signedUrl)

    // const { bucket, region } = appS3Bucket

    // const client = new S3Client({
    //   region: region,
    //   apiVersion: '2012-10-17',
    //   credentials: {
    //     accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    //     secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    //   },
    // })

    // const params = {
    //   Bucket: bucket,
    //   Key: `${appEnvPrefix()}/${path}`,
    //   ContentType: file.type,
    //   Body: file,
    // }
    // const command = new PutObjectCommand(params)
    // await client.send(command)
    return `${appEnvPrefix()}/${path}`
  } catch (error) {
    console.error(error)
    throw new Error(`Error uploading ${path}`)
  }
}
