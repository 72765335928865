import * as React from 'react'
const UnitIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={18} fill="none" {...props}>
    <path
      stroke={props.stroke || `#5F6787`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M9.965 15.628h3.138c.495 0 .897-.41.897-.914V3.286a.906.906 0 0 0-.897-.914H9.965v13.256Z"
    />
    <path
      fill={props.stroke || `#5F6787`}
      d="M8.397 9.458a.906.906 0 0 1-.897.914.906.906 0 0 1-.896-.914c0-.505.401-.914.896-.914s.897.41.897.914Z"
    />
    <path
      stroke={props.stroke || `#5F6787`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M8.936 1.01 1 2.225v13.55l7.936 1.215a.904.904 0 0 0 1.03-.905V1.915a.903.903 0 0 0-1.03-.905Z"
    />
  </svg>
)
export default UnitIcon
