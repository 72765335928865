import { useMediaQuery } from '@react-hook/media-query'

import Modal from 'shared/components/Modal'
import ChipPicker from 'shared/components/ChipPicker'

import './styles.scss'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import { useMemo, useState } from 'react'
import ArrowButton from '../ArrowButton'
import DropdownSheet from '../DropdownSheet'
import ModelViewer from './ModelViewer'
import BottomDrawer from '../BottomDrawer'

export default function FloorPlansLayout({
  unit,
  floorPlans,
  currentFloor,
  onFloorPlanChange,
  onLevelChange,
  showModal,
  levelIndex,
  openModal,
  closeModal,
  levels = [],
  isST = false,
  chipClassNames = '',
  activeItemClassNames = '',
}) {
  const isMobileLandscape = useMediaQuery('(orientation: landscape) and (max-width: 1023px)')

  function prevFloorPlan() {
    if (currentFloor === 0) {
      onFloorPlanChange(floorPlans.length - 1)
    } else {
      onFloorPlanChange(currentFloor - 1)
    }
  }

  function nextFloorPlan() {
    if (currentFloor === floorPlans.length - 1) {
      onFloorPlanChange(0)
    } else {
      onFloorPlanChange(currentFloor + 1)
    }
  }

  const floorPlanNames = useMemo(() => {
    return floorPlans.map(
      (i) => `${i.vendor?.full_name || 'Unfurnished'}${i.display_name ? ` (${i.display_name})` : ''}`,
    )
  }, [floorPlans])

  const levelsDropdownOptions = useMemo(() => {
    return levels.map((level, index) => ({
      label: `${level.display_name || (level.floor_number === 0 ? 'Ground floor' : `Floor ${level.floor_number}`)}`,
      value: index,
    }))
  }, [levels])

  const zoomed = false

  const [activeDropdownSheet, setActiveDropdownSheet] = useState()

  return (
    <>
      {unit && (
        <div
          className={`w-full h-full relative  overflow-x-hidden bg-[#FAFAFA] ${zoomed ? 'lg:pt-0' : ''} ${
            isST ? ' ' : 'lg:pb-0'
          }`}
        >
          <div className="w-full flex flex-col h-full relative">
            <div className="flex px-[24px] absolute top-[16px] z-[30] w-full lg:hidden">
              {levels.length > 1 && (
                <>
                  <div className="w-full mr-[16px]">
                    <DropdownSheet
                      onToggle={setActiveDropdownSheet}
                      activeDropdownId={activeDropdownSheet}
                      isOpen={activeDropdownSheet === 'level'}
                      id="level"
                      title="Choose Level"
                      label={levelsDropdownOptions[levelIndex].label}
                      items={levelsDropdownOptions.map((l) => l.label)}
                      picked={levelsDropdownOptions[levelIndex].label}
                      onPicked={(_, i) => onLevelChange(i)}
                      columnView={isMobileLandscape}
                      classNames="landscape:lg:max-w-none  w-[calc(100%+18px)] lg:mb-[30px] lg:mb-0 pr-[18px]"
                      itemClassNames={`landscape:mb-[10px] ${chipClassNames}`}
                      activeItemClassNames={`${activeItemClassNames} !bg-black`}
                    />
                  </div>
                  <div className="w-full">
                    <DropdownSheet
                      onToggle={setActiveDropdownSheet}
                      activeDropdownId={activeDropdownSheet}
                      isOpen={activeDropdownSheet === 'design'}
                      id="design"
                      title="Choose design"
                      label={floorPlanNames[currentFloor]}
                      items={floorPlanNames}
                      picked={floorPlanNames[currentFloor]}
                      onPicked={(_, i) => onFloorPlanChange(i)}
                      columnView={isMobileLandscape}
                      classNames={`lg:h-[32px] landscape:lg:max-w-none mr-[-16px] lg:ml-[16px] w-[calc(100%+16px)] lg:mr-[-32px] lg:w-[calc(100%+32px)] mb-[30px] lg:mb-0 hidden lg:block`}
                      itemClassNames={`landscape:mb-[10px] ${chipClassNames}`}
                      activeItemClassNames={`${activeItemClassNames} !bg-black`}
                    />
                  </div>
                </>
              )}
              {levels.length === 1 && (
                <>
                  <ChipPicker
                    items={floorPlanNames}
                    picked={floorPlanNames[currentFloor]}
                    onPicked={(_, i) => onFloorPlanChange(i)}
                    columnView={isMobileLandscape}
                    classNames={`lg:h-[32px] landscape:lg:max-w-none mr-[-16px] lg:ml-[16px] w-[calc(100%+16px)] lg:mr-[-32px] lg:w-[calc(100%+32px)] mb-[30px] lg:mb-0`}
                    itemClassNames={`landscape:mb-[10px] ${chipClassNames}`}
                    activeItemClassNames={`${activeItemClassNames} !bg-black`}
                  />
                </>
              )}
            </div>
            <div
              className={` items-center hidden lg:flex z-[500] mt-[28px] ml-[16px] transition-all ${
                zoomed ? 'lg:absolute' : ''
              }`}
            >
              {' '}
              {levels.length > 1 && (
                <div className="border-r-[1px] border-solid border-[#C7CCD9] lg:pr-[16px]">
                  <DropdownSheet
                    onToggle={setActiveDropdownSheet}
                    activeDropdownId={activeDropdownSheet}
                    id="level"
                    isOpen={activeDropdownSheet === 'level'}
                    title="Level"
                    label={levelsDropdownOptions[levelIndex].label}
                    items={levelsDropdownOptions.map((l) => l.label)}
                    picked={levelsDropdownOptions[levelIndex].label}
                    onPicked={(_, i) => onLevelChange(i)}
                    columnView={isMobileLandscape}
                    classNames="landscape:lg:max-w-none  w-[calc(100%+18px)] lg:mb-[30px] lg:mb-0 pr-[18px]"
                    itemClassNames={`landscape:mb-[10px] ${chipClassNames}`}
                    activeItemClassNames={`${activeItemClassNames} !bg-black`}
                  />
                  {/* <Select  onChange={onLevelChange} options={levelsDropdownOptions} value={levelIndex} /> */}
                </div>
              )}
              <div className='flex-1 overflow-x-hidden '>
              <div className="w-full px-[18px] pr-0 flex items-center lg:hidden">
                <ChipPicker
                  items={floorPlanNames}
                  picked={floorPlanNames[currentFloor]}
                  onPicked={(_, i) => onFloorPlanChange(i)}
                  columnView={isMobileLandscape}
                  classNames="landscape:lg:max-w-none  w-[calc(100%+18px)] lg:mb-[30px] lg:mb-0 pr-[18px]"
                  itemClassNames={`landscape:mb-[10px] ${chipClassNames}`}
                  activeItemClassNames={`${activeItemClassNames} !bg-black`}
                />
              </div>
              <ChipPicker
                items={floorPlanNames}
                picked={floorPlanNames[currentFloor]}
                onPicked={(_, i) => onFloorPlanChange(i)}
                columnView={isMobileLandscape}
                classNames={`lg:h-[32px] landscape:lg:max-w-none mr-[-16px] lg:ml-[16px] w-[calc(100%+16px)] lg:mr-[-32px] lg:w-[calc(100%+32px)] mb-[30px] lg:mb-0 hidden lg:block`}
                itemClassNames={`landscape:mb-[10px] ${chipClassNames}`}
                activeItemClassNames={`${activeItemClassNames} !bg-black`}
              />
              </div>
            </div>
            <div className="h-full w-full absolute top-0 right-0">
              <ModelViewer unit_plan={floorPlans[currentFloor]} />
            </div>
          </div>
          {floorPlanNames.length > 1 && (
            <>
              <ArrowButton
                type="button"
                className="rotate-180 absolute top-[50%] mt-[-21px] lg:mt-[-18px] left-[20px] z-[10]"
                onClick={prevFloorPlan}
              />
              <ArrowButton
                type="button"
                className="absolute top-[50%] mt-[-21px] lg:mt-[-18px] right-[20px] z-[10]"
                onClick={nextFloorPlan}
              />
            </>
          )}
        </div>
      )}
      {showModal && (
        <Modal toggleModal={closeModal} showCloseBtn closeAnywhere>
          <img
            src={getCdnAssetUrl(floorPlans[currentFloor]?.src)}
            alt={floorPlans[currentFloor]?.vendor?.name}
            className="object-contain w-full h-[100%] absolute top-0"
          />
        </Modal>
      )}
    </>
  )
}
