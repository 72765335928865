import React, { useState } from 'react'

// Icons
import { ReactComponent as LetterSuccessIcon } from 'shared/assets/icons/letter-success.svg'
import { ReactComponent as LetterErrorIcon } from 'shared/assets/icons/letter-error.svg'
import Toggle from 'shared/components/Toggle'
import { Link, useNavigate } from '@tanstack/react-location'
import { ReactComponent as DeleteInviteIcon } from 'shared/assets/icons/delete-icon-mm.svg'
import { Modal } from 'MediaManager/components/Modal'
import TinyModal from 'shared/components/TinyModal'
import { deleteUnitPlans, deleteVideos } from 'MediaManager/services'
import { getCdnAssetUrl } from 'shared/helpers/assets'

const Modals = {
  VIDEO_DELETE: 'VIDEO_DELETE',
  FLOOR_PLANS_DELETE: 'FLOOR_PLANS_DELETE',
}

function TableRow({ unitType, property, onDelete, onUnitTypeConfigurationChange }) {
  const navigate = useNavigate()

  const [modal, setModal] = useState(null)

  function calculateImagesCount(unitType) {
    let count = 0

    unitType.unit_type_levels
      .map((l) => l.rooms)
      .flat()
      .forEach((room) => {
        room.room_designs.forEach((design) => {
          design.photos.forEach((photo) => {
            count++
          })
        })
      })
    return count
  }

  function calculateDesignsCount(unitType) {
    return unitType.unit_type_levels.map(l=>l.design_layouts).flat().length
  }

  function getUniqueVendorsCount(unitType) {
    const vendors = []

    unitType.unit_type_levels
      .map((l) => l.rooms)
      .flat()
      .forEach((room) => {
        room.room_designs.forEach((design) => {
          if (!vendors.includes(design.main_vendor.id)) {
            vendors.push(design.main_vendor.id)
          }
        })
      })
    return vendors.length
  }

  function getUnfurnishedUnitPlansCount(unitType) {
    return unitType.unit_type_levels
      .map((l) => l.unit_plans)
      .flat()
      .filter((plan) => !plan.vendor).length
  }

  function getVendorUnitPlansCount(unitType) {
    return unitType.unit_type_levels
      .map((l) => l.unit_plans)
      .flat()
      .filter((plan) => plan.vendor).length
  }

  function getFloorPlansStatus(unitType) {
    if (getUnfurnishedUnitPlansCount(unitType) < 1) {
      return false
    }
    if (getVendorUnitPlansCount(unitType) < getUniqueVendorsCount(unitType)) {
      return false
    }
    return true
  }

  function getImagesStatus(unitType) {
    const valids = []

    //==========================================================================
    // With designs
    //==========================================================================
    unitType.unit_type_levels
      .map((l) => l.rooms)
      .flat()
      .forEach((room) => {
        room.room_designs.forEach((design) => {
          const { photos, design_components } = design
          const availableAssets = design_components
            .map(({ assets }) => assets)
            .flat()
            .map(({ asset }) => asset)
          const photoAssets = photos
            .map(({ assets }) => assets)
            .flat()
            .map(({ asset }) => asset)

          const result = availableAssets.filter(({ id }) => !photoAssets.find((asset) => asset.id === id))

          valids.push(result.length === 0)
        })
      })
    //==========================================================================
    // Without designs
    //==========================================================================
    const emptyRooms = unitType.unit_type_levels
      .map((l) => l.rooms)
      .flat()
      .filter(
        (room) =>
          room.room_designs.length > 0 &&
          room.room_designs.every((design) =>
            design.design_components.every((component) => component.assets.length === 0),
          ),
      )
    valids.push(emptyRooms.every((room) => room.room_designs.some((design) => design.photos.length > 0)))

    return valids.every((val) => val)
  }

  function getFloorPlanLabel(p) {
    const label = `${p.vendor?.full_name || 'Unfurnished'}${p.display_name ? ` (${p.display_name})` : ''}`
    return label || p.src
  }

  function renderStatusIcon(unitType) {
    const statuses = [getFloorPlansStatus(unitType), getImagesStatus(unitType)]
    if (statuses.some((val) => !val)) {
      return <LetterErrorIcon />
    }
    return <LetterSuccessIcon />
  }

  function onFloorPlanClick(unitType) {
    navigate({ to: '/floorplans' })
  }

  async function onVideoDelete(video) {
    if (window.confirm(`Are you sure you want to delete video ${video.display_name || video.name}? `)) {
      await deleteVideos(video.id)
      onDelete()
    }
  }

  async function onFloorPlanDelete(plan) {
    if (window.confirm(`Are you sure you want to delete floor plan "${getFloorPlanLabel(plan)}"? `)) {
      await deleteUnitPlans(plan.id)
      onDelete()
    }
  }

  const { ready_for_publish, is_mirrored, supports_tour_page_emails } = unitType.unit_types_configuration_options

  function addNavigate(e, page){
    e.preventDefault()
    navigate({
      to: `/${page}?state=${btoa(
        JSON.stringify({ property: property, line: unitType.id}),
      )}`,
    })
  }

  const floorPlans = unitType.unit_type_levels.map((l) => l.unit_plans).flat()

  return (
    <>
      <tr>
        <td className="flex items-center">
          {renderStatusIcon(unitType)}
          <span className="ml-[12px]">
            {unitType.name}
            {is_mirrored ? ' (mirrored) ' : ''}
          </span>
        </td>
        <td>{calculateDesignsCount(unitType)}</td>
        <td
          // onClick={() => {
          //   onFloorPlanClick(unitType)
          // }}
          className={`${!getFloorPlansStatus(unitType) ? 'text-[#D32F2F]' : ''} flex items-center`}
        >
          <a href="#?" onClick={e=>addNavigate(e, 'floorplans')}>{`${getUnfurnishedUnitPlansCount(unitType)} + ${getVendorUnitPlansCount(unitType)}/${getUniqueVendorsCount(
            unitType,
          )}`}</a>
          {floorPlans.length > 0 && <button
            type="button"
            className="ml-5 text-black"
            onClick={(e) => {
              e.stopPropagation()
              setModal(Modals.FLOOR_PLANS_DELETE)
            }}
          >
            Edit
          </button>}
        </td>
        <td
          onClick={(e) => {
            unitType.videos.length > 0 ? setModal(Modals.VIDEO_DELETE) : addNavigate(e, 'featuredvideos')
          }}
        >
          {unitType.videos.length}
        </td>
        <td className={`${!getImagesStatus(unitType) ? 'text-[#D32F2F]' : ''}`}>
          <Link to={`/manage-line-images?propertyId=${property}&line=${unitType.id}`}>
            {calculateImagesCount(unitType)}
          </Link>
        </td>
        <td>
          <Toggle
            checked={ready_for_publish}
            onChange={() => {
              onUnitTypeConfigurationChange(unitType, { ready_for_publish: !ready_for_publish })
            }}
          />
        </td>
        <td>
          <Toggle
            checked={supports_tour_page_emails}
            onChange={() => {
              onUnitTypeConfigurationChange(unitType, { supports_tour_page_emails: !supports_tour_page_emails })
            }}
          />
        </td>
      </tr>

      <>
        {modal === Modals.VIDEO_DELETE && (
          <TinyModal
            onClose={() => {
              setModal(null)
            }}
          >
            <div className="text-2xl w-full mb-[20px]">Delete videos</div>
            <div className="max-h-[500px] overflow-y-auto w-full">
              {unitType.videos.map((v) => (
                <div className="flex w-full justify-between p-1 px-2 bg-[rgba(0,0,0,.1)] rounded-md mt-[10px] first:mt-[0]">
                  {v.display_name || v.name || v.src}
                  <button
                    type="button"
                    onClick={() => {
                      onVideoDelete(v)
                    }}
                  >
                    <DeleteInviteIcon />
                  </button>
                </div>
              ))}
            </div>
          </TinyModal>
        )}
        {modal === Modals.FLOOR_PLANS_DELETE && (
          <TinyModal
            className="lg:!w-max"
            onClose={() => {
              setModal(null)
            }}
          >
            <div className="text-2xl w-full mb-[20px]">Delete floor plans</div>
            <div className="max-h-[500px] overflow-y-auto w-full">
              {unitType.unit_type_levels
                .map((l) => l.unit_plans)
                .flat()
                .map((p) => (
                  <div className="flex w-full justify-between p-1 px-2 bg-[rgba(0,0,0,.1)] rounded-md mt-[10px] first:mt-[0] items-center">
                    <div className="flex items-center">
                      <img src={getCdnAssetUrl(p.src)} className="w-[200px]" />
                      <span className="mx-[32px]">{getFloorPlanLabel(p)}</span>
                    </div>

                    <div className='flex items-center'>
                      <button
                        type="button"
                        onClick={() => {
                          onFloorPlanDelete(p)
                        }}
                      >
                        <DeleteInviteIcon />
                      </button>
                      <Link className="ml-5" to={`/floorplans?plan=${p.id}`}>
                        Edit
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </TinyModal>
        )}
      </>
    </>
  )
}

export default TableRow
