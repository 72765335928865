import { ReactComponent as ModalCloseSmallIcon } from 'shared/assets/icons/modalCloseSmall.svg'
import { ReactComponent as WarningInfoIcon } from 'shared/assets/icons/infoSmall.svg'

export function Modal({ children, onClose, isWarning, title, className = '' }) {
  return (
    <div className="h-screen w-screen bg-white/80 absolute top-0 left-0 flex items-center justify-center z-50">
      <div className={`w-[50%] h-[80%] xl:h-[60%] bg-white shadow-lg overflow-hidden rounded-xl relative ${className} max-w-[680px]`}>
        <div className="flex items-center justify-between h-[50px] px-[24px]">
          <div className="text-lg font-medium">{title}</div>
          {isWarning && <WarningInfoIcon className='rotate-180 w-[27px] h-[27px]'/>}
          {onClose && <button onClick={onClose}>
            <ModalCloseSmallIcon />
          </button>}
        </div>
        <div className="h-[calc(100%-50px)] overflow-y-auto p-[24px] pt-[0]">{children}</div>
      </div>
    </div>
  )
}
