import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Zoom, Keyboard, Mousewheel } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/zoom'
import 'swiper/css/keyboard'

import './styles.scss'

export default function Carousel({
  items,
  navigation = false,
  slidesPerView = 1,
  initialSlide = 0,
  currentSlide = initialSlide,
  separatorIndex,
  separatorClassList,
  slideClassName = '',
  className = '',
  slidesRefs,
  ...props
}) {
  const [carouselInstance, setCarouselInstance] = useState()

  useEffect(() => {
    if (carouselInstance) {
      carouselInstance.slideTo(currentSlide, 400, false)
    }
  }, [currentSlide, carouselInstance])

  function onSwiperInit(instance) {
    props.onSwiper && props.onSwiper(instance)
    setCarouselInstance(instance)
  }

  return (
    <Swiper
      modules={[Navigation, Zoom, Keyboard, Mousewheel]}
      navigation={navigation}
      initialSlide={initialSlide}
      slidesPerView={slidesPerView}
      onSwiper={onSwiperInit}
      className={`${!props.autoHeight ? 'h-full' : ''} ${className}`}
      {...props}
    >
      {items.map((item, i) => (
        <SwiperSlide key={i} className={`${separatorIndex === i ? separatorClassList : ''} ${slideClassName}`}>
          {(data) => (
            <>
              {slidesRefs && (
                <em
                  ref={(ref) => (slidesRefs.current[i] = ref)}
                  className="hidden"
                  data-visible={data.isVisible ? 1 : 0}
                />
              )}
              {item}
            </>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
