import React from 'react'
import Footer from 'shared/components/Footer'
import sparxLogo from 'shared/assets/images/sparx-logo.png'
import { useStore } from 'Resident/store'
function FooterLayout({ children }) {
  const { appData } = useStore()

  return (
    <div className="h-full w-full">
      <div
        className={`${
          appData ? 'h-[calc(100%-62px)]' : 'h-full pb-[62px] lg:pb-0'
        } lg:min-h-[700px] lg:h-[calc(100%-30px)] w-full overflow-auto`}
      >
        {children}
      </div>
      <div className="hidden lg:block">
        <Footer />
      </div>
      {appData && (
        <div className={`lg:hidden flex items-center justify-center h-[62px]`}>
          <>
            Powered by <img src={sparxLogo} className="h-[16px] ml-[8px]" alt="sparx-logo" />
          </>
        </div>
      )}
    </div>
  )
}

export default FooterLayout
