import PersonalizedLinkModal from 'CRM/components/Modals/PersonalizedLink'
import { useEffect, useState } from 'react'
import { logDevelopment } from 'shared/helpers/log'
import { ReactComponent as ArrowRightPersonalize } from 'shared/assets/icons/arrow-right-small-white.svg'
import { ReactComponent as CheckmarkViolet } from 'shared/assets/icons/checkmark-violet-small.svg'
import { ReactComponent as CheckmarkVioletMobile } from 'shared/assets/icons/checkmark-violet-mobile.svg'
import { ReactComponent as CopySmall } from 'shared/assets/icons/copy-small.svg'
import { ReactComponent as CopySmallViolet } from 'shared/assets/icons/copy-small-violet.svg'
import { ReactComponent as CopyMobile } from 'shared/assets/icons/copy-icon-mobile-crm-gray.svg'
import { useStore } from 'CRM/store'
import { appEnvPrefix, generateUUID, isProductionEnv } from 'shared/helpers/general'
import { apartmentPageHost } from '../../helpers'
import { useMediaQuery } from '@react-hook/media-query'
import { toggleWrapScroll } from 'CRM/helpers/layout'
import { upsertApartmentPageInvitation } from 'CRM/services/invites'

export function LineCopyButton({ unit, icon, extra = '', className = '', copyTooltip = false, label, line }) {
  const { setLastCopiedUnit, lastCopiedUnit, selectedProperty, user } = useStore()
  const [personalizedModalShown, setPersonalizedModalShown] = useState(false)
  const [hideTimeout, setHideTimeout] = useState(null)
  const [tooltipShown, setTooltipShown] = useState(false)
  const isMobile = useMediaQuery('(max-width: 1023px)')
  async function onCopy(prospectName) {
    if (!personalizedModalShown) {
      openPersonalizedModal()
    }

    const invitation_id = prospectName ? generateUUID() : null;
   
    const uri = encodeURI(
      `https://${apartmentPageHost()}.sparx.ai/?property_id=${selectedProperty.id}&property_name=${selectedProperty.property_name}&unit_number=${
        unit.unit_number
      }&building_name=${unit.building_name}&agent_id=${user.id}${extra ? `&${extra}` : ''}${prospectName ? `&person=${prospectName}` : ''}${invitation_id ? `&invitation_id=${invitation_id}` : ''}`,
    )

    if(invitation_id){
      await upsertApartmentPageInvitation(invitation_id, uri)
    }

    try {
      logDevelopment(uri)
      navigator.clipboard.writeText(uri)
    } catch (error) {
      console.log(error)
      console.log(uri)
    }
  }

  function hideTooltipWithTimeout() {
    if (hideTimeout) {
      clearTimeout(hideTimeout)
    }
    const timeout = setTimeout(() => {
      setTooltipShown(false)
    }, 3000)
    setHideTimeout(timeout)
  }

  function openPersonalizedModal(e) {
    if(e){
      e.stopPropagation()
    }
    setPersonalizedModalShown(true)
    toggleWrapScroll()
    setTooltipShown(false)
    clearTimeout(hideTimeout)
  }

  useEffect(() => {
    if (lastCopiedUnit.id !== unit.id) {
      setTooltipShown(false)
    }
  }, [lastCopiedUnit, unit])

  const isCopied = lastCopiedUnit.id === unit.id

  return (
    <>
      {personalizedModalShown && (
        <PersonalizedLinkModal
          unit={unit}
          line={line}
          onClose={() => {
            setPersonalizedModalShown(false)
            toggleWrapScroll()
          }}
          onCopy={onCopy}
        />
      )}
      <button
        type="button"
        className={`bg-transparent p-[5px] mr-[-5px] border-none ml-[9px] group relative ${className}`}
        onClick={() => onCopy()}
      >
        {copyTooltip && (
          <div
            className={`bg-[#16C270] overflow-hidden left-[24px] bottom-[40px] z-[100] lg:bottom-0 lg:top-[-42px] lg:left-[-115px] lg:ml-[50%] fixed lg:absolute flex items-center justify-center h-[48px] lg:h-[31px] w-[calc(100%-48px)] lg:w-[227px] text-[16px] lg:text-[14px] rounded-full lg:rounded-[3px]  font-medium lg:font-normal text-white ${
              tooltipShown ? 'fadeIn' : 'fadeOut'
            }`}
            onMouseEnter={() => {
              clearTimeout(hideTimeout)
            }}
            onMouseLeave={() => {
              hideTooltipWithTimeout()
            }}
          >
            Link copied.
            <span className="flex items-center">
              {' '}
              <button onClick={openPersonalizedModal} className="underline mx-[5px]">
                Personalize Link
              </button>
              <ArrowRightPersonalize />
            </span>
          </div>
        )}
        <div className='lg:h-[20px] overflow-hidden  flex items-center'>
          {isCopied && tooltipShown ? (
            <>
              <div className="hidden lg:inline-block">
                <CheckmarkViolet />
              </div>
              <div className="lg:hidden">
                <CheckmarkVioletMobile />
              </div>
            </>
          ) : !icon ? (
            <>
              <div className="lg:group-hover:hidden">
                {!isMobile && <CopySmall />}
                {isMobile && <CopyMobile />}
              </div>
              <div className="hidden lg:group-hover:block">
                <CopySmallViolet />
              </div>
            </>
          ) : (
            icon
          )}
        </div>
        {label && <span className="ml-[6px] font-medium">{label}</span>}
      </button>
    </>
  )
}
