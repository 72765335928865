import { useEffect } from 'react'
import { Router, Outlet, useSearch, ReactLocation } from '@tanstack/react-location'
import { useMediaQuery } from '@react-hook/media-query'
import { routes } from 'IFrameApp/navigation'
import { events, iframe } from 'shared/analytics'
import { useStore } from './store'
import HotJar from 'shared/analytics/hotjar'

const location = new ReactLocation()

function DynamicPrice() {
  const { price } = useSearch()
  const { setDynamicApartmentPrice } = useStore()
  useEffect(() => {
    if (price) {
      setDynamicApartmentPrice(price)
    }
  }, [price, setDynamicApartmentPrice])
}

function App() {
  // TODO: Figure out how to not repeat this part of the code on each app
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const isMobileLandscape = useMediaQuery('(orientation: landscape) and (max-width: 1023px)')

  useEffect(()=>{
    iframe(events.pl)
  }, [])

  useEffect(() => {
    if (isMobile) {
      iframe(isMobileLandscape ? events.alctl : events.alctp, () => ({ path: window.location.href }))
    }
  }, [isMobile, isMobileLandscape])

  const onVisibilityChange = () => {
    if (document.hidden) {
      iframe(events.abh)
    } else {
      iframe(events.abv)
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange)
    return () => document.removeEventListener('visibilitychange', onVisibilityChange)
  }, [])

  return (
    <div className="h-full bg-white">
      <HotJar/>
      <Router location={location} routes={routes}>
        <DynamicPrice />
        <Outlet />
      </Router>
    </div>
  )
}

export default App
