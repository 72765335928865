import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as ImgLoader } from 'shared/assets/icons/imgBig.svg'
import { useStore } from 'IFrameApp/store'
import { getCdnAssetUrl } from 'shared/helpers/assets'

function SlideImage({ image }) {
  const { overlayShown } = useStore()

  const imagePlaceholder = useRef()
  useEffect(() => {
    if (imagePlaceholder && imagePlaceholder.current) {
      imagePlaceholder.current.style.height = `${(1284 / 2778) * imagePlaceholder.current.offsetWidth}px`
    }
  }, [imagePlaceholder])

  const [loaded, setLoaded] = useState(false)

  return (
    <div className="h-full flex justify-center swiper-zoom-container relative">
      <div
        className={`${
          overlayShown ? 'gradient-animate-in' : 'animate-out'
        } absolute top-0 left-0  w-full h-full flex flex-col justify-between z-40`}
      >
        <div className="h-[82px] gradient-tb w-full"></div>
      </div>
      {!loaded && (
        <div className="w-full lg:h-[calc(0.46*(100vw-336px))] h-[calc(0.46*100vw)] bg-[#F5F5F8] flex-1 flex items-center justify-center">
          <ImgLoader />
        </div>
      )}

      <img
        alt=""
        onLoad={() => {
          setLoaded(true)
        }}
        src={getCdnAssetUrl(image.src)}
        className={`w-full h-auto lg:h-full lg:w-auto object-contain swiper-zoom-target relative z-10 ${
          !loaded ? 'hidden' : ''
        }`}
      />
    </div>
  )
}

export default SlideImage
