export const eventDictionary = {
  tcp: 'tour_code_pageview',
  tccf: 'tour_code_continue_failure',
  tccs: 'tour_code_continue_success',
  wp: 'welcome_pageview',
  wdc: 'welcome_disclaimer_checked',
  wdu: 'welcome_disclaimer_unchecked',
  wtaco: 'welcome_terms_and_conditions_open',
  wtacc: 'welcome_terms_and_conditions_close',
  wcc: 'welcome_continue_click',
  dgp: 'design_gallery_pageview',
  pgp: 'prospect_gallery_pageview',
  fpp: 'floor_plans_pageview',
  fvp: 'featured_videos_pageview',
  wip: 'wishlist_pageview',
  mfl: 'menu_feedback_link',
  mll: 'menu_logo_link',
  mppco: 'menu_property_pill_click_open',
  mppcc: 'menu_property_pill_click_close',
  mapco: 'menu_apt_pill_click_open',
  mapcc: 'menu_apt_pill_click_close',
  mas: 'menu_apt_selected',
  mdgc: 'menu_design_gallery_click',
  mpgc: 'menu_prospect_gallery_click',
  mwc: 'menu_wishlist_click',
  mfvc: 'menu_featured_videos_click',
  mfpc: 'menu_floor_plans_click',
  ffl: 'footer_feedback_link',
  fll: 'footer_logo_link',
  dgic: 'design_gallery_info_click',
  dgrpc: {
    name: 'design_gallery_room_pill_click',
    fn: (store) => {
      store.getState().setIsEngaged(true)
    },
  },
  dgrpch: 'design_gallery_room_pill_changed',
  dgcdc: 'design_gallery_change_design_click',
  dgdc: 'design_gallery_design_changed',
  dgiv: 'design_gallery_image_view',
  dgibc: 'design_gallery_image_back_click',
  dginc: {
    name: 'design_gallery_image_next_click',
    fn: (store) => {
      store.getState().setIsEngaged(true)
    },
  },
  dgsuc: 'design_gallery_show_unfurnished_click',
  dgsfc: 'design_gallery_show_furnished_click',
  dgidh: 'design_gallery_image_dot_hover',
  dgidc: 'design_gallery_image_dot_click',
  dgidl: 'design_gallery_image_dot_link',
  dgidph: 'design_gallery_image_dot_popover_hover',
  dgidpl: 'design_gallery_image_dot_popup_link',
  dgidp3dc: 'design_gallery_image_dot_popup_3D_click',
  dgcc: 'design_gallery_cart_click',
  dgach: 'design_gallery_asset_card_hover',
  dgacc: 'design_gallery_asset_card_click',
  dgacl: 'design_gallery_asset_card_link',
  dga3dc: 'design_gallery_asset_3D_click',
  dgihh: 'design_gallery_image_heart_hover',
  dgihc: 'design_gallery_image_heart_click',
  dgihl: 'design_gallery_image_heart_link',
  dgihph: 'design_gallery_image_heart_popover_hover',
  dghdpl: 'design_gallery_heart_dot_popup_link',
  dghdp3dc: 'design_gallery_heart_dot_popup_3D_click',
  dgwach: 'design_gallery_wishlist_asset_card_hover',
  dgwacc: 'design_gallery_wishlist_asset_card_click',
  dgwacl: 'design_gallery_wishlist_asset_card_link',
  dgwa3dc: 'design_gallery_wishlist_asset_3D_click',
  dgvitc: 'design_gallery_view_items_click',
  dgvimc: 'design_gallery_view_images_click',
  dgfc: 'design_gallery_filter_click',
  dgarvo: 'design_gallery_asset_ruler_view_open',
  dgarvc: 'design_gallery_asset_ruler_view_close',
  dgirvo: 'design_gallery_images_ruler_view_open',
  dgirvc: 'design_gallery_images_ruler_view_close',
  dgiric: 'design_gallery_images_ruler_image_click',
  dgsavo: 'design_gallery_single_asset_view_open',
  dgsavc: 'design_gallery_single_asset_view_close',
  dgsal: 'design_gallery_single_asset_link',
  dgsa3dc: 'design_gallery_single_asset_3D_click',
  dgvwc: 'design_gallery_vendor_website_click',
  dgizi: 'design_gallery_image_zoom_in',
  dgizo: 'design_gallery_image_zoom_out',
  wfra: 'wishlist_filter_room_applied',
  wfrc: 'wishlist_filter_room_click',
  wfva: 'wishlist_filter_vendor_applied',
  wfvc: 'wishlist_filter_vendor_click',
  wvwc: 'wishlist_vendor_website_click',
  wccl: 'wishlist_cart_click',
  wacl: 'wishlist_asset_card_link',
  wph: 'wishlist_preview_hover',
  wpc: 'wishlist_preview_click',
  fpdc: {
    name: 'floor_plans_design_click',
    fn: (store) => {
      store.getState().setIsEngaged(true)
    },
  },
  fpdeo: 'floor_plans_design_enlarge_open',
  fpdec: 'floor_plans_design_enlarge_close',
  fvvs: {
    name: 'featured_videos_video_start',
    fn: (store) => {
      store.getState().setIsEngaged(true)
    },
  },
  fvve: 'featured_videos_video_end',
  fvvo5p: 'featured_videos_video_over_50_percent',
  pgie: 'prospect_gallery_image_enlarge',
  pgeiv: 'prospect_gallery_enlarged_image_view',
  pgbc: 'prospect_gallery_back_click',
  pgnc: 'prospect_gallery_next_click',
  pgiec: 'prospect_gallery_image_enlarge_close',
  pgvs: 'prospect_gallery_video_start',
  pgve: 'prospect_gallery_video_end',
  pgvo5p: 'prospect_gallery_video_over_50_percent',
  abh: 'app_became_hidden',
  abv: 'app_became_visible',
  alctl: 'app_layout_changed_to_landscape',
  alctp: 'app_layout_changed_to_portrait',
  apbtc: 'apartment_page_book_tour_click',
  apcc: 'apartment_page_call_click',
  pl: 'page_loaded',
  welcome_screen_from_deep_link: 'welcome_screen_from_deep_link',
  signup_launched: 'signup_launched',
  signup_password_set: 'signup_password_set',
  forgot_password_launched: 'forgot_password_launched',
  review_profile_launched: 'review_profile_launched',
  review_profile_successful: 'review_profile_successful',
  landing_page_continue_web: 'landing_page_continue_web',
  landing_page_app_store: 'landing_page_app_store',
  settings_update_successful: 'settings_update_successful',
  settings_update_cancelled: 'settings_update_cancelled',
  menu_button_tapped: 'menu_button_tapped',
  home_button_tapped: 'home_button_tapped',
  menu_design_packages_tapped: 'menu_design_packages_tapped',
  menu_settings_tapped: 'menu_settings_tapped',
  menu_disclaimer_tapped: 'menu_disclaimer_tapped',
  menu_help_tapped: 'menu_help_tapped',
  menu_landing_page_tapped: 'menu_landing_page_tapped',
  dp_screen_view: 'dp_screen_view',
  dp_screen_info_button_engaged: 'dp_screen_info_button_engaged',
  dp_screen_gallery_button_tapped: 'dp_screen_gallery_button_tapped',
  dp_screen_cart_button_tapped: 'dp_screen_cart_button_tapped',
  dp_screen_expand_tapped: 'dp_screen_expand_tapped',
  dp_screen_collapse_tapped: 'dp_screen_collapse_tapped',
  dp_screen_room_selected: 'dp_screen_room_selected',
  dp_screen_asset_added: 'dp_screen_asset_added',
  dp_screen_asset_removed: 'dp_screen_asset_removed',
  dp_screen_asset_quantity_plus: 'dp_screen_asset_quantity_plus',
  dp_screen_asset_quantity_minus: 'dp_screen_asset_quantity_minus',
  cart_screen_view: 'cart_screen_view',
  cart_screen_package_expanded_tapped: 'cart_screen_package_expanded_tapped',
  cart_screen_send_cart_tapped: 'cart_screen_send_cart_tapped',
  cart_screen_back_tapped: 'cart_screen_back_tapped',
  cart_screen_cart_sent_ok: 'cart_screen_cart_sent_ok',
  signin_launched: 'signin_launched',
  signin_successful: 'signin_successful',
  forgot_password_email_entered: 'forgot_password_email_entered',
  forgot_password_password_set: 'forgot_password_password_set',
  cart_screen_asset_removed: 'cart_screen_asset_removed',
  gallery_screen_package_view: 'gallery_screen_package_view',
  gallery_screen_cart_button_tapped: 'gallery_screen_cart_button_tapped',
  gallery_screen_expand_tapped: 'gallery_screen_expand_tapped',
  gallery_screen_collapse_tapped: 'gallery_screen_collapse_tapped',
  gallery_screen_room_selected: 'gallery_screen_room_selected',
  gallery_screen_right_nav_tapped: 'gallery_screen_right_nav_tapped',
  gallery_screen_left_nav_tapped: 'gallery_screen_left_nav_tapped',
  gallery_screen_floor_plans_tapped: 'gallery_screen_floor_plans_tapped',
  gallery_screen_photos_tapped: 'gallery_screen_photos_tapped',
  gallery_screen_video_tapped: 'gallery_screen_video_tapped',
  gallery_screen_back_tapped: 'gallery_screen_back_tapped',
  gallery_screen_asset_added: 'gallery_screen_asset_added',
  gallery_screen_asset_removed: 'gallery_screen_asset_removed',
  gallery_screen_asset_quantity_plus: 'gallery_screen_asset_quantity_plus',
  gallery_screen_asset_quantity_minus: 'gallery_screen_asset_quantity_minus',
  gallery_screen_photo_zoom_in_tapped: 'gallery_screen_photo_zoom_in_tapped',
  gallery_screen_photo_zoom_out_tapped: 'gallery_screen_photo_zoom_out_tapped',
  gallery_screen_floor_plan_zoom_in_tapped: 'gallery_screen_floor_plan_zoom_in_tapped',
  gallery_screen_floor_plan_zoom_out_tapped: 'gallery_screen_floor_plan_zoom_out_tapped',
  gallery_screen_furniture_dot_hovered: 'gallery_screen_furniture_dot_hovered',
  gallery_screen_furniture_card_hovered: 'gallery_screen_furniture_card_hovered',
  gallery_screen_video_play_tapped: 'gallery_screen_video_play_tapped',
  gallery_screen_video_pause_tapped: 'gallery_screen_video_pause_tapped',
  gallery_screen_video_viewed: 'gallery_screen_video_viewed',
  gallery_screen_photo_viewed: 'gallery_screen_photo_viewed',
  gallery_screen_floor_plan_viewed: 'gallery_screen_floor_plan_viewed'
}
