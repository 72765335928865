import * as React from 'react'
const FloorPlansIcon = (props) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 3.25C1.5 2.65326 1.73705 2.08097 2.15901 1.65901C2.58097 1.23705 3.15326 1 3.75 1H17.25C17.8467 1 18.419 1.23705 18.841 1.65901C19.2629 2.08097 19.5 2.65326 19.5 3.25V16.75C19.5 17.3467 19.2629 17.919 18.841 18.341C18.419 18.7629 17.8467 19 17.25 19H3.75C3.15326 19 2.58097 18.7629 2.15901 18.341C1.73705 17.919 1.5 17.3467 1.5 16.75V3.25Z"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 1V19"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.5 13.375H10.5"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 10H19.5"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 10V19"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 14.5H19.5"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
export default FloorPlansIcon
