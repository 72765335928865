import React, { useEffect, useState } from 'react'
import Sidebar from 'Resident/components/Sidebar'
import PropertyDescriptionDetailsDropdown, {
  getUnitDetailsLabel,
} from 'shared/components/PropertyDescriptionDetailsDropdown'
import { useStore } from 'Resident/store'

import { ReactComponent as MenuIcon } from 'shared/assets/icons/menu.svg'
import { ReactComponent as UnitIcon } from 'shared/assets/icons/unit-resident.svg'
import { ReactComponent as ResidentLogoSparx } from 'shared/assets/icons/resident-logo-navbar.svg'
import { ReactComponent as CloseIcon } from 'shared/assets/icons/close.svg'
import { ReactComponent as AppStoreBlack } from 'shared/assets/icons/appstore-black.svg'
import { ReactComponent as DisclaimerIcon } from 'shared/assets/icons/disclaimer-icon.svg'

import PackagesIcon from '../components/Sidebar/icons/packages'
import SettingsIcon from '../components/Sidebar/icons/settings'

import sparxLogo from 'shared/assets/images/sparx-logo.png'

import { IconButton } from 'TourApp/components/Sidebar'

import { Tooltip } from 'react-tooltip'
import { useMediaQuery } from '@react-hook/media-query'
import PropertyChip from 'Resident/components/PropertyChip'
import Footer from 'shared/components/Footer'
import TinyModal from 'shared/components/TinyModal'
import Button from 'shared/components/Button'
import { NavLink, useLocation } from 'react-router-dom'
import { isMobileSource } from 'Resident/helpers/general'
import DisclaimerContent, { disclaimerText } from 'shared/components/Disclaimer'
import { isAgentApp, isInstantApp, isMinimalApp } from 'Resident/helpers/app'
import Help from 'Resident/components/Help'
import CortBadge from 'Resident/components/CortBadge'
import MobileGuard from 'shared/components/MobileGuard'
import { safeConfigurationOptions } from 'shared/helpers/general'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { group3 } from 'shared/analytics/resident'

const items = [
  {
    path: '/packages',
    label: 'Design Packages',
    icon: (active) => <PackagesIcon stroke={active ? 'black' : '#5F6787'} />,
    onClick: ()=>{
      rpevent(eventDictionary.menu_design_packages_tapped, group3)
    }
  },
  // {
  //   path: '/gallery',
  //   label: 'My Gallery',
  //   icon: <GalleryIcon />,
  // },
  {
    path: '/settings',
    label: 'Settings',
    icon: (active) => <SettingsIcon stroke={active ? 'black' : '#5F6787'} />,
    onClick: ()=>{
      rpevent(eventDictionary.menu_settings_tapped, group3)
    }
  },
]

const separated = ['/settings']

export function Disclaimer({ icon = false, className = '' }) {
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const [popupShown, setPopupShown] = useState(false)

  const text = <DisclaimerContent content={[disclaimerText.first, disclaimerText.second]} />
  return (
    <>
      <Tooltip
        id="tooltip"
        noArrow
        className="default-tooltip !w-[452px] border-[1px] border-solid bg-[rgba(199,204,217,0.4)] !p-[24px]"
      >
        {text}
      </Tooltip>
      {popupShown && (
        <TinyModal className="!px-[30px]">
          <div className="text-[18px] font-semibold">Disclaimer</div>
          <div className="text-[#5F6787] text-center mt-[24px]">{text}</div>
          <Button
            rounded
            className="w-[233px] mt-[48px]"
            onClick={() => {
              setPopupShown(false)
            }}
          >
            OK
          </Button>
        </TinyModal>
      )}
      <button
        type="button"
        className={`underline text-[#5F6787] text-[12px] relative ${className}`}
        data-tooltip-id="tooltip"
        data-tooltip-position-strategy="fixed"
        data-tooltip-offset="16"
        data-tooltip-place="right-end"
        onClick={() => {
          rpevent(eventDictionary.menu_disclaimer_tapped, group3)
          isMobile && setPopupShown(!popupShown)
        }}
      >
        {!icon && <span>Disclaimer</span>}
        {icon && <DisclaimerIcon />}
      </button>
    </>
  )
}

function Separator({ className = '' }) {
  return (
    <div className="flex w-full justify-center">
      <div className={`h-[1px] w-[40px] bg-[#C7CCD9] ${className}`}></div>
    </div>
  )
}

function MenuContent() {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const { sidebar, appData, setWelcomeDrawer, welcomeDrawer, appType } = useStore()
  const minimized = sidebar.minimized && !isMobile

  function isPathActive(isActive, path){
    if(!isActive && path === '/packages' && pathname === '/checkout'){
      return true
    }
    return isActive
  }

  function renderMenuItem(item, index) {
    return (
      <>
        {!minimized && (
          <NavLink onClick={item.onClick}
            key={item.path}
            to={`${item.path}`}
            className={({ isActive }) =>
              `font-medium flex items-center mt-[20px] ${isPathActive(isActive, item.path) ? 'text-black' : 'text-[#5F6787]'}`
            }
          >
            {({ isActive }) => (
              <>
                {item.icon(isPathActive(isActive, item.path))}
                <span className="ml-[16px]">{item.label}</span>
              </>
            )}
          </NavLink>
        )}
        {minimized && (
          <NavLink onClick={item.onClick} key={item.path} to={`${item.path}`} className="mt-[8px] first:mt-[0]">
            {({ isActive }) => (
              <div>
                <IconButton>{item.icon(isPathActive(isActive, item.path))}</IconButton>
              </div>
            )}
          </NavLink>
        )}
      </>
    )
  }



  function getWelcomeText() {
    if (isMinimalApp(appType)) {
      return 'Explore tailor-made interior designs for unit:'
    } else if (isAgentApp(appType)) {
      return `Explore interior design packages for unit:`
    } else if (isInstantApp(appType)) {
      return `Explore interior design packages for:`
    } else {
      return `Hey ${appData.user.first_name}, explore tailor-made interior designs for unit:`
    }
  }

  const lineMode = isInstantApp(appType)

  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        {!minimized && <div className="leading-[21px] font-medium pr-[10px] lg:pr-[0]">{getWelcomeText()}</div>}

        {!minimized && (
          <PropertyDescriptionDetailsDropdown
            className="mt-[16px]"
            active
            details={appData.unit}
            lineOnly={lineMode}
            onToggle={null}
          />
        )}
        {!isMinimalApp(appType) && !isAgentApp(appType) && !isInstantApp(appType) && (
          <div className={`flex flex-col  ${minimized ? 'items-center' : 'items-start'}`}>
            {minimized && (
              <>
                <Tooltip id={appData.unit.id} noArrow className="default-tooltip fadeShow w-max">
                  <div>{lineMode ? `Line ${appData.unit.unit_type.name}` : `#${appData.unit.unit_number}`}</div>
                </Tooltip>
                <div
                  data-tooltip-id={appData.unit.id}
                  data-tooltip-position-strategy="fixed"
                  data-tooltip-offset="16"
                  data-tooltip-place="right-center"
                  className="border-[#C7CCD9] border-solid border-[1px] h-[40px] w-[40px] flex items-center justify-center rounded-[5px]"
                >
                  <UnitIcon />
                </div>
              </>
            )}
            {minimized && <Separator className="my-[16px]" />}
            <div className={`${minimized ? '' : 'mt-[30px]'}`}>
              {items.filter((item) => !separated.includes(item.path)).map(renderMenuItem)}
            </div>
            {items.filter((item) => separated.includes(item.path)).map(renderMenuItem)}
          </div>
        )}
      </div>

      <div>
        {!isAgentApp(appType) && (
          <div className={`flex  ${minimized ? 'justify-center' : ''}`}>
            <Help minimal={minimized} />
          </div>
        )}
        <div className="flex items-center justify-between">
          {!isAgentApp(appType) && <div>{!minimized && <Disclaimer />}</div>}
          {!minimized && !isMinimalApp(appType) && !isAgentApp(appType) && !isInstantApp(appType) && (
            <button
              type="button"
              onClick={() => {
                rpevent(eventDictionary.menu_landing_page_tapped, group3)
                setWelcomeDrawer({ opened: !welcomeDrawer.opened })
              }}
            >
              {' '}
              <AppStoreBlack />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

function Navbar() {
  const { sidebar, appData } = useStore()
  const [toggled, setToggled] = useState(false)

  useEffect(() => {
    if (toggled) {
      document.getElementsByTagName('body')[0].classList.add('freeze')
    } else {
      document.getElementsByTagName('body')[0].classList.remove('freeze')
    }
    return function () {
      document.getElementsByTagName('body')[0].classList.remove('freeze')
    }
  }, [toggled])

  const unitLabel = getUnitDetailsLabel(appData.unit, appData.unit.unit_type, false)

  return (
    <>
      {!toggled && (
        <div className="h-[72px]  relative z-[400] shrink-0  px-[17px]  w-full flex justify-between items-center border-b-[1px] border-solid border-[#E5E7ED]">
          <div>
            <NavLink to="/packages" onClick={()=>{ 
              rpevent(eventDictionary.home_button_tapped, group3)
            }}>
              <ResidentLogoSparx />
            </NavLink>
          </div>
          <div className="flex flex-col items-center">
            <div className="font-semibold">{unitLabel}</div>
            <div className="font-light text-[#5F6787] text-[12px]">PARKMERCED</div>
          </div>
          <button type="button" onClick={() => {
            rpevent(eventDictionary.menu_button_tapped, group3)
            setToggled(!toggled)
          }}>
            <MenuIcon />
          </button>
        </div>
      )}
      {toggled && (
        <div className="fixed top-0 left-0 bg-[white] z-[400] w-screen h-full flex flex-col">
          <div className="p-[26px] pb-[33px] flex-1 flex-col flex">
            <div className="flex items-start justify-between">
              <PropertyChip />

              <button type="button" onClick={() => setToggled(!toggled)} className="mr-[-5px]">
                {' '}
                <CloseIcon />
              </button>
            </div>
            <div className="mt-[32px] flex-1">
              <MenuContent />
            </div>
          </div>
          <div className="h-[84px] bg-[#FAFAFA] p-[26px] border-solid items-center border-[#C7CCD9] border-[1px] flex justify-center">
            <div>
              <div className="flex items-center justify-center text-[14px]">
                Powered by <img src={sparxLogo} className="h-[16px] ml-[8px]" />
              </div>
              <div className="text-[#C7CCD9] text-[10px] mt-[5px] font-normal">
                © All Rights Reserved SparX Inc. {new Date().getFullYear()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

function AgentNavbar() {
  const { sidebar, appData } = useStore()
  const [toggled, setToggled] = useState(false)

  useEffect(() => {
    if (toggled) {
      document.getElementsByTagName('body')[0].classList.add('freeze')
    } else {
      document.getElementsByTagName('body')[0].classList.remove('freeze')
    }
    return function () {
      document.getElementsByTagName('body')[0].classList.remove('freeze')
    }
  }, [toggled])

  function renderBedroomsLabel(){
    if(unit_type.number_of_bedrooms === 0){
      return 'Studio'
    }
    return <>{unit_type.number_of_bedrooms} Bed{unit_type.number_of_bedrooms > 1 ? 's' : ''}</>
  }

  function renderBathroomsLabel(){
    if(unit_type.number_of_bathrooms === 0){
      return ''
    }
    return <> · {unit_type.number_of_bathrooms} Bath{unit_type.number_of_bathrooms > 1 ? 's' : ''}</>
  }

  const { unit_type } = appData.unit
  return (
    <>
      {!toggled && (
        <div className="h-[72px]  relative z-[400] shrink-0  p-[24px]  w-full flex justify-between  border-b-[1px] border-solid border-[#E5E7ED]">
          <div className="flex flex-col justify-center">
            <div className="text-[18px]">
              {unit_type.property.property_name} · Unit {getUnitDetailsLabel(appData.unit, unit_type, false)}
            </div>
            <div className="text-[#3D4256] opacity-60">
              {renderBedroomsLabel()}{renderBathroomsLabel()}{safeConfigurationOptions(unit_type.property.properties_configuration_options).show_unit_size && unit_type.size && <span> · {unit_type.size} ft sq</span>}
            </div>
          </div>
          <CortBadge className="!top-0 !m-0" />
        </div>
      )}
    </>
  )
}

function SidebarLayout({ children, className = '' }) {
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const { source, appType } = useStore()
  const location = useLocation()
  return (
    <div className="h-full md:h-[calc(100%-48px)] lg:min-h-full lg:pb-[30px] bg-[#FAFAFA] lg:min-w-[1300px] overflow-hidden">
      <div className="h-full w-full flex flex-col lg:flex-row">
        {!isMobile && (
          <Sidebar>
            <MenuContent />
          </Sidebar>
        )}
        {isAgentApp(appType) && <CortBadge className='hidden lg:block' />}
        {isMobile && !isMobileSource(source) && !isAgentApp(appType) && <Navbar />}
        {isAgentApp(appType) && (
          <div className="lg:hidden">
            <AgentNavbar />
          </div>
        )}
        {isAgentApp(appType) && <MobileGuard className="md:hidden" />}
        <div className={`w-full h-full overflow-y-auto flex-1 ${className}`}>{children}</div>
      </div>

      <Footer disableLink poweredByText="Powered by" className="hidden md:flex z-[500] absolute bottom-0 left-0" />
    </div>
  )
}

export default SidebarLayout
