import * as React from 'react'
const SvgComponent = (props) => (
  <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.0059 12.7938L13.0111 23L3.01628 12.7938C2.35703 12.1323 1.83775 11.3373 1.49114 10.4588C1.14452 9.5803 0.978089 8.6373 1.00231 7.6892C1.02653 6.7411 1.24089 5.80844 1.63188 4.94996C2.02287 4.09147 2.58202 3.32575 3.27413 2.70102C3.96624 2.07629 4.77631 1.60608 5.65333 1.31999C6.53035 1.03391 7.45532 0.938157 8.37 1.03876C9.28468 1.13936 10.1692 1.43414 10.968 1.90454C11.7668 2.37493 12.4624 3.01075 13.0111 3.77196C13.5622 3.01628 14.2586 2.38601 15.0568 1.92061C15.855 1.45521 16.7378 1.16469 17.6499 1.06723C18.5621 0.969769 19.4839 1.06747 20.3577 1.35422C21.2315 1.64097 22.0385 2.11059 22.7281 2.7337C23.4178 3.3568 23.9753 4.11998 24.3658 4.97545C24.7562 5.83093 24.9712 6.76029 24.9973 7.70538C25.0234 8.65046 24.86 9.59093 24.5173 10.4679C24.1747 11.3449 23.6601 12.1395 23.0059 12.802"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
export default SvgComponent
