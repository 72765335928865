import { deleteInvite } from 'CRM/services/invites'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Chip } from '../Chip'
import { ReactComponent as ThreeDotsIcon } from 'shared/assets/icons/three-dots-icon.svg'
import { ReactComponent as CheckmarkCopySuccess } from 'shared/assets/icons/checkmark-copy-success.svg'
import { ReactComponent as DeleteInviteIcon } from 'shared/assets/icons/delete-invite-icon.svg'
import { ReactComponent as CopyInviteIcon } from 'shared/assets/icons/invite-copy.svg'
import { ReactComponent as CopyLinkIcon } from 'shared/assets/icons/resident-copy-link-icon.svg'
import { getChipVariant } from '../../helpers'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { useStore } from 'CRM/store'
import { copyToClipboard, safeConfigurationOptions } from 'shared/helpers/general'

export function InviteRow({ invite, rowId, menuToggled, onMenuToggled, onDelete }) {
  const { selectedProperty } = useStore()
  const [copied, setCopied] = useState(false)
  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (menuToggled) {
        onMenuToggled(null)
      }
    },
  })

  function menuAction(cb, { skipClose } = {}) {
    return function () {
      cb && cb()
      !skipClose && onMenuToggled(null)
    }
  }

  useEffect(() => {
    if (!menuToggled) {
      setCopied(false)
    }
  }, [menuToggled])

  const actions = [
    {
      icon: <DeleteInviteIcon />,
      label: 'Delete',
      action: menuAction(async () => {
        await deleteInvite(invite.unit.id, invite.user.id)
        onDelete && onDelete()
      }),
    },
    {
      icon: copied === 'text' ? <CheckmarkCopySuccess /> : <CopyInviteIcon />,
      label: copied === 'text' ? 'Invitation Copied' : 'Copy Invitation',
      className: copied === 'text' ? 'text-[#16C270]' : '',
      action: menuAction(
        async () => {
          copyToClipboard(invite.invitation_text)
          setCopied('text')
        },
        { skipClose: true },
      ),
    },
    {
      icon: copied === 'ios' ? <CheckmarkCopySuccess /> : <CopyLinkIcon />,
      label: copied === 'ios' ? 'Link Copied' : 'Resident App Link',
      className: copied === 'ios' ? 'text-[#16C270]' : '',
      action: menuAction(
        async () => {
          copyToClipboard(invite.invitation_link_ios)
          setCopied('ios')
        },
        { skipClose: true },
      ),
    },
    {
      icon: copied === 'web' ? <CheckmarkCopySuccess /> : <CopyLinkIcon />,
      label: copied === 'web' ? 'Link Copied' : 'Resident Page Link',
      className: copied === 'web' ? 'text-[#16C270]' : '',
      action: menuAction(
        async () => {
          copyToClipboard(invite.invitation_link_web)
          setCopied('web')
        },
        { skipClose: true },
      ),
    },
  ]
  
  const { is_mirrored } = safeConfigurationOptions(invite.unit.unit_type.unit_types_configuration_options)

  return (
    <tr className="border-solid border-[1px] border-[#C7CCD9] h-[64px] bg-white">
      <td className="pl-[24px]">{moment(invite.last_sent_date).format('MM/DD/YYYY')}</td>
      <td>{[invite.user.first_name, invite.user.last_name].join(' ')}</td>
      <td>
        <Chip>{invite.user.email}</Chip>
      </td>
      <td>#{invite.unit.name || invite.unit.unit_number}</td>
      <td>
        {invite.unit.unit_type.name}
        {is_mirrored ? ' (mirrored)' : ''}
      </td>
      {selectedProperty.properties_configuration_options.building_as_property ? (
        <td>{invite.unit.building_name}</td>
      ) : null}
      <td>
        <Chip variant={getChipVariant(invite.invitation_status)} className="capitalize">
          {invite.invitation_status}
        </Chip>
      </td>
      <td className="pr-[24px]">
        <div className="flex items-center justify-end relative" ref={ref}>
          <button className="p-[10px] mr-[-10px]" onClick={() => onMenuToggled(menuToggled ? null : rowId)}>
            <ThreeDotsIcon />
          </button>
          {menuToggled && (
            <div className="absolute right-0 top-[20px] w-max bg-white shadow-[0px_0px_12px_0px_rgba(0,0,0,0.05)]  z-[10] rounded-[6px] p-[8px]">
              {actions.map((action) => (
                <button
                  type="button"
                  onClick={() => {
                    action.action()
                  }}
                  className={`flex items-center font-medium  h-[38px] rounded-[6px] py-[9px] px-[13px] hover:bg-[#E5E7ED] ${action.className}`}
                >
                  {action.icon}
                  {action.label && <span className="ml-[10px]">{action.label}</span>}
                </button>
              ))}
            </div>
          )}
        </div>
      </td>
    </tr>
  )
}
