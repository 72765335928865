export const tourStoreCreator = function (setter) {
  return {
    tourData: undefined,
    setTourData: (tourData) => {
      setter(() => ({ tourData }))
    },
    selectedApartmentId: 1,
    setSelectedApartmentId: (selectedApartmentId) => {
      setter(() => ({ selectedApartmentId }))
    },
    dynamicApartmentPrice: null,
    setDynamicApartmentPrice: (dynamicApartmentPrice) => {
      setter(() => ({ dynamicApartmentPrice }))
    },
    selectedDesignLabel: null,
    setSelectedDesignLabel: (selectedDesignLabel) => {
      setter(() => ({ selectedDesignLabel }))
    }
  }
}
