import { Navigate, ReactLocation } from '@tanstack/react-location'
// Screens
import LogInScreen from './screens/Auth/LogIn'
import SignUpScreen from './screens/Auth/SignUp'
import ResetPasswordScreen from './screens/Auth/ResetPassword'
import NotFoundScreen from './screens/NotFound'

import SetPasswordScreen from './screens/Auth/SetPassword'
import SettingsScreen from './screens/Settings'
import UpdatePasswordScreen from './screens/Auth/UpdatePassword'
import ApartmentsScreen from './screens/Apartments'
import RouteGuard from './components/RouteGuard'
import HeaderLayout from './layouts/HeaderLayout'
import PropertyGuard from './components/PropertyGuard'
import LoginLayout from './layouts/LoginLayout'
import ResidentInvitesScreen from './screens/ResidentInvites'
import ResidentInvitesAddScreen from './screens/ResidentInvites/Add'

export const routes = [
  {
    path: '/signin',
    element: (
      <RouteGuard loggedIn>
        <LoginLayout>
          <LogInScreen />
        </LoginLayout>
      </RouteGuard>
    ),
  },
  // {
  //   path: '/tour',
  //   element: <TourScreen />,
  // },
  // {
  //   path: '/tours',
  //   element: <ToursScreen />,
  // },
  {
    path: '/signup',
    element: (
      <RouteGuard loggedIn>
        <LoginLayout>
          <SignUpScreen />
        </LoginLayout>
      </RouteGuard>
    ),
  },
  {
    path: '/reset-password',
    element: (
      <LoginLayout>
        <ResetPasswordScreen />
      </LoginLayout>
    ),
  },
  {
    path: '/apartments',
    element: (
      <RouteGuard loggedOut>
        <HeaderLayout>
          <PropertyGuard>
            <ApartmentsScreen />
          </PropertyGuard>
        </HeaderLayout>
      </RouteGuard>
    ),
  },
  {
    path: '/settings',
    element: (
      <RouteGuard loggedOut>
        <HeaderLayout>
          <SettingsScreen />
        </HeaderLayout>
      </RouteGuard>
    ),
  },
  {
    path: '/resident-invites',
    children: [
      {
        path: '/',
        element: (
          <RouteGuard loggedOut>
            <HeaderLayout>
              <ResidentInvitesScreen />
            </HeaderLayout>
          </RouteGuard>
        ),
      },
      {
        path: '/add',
        element: (
          <RouteGuard loggedOut>
            <HeaderLayout>
              <ResidentInvitesAddScreen />
            </HeaderLayout>
          </RouteGuard>
        ),
      },
    ],
  },
 
  {
    path: '/set-password',
    element: (
      <LoginLayout>
        <SetPasswordScreen />
      </LoginLayout>
    ),
  },
  {
    path: '/update-password',
    element: (
      <RouteGuard loggedOut>
        <LoginLayout>
          <UpdatePasswordScreen />
        </LoginLayout>
      </RouteGuard>
    ),
  },
  {
    path: '/',
    element: <Navigate to="/signin" />,
  },
  {
    path: '/*',
    element: <NotFoundScreen />,
  },
  {
    element: <Navigate to="/" />,
  },
]

export const location = new ReactLocation()

export const pageTitles = {}

export const navLinks = [
  {
    path: '',
    title: '',
  },
]
