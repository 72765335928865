import { EAppType } from 'Resident/constants/appType'

function initialAppData() {
  const data = {}
  if (sessionStorage.getItem('instantUnitId')) {
    data.instantUnitId = Number(sessionStorage.getItem('instantUnitId'))
  }
  if (sessionStorage.getItem('agentEmail')) {
    data.agentEmail = sessionStorage.getItem('agentEmail')
  }
  if (sessionStorage.getItem('agentId')) {
    data.agentId = sessionStorage.getItem('agentId')
  }
  return Object.keys(data).length ? data : null
}

export const generalStoreCreator = function (setter) {
  return {
    inviteData: null,
    setInviteData: (inviteData) => {
      setter(() => ({ inviteData }))
    },
    appData: initialAppData(),
    setAppData: (appData) => {
      setter(() => ({ appData }))
    },
    source: sessionStorage.getItem('source'),
    setSource: (source) => {
      sessionStorage.setItem('source', source)
      setter(() => ({ source }))
    },
    appType: localStorage.getItem('appType') || sessionStorage.getItem('appType') || EAppType.STANDART,
    setAppType: (appType) => {
      sessionStorage.setItem('appType', appType)
      setter(() => ({ appType }))
    },
    packages: null,
    setPackages: (packages) => {
      setter(() => ({ packages }))
    },
    loginFlowPassedMarker: null,
    setLoginFlowPassedMarker: (loginFlowPassedMarker) => {
      setter(() => ({ loginFlowPassedMarker }))
    },
  }
}
