import PackageRow from 'Resident/components/PackageRow'
import AgentPackageRow from 'Resident/components/PackageRow/Agent'
import { EBedroomClassifications } from 'Resident/components/PackageRow/Agent/components/BedroomFilter'
import WelcomeModal from 'Resident/components/WelcomeModal'
import { RPScreens } from 'Resident/constants/appType'
import { isAgentApp, isStandartApp } from 'Resident/helpers/app'
import { isMobileSource } from 'Resident/helpers/general'
import { usePropertyLogo } from 'Resident/hooks/usePropertyLogo'
import { useScreen } from 'Resident/hooks/useScreen'
import { useStore } from 'Resident/store'
import { useEffect, useMemo, useState } from 'react'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { useAnalytics } from 'shared/analytics/hooks/useAnalytics'
import { group3 } from 'shared/analytics/resident'
import { objectCopy } from 'shared/helpers/general'

function AgentRow() {
  const { packages, source, appType } = useStore()

  const [bedroomFilter, setBedroomFilter] = useState('queen')

  const { logo } = usePropertyLogo()

  const bedFilter = useMemo(() => {
    return packages.some(
      (p) =>
        p.room_classifications.filter((r) => r.room_classification.id === EBedroomClassifications.MASTER_BEDROOM)
          .length > 0,
    )
  }, [packages])

  const finalPackages = useMemo(() => {
    if (!bedFilter) {
      return packages
    }
    return objectCopy(packages).filter((p) => {
      const bedroom = p.room_classifications.find((r) => r.room_classification.id === EBedroomClassifications.BEDROOM)
      if (bedroomFilter !== 'out') {
        return (
          bedroom &&
          bedroom.package_assets.find((a) => {
            return a.category.category_name.toLowerCase().includes(bedroomFilter.toLowerCase())
          })
        )
      } else {
        return (
          !bedroom ||
          (!bedroom.package_assets.find((a) => {
            return a.category.category_name.toLowerCase().includes('twin')
          }) &&
            !bedroom.package_assets.find((a) => {
              return a.category.category_name.toLowerCase().includes('queen')
            }))
        )
      }
    })
  }, [packages, bedroomFilter, bedFilter])

  return (
    <>
      <div
        className={`h-[calc(100vh-72px-48px)] lg:h-full p-[54px] lg:px-[25px] flex flex-col lg:block overflow-auto ${
          logo ? 'lg:py-[26px]' : 'lg:py-[33px]'
        }`}
      >
        <div className="text-[28px] lg:text-[24px] font-semibold shrink-0">Design Packages</div>
        <div className="mt-[5px] lg:mt-[35px]">
          {/* {isAgentApp(appType) && bedFilter && <BedroomFilter picked={bedroomFilter} onChange={setBedroomFilter} />} */}
          {finalPackages.map((item) => (
            <AgentPackageRow key={item.id} pack={item} />
          ))}
        </div>
      </div>
      {!isMobileSource(source) && isStandartApp(appType) && <WelcomeModal />}
    </>
  )
}

function DesignPackagesScreen(props) {
  const { packages, source, appType, loginFlowPassedMarker, setLoginFlowPassedMarker } = useStore()

  useEffect(()=>{
    if(loginFlowPassedMarker){
      rpevent(eventDictionary.signin_successful, group3)
      setLoginFlowPassedMarker(false)
    }
  }, [loginFlowPassedMarker, setLoginFlowPassedMarker])
  
  useScreen(RPScreens.DP)

  const { sendOnLoad } = useAnalytics()

  sendOnLoad(()=>{
    rpevent(eventDictionary.dp_screen_view, group3)
  })

  return isAgentApp(appType) ? (
    <AgentRow />
  ) : (
    <>
      <div className="h-full px-[25px] py-[26px] flex flex-col lg:block overflow-auto">
        <div className="text-[18px] lg:text-[24px] font-semibold shrink-0">Design Packages</div>
        <div className="mt-[35px]">
          {packages.map((item) => (
            <PackageRow key={item.id} pack={item} />
          ))}
        </div>
      </div>
      {!isMobileSource(source) && isStandartApp(appType) && <WelcomeModal />}
    </>
  )
}

export default DesignPackagesScreen
