import { ReactComponent as ZoomInIcon } from 'shared/assets/icons/zoom-in.svg'
import { ReactComponent as ZoomOutIcon } from 'shared/assets/icons/zoom-out.svg'

export function ZoomControls({ zoomIn, zoomOut, className = '' }) {
  return (
    <div
      className={`rounded-full bg-white  z-[100]  flex flex-col shadow-[0px_1px_1px_0px_rgba(0,0,0,0.1)] w-[36px] ${className}`}
    >
      <button
        type="button"
        onClick={() => {
          zoomIn()
        }}
        className="h-[38px] w-full flex items-center group justify-center"
      >
        <div className="group-active:scale-[0.9]">
          <ZoomInIcon />
        </div>
      </button>
      <button
        type="button"
        onClick={() => {
          zoomOut()
        }}
        className="h-[38px] w-full flex items-center group justify-center border-t-[1px] border-solid border-[#E5E7ED]"
      >
        <div className="group-active:scale-[0.9]">
          <ZoomOutIcon />
        </div>
      </button>
    </div>
  )
}
