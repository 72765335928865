import Footer from 'CRM/components/Footer'
import React from 'react'

function FooterLayout({ children, className = '' }) {
  return (
    <>
      <div className={`min-h-[calc(100%-84px)] lg:min-h-[calc(100vh-40px)]  ${className}`}>{children}</div>
      <Footer />
    </>
  )
}

export default FooterLayout
