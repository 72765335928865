export const generalStoreCreator = function (setter) {
  return {
    title: 'Virtual Tour',
    setTitle: (title) => {
      document.title = `${title} - SparX`
      setter(() => ({ title }))
    },
    parentQueryParams: {},
    setParentQueryParams: (parentQueryParams) => {
      setter(() => ({ parentQueryParams }))
    },
    overlayShown: false,
    setOverlayShown: (overlayShown) => {
      setter(() => ({ overlayShown }))
    },
    isEngaged: false,
    setIsEngaged: (isEngaged) => {
      setter(() => ({ isEngaged }))
    },
  }
}
