import { useState } from 'react'

export function useAnalytics() {
  const [sent, setSent] = useState(false)

  return {
    sendOnLoad: (cb) => {
      if (sent) {
        return
      }
      setSent(true)
      cb()
    },
  }
}
