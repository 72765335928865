import React, { useEffect, useState } from 'react'
// Icons
import { ReactComponent as FilterIcon } from 'shared/assets/icons/filter-icon-crm.svg'
import { ReactComponent as FilterIconWhite } from 'shared/assets/icons/filter-icon-crm-white.svg'
import { ReactComponent as ArrowDown } from 'shared/assets/icons/arrow-down-header.svg'
import Button from 'shared/components/Button'
import Checkbox from '../Checkbox'
import { useSwipeable } from 'react-swipeable'
import { toggleWrapScroll } from 'CRM/helpers/layout'

function BedroomsDropdown({ options, onChange }) {
  const [toggled, setToggled] = useState(false)

  const [values, setValues] = useState({})

  function onCheckboxChange(item, selected) {
    setValues((vals) => ({
      ...vals,
      [item.value]: selected,
    }))
  }

  useEffect(() => {
    onChange(
      Object.entries(values)
        .filter(([item, val]) => val)
        .map(([item]) => item),
    )
  }, [values])

  function getFilterActiveLabel() {
    return Object.entries(values)
      .filter(([item, val]) => val)
      .map(([item]) => item)
      .map((val) => {
        const item = options.find((opt) => opt.value == val)
        return item.value ? `${item.label} Bedroom` : item.label
      })
      .join(',')
  }

  return (
    <div className="py-[24px] border-b-[1px] border-solid border-[rgba(199,204,217,0.6)]">
      <div
        className="flex justify-between"
        onClick={() => {
          setToggled(!toggled)
        }}
      >
        <div>
          <div className="font-semibold">Bedrooms #</div>
          <div className="text-[#5F6787] pr-[5px]">{getFilterActiveLabel()}</div>
        </div>
        <button type="button">
          <ArrowDown />
        </button>
      </div>
      {toggled && (
        <div className="mt-[5px]">
          {options.map((option) => (
            <div className="mt-[20px] flex items-center">
              <Checkbox onChange={(e) => onCheckboxChange(option, e.target.checked)} />
              <span className="ml-[12px]">{option.value ? `${option.label} Bedroom` : `Studio`}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

function LinesDropdown({ options, onChange }) {
  const [toggled, setToggled] = useState(false)
  const [values, setValues] = useState({})

  function onCheckboxChange(item, selected) {
    setValues((vals) => ({
      ...vals,
      [item]: selected,
    }))
  }

  function getFilterActiveLabel() {
    return Object.entries(values)
      .filter(([item, val]) => val)
      .map(([item]) => item)
      .map((val) => {
        const item = options.find((opt) => opt == val)
        return `Line ${item}`
      })
      .join(', ')
  }

  useEffect(() => {
    onChange(
      Object.entries(values)
        .filter(([item, val]) => val)
        .map(([item]) => item),
    )
  }, [values])

  return (
    <div className="py-[24px] border-b-[1px] border-solid border-[rgba(199,204,217,0.6)]">
      <div
        className="flex justify-between "
        onClick={() => {
          setToggled(!toggled)
        }}
      >
        <div>
          <div className="font-semibold">Line</div>
          <div className="text-[#5F6787] leading-[2] max-h-[80px] overflow-y-auto mb-[4px] pr-[5px]">
            {getFilterActiveLabel()}
          </div>
        </div>
        <button type="button">
          <ArrowDown />
        </button>
      </div>
      {toggled && (
        <div className="mt-[5px] flex h-[100px] overflow-y-auto overflow-x-hidden flex-wrap">
          {options.map((option) => (
            <div className="mt-[20px] w-[50%] flex items-center">
              <Checkbox checked={values[option]} onChange={(e) => onCheckboxChange(option, e.target.checked)} />
              <span className="ml-[12px]">{option}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

function MobileFilterSheet({ bedroomsOptions, linesOptions, onClose, setBedroomsFilter, setLineFilter }) {
  function onBedroomsFilterChange(data) {
    setBedroomsFilter(data)
  }
  function onLinesFilterChange(data) {
    setLineFilter(data)
  }

  const handlers = useSwipeable({
    onSwipedDown: onClose,
    preventScrollOnSwipe: true,
  })

  return (
    <div className="w-full bg-white z-[100] border-t-[1px] border-solid border-[rgba(199,204,217,0.4)] rounded-t-[15px] p-[40px] pt-[55px] max-h-[600px] overflow-y-auto">
      <div
        className="h-[34px] absolute w-[calc(100%-2px)] top-[2px] left-[1px] flex items-center justify-center bg-white z-[200] rounded-t-[15px]"
        {...handlers}
      >
        <div className="h-[4px] rounded-full bg-[#5F6787] w-[30px]"></div>
      </div>
      <div className="font-semibold uppercase text-[#5F6787] opacity-60 mb-[8px]">Filter By</div>
      <BedroomsDropdown options={bedroomsOptions} onChange={onBedroomsFilterChange} />
      <LinesDropdown options={linesOptions} onChange={onLinesFilterChange} />
      <div className="mt-[76px]">
        <Button rounded className="w-full bg-[#A93DCB] font-normal" onClick={onClose}>
          Done
        </Button>
      </div>
    </div>
  )
}

function MobileFilter({ bedroomsOptions, linesOptions, setBedroomsFilter, setLineFilter, lineFilter, bedroomsFilter }) {
  const [filterShown, setFilterShown] = useState(false)

  const hasFilterValues = lineFilter.length > 0 || bedroomsFilter.length > 0

  return (
    <div
      className={`lg:hidden ml-[16px] px-[16px] h-[44px] z-[150] rounded-full flex items-center justify-center border-[1px] border-solid border-[#A93DCB] ${
        filterShown || hasFilterValues ? `bg-[#A93DCB]` : `bg-transparent`
      }`}
    >
      <button
        type="button"
        onClick={() => {
          toggleWrapScroll()
          setFilterShown(!filterShown)
        }}
      >
        {filterShown || hasFilterValues ? <FilterIconWhite /> : <FilterIcon />}
      </button>
      <div
        className={`transition-all duration-300 absolute w-full left-0 bottom-0 overflow-hidden ${
          filterShown ? 'max-h-[100%]' : 'max-h-[0]'
        }`}
      >
        <MobileFilterSheet
          setBedroomsFilter={setBedroomsFilter}
          setLineFilter={setLineFilter}
          bedroomsOptions={bedroomsOptions}
          linesOptions={linesOptions}
          onClose={() => {
            toggleWrapScroll()
            setFilterShown(false)
          }}
        />
      </div>
    </div>
  )
}

export default MobileFilter
