import { ReactComponent as ThreeDotsVioletIcon } from 'shared/assets/icons/three-dots-icon-violet.svg'
import { ReactComponent as DeleteInviteIcon } from 'shared/assets/icons/delete-invite-icon.svg'

import { ReactComponent as CopyInviteIcon } from 'shared/assets/icons/invite-copy.svg'
import { useState } from 'react'
import { deleteInvite } from 'CRM/services/invites'
import moment from 'moment'
import BottomDrawer from 'shared/components/BottomDrawer'
import { Chip } from '../Chip'
import { getChipVariant } from '../../helpers'
import { copyToClipboard } from 'shared/helpers/general'
import { ReactComponent as CheckmarkCopySuccess } from 'shared/assets/icons/checkmark-copy-success.svg'
import { ReactComponent as CopyLinkIcon } from 'shared/assets/icons/resident-copy-link-icon.svg'

export function InviteItem({ invite, onDelete, className = '' }) {
  const [menuToggled, setMenuToggled] = useState(false)
  const [copied, setCopied] = useState(false)

  function menuAction(cb, { skipClose } = {}) {
    return function () {
      cb && cb()
      !skipClose && setMenuToggled(!menuToggled)
    }
  }

  const actions = [
    {
      icon: <DeleteInviteIcon />,
      label: 'Delete',
      action: menuAction(async () => {
        await deleteInvite(invite.unit.id, invite.user.id)
        onDelete && onDelete()
      }),
    },
    {
      icon: copied === 'text' ? <CheckmarkCopySuccess /> : <CopyInviteIcon />,
      label: copied === 'text' ? 'Invitation Copied' : 'Copy Invitation',
      className: copied === 'text' ? 'text-[#16C270]' : '',
      action: menuAction(
        async () => {
          copyToClipboard(invite.invitation_text)
          setCopied('text')
        },
        { skipClose: true },
      ),
    },
    {
      icon: copied === 'ios' ? <CheckmarkCopySuccess /> : <CopyLinkIcon />,
      label: copied === 'ios' ? 'Link Copied' : 'Resident App Link',
      className: copied === 'ios' ? 'text-[#16C270]' : '',
      action: menuAction(
        async () => {
          copyToClipboard(invite.invitation_link_ios)
          setCopied('ios')
        },
        { skipClose: true },
      ),
    },
    {
      icon: copied === 'web' ? <CheckmarkCopySuccess /> : <CopyLinkIcon />,
      label: copied === 'web' ? 'Link Copied' : 'Resident Page Link',
      className: copied === 'web' ? 'text-[#16C270]' : '',
      action: menuAction(
        async () => {
          copyToClipboard(invite.invitation_link_web)
          setCopied('web')
        },
        { skipClose: true },
      ),
    },
  ]

  return (
    <>
      <div
        className={`border-[1px] border-solid border-[rgba(199,204,217,4)] rounded-[6px] px-[18px] py-[22px] bg-white ${className}`}
      >
        <div className="flex justify-between items-start">
          <div>
            <div className="text-[18px] font-semibold">{[invite.user.first_name, invite.user.last_name].join(' ')}</div>
            <div className="text-[14px] font-medium text-[#5F6787]">{invite.user.email}</div>
          </div>
          <button
            className="p-[5px]"
            type="button"
            onClick={() => {
              setMenuToggled(!menuToggled)
            }}
          >
            <ThreeDotsVioletIcon />
          </button>
        </div>
        <div className="grid grid-cols-2 gap-[20px] my-[20px]">
          <div>
            <div className="text-[12px] text-[#C7CCD9] font-semibold uppercase">unit</div>
            <div className="font-medium">#{invite.unit.name || invite.unit.unit_number}</div>
          </div>
          <div>
            <div className="text-[12px] text-[#C7CCD9] font-semibold uppercase">line</div>
            <div className="font-medium">{invite.unit.unit_type.name}</div>
          </div>
          <div>
            <div className="text-[12px] text-[#C7CCD9] font-semibold col-span-2 uppercase">building</div>
            <div className="font-medium">{invite.unit.building_name}</div>
          </div>
        </div>
        <div className="flex justify-between items-center w-full">
          <div className="text-[12px] font-medium text-[#5F6787] opacity-60">
            Invite Date {moment(invite.last_sent_date).format('MM/DD/YYYY')}
          </div>
          <Chip variant={getChipVariant(invite.invitation_status)} className="capitalize">
            {invite.invitation_status}
          </Chip>
        </div>
      </div>
      <BottomDrawer
        isOpen={menuToggled}
        onClose={() => {
          setMenuToggled(false)
        }}
      >
        {actions.map((action, index) => (
          <button
            key={index}
            type="button"
            onClick={() => {
              action.action()
            }}
            className="flex items-center font-medium h-[38px] rounded-[6px] py-[9px] mt-[5px] px-[13px] hover:bg-[#E5E7ED]"
          >
            {action.icon}
            {action.label && <span className="ml-[10px]">{action.label}</span>}
          </button>
        ))}
      </BottomDrawer>
    </>
  )
}
