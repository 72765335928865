import React, { useEffect, useState, useMemo } from 'react'
import { useMediaQuery } from '@react-hook/media-query'
import { useStore } from 'IFrameApp/store'
import Carousel from 'shared/components/Carousel'

import { events, iframe } from 'shared/analytics'
import { RoomDesignPicker } from './RoomPicker'
import SlideImage from './components/SlideImage'
import { addAmenitiesRoom, addMoreRoomCategory, divideRoomsByWeight } from 'shared/helpers/gallery'

function GalleryScreen() {
  const { tourData, setSelectedDesignLabel, selectedDesignLabel, setOverlayShown, overlayShown } = useStore()
  const [apartment, setApartment] = useState()
  const [rooms, setRooms] = useState([])
  const [pickedRoom, setPickedRoom] = useState()
  const [carouselInstance, setCarouselInstance] = useState(null)
  const [pickedDesignLayout, setPickedDesignLayout] = useState()
  const [pickedImage, setPickedImage] = useState()
  const [zoomed, setZoomed] = useState(false)
  const [activeSlide, setActiveSlide] = useState(0)
  const [roomPickerInstance, setRoomPickerInstance] = useState(null)
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  useEffect(() => {
    if (carouselInstance && activeSlide === 0) {
      carouselInstance.slideTo(0, 0)
    }
  }, [activeSlide, carouselInstance])

  const isMobile = useMediaQuery('(max-width: 1023px)')
  useEffect(() => {
    const { unit } = tourData
    const apartment = JSON.parse(JSON.stringify(unit))
    
    apartment.unit_type.unit_type_levels[0].rooms = apartment.unit_type.unit_type_levels[0].rooms
      .filter((room) => room.room_designs.length > 0)
      .filter((room) => room.room_designs.some((layout) => layout.photos.length > 0))

    apartment.unit_type.unit_type_levels[0].rooms = apartment.unit_type.unit_type_levels[0].rooms.map((room) => ({
      ...room,
      room_designs: room.room_designs.filter((design) => design.photos.length > 0),
    }))

    const [groupOne, groupTwo] = divideRoomsByWeight(apartment)

    apartment.unit_type.unit_type_levels[0].rooms = [...groupOne, ...groupTwo]

    //==========================================================================
    // More category for room
    //==========================================================================

    addMoreRoomCategory(apartment)

    //==========================================================================
    // Amenities virtual room
    //==========================================================================

    addAmenitiesRoom(apartment)

    setApartment(apartment)
    setRooms(apartment.unit_type.unit_type_levels[0].rooms)
    setPickedRoom(apartment.unit_type.unit_type_levels[0].rooms[0])
    console.log(apartment.unit_type.unit_type_levels[0])
    setPickedDesignLayout(apartment.unit_type.unit_type_levels[0].rooms[0].room_designs[0])
  }, [tourData])

  const designImages = useMemo(() => {
    const images = []
    if (apartment) {
      const rooms = apartment.unit_type.unit_type_levels[0].rooms
      rooms.forEach((room) => {
        const layouts = room.room_designs
        layouts.forEach((design) => {
          design.photos.forEach((image) => {
            images.push({
              ...{
                ...image,
                assets: image.assets.map((asset) => ({
                  ...asset,
                })),
              },
              roomId: room.id,
              display_name: image.display_name,
              designLayoutId: design.id,
              designLayoutName: design.design_name,
            })
          })
        })
      })
    }
    return images
  }, [apartment])

  useEffect(() => {
    if (designImages) {
      setPickedImage(designImages[0])
    }
  }, [designImages])

  useEffect(() => {
    // When ever room pill is changed or selected
    // (on first entering, after room pill clicked, after navigation between images etc.)
    if (pickedRoom) {
      iframe(events.dgrpch, getGalleryEventProperties)
    }
  }, [pickedRoom, activeSlide])

  useEffect(() => {
    // Design drop button clicked / design swiped on mobile
    iframe(events.dgdc, getGalleryEventProperties)
  }, [pickedDesignLayout])

  useEffect(() => {
    iframe(events.dgiv, getGalleryEventProperties)
  }, [activeSlide])

  function getGalleryEventProperties() {
    // Group_5
    const properties = {
      physical_room_id: pickedRoom?.id,
      physical_room_name: pickedRoom?.classification,
      design_id: pickedImage?.designLayoutId,
      design_name: pickedImage?.designLayoutName,
      vendor_id: pickedDesignLayout?.main_vendor?.id,
      vendor_name: pickedDesignLayout?.main_vendor?.full_name,
      image_id: pickedImage?.id, // Group_6
    }
    return properties
  }

  function onRoomPicked(room) {
    setPickedRoom(room)
    setPickedDesignLayout(room.room_designs[0])
    const imageIndex = designImages.findIndex(
      (image) => image.roomId === room.id && image.designLayoutId === room.room_designs[0].id,
    )

    if (carouselInstance) {
      carouselInstance.slideTo(imageIndex, 400)
    }

    iframe(events.dgrpc, () => ({
      ...getGalleryEventProperties(),
      new_physical_room_id: room.id,
      new_physical_room_name: room.name || room.classification,
    }))
  }

  function onCarouselSlideChange() {
    if (roomPickerInstance) {
      roomPickerInstance.closeDropdowns()
    }
    const image = designImages[carouselInstance.activeIndex]
    setPickedImage(image)
    const room = apartment.unit_type.unit_type_levels[0].rooms.find(({ id }) => id === image.roomId)
    setPickedRoom(room)
    setPickedDesignLayout(room.room_designs.find((design) => design.id === image.designLayoutId))

    const diff = carouselInstance.activeIndex - activeSlide
    if (diff === 1) {
      iframe(events.dginc, getGalleryEventProperties)
    }
    if (diff === -1) {
      iframe(events.dgibc, getGalleryEventProperties)
    }
    setActiveSlide(carouselInstance.activeIndex)
  }

  function onDesignPicked(layout) {
    setPickedDesignLayout(layout)
    iframe(events.dgcdc, getGalleryEventProperties)
    const imageIndex = designImages.findIndex(
      (image) => image.roomId === pickedRoom.id && image.designLayoutId === layout.id,
    )

    if (carouselInstance) {
      carouselInstance.slideTo(imageIndex, 400)
    }
  }

  function getPickedDesignIndex() {
    return pickedRoom.room_designs.findIndex((layout) => layout.id === pickedDesignLayout.id)
  }

  function getPickedDesignPhotos() {
    return pickedDesignLayout ? pickedDesignLayout.photos : []
  }

  function slideToDesignImage(designImage) {
    const imageIndex = designImages.findIndex((item) => item.id === designImage.id)
    carouselInstance.slideTo(imageIndex, 400)
  }

  useEffect(() => {
    if (pickedRoom) {
      const roomName = `${pickedRoom.name || pickedRoom.classification}`
      setSelectedDesignLabel(`${roomName}`)
      if (pickedImage && pickedImage.assets.length === 0) {
        setSelectedDesignLabel(`${pickedImage.display_name ? pickedImage.display_name : roomName}`)
      }
      if (pickedDesignLayout.is_amenities) {
        const label = `${pickedImage.display_name ? ` - ${pickedImage.display_name}` : ''}`
        setSelectedDesignLabel(`${roomName}${label}`)
      }
      if (pickedRoom.room_designs.length > 1 || pickedDesignLayout.is_more) {
        const label = ` - ${
          pickedDesignLayout.is_more
            ? `${pickedImage.display_name ? `${pickedImage.display_name}` : 'Other Images'}`
            : `Design ${getPickedDesignIndex() + 1}`
        }`
        setSelectedDesignLabel(`${roomName}${label}`)
      }
    }
  }, [pickedRoom, pickedDesignLayout, setSelectedDesignLabel, pickedImage])

  useEffect(() => {
    return () => {
      setSelectedDesignLabel(null)
    }
  }, [setSelectedDesignLabel])

  function onGalleryMouseMove(event) {
    if (event.clientY <= 80) {
      if (!overlayShown) {
        setOverlayShown(true)
      }
    } else {
      if (overlayShown) {
        setOverlayShown(false)
      }
    }
  }

  function onGalleryMouseOut(event) {
    const gallery = document.getElementsByClassName('desktop-gallery')[0]
    if (gallery.contains(event.relatedTarget)) {
      return
    }
    if (overlayShown) {
      setOverlayShown(false)
    }
  }

  return (
    <div
      onMouseMove={onGalleryMouseMove}
      onMouseOut={onGalleryMouseOut}
      className={`lg:bg-[#242528] h-full relative overflow-hidden ${isDesktop ? 'desktop-gallery' : 'mobile-gallery'}`}
    >
      <div className="hidden lg:block">
        <RoomDesignPicker
          hideDesignNames
          rooms={rooms}
          onInit={(instance) => setRoomPickerInstance(instance)}
          autodimmed={true}
          pickedRoom={pickedRoom}
          onRoomPicked={onRoomPicked}
          onDesignPicked={onDesignPicked}
        />
      </div>
      <div className="h-full overflow-hidden">
        <Carousel
          onSwiper={setCarouselInstance}
          navigation
          keyboard={{
            enabled: true,
            onlyInViewport: false,
          }}
          zoom={isMobile}
          onZoomChange={(i, s) => {
            setZoomed(s > 1)
            iframe(s > 1 ? events.dgizi : events.dgizo, getGalleryEventProperties)
          }}
          onSlideChange={onCarouselSlideChange}
          className="h-full main-slider"
          items={designImages.map((image) => (
            <SlideImage image={image} />
          ))}
        />
      </div>
      {pickedRoom && pickedDesignLayout && pickedImage && (
        <div className="absolute z-10 landscape:bottom-[38px] portrait:bottom-[74px] lg:landscape:bottom-[26px] left-[50%] translate-x-[-50%] translate-y-[-50%] lg:translate-y-[0] flex flex-col items-center">
          <div
            className={`justify-center absolute  bottom-[-34px] lg:relative lg:bottom-[initial] flex h-[18px] bg-white rounded-full px-[8px] py-[5px] lg:bg-transparent ${
              getPickedDesignPhotos().length > 1 ? '' : 'opacity-0'
            }`}
          >
            {getPickedDesignPhotos().map((item, index) => (
              <div
                onClick={() => slideToDesignImage(item)}
                className={`h-[8px] w-[8px] border-solid border-[1px] border-black lg:border-white rounded-full ${
                  item.id === pickedImage.id ? 'bg-black lg:bg-white' : 'bg-transparent cursor-pointer'
                } ${index > 0 ? 'ml-[4px] lg:ml-[3px]' : ''}`}
              ></div>
            ))}
          </div>
          <div className="text-black lg:text-white font-semibold text-shadow hidden lg:block text-[18px] leading-[22px] mt-[5px]">
            {selectedDesignLabel}
          </div>
        </div>
      )}
    </div>
  )
}

export default GalleryScreen
