import { validateToken } from 'CRM/helpers/auth'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { getAuth } from 'CRM/helpers/auth'
import { apiHost } from './api'
import { isProductionBuild } from './general'

export function client() {
  return new ApolloClient({
    connectToDevTools: isProductionBuild() ? !!localStorage.getItem('enableApolloDevTools') : true,
    uri: `${apiHost}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      Authorization: `Bearer ${getAuth()}`,
    },
  })
  
}

export async function mutate(variables, mutation, params = {}) {
  async function callback() {
    try {
      const result = await client().mutate({
        errorPolicy: 'all',
        variables,
        mutation,
        context: {
          headers: {
            Authorization: params.anonymous ? process.env.REACT_APP_API_TOKEN : `Bearer ${getAuth()}`,
          },
        }
      })
      if (result.errors && result.errors.length > 0) {
        console.error(`Mutation error: ${result.errors[0].message}`)
        throw new Error(result)
      }
      return result
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  return params.anonymous ? callback() : validateToken(callback)
}

export async function query(query, params = {}) {
  return validateToken(async () => {
    const context =  {
      headers: {
        Authorization: getAuth() && !params.anonymous ? `Bearer ${getAuth()}` : process.env.REACT_APP_API_TOKEN ,
      },
    }
    try {
      return await client().query(
        query.variables
          ? {
              ...query,
              context
            }
          : {
              errorPolicy: 'all',
              query: query,
              context
            },
      )
    } catch (error) {
      console.error(error)
      throw error
    }
  })
}
