export const errorMessages = {
  required: 'Please fill this field',
}

export const tableCols = (property) => {
  return [
    {
      label: 'last invite',
      sortingKey: 'last_sent_date',
    },
    {
      label: 'Invitee',
      sortingKey: 'user.first_name',
    },
    {
      label: 'Email',
      sortingKey: 'user.email',
    },
    {
      label: 'Unit',
      sortingKey: 'unit.name',
    },
    {
      label: 'Line',
      sortingKey: 'unit.unit_type.name',
    },
    property.properties_configuration_options.building_as_property ? {
      label: 'Building',
      sortingKey: 'unit.building_name',
    } : null,
    {
      label: 'Status',
      sortingKey: 'unit.invitation_status',
    },
    {
      label: 'Actions',
      className: 'justify-end',
    },
  ].filter(val=>val)
}

export function searchFields(invite) {
  return [
    invite.user.first_name,
    invite.user.last_name,
    invite.user.email,
    invite.unit.name,
    invite.unit.unit_number,
    invite.unit.unit_type.name,
    invite.unit.building_name,
    invite.last_sent_date,
    invite.invitation_status,
  ]
}

export const InviteCreateStatus = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}
