import { Navigate, ReactLocation } from '@tanstack/react-location'
// Screens
import ActionsScreen from './screens/Actions'
import NotFoundScreen from 'CRM/screens/NotFound'
import RouteGuard from 'CRM/components/RouteGuard'
import HeaderLayout from './layouts/HeaderLayout'
import FloorPlansScreen from './screens/FloorPlans'
import FeaturedVideosScreen from './screens/FeaturedVideos'
import ImagesScreen from './screens/Images'
import ManagePropertyMainScreen from './screens/Property/ManageMain'
import ManagePropertyLineImagesScreen from './screens/Property/ManageLineImages'
import SigninScreen from './screens/Signin'
import { ROUTES } from './common/constants'
import ManageRealEstateCompaniesMainScreen from './screens/RealEstateCompanies/ManageMain'
import ManageRealEstateCompaniesEditScreen from './screens/RealEstateCompanies/Edit'
import PropertyEditScreen from './screens/Property/Edit'

export const routes = [
  {
    path: ROUTES.HOME,
    element: (
      <RouteGuard loggedOut>
        <ActionsScreen />
      </RouteGuard>
    ),
  },
  {
    path: ROUTES.FLOOR_PLANS,
    element: (
      <RouteGuard loggedOut>
        <FloorPlansScreen />
      </RouteGuard>
    ),
  },
  {
    path: ROUTES.FEATURED_VIDEOS,
    element: (
      <RouteGuard loggedOut>
        <FeaturedVideosScreen />
      </RouteGuard>
    ),
  },
  {
    path: `${ROUTES.REAL_ESTATE_COMPANIES}/edit`,
    element: (
      <RouteGuard loggedOut>
        <ManageRealEstateCompaniesEditScreen />
      </RouteGuard>
    ),
  },
  {
    path: ROUTES.REAL_ESTATE_COMPANIES,
    exact: true,
    element: (
      <RouteGuard loggedOut>
        <ManageRealEstateCompaniesMainScreen />
      </RouteGuard>
    ),
  },
  {
    path: ROUTES.PROPERTY.ADD,
    element: (
      <RouteGuard loggedOut>
        <PropertyEditScreen />
      </RouteGuard>
    ),
  },
  {
    path: ROUTES.PROPERTY.EDIT,
    element: (
      <RouteGuard loggedOut>
        <PropertyEditScreen />
      </RouteGuard>
    ),
  },
  {
    path: ROUTES.PROPERTY.MANAGE,
    element: (
      <RouteGuard loggedOut>
        <ManagePropertyMainScreen />
      </RouteGuard>
    ),
  },
  {
    path: ROUTES.MANAGE_LINE_IMAGES,
    element: (
      <RouteGuard loggedOut>
        <ManagePropertyLineImagesScreen />
      </RouteGuard>
    ),
  },
  {
    path: ROUTES.IMAGES,
    element: (
      <RouteGuard loggedOut>
        <ImagesScreen />
      </RouteGuard>
    ),
  },
  {
    path: ROUTES.SIGNIN,
    element: (
      <RouteGuard loggedIn>
        <HeaderLayout>
          <SigninScreen />
        </HeaderLayout>
      </RouteGuard>
    ),
  },
  {
    path: '/*',
    element: <NotFoundScreen />,
  },
  {
    element: <Navigate to={ROUTES.HOME} />,
  },
]

export const location = new ReactLocation()

export const pageTitles = {}

export const navLinks = [
  {
    path: '',
    title: '',
  },
]
