import { useCallback, useEffect, useState } from 'react'

export function useRequest(cb, options = {}) {
  const [data, setData] = useState(options.default || undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const request = useCallback(async () => {
    setLoading(true)
    try {
      const result = await cb()
      setData(result)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [cb])

  useEffect(() => {
    request()
  }, [request])

  function refresh() {
    request()
  }

  return {
    data,
    loading,
    error,
    refresh,
  }
}
