import { useState } from 'react'
import { useMediaQuery } from '@react-hook/media-query'

import { useStore } from 'IFrameApp/store'
import { postMessage } from 'IFrameApp/helpers/general'

import Modal from 'shared/components/Modal'
import Button from 'shared/components/Button'
import { sparxUrl } from 'shared/settings'
import { events, iframe } from 'shared/analytics'
import sparxLogo from 'shared/assets/images/sparx-logo.png'

import { ReactComponent as PhoneIcon } from 'shared/assets/icons/phone.svg'
import { ReactComponent as ModalCloseSmallIcon } from 'shared/assets/icons/modalCloseSmall.svg'

const BookButton = ({ className = '', withPhone = false }) => {
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const { selectedDesignLabel, parentQueryParams } = useStore()
  const [disclaimerOpened, setDisclaimerOpened] = useState(false)
  function onTourBookClick() {
    postMessage('on_tour_book', { ...parentQueryParams })
    iframe(events.apbtc)
  }

  function onCallActionClick() {
    postMessage('on_call_request', { ...parentQueryParams })
    iframe(events.apcc)
  }

  function openDisclaimer(e) {
    e.preventDefault()
    setDisclaimerOpened(true)
  }

  return (
    <>
      <div
        className={`flex flex-col items-center absolute bottom-0 left-0 landscape:relative lg:landscape:absolute w-full ${className} z-20 shadow-[0px_0px_22px_rgba(95,103,135,0.06)] lg:shadow-none`}
      >
        {selectedDesignLabel && (
          <div className="text-black lg:text-white font-semibold mb-[28px] text-[18px] lg:hidden">
            {' '}
            {selectedDesignLabel}
          </div>
        )}
        <div className="flex w-full">
          <Button
            className="rounded-full w-full !h-[40px] lg:!h-[35px] font-semibold text-[14px] lg:text-[18px] lg:font-semibold !bg-[#53A2EC]"
            onClick={onTourBookClick}
          >
            BOOK A TOUR
          </Button>
          {withPhone && (
            <Button className="rounded-full !h-[40px] ml-[8px] !min-w-[72px] !bg-[#00AF03]" onClick={onCallActionClick}>
              <PhoneIcon />
            </Button>
          )}
        </div>
        <div className="flex mt-[16px] items-end leading-[0px]">
          <a href="!#" onClick={openDisclaimer}>
            <span className="mr-[4px]  font-normal text-[10px] leading-[10px] underline">Virtually Staged by</span>
          </a>
          <a href={sparxUrl} target="_blank" rel="noreferrer">
            <img src={sparxLogo} className="h-[12px]" alt="sparx-logo" />
          </a>
        </div>
      </div>
      {disclaimerOpened && (
        <Modal
          className="bg-[rgba(255,255,255,.6)] w-screen h-screen"
          plain
          closeAnywhere={isMobile}
          toggleModal={setDisclaimerOpened}
        >
          <div className="w-[calc(100%-48px)] lg:w-[500px] bg-white p-[16px] lg:py-[26px] lg:px-[33px] lg:pr-[66px] relative shadow-[0px_0px_12px_rgba(0,0,0,0.05)]">
            {!isMobile && (
              <button className="absolute right-[14px] top-[14px]" onClick={() => setDisclaimerOpened(false)}>
                <ModalCloseSmallIcon />
              </button>
            )}
            <span className="text-[16px] lg:text-[18px] leading-[24px] font-normal">
              Most videos and images have been virtually staged to help showcase potential of spaces in the residences.
            </span>
          </div>
        </Modal>
      )}
    </>
  )
}

export default BookButton
