import React, { useEffect } from 'react'
import Hotjar from '@hotjar/browser'
import axios from 'axios'
import { isProductionBuild, isProductionEnv } from '../helpers/general'

const search = new URLSearchParams(window.location.search)

function isHotJarDebugMode() {
  return !!localStorage.getItem('hj.debug')
}

function HotJarAllowedCallback(cb) {
  if ((isProductionBuild() && isProductionEnv()) || isHotJarDebugMode()) {
    cb()
  } else {
    console.log(`Skipping HotJar recording due to non matching environment`)
  }
}

export function setAppVariant(appVariant) {
  if (window.hj) {
    if (isHotJarDebugMode()) {
      console.log(`Setting HotJar app variant to ${appVariant}`)
    }
    window.hj('identify', null, {
      appVariant,
    })
  }
}

export function startHotJarRecording(delay = 1000) {
  HotJarAllowedCallback(() => {
    setTimeout(() => {
      console.log(`Starting hotjar recording ...`)
      window.hj('startRecording')
    }, delay)
  })
}

export function getHotjarSiteId() {
  const debugSiteId = localStorage.getItem('hj.siteId')
  const siteId = process.env.REACT_APP_HOTJAR_SITE_ID || (debugSiteId ? Number(debugSiteId) : null)
  return siteId
}

function HotJar({ delayed }) {
  function init() {
    if (localStorage.getItem('hj.disable')) {
      console.log('HotJar analytics disabled')
      return
    }

    const siteId = getHotjarSiteId()
    const hotjarVersion = 6

    // Initializing with `debug` option:
    if (siteId) {
      const debug = isHotJarDebugMode()
      if (debug) {
        console.log(`Initializing HotJar with site id ${siteId}`)
      }

      Hotjar.init(siteId, hotjarVersion, {
        debug,
      })

      if (!delayed) {
        startHotJarRecording()
      }
    } else {
      console.error(`HotJar site id not specified`)
    }
  }

  useEffect(() => {
    if (!search.get('skipAnalytics')) {
      HotJarAllowedCallback(() => {
        init()
      })
    } else {
      console.warn('Skipping HotJar init due to skipAnalytics flag')
    }
  }, [])

  return
}

export default HotJar
