import * as React from 'react'
const SvgComponent = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 6H13.0095" stroke={props.stroke || '#5F6787'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M1 4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H16C16.7956 1 17.5587 1.31607 18.1213 1.87868C18.6839 2.44129 19 3.20435 19 4V16C19 16.7956 18.6839 17.5587 18.1213 18.1213C17.5587 18.6839 16.7956 19 16 19H4C3.20435 19 2.44129 18.6839 1.87868 18.1213C1.31607 17.5587 1 16.7956 1 16V4Z"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 13.9998L6 8.99983C6.928 8.10683 8.072 8.10683 9 8.99983L14 13.9998"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 11.9998L13 10.9998C13.928 10.1068 15.072 10.1068 16 10.9998L19 13.9998"
      stroke={props.stroke || '#5F6787'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
export default SvgComponent

{
  /* <svg
xmlns="http://www.w3.org/2000/svg"
width={18}
height={18}
fill="none"
{...props}
>
<path
  stroke={props.stroke || '#5F6787'}
  strokeLinecap="round"
  strokeLinejoin="round"
  d="M1 3.667A2.667 2.667 0 0 1 3.667 1h10.666A2.667 2.667 0 0 1 17 3.667v10.666A2.667 2.667 0 0 1 14.333 17H3.667A2.667 2.667 0 0 1 1 14.333V3.667Z"
/>
<circle cx={11.5} cy={5.5} r={0.5} fill={props.stroke || '#5F6787'} />
<path
  stroke={props.stroke || '#5F6787'}
  strokeLinecap="round"
  strokeLinejoin="round"
  d="m1 12.555 4.444-4.444c.825-.794 1.842-.794 2.667 0l4.445 4.444M10.778 10.777l.889-.888c.825-.794 1.841-.794 2.666 0L17 12.555"
/>
</svg> */
}
