import { useState } from "react"
import { ReactComponent as ImgLoader } from 'shared/assets/icons/imgBig.svg'
import { ReactComponent as ImgLoaderSmall } from 'shared/assets/icons/img.svg'
import { getCdnAssetUrl } from "shared/helpers/assets"

export function StagingImage({ index, carouselInstance, image }) {
  const [loaded, setLoaded] = useState(false)

  return (
    <>
      {!loaded && (
        <div className="h-full aspect-[2.16/1] bg-[#F5F5F8] flex justify-center items-center">
          <div className="hidden lg:block">
            <ImgLoader />
          </div>
          <div className="lg:hidden">
            <ImgLoaderSmall />
          </div>
        </div>
      )}
      <img
        src={getCdnAssetUrl(image.src)}
        alt=""
        onLoad={() => setLoaded(true)}
        className={`border-solid border-[1px] lg:border-[2px] border-transparent ${
          index === carouselInstance.realIndex
            ? 'border-black cursor-default'
            : 'cursor-pointer'
        } h-full w-auto ${!loaded ? 'hidden' : ''}`}
      />
    </>
  )
}
