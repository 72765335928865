import React from 'react'

function PurchaseTypeTag({ purchaseType }) {
  return (
    <div
      className={`uppercase ${
        purchaseType === 'rent' ? 'bg-[#A93DCB]' : 'bg-[#4267EA]'
      } text-white font-semibold rounded-full leading-[10px] px-[8px] h-[18px] text-[10px] tracking-[1px] flex items-center justify-center`}
    >
      {purchaseType}
    </div>
  )
}

export default PurchaseTypeTag
