import React, { useState } from 'react'
import SelectPackageModal from '../SelectPackageModal'
import Button from 'shared/components/Button'

function SelectPackage({ pack }) {
  const [selectToggled, setSelectToggled] = useState(false)

  return (
    <>
      <Button
        rounded
        className="h-[37px] ml-[12px] !min-w-[128px] !w-[128px]"
        onClick={(e) => {
          e.stopPropagation()
          setSelectToggled(true)
        }}
      >
        Select
      </Button>
      {selectToggled && (
        <SelectPackageModal
          pack={pack}
          onClose={() => {
            setSelectToggled(false)
          }}
        />
      )}
    </>
  )
}

export default SelectPackage
