import { Link } from '@tanstack/react-location'
import { events, iframe } from 'shared/analytics'
import { useStore } from 'IFrameApp/store'
import BookButton from '../BookButton'
import PropertyDescriptionDetails from '../PropertyDescriptionDetails'
import { ReactComponent as SidebarToggleIcon } from 'shared/assets/icons/sidebar-toggle.svg'

import './styles.scss'
import { IconButton, SidebarToggleButton } from 'TourApp/components/Sidebar'
import { getUnitDetailsLabel } from 'shared/components/PropertyDescriptionDetailsDropdown'
import UnitIcon from 'TourApp/components/Sidebar/icons/unit'
import { Fragment, useEffect, useState } from 'react'

function Sidebar({ links, unit }) {
  const { sidebar, setSidebar, setIsEngaged } = useStore()
  function onMenuLinkClick(key) {
    const eventNames = {
      gallery: events.mdgc,
      floorplans: events.mfpc,
      videos: events.mfvc,
    }
    setIsEngaged(true)
    iframe(eventNames[key.replace("/", '')])
  }

  function renderNormalSidebar() {
    return (
      <div className={`${sidebar.minimized ? 'hidden' : 'lg:mt-[-50px]'}`}>
        <div className={`animation-delay-150 ${sidebar.minimized ? 'fadeHide' : 'opacity-0 fadeShow'}`}>
          <PropertyDescriptionDetails details={unit} className="text-black" />
          <div className="bg-[#C7CCD9] h-[1px] w-full mt-[29px]" />
          <nav className="font-medium text-[14px] leading-[21px] mt-[15px] flex flex-col">
            {links.map((l) => (
              <Link
                to={`/${l.path}`}
                key={l.path}
                className="screen-link mt-[16px]"
                data-test-id={l.path}
                onClick={() => onMenuLinkClick(l.path)}
              >
                {l.title}
              </Link>
            ))}
          </nav>
          <BookButton className="bottom-[25px] left-[18px] right-[18px] !w-[calc(100%-36px)]" />
        </div>
      </div>
    )
  }

  function renderMinimizedSidebar() {
    return (
      <div className={`h-full flex flex-col items-center ${sidebar.minimized ? '' : 'hidden'}`}>
        <IconButton className={`mb-[8px]`} active={true} tooltip={`Unit ${unit.name}`}>
          {' '}
          <UnitIcon stroke="black" />
        </IconButton>
        <div className="h-[1px] w-[40px] bg-[rgba(199,204,217,1)] my-[16px] mb-[8px] mt-[8px]"></div>
        <>
          {links.map((l, index) => (
            <Link to={`/${l.path}`} key={l.path} data-test-id={l.path} onClick={() => onMenuLinkClick(l.path)}>
              <Fragment key={index}>
                <Link
                  to={`/${l.path}`}
                  key={l.path}
                  data-test-id={l.path}
                  onClick={() => {
                    // toggleNavbar('', false)
                    onMenuLinkClick(l.path)
                  }}
                >
                  {({ isActive }) => {
                    return (
                      <IconButton tooltip={l.title} className={`mt-[8px]`} active={isActive}>
                        {l.icon(isActive)}
                      </IconButton>
                    )
                  }}
                </Link>
              </Fragment>
            </Link>
          ))}
        </>
      </div>
    )
  }

  function toggleSidebar() {
    setSidebar({ minimized: !sidebar.minimized })
  }

  return (
    <div
      className={`iframe-sidebar hidden lg:flex h-full w-screen ${
        sidebar.minimized ? 'lg:w-[56px]' : 'lg:w-[213px]  px-[24px]'
      } lg:bg-white group transition-all duration-300 
      ease-out flex-col fixed z-50 lg:relative shrink-0 overflow-x-hidden overflow-y-auto border-solid lg:border-r-[1px] border-[#C7CCD9] pt-[33px] lg:pt-0`}
    >
      <SidebarToggleButton
        onToggle={toggleSidebar}
        className={`"w-full relative h-[24px] my-[30px]  hidden  lg:flex ${
          sidebar.minimized ? `justify-center px-[18px]` : `justify-end`
        }`}
        minimized={sidebar.minimized}
      />

      {renderNormalSidebar()}
      {renderMinimizedSidebar()}
    </div>
  )
}

export default Sidebar
