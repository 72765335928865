import ManageProperty from 'MediaManager/components/Property/Manage'
import ScreenLayout from 'MediaManager/layouts/ScreenLayout'
import React from 'react'

function ManagePropertyMainScreen(props) {
  return <ScreenLayout title="Manage Property">
    <ManageProperty/>
  </ScreenLayout>
}

export default ManagePropertyMainScreen
