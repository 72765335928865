import React from 'react'
import HeaderLayout from './HeaderLayout'
import Button from 'shared/components/Button'

function ScreenLayout({ title, children }) {
  return (
    <div className="h-full bg-white">
      <HeaderLayout title={title}>
        <div className="container mx-auto">
          <div className='mb-[32px] mt-[32px]'>{children}</div>
        </div>
      </HeaderLayout>
    </div>
  )
}

export default ScreenLayout
