import { useMediaQuery } from '@react-hook/media-query'
import PackageAssetPopover from 'Resident/components/AssetPopover'
import { useStore } from 'Resident/store'
import React, { useEffect, useRef, useState } from 'react'
import { Popover } from 'react-tiny-popover'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import { ReactComponent as PackageAssetDot } from 'shared/assets/icons/resident-package-asset.dot.svg'
import { ReactComponent as PackageAssetTickDot } from 'shared/assets/icons/resident-tick-asset-dot.svg'
import { isAgentApp } from 'Resident/helpers/app'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { rpevent } from 'shared/analytics'
import { eventDictionary } from 'shared/analytics/eventDictionary'
import { group6 } from 'shared/analytics/resident'


function Dot({ position, assets, asset, pack, zoomed, onHovered }) {
  const { hoveredAsset, setHoveredAsset, appType } = useStore()
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const isCore = isAgentApp(appType) ? false : ((asset.package_asset || {}).type === 'core')
  const [dotHovered, setDotHovered] = useState(null)
  const active =
    hoveredAsset && asset.package_asset ? (hoveredAsset.identifier === asset.asset.identifier && hoveredAsset.type === asset.package_asset.type ? true : false) : true

  const added = asset.package_asset ? asset.package_asset.added : false


  

  const background =
    isCore || added
      ? pack.vendor.is_rental
        ? active
          ? 'bg-[rgba(169,61,203,1)]'
          : 'bg-[rgba(169,61,203,0.5)]'
        : 'bg-[rgba(66,103,234,0.6)]'
      : 'bg-[rgba(0,0,0,0.3)]'

  function onAssetPick(asset) {
    if (asset) {
      setHoveredAsset(asset.package_asset)
      setDotHovered(true)
    } else {
      setHoveredAsset(null)
      setDotHovered(false)
    }
  }

  useEffect(()=>{
    if(isMobile && hoveredAsset?.identifier !== asset?.identifier){
      setDotHovered(false)
    }
  }, [hoveredAsset, isMobile, asset])

  const firstIndex =
    hoveredAsset &&
    hoveredAsset.card &&
    assets.findIndex((i) => i.package_asset.identifier === hoveredAsset.identifier && i.package_asset.type === hoveredAsset.type)

  return (
    <Popover
      isOpen={dotHovered || firstIndex === asset.index}
      containerClassName="z-[400]"
      padding={-5}
      positions={['right', 'top', 'left', 'bottom']} // preferred positions by priority
      content={
        hoveredAsset && (
          <PackageAssetPopover
            onMouseEnter={() => {
              onAssetPick(asset)
            }}
            onMouseLeave={() => {
              onAssetPick(null)
              setHoveredAsset(null)
            }}
            dimensions={asset.asset}
            rent={pack.vendor.is_rental}
            vendor={pack.vendor.full_name}
            asset={hoveredAsset}
          />
        )
      }
    >
      <div
        {...(!isMobile
          ? {
              onMouseEnter: () => {
                if (!hoveredAsset || hoveredAsset.identifier !== asset.identifier) {
                  onHovered && onHovered(asset)
                }
                onAssetPick(asset)
              },
              onMouseLeave: () => {
                onAssetPick(null)
              },
            }
          : {
              onClick: () => {
                if(hoveredAsset?.identifier === asset?.identifier) {
                  onAssetPick(null)
                  return
                }
                onAssetPick(asset)
              },
              
            })}
        className={`  h-[20px] w-[20px] z-[10] absolute ${background} ${
          active ? 'opacity-100' : 'opacity-30'
        } transition-all border-[1px] border-solid border-white rounded-full flex items-center justify-center`}
        style={{
          ...position,
          ...{ 
            transform: `scale(${zoomed ? .4 : 1})` 
        }
        }}
      >
        {isCore && <PackageAssetDot />}
        {!isCore && (
          <>{added ? <PackageAssetTickDot /> : <div className="h-[4px] w-[4px] bg-[white] rounded-full"></div>}</>
        )}
      </div>
    </Popover>
  )
}

function DotsLayer({ imageRef, photo, size, pack, zoomed, onDotHovered }) {
  const [maxW, setMaxW] = useState()
  useEffect(() => {
    if (imageRef && imageRef.current) {
      const ro = new ResizeObserver(([image]) => {
        setMaxW(image.contentRect.width)
      })
      ro.observe(imageRef.current)
    }
  }, [imageRef])

  return (
    <div
      className="absolute top-[50%] translate-y-[-50%] object-contain w-full transparent_gradient z-[100]"
      style={{ aspectRatio: size.width / size.height, maxWidth: `${maxW}px` }}
    >
      {photo.assets
        .filter(({ x_axis, y_axis }) => x_axis <= 100 && y_axis <= 100)
        .map((item) => (
          <Dot
          onHovered={onDotHovered}
            zoomed={zoomed}
            pack={pack}
            assets={photo.assets}
            position={{ left: `${item.x_axis}%`, top: `${item.y_axis}%` }}
            asset={item}
          />
        ))}
    </div>
  )
}

function Photo({ photo, pack, dots, zoomed, onDotHovered }) {
  const ref = useRef()
  const [dotsSize, setDotsSize] = useState()

  function aspectRatio(image) {
    const height = image.naturalHeight
    const width = image.naturalWidth

    const gcd = (...arr) => {
      const _gcd = (x, y) => (!y ? x : gcd(y, x % y))
      return [...arr].reduce((a, b) => _gcd(a, b))
    }

    const gcdResult = gcd(width, height)

    return {
      width: width / gcdResult,
      height: height / gcdResult,
    }
  }

  function onImageLoad(e) {
    setDotsSize({
      ...aspectRatio(e.target),
    })
  }
  
  return (
    <>
      {dots && dotsSize && <DotsLayer onDotHovered={onDotHovered} zoomed={zoomed} imageRef={ref} size={dotsSize} pack={pack} photo={photo} />}
      <img
        ref={ref}
        src={`${getCdnAssetUrl(photo.src)}`}
        onLoad={onImageLoad}
        className="h-full object-contain"
        alt={photo.id}
      />
    </>
  )
}

export default Photo
