import { useLocation, useNavigate, useSearch } from '@tanstack/react-location'
import Page from 'CRM/components/Page'
import { Dropdown } from 'CRM/screens/Apartments/components/Dropdown'
import React, { useEffect, useMemo, useState } from 'react'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useStore } from 'CRM/store'
import { smartSort } from 'shared/helpers/arrays'
import { arrayUniqueByKey, copyToClipboard } from 'shared/helpers/general'
import { upsertInvite } from 'CRM/services/invites'
import ErrorMessage from 'shared/components/ErrorMessage'
import { errorMessages } from 'shared/settings'
import { useMediaQuery } from '@react-hook/media-query'
import StatusPopup from '../components/StatusPopup'
import { InviteCreateStatus } from '../constants'
import { EMAIL_REGX } from 'shared/constants'
import { ReactComponent as InviteCopyIcon } from 'shared/assets/icons/invite-copy.svg'
import { ReactComponent as InviteCopyIconSuccess } from 'shared/assets/icons/invitation-copy-success-white-crm.svg'
import SearchDropdown from 'shared/components/SearchDropdown'


function ResidentInvitesAddScreen(props) {
  const { selectedProperty: property, user } = useStore()
  const [result, setResult] = useState()
  const [resultData, setResultData] = useState()
  const search = useSearch()
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const [buildingFilter, setBuildingFilter] = useState(search.building || '')

  const units = useMemo(() => {
    return property.unit_types
      .filter(({ unit_types_configuration_options }) => unit_types_configuration_options.ready_for_publish)
      .map(({ units }) => units)
      .flat()
      .filter((unit) => (buildingFilter ? unit.building_name === buildingFilter : true))
      .sort(smartSort('name', 'asc'))
      .map((unit) => ({ value: unit.id, label: `#${unit.name}` }))
  }, [property, buildingFilter])

  const buildings = useMemo(() => {
    return arrayUniqueByKey(
      property.unit_types
        .filter(({ unit_types_configuration_options }) => unit_types_configuration_options.ready_for_publish)
        .map(({ units }) => units)
        .flat()
        .map((unit) => ({ building: unit.building_name })),
      'building',
    )
      .map(({ building }) => building)
      .map((building) => ({ value: building, label: building }))
      .sort(smartSort('label'))
  }, [property])

  function currentPropertyAsBuiding() {
    return property.unit_types
      .filter(({ unit_types_configuration_options }) => unit_types_configuration_options.ready_for_publish)
      .some(({ property }) => property.properties_configuration_options.building_as_property)
  }

  const location = useLocation()

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(errorMessages.required),
    lastName: Yup.string().required(errorMessages.required),
    email: Yup.string().matches(EMAIL_REGX, 'Invalid email address').required(errorMessages.required),
    unit: Yup.number().required(errorMessages.required),
  })
  const formik = useFormik({
    initialValues: {
      email: '',
      unit: (search.unit ? Number(search.unit) : ''),
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema,
    isInitialValid: false,
    onSubmit,
  })

  async function onSubmit() {
    const { unit, firstName, lastName, email, phone} = formik.values

    try {
      const { data } = await upsertInvite({
        unit_id: Number(unit),
        prospect_full_name: `${firstName} ${lastName}`,
        prospect_email: email,
        phone_number: phone,
        // last_sent_date: '',
        // invitation_status: '',
        agent_id: user.id,
      })
      setResultData(data.upsert_prospect_invitations[0])
      setResult(InviteCreateStatus.SUCCESS)
    } catch (error) {
      setResult(InviteCreateStatus.ERROR)
    }
  }

  // useEffect(() => {
  //   setResult(InviteCreateStatus.SUCCESS)
  // }, [])

  function onBack() {
    location.history.back()
  }

  function hasError(field) {
    return formik.errors[field] && formik.touched[field]
  }

  function onStatusPopupClose() {
    setResult(null)
    if (result === InviteCreateStatus.SUCCESS) {
      location.history.back()
    }
  }

  useEffect(() => {
    if (units.length === 1 && !search.unit) {
      formik.setFieldValue('unit', units[0].value)
    }
  }, [units, search])

  return (
    <Page className="New Invite">
      <div className="flex items-center justify-between">
        <div className="text-[24px] font-semibold">New Invite</div>
        <div className="items-center hidden lg:flex">
          <Button rounded className="mr-[16px]" variant="secondary" onClick={onBack}>
            Cancel
          </Button>
          <Button
            disabled={!formik.isValid || formik.isSubmitting}
            onClick={() => {
              formik.submitForm()
            }}
            rounded
            loading={formik.isSubmitting}
          >
            Save
          </Button>
        </div>
      </div>
      <div className="mt-[56px]">
        <form onSubmit={formik.handleSubmit}>
          <div className="lg:w-1/2">
            <div class="grid grid-cols-2 gap-[18px] w-full">
              {currentPropertyAsBuiding() && (
                <div className="col-span-2 lg:col-span-1">
                  {/* <Dropdown
                    disabled={search.unit}
                    items={buildings}
                    drawerMode={isMobile}
                    className="w-full"
                    placeholder="Building Name"
                    value={buildingFilter}
                    onChange={setBuildingFilter}
                  /> */}
                   <SearchDropdown isDisabled={search.unit} options={buildings} value={buildings.find(({value})=>value === buildingFilter)}  onChange={e=>setBuildingFilter(e.value)}/>
                </div>
              )}
              <div className="col-span-2 lg:col-span-1">
                <div>
                  {/* <Dropdown
                    drawerMode={isMobile}
                    items={units}
                    disabled={search.unit || units.length === 1}
                    className="w-full"
                    placeholder="Unit Number"
                    value={formik.values.unit}
                    onChange={(val) => formik.setFieldValue('unit', val)}
                  /> */}
                   <SearchDropdown isDisabled={search.unit || units.length === 1} options={units} value={units.find(({value})=>value === formik.values.unit)} onChange={(e) => formik.setFieldValue('unit', e.value)}/>
                </div>
              </div>
              <div className="col-span-1">
                <Input
                  name="firstName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="First Name*"
                />
                {hasError('firstName') && <ErrorMessage text={formik.errors.firstName} />}
              </div>
              <div className="col-span-1">
                <Input
                  name="lastName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="Last Name*"
                />
                {hasError('lastName') && <ErrorMessage text={formik.errors.lastName} />}
              </div>
              <div className="col-span-2 lg:col-span-1">
                <Input name="email" onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="Email*" />
                {hasError('email') && <ErrorMessage text={formik.errors.email} />}
              </div>
              <div className="col-span-2 lg:col-span-1">
                <Input
                  name="phone"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="Phone Number"
                />
              </div>
            </div>
          </div>
          <button type="submit" hidden></button>
        </form>
      </div>
      <div className="items-center lg:hidden mt-[84px]">
        <Button rounded className="mr-[16px] w-full" variant="secondary" onClick={onBack}>
          Cancel
        </Button>
        <Button
          disabled={!formik.isValid || formik.isSubmitting}
          className="w-full mt-[16px]"
          onClick={formik.handleSubmit}
          rounded
          loading={formik.isSubmitting}
        >
          Save
        </Button>
      </div>
      {result && (
        <StatusPopup
          type={result}
          onClose={onStatusPopupClose}
          actions={resultData ? <CopyInvitation data={resultData} /> : ''}
        />
      )}
    </Page>
  )
}

function CopyInvitation({ data }) {
  const [copied, setCopied] = useState(false)

  function onCopy(e) {
    e.stopPropagation()
    copyToClipboard(data.invitation_text)
    setCopied(true)
  }

  return (
    <Button
      rounded
      variant="secondary"
      onClick={onCopy}
      className={`mt-[40px] !px-[24px] ${copied ? '!bg-[#16C270] text-white !border-transparent' : ''}`}
    >
      {!copied && (
        <>
          <InviteCopyIcon /> <span className="ml-[10px]">Copy Invitation</span>
        </>
      )}
      {copied && (
        <>
          <InviteCopyIconSuccess />
          <span className="ml-[10px]">Invitation Copied</span>
        </>
      )}
    </Button>
  )
}

export default ResidentInvitesAddScreen
