import { Link, useLocation } from '@tanstack/react-location'
import { getAvailableMenuItems } from 'TourApp/routes'
import { useStore } from 'TourApp/store'
import React from 'react'
import { events, posttour } from 'shared/analytics'

function BottomBar(props) {
  const { tourData, selectedApartmentId, isLineApp, setIsEngaged } = useStore()
  const apartment = tourData.units.find(({ unit }) => unit.id === selectedApartmentId)
  const PHOTOS = apartment.photos
  const VIDEOS = apartment.unit.unit_type.videos.filter((v) => v.is_official && v.is_verified)
  const lengthLimit = 5
  const location = useLocation()
  const links = getAvailableMenuItems(tourData, selectedApartmentId, VIDEOS, PHOTOS, isLineApp)

  const list = links

  function getItemsIcon(item, isActive) {
    if (list.length > lengthLimit) {
      return item.icon(isActive, { activeColor: '#A93DCB', defaultColor: '#000' })
    } else {
      return item.iconBig(isActive, { activeColor: '#A93DCB', defaultColor: '#000' })
    }
  }

  function onLinkClick({path}){
   const eventNames = {
      gallery: events.mdgc,
      wishlist: events.mwc,
      floorplans: events.mfpc,
      videos: events.mfvc,
      photos: events.mpgc,
    }
    const event = eventNames[path] || null
    if(`/${path}` !== location.current.pathname){
      setIsEngaged(true)
    }
    posttour(event)
  }

  return (
    <div className="h-[72px] w-full bg-orange flex bg-white relative z-[40]">
      {list.map((item) => (
        <div className="border-t-[1px] border-[#C7CCD9] border-solid w-full">
          <Link to={`/${item.path}`} onClick={()=>onLinkClick(item)} key={item.path} className="screen-link lg:mt-[16px]" data-test-id={item.path}>
            {({ isActive }) => {
              return (
                <div
                  className={`flex items-center justify-center h-full font-medium relative  ${
                    isActive ? 'text-[#A93DCB]' : ''
                  } ${list.length > lengthLimit ? 'text-[10px] leading-[10px]' : 'text-[12px] leading-[12px]'}`}
                >
                  <div className="flex flex-col justify-center items-center">
                    <div>{getItemsIcon(item, isActive)}</div>
                    <span className="mt-[10px] ">{item.shortTitle || item.title}</span>
                  </div>
                  {isActive && <div className="bg-[#A93DCB] h-[2px] rounded-[4px] w-full absolute bottom-0 left-0"></div>}
                </div>
              )
            }}
          </Link>
        </div>
      ))}
    </div>
  )
}

export default BottomBar
