import Page from 'CRM/components/Page'
import React, { useEffect } from 'react'
import Button from 'shared/components/Button'
// Icons
import { ReactComponent as EditIcon } from 'shared/assets/icons/edit-settings.svg'
import { ReactComponent as CloseIcon } from 'shared/assets/icons/cross-mobile-menu.svg'
import IconButton from 'shared/components/IconButton'
import { useNavigate } from '@tanstack/react-location'
import { useStore } from 'CRM/store'

function SettingsScreen(props) {
  const { setTitle, user } = useStore()
  const navigate = useNavigate()

  useEffect(() => {
    setTitle(`Settings`)
  }, [setTitle])

  return (
    <Page>
      <div className="flex items-center justify-between">
        <span className="text-[24px] font-medium lg:font-semibold">Settings</span>
        <Button
          rounded
          className="!bg-[#A93DCB] hidden lg:inline-block"
          onClick={() => {
            navigate({ to: '/apartments' })
          }}
        >
          Close
        </Button>
        <button
            className='lg:hidden'
            type="button"
            onClick={() => {
              window.history.back()
            }}
          >
            <CloseIcon />
          </button>
      </div>
      <div className="mt-[39px] lg:mt-[60px]">
        <div>
          <span className="text-[18px] font-semibold">User settings</span>
        </div>
        <div className="lg:mt-[40px] flex flex-col lg:flex-row lg:items-center">
          <div className="lg:w-1/5 mt-[36px] lg:mt-0">
            <div className="uppercase text-[#5F6787] lg:text-[#A93DCB] text-[12px] font-semibold">username</div>
            <div className="text-[16px] font-medium mt-[12px]">
              {user.first_name} {user.last_name}
            </div>
          </div>
          <div className="lg:w-1/5 mt-[36px] lg:mt-0">
            <div className="uppercase text-[#5F6787] lg:text-[#A93DCB] text-[12px] font-semibold">password</div>
            <div className="flex items-center">
              <div className="text-[16px] font-medium mt-[12px]">
                <div>***********</div>
              </div>
              <div className="flex items-center ml-[30px]">
                <IconButton
                  onClick={() => {
                    navigate({ to: '/update-password' })
                  }}
                >
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:mt-[40px] flex flex-col lg:flex-row lg:items-center">
          <div className="lg:w-1/5 mt-[36px] lg:mt-0">
            <div className="uppercase text-[#5F6787] lg:text-[#A93DCB] text-[12px] font-semibold">title</div>
            <div className="text-[16px] font-medium mt-[12px]">{user.user_type.user_type}</div>
          </div>
          <div className="lg:w-1/5 mt-[36px] lg:mt-0">
            <div className="uppercase text-[#5F6787] lg:text-[#A93DCB] text-[12px] font-semibold">phone number</div>
            <div className="text-[16px] font-medium mt-[12px]">{user.phone_number || '-'}</div>
          </div>
          <div className="lg:w-1/5 mt-[36px] lg:mt-0">
            <div className="uppercase text-[#5F6787] lg:text-[#A93DCB] text-[12px] font-semibold">email</div>
            <div className="text-[16px] font-medium mt-[12px]">{user.email}</div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default SettingsScreen
