import React from 'react'

import Header from 'MediaManager/components/Header'

function HeaderLayout({ children, title }) {
  return (
    <>
      <Header title={title} />
      <div className="h-[calc(100vh-80px)] overflow-y-auto">{children}</div>
    </>
  )
}

export default HeaderLayout
