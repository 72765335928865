import React from 'react'
import { ReactComponent as Alert } from './icons/alert.svg'

function MobileGuard({ className = '' }) {
  return (
    <div
      className={`fixed top-0 left-0 z-[1000] backdrop-blur-xl bg-[#d5d5d551] flex items-center justify-center w-full h-full ${className}`}
    >
      <div className="text-[24px] text-center p-[48px] flex flex-col items-center">
        <div className="text-[48px] mb-[32px] ">
          <Alert />
        </div>
        <div className='leading-[32px]'>App is available only on tablet and desktop devices</div>
        <div className='text-[16px] mt-[16px]'>Please switch to your tablet or desktop with compatible web-browser</div>
      </div>
    </div>
  )
}

export default MobileGuard
