import React, { useMemo, useState } from 'react'
import { ReactComponent as UserMobile } from 'shared/assets/icons/user-mobile.svg'
import { ReactComponent as DashboardIcon } from 'shared/assets/icons/dashboard.svg'
import { ReactComponent as SettingsIcon } from 'shared/assets/icons/settings-crm.svg'
import { ReactComponent as LogoutIcon } from 'shared/assets/icons/logout-crm-red.svg'
import { ReactComponent as SupportIcon } from 'shared/assets/icons/support.svg'
import { ReactComponent as BuildingIcon } from 'shared/assets/icons/building-crm.svg'
import { ReactComponent as CloseMobileMenu } from 'shared/assets/icons/cross-mobile-menu.svg'
import { ReactComponent as PropertyArrowDown } from 'shared/assets/icons/property-dropdown-arrow-down-mobile.svg'
import { ReactComponent as ResidentAppIcon } from 'shared/assets/icons/resident-app-icon.svg'

import { objectCopy } from 'shared/helpers/general'
import sparxLogo from 'shared/assets/images/sparx-logo.png'
import { Link } from '@tanstack/react-location'
import { smartSort } from 'shared/helpers/arrays'
import FeatureGuard, { EFeatures } from 'shared/components/FeatureGuard'

function MobilePropertyDropdown({ property, properties, onPropertySelected }) {
  const [toggled, setToggled] = useState(false)

  function onPicked(property) {
    setToggled(false)
    onPropertySelected(property)
  }

  const filteredProperties = useMemo(() => {
    return objectCopy(properties)
      .filter(({ property_name }) => property_name !== property.property_name)
      .filter(({ unit_types }) => unit_types.some(({ unit_types_configuration_options }) => unit_types_configuration_options.ready_for_publish))
  }, [properties, property])

  function hasMultipleProperties() {
    return filteredProperties.length > 1
  }

  return (
    <div className="flex flex-col w-full">
      <div
        className="flex items-center justify-between w-full cursor-pointer"
        onClick={() => {
          hasMultipleProperties() && setToggled(!toggled)
        }}
      >
        <div className="flex items-center">
          <BuildingIcon /> <span className="ml-[14px] font-medium">{property.property_name}</span>
        </div>
        {properties.length > 1 && <PropertyArrowDown />}
      </div>
      {toggled && (
        <div className="pl-[34px] mt-[12px] font-normal">
          {filteredProperties.sort(smartSort('property_name')).map((property) => (
            <div className="mt-[20px]">
              <button
                type="button"
                className="text-left"
                onClick={() => {
                  onPicked(property)
                }}
              >
                {property.property_name}
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

function MobileMenu({ user, logo, property, properties, onPropertySelected, onLogout, onClose, dashboardUrl }) {
  return (
    <div className="h-screen absolute top-0 bg-white w-full z-[300] left-0 flex flex-col justify-between">
      <div className="px-[24px]">
        <div className="mt-[20px] flex items-center justify-between">
          <div>
            <img src={logo} alt="logo" className={`${logo ? '' : 'hidden'} h-[32px] lg:h-[34px]`} />
          </div>
          <div>
            <button type="button" onClick={onClose} className='mr-[10px] mt-[5px]'>
              <CloseMobileMenu />
            </button>
          </div>
        </div>
        <div className="my-[50px] flex items-center">
          <div className="h-[45px] w-[45px] bg-[#C7CCD9] rounded-full flex items-center justify-center ">
            {' '}
            <UserMobile />
          </div>
          <span className="ml-[16px] text-[18px] font-semibold">
            {user.first_name} {user.last_name}
          </span>
        </div>
        <div>
          <div className="text-[18px] py-[24px] flex items-center font-medium border-b-[1px] border-solid border-[#C7CCD9]">
            <MobilePropertyDropdown
              property={property}
              properties={properties}
              onPropertySelected={onPropertySelected}
            />
          </div>
          <a href={dashboardUrl} target="_blank" rel="noreferrer">
            <div className="text-[18px] py-[24px] flex items-center font-medium border-b-[1px] border-solid border-[#C7CCD9]">
              <DashboardIcon /> <span className="ml-[14px]">Dashboard</span>
            </div>
          </a>
          <FeatureGuard feature={EFeatures.INVITES} data={{property}}>
            <Link to="/resident-invites" onClick={onClose}>
              <div className="text-[18px] py-[24px] flex items-center font-medium border-b-[1px] border-solid border-[#C7CCD9]">
                <ResidentAppIcon />
                <span className="ml-[14px]">Residents</span>
              </div>
            </Link>
          </FeatureGuard>
          <a href="mailto:support@sparx.ai">
            <div className="text-[18px] py-[24px] flex items-center font-medium border-b-[1px] border-solid border-[#C7CCD9]">
              <SupportIcon /> <span className="ml-[14px]">Support</span>
            </div>
          </a>
          <Link to="/settings" onClick={onClose}>
            <div className="text-[18px]  py-[24px] flex items-center font-medium border-b-[1px] border-solid border-[#C7CCD9]">
              <SettingsIcon /> <span className="ml-[14px]">Settings</span>
            </div>
          </Link>
          <div className="text-[18px] py-[24px]  font-medium border-b-[1px] border-solid border-[#C7CCD9] text-[#F13C3C]">
            <buton type="button" className="flex items-center" onClick={onLogout}>
              <LogoutIcon /> <span className="ml-[14px]">Logout</span>
            </buton>
          </div>
        </div>
      </div>
      <div className="h-[84px] shrink-0 bg-[#FAFAFA] border-t-[1px] border-[#C7CCD9] border-solid flex flex-col items-center justify-center">
        <div>
          <img className="h-[17px]" src={sparxLogo} alt="logo-sparx" />
        </div>
        <div className="text-[10px] mt-[16px] text-[#C7CCD9]">
          © All Rights Reserved SparX Inc. {new Date().getFullYear()}
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
