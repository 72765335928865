export function getChipVariant(status) {
  switch (status.toLowerCase()) {
    case 'pending':
      return 'grey'
    case 'accepted':
      return 'green'
    default:
      break
  }
}
