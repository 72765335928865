import { agentCheckout } from 'Resident/services/checkout'
import { useStore } from 'Resident/store'
import { useFormik } from 'formik'
import React from 'react'
import Button from 'shared/components/Button'
import Checkbox from 'shared/components/Checkbox'
import ErrorMessage from 'shared/components/ErrorMessage'
import Input from 'shared/components/Input'
import TinyModal from 'shared/components/TinyModal'
import { EMAIL_REGX } from 'shared/constants'
import { errorMessages } from 'shared/settings'
import * as Yup from 'yup'

function SelectPackageModal({ onClose, pack }) {
  const { appData } = useStore()
  const validationSchema = Yup.object().shape({
    email: Yup.string().matches(EMAIL_REGX, 'Invalid email address'),
  })
  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      consent: false,
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema,
    isInitialValid: false,
    onSubmit,
  })

  async function onSubmit() {
    try {
      await agentCheckout(
        {
          selected_package: {
            id: pack.id,
          },
          prospect_email: formik.values.email,
          unit_id: appData.unit.id,
          user_id: Number(appData.agentId),
          prospect_full_name: formik.values.fullName,
          email: appData.agentEmail,
          bedroom_type: 'Queen Bed'
        },
        pack.vendor.full_name,
      )
    } catch (error) {
      alert("Error occured when sending form, please try again later ...")
    } finally {
      onClose()
    }
  }

  const submitDisabled =
    (formik.values.email && !formik.values.consent) ||
    !formik.values.fullName ||
    Object.values(formik.errors).some((val) => val)

  

  return (
    <TinyModal className="!w-[590px]" light onClose={onClose} closeIcon>
      <div className="lg:px-[64px] lg:py-[40px]">
        <div className="text-[22px] leading-[22px] text-center">Resident Details</div>
        <div className="text-[18px] font-light mt-[12px] text-center">Please enter resident’s details</div>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-[40px]">
            <Input
              placeholder="Full name *"
              name="fullName"
              value={formik.values.fullName}
              onChange={formik.handleChange}
            />
            <Input
              placeholder="Email"
              className="mt-[16px]"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.email}
            />

            <ErrorMessage text={formik.errors.email} />
            {formik.values.email && (
              <div className="flex items-center font-light mt-[16px]">
                <Checkbox
                  onChange={() => {
                    formik.setFieldValue('consent', !formik.values.consent)
                  }}
                  checked={formik.values.consent}
                />
                <span className="ml-[10px]">I consent for SparX to send me emails. </span>
              </div>
            )}
          </div>
          <div className="mt-[40px] flex flex-col items-center">
            <Button type="submit" disabled={submitDisabled} rounded className="lg:min-w-[220px]">
              Done
            </Button>
            <div className="text-[rgba(61,66,86,0.6)] text-[14px] font-light mt-[16px]">
              Requires a minimum of 90 day contract
            </div>
          </div>
        </form>
      </div>
    </TinyModal>
  )
}

export default SelectPackageModal
