import React from 'react'

function Chip({ className, children, ...props }) {
  return (
    <div
      className={`h-[32px] py-[15px] px-[18px] lg:px-[32px] rounded-full flex items-center font-medium text-[14px] lg:text-[16px] backdrop-blur-[20px] bg-[rgba(255,255,255,.5)] ${className}`}
      {...props}
    >
      {children}
    </div>
  )
}

export default Chip
