import moment from 'moment'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { generateUUID, isApartmentApp, isIFrameApp, isPostTourApp, isProductionEnv } from 'shared/helpers/general'
import { useStore as usePostTourStore } from 'TourApp/store'
import { useStore as useIFrameStore } from 'IFrameApp/store'
import { useStore as useResidentStore } from 'Resident/store'
import { eventDictionary } from './eventDictionary'
import { analyticsDebugEnabled } from 'shared/helpers/analytics'
import { postMessage } from 'IFrameApp/helpers/general'
import { EAppType } from 'Resident/constants/appType'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

const eventsBuffer = {
  events: [],
  subscribeCb: undefined,
  subscribe: function (cb) {
    this.subscribeCb = cb
  },
  add: function (event) {
    this.events.push(event)
    if (this.subscribeCb) {
      this.subscribeCb(this.events)
    }
  },
}

//==========================================================================
// Sparx Session ID - https://sparx-app.atlassian.net/browse/AT-1004
// Should be generated once on document load and persistent for session until page refresh
//==========================================================================
const sparxSessionId = generateUUID()

window.eventsBuffer = eventsBuffer

const firebaseApp = initializeApp(firebaseConfig)
const firebaseAnalytics = getAnalytics(firebaseApp)


function getAppVersion(app){
  return process.env[`REACT_APP_${app}_VERSION`] || process.env.REACT_APP_VERSION
}

export const products = {
  tp: {
    appName: 'Post Tour 1.2',
    abr: 'TP',
    version: getAppVersion('POST_TOUR'),
  },
  ap: {
    appName: 'Apartment Page 1.0',
    abr: 'AP',
    version: getAppVersion('APARTMENT_PAGE'),
  },
  st: {
    appName: 'IFrame App 1.0',
    abr: 'ST',
    version: getAppVersion('IFRAME'),
  },
  [EAppType.AGENT] : {
    appName: 'Furniture as Amenity 1.0',
    abr: 'FAA',
    version: getAppVersion('FAA'),
  },
  [EAppType.INSTANT]:{
    appName: 'Instant Access Resident Page 1.0',
    abr: 'IARP',
    version: getAppVersion('IARP'),
  },
  [EAppType.MINIMAL]:{
    appName: 'Resident Page for CORT REP 1.0',
    abr: 'RPCO',
    version: getAppVersion('RPCO'),
  },
  [EAppType.STANDART]:{
    appName: 'Resident Page 1.0',
    abr: 'RP',
    version: getAppVersion('RP'),
  }
  
}

function getSelectedUnit(store) {
  if (store.selectedApartmentId) {
    // Because `id` can be as string and as number
    // eslint-disable-next-line eqeqeq
    return store.tourData?.unit || store.tourData?.units?.find(({ unit }) => unit.id == store.selectedApartmentId)?.unit
  } else {
    return
  }
}

//==========================================================================
// Compose special unit_type_name
//==========================================================================

function getUnitTypeName(type) {
  var identifier = ''
  var trimmedName = type.name.trim()

  if (trimmedName !== '') {
    identifier = type.name
  }

  const nick_name = type.nick_name || type.unit_type

  if (nick_name && nick_name.trim() !== '' && nick_name.trim() !== trimmedName) {
    if (identifier === '') {
      identifier = nick_name
    } else {
      identifier += ' - ' + nick_name
    }
  }

  if (type.unit_types_configuration_options.is_mirrored) {
    identifier += ' (mirrored)'
  }

  return identifier
}

//==========================================================================
// Produces selected unit properties in case if some unit is already selected
//==========================================================================
function unitEventProperties(store) {
  const unit = getSelectedUnit(store)
  if (unit) {
    // Group_4 (all props below)
    return {
      unit_id: store.isLineApp ? null : unit.id,
      unit_number: unit.unit_number,
      unit_type_id: unit.unit_type.id,
      unit_type_name: getUnitTypeName(unit.unit_type),
      unit_type_description: unit.unit_type.name,
    }
  } else {
    return {}
  }
}

//==========================================================================
// Generate respective product name for tour app
//==========================================================================
function getTourAppProductName() {
  if (isPostTourApp()) {
    return products.tp
  }
  if (isApartmentApp()) {
    return products.ap
  }
}

//==========================================================================
// Produces overall available tour properties at particular state
//==========================================================================
export const tourEventProperties = (store) => {
  if (store.tourData) {
    const { tourData, source } = store
    const unit = getSelectedUnit(store)?.unit_type
    const { agent } = store.tourData
    return {
      ...(isApartmentApp() && source ? { source } : {}),
      tour_id: store.tourData?.uuid || '', // Group_2
      ...(agent
        ? {
            leasing_agent_id: agent?.id, // Group_3 (all props below)
            leasing_agent_name: `${agent?.first_name} ${agent?.last_name}`,
          }
        : {}),
      ...(unit
        ? {
            property_id: unit.property.id,
            property_name: unit.property.property_name,
            real_estate_id: unit.property.real_estate_company.id,
            // Conditional prospect name and email
            ...(tourData.app_user_first_name
              ? { prospect_name: `${tourData.app_user_first_name || ''} ${tourData.app_user_last_name || ''}`.trim() }
              : {}),
            ...(tourData.app_user_email ? { prospect_email: tourData.app_user_email } : {}),

            real_estate_name: unit.property.real_estate_company.company_name,
          }
        : {}),
    }
  } else {
    return {}
  }
}

function send(useStore, app, eventName, transformerFn, noUnitProps = false) {
  const store = useStore.getState()
  const time = new Date()
  const eventData = {
    sparx_session_id: sparxSessionId,
    timestamp: moment(time).format(),
    product: app.abr, // Group_1
    version: app.version, // Group_1
    is_engaged: store.isEngaged,
    ...tourEventProperties(store),
    ...(noUnitProps ? {} : unitEventProperties(store)),
    ...(transformerFn ? transformerFn(store) : {}),
  }

  if (!store.skipAnalytics) {
    if (analyticsDebugEnabled()) {
      window.eventsBuffer.add({
        name: eventName,
        data: eventData,
      })
      console.log(`@${app.appName} --> event: ${eventName}`, eventData)
    }

    if (isIFrameApp()) {
      postMessage(`analytics.${eventName}`, eventData)
    } else {
      logEvent(firebaseAnalytics, eventName, eventData)
    }
  }
}

//==========================================================================
// analytics interface for sending events to Firebase
// Usage: [appName](eventName: string, transformerFn?: (store)=>Record<any, any>)
//==========================================================================

export const events = eventDictionary

export const posttour = (eventName, transformerFn, noUnitProps) => {
  try {
    if (eventName.fn) {
      eventName.fn(usePostTourStore)
    }
    send(usePostTourStore, getTourAppProductName(), eventName.fn ? eventName.name : eventName, transformerFn, noUnitProps)
  } catch (error) {
    console.log(`Error in posttour event. Analytics event ${eventName} failed to send`, error)
  }
}

export const iframe = (eventName, transformerFn) => {
  
  try {
    if (eventName.fn) {
      eventName.fn(useIFrameStore)
    }
    send(useIFrameStore, products.st, eventName.fn ? eventName.name : eventName, transformerFn)
  } catch (error) {
    console.log(`Error in iframe event. Analytics event ${eventName} failed to send`, error)
  }
}

export const rpevent = (eventName, transformerFn, appFilter) => {
  try {
    if (eventName.fn) {
      eventName.fn(useIFrameStore)
    }
  
    const { appType } = useResidentStore.getState()
  
    //==========================================================================
    // Block if current app is not included in the expected app events list, otherwise allow all apps
    //==========================================================================
    if(appFilter && !appFilter.includes(appType)){
      return
    }
  
    send(useResidentStore, products[appType], eventName.fn ? eventName.name : eventName, transformerFn)
  } catch (error) {
    console.log(`Error in rpevent. Analytics event ${eventName} failed to send`, error)
  }
}

// Group_1: product
// Group_2: Group_1, tour_id
// Group_3: real_estate_id, real_estate_name, property_id, property_name, leasing_agent_id, leasing_agent_name
// Group_4: Group_3, unit_type_id, unit_type_name (may be null), unit_type_description (may be null), unit_id, unit_number
// Group_5: Group_4, physical_room_id, physical_room_name, design_id, design_name, vendor_id, vendor_name
// Group_6: Group_5, image_id
// Group_7: Group_6, asset_id, asset_name
