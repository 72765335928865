import React from 'react'
import Footer from 'CRM/components/Footer'

function LoginLayout({ children, className }) {
  return (
    <>
      <div className={`h-full lg:h-[calc(100vh-40px)] flex items-center justify-center ${className}`}>{children}</div>
      <Footer className="hidden lg:flex" />
    </>
  )
}

export default LoginLayout
