import Input from 'MediaManager/components/Form/Input'
import LogoUpload from 'MediaManager/components/LogoUpload'
import ScreenLayout from 'MediaManager/layouts/ScreenLayout'
import { useEffect, useMemo, useState } from 'react'
import Checkbox from 'shared/components/Checkbox'
import Select from 'shared/components/Select'
import Toggle from 'shared/components/Toggle'
// import { propertySettings } from './settings'
import Button from 'shared/components/Button'
import {
  getPropertyById,
  getPropertyConfigurationOptionsFields,
  upsertProperty,
  upsertPropertyConfiguration,
} from 'MediaManager/services/property'
import { useFormik } from 'formik'
import { useNavigate, useSearch } from '@tanstack/react-location'
import { configurationOptions, snakeCaseToTitleCase, whitespacesToUnderscore } from 'shared/helpers/general'
import { uploadFileToS3 } from 'MediaManager/helpers/s3'
import ActionSuccessModal from 'MediaManager/components/ActionSuccessModal'
import Loader from 'shared/components/Loader'
import ExternalPropertyMappingsEditor from './components/ExternalMappings'
import { USStates } from 'MediaManager/constants/states'
import * as Yup from 'yup'
import { EMAIL_REGX } from 'shared/constants'
import { errorMessages } from 'shared/settings'
import ErrorMessage from 'shared/components/ErrorMessage'

function PropertyEditScreen(props) {
  // const settings = propertySettings
  const [propertyConfigurationOptionsFields, setPropertyConfigurationOptionsFields] = useState([])
  const [data, setData] = useState({})
  const { id, real_estate_company_id } = useSearch()
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const propertyMainFields = [
    'id',
    'property_name',
    'number_of_buildings',
    'number_of_units',
    'leasing_office_main_email',
    'phone_number',
    'website',
    'long_name',
    'city',
    'zip',
    'state',
    'street',
    'email',
    'country',
    'class',
    'listing_option',
    'real_estate_company_id',
  ]

  const validationSchema = Yup.object().shape({
    leasing_office_main_email: Yup.string().matches(EMAIL_REGX, 'Invalid email address'),

    property_name: Yup.string().required(errorMessages.required),
    street: Yup.string().required(errorMessages.required),
    city: Yup.string().required(errorMessages.required),
    zip: Yup.string().required(errorMessages.required),
    number_of_units: Yup.number().required(errorMessages.required),
    number_of_buildings: Yup.number().required(errorMessages.required),
  })

  const formik = useFormik({
    initialValues: {},
    validateOnChange: true,
    validateOnBlur: true,
    initialTouched: false,
    validationSchema,
    onSubmit: async () => {
      try {
        const values = { ...formik.values, real_estate_company_id }
        const propertyData = propertyMainFields.reduce((acc, cur) => ({ ...acc, [cur]: values[cur] }), {})
        // Automatically detect and convert numeric string values to numbers
        Object.keys(propertyData).forEach((key) => {
          const value = propertyData[key]
          if (
            typeof value === 'string' &&
            value.trim() !== '' &&
            !isNaN(value) &&
            !['zip', 'phone_number'].includes(key)
          ) {
            propertyData[key] = Number(value)
          }
        })

        const images = {}

        for (const image of Object.keys(files)) {
          const file = files[image]
          if (file === null) {
            images[image] = ''
            continue
          }
          const result = await uploadFileToS3(
            file,
            whitespacesToUnderscore(`properties/${propertyData.property_name}/${image}/${file.name}`),
          )
          images[image] = result
        }

        const { data } = await upsertProperty({ ...propertyData, ...images })
        const upsertedPropertyId = data.upsert_properties[0].id
        await upsertPropertyConfiguration({
          ...propertyConfigurationOptionsFields.reduce(
            (acc, cur) => ({ ...acc, [cur.name]: formik.values[cur.name] }),
            {},
          ),
          id: configurationOptions(data).id,
          property_id: upsertedPropertyId,
        })
        setResultValue('success')
        navigate({ to: `/property/edit?id=${upsertedPropertyId}` })
      } catch (error) {
        console.error(error)
        setResultValue('error')
      }
    },
  })

  useEffect(() => {
    if (!id) {
      setIsLoading(false)
    }
  }, [id])

  useEffect(() => {
    if (id && propertyConfigurationOptionsFields && propertyConfigurationOptionsFields.length > 0) {
      ;(async () => {
        const { data } = await getPropertyById(
          id,
          propertyConfigurationOptionsFields.map((f) => f.name),
        )
        const property = data.properties_by_id
        setData(property)
        const values = propertyMainFields.reduce((acc, cur) => ({ ...acc, [cur]: property[cur] }), {})
        const configurationOptionsValues = configurationOptions(property)
        delete configurationOptionsValues.id
        formik.setValues({ ...values, ...configurationOptionsValues })

        setIsLoading(false)
      })()
    } else {
      setData({})
    }
  }, [id, propertyConfigurationOptionsFields])

  useEffect(() => {
    ;(async () => {
      const data = await getPropertyConfigurationOptionsFields()
      setPropertyConfigurationOptionsFields(data.filter((data) => !['is_deleted', 'property'].includes(data.name)))
    })()
  }, [])

  const settings = useMemo(() => {
    return propertyConfigurationOptionsFields
      .filter((field) => !['id', 'is_active'].includes(field.name))
      .map((field) => {
        return {
          label: snakeCaseToTitleCase(field.name),
          name: field.name,
        }
      })
  }, [propertyConfigurationOptionsFields])

  const listingOptions = ['show_all_units', 'show_all_units_in_lines_with_vacant_units', 'show_only_vacant_units'].map(
    (i) => ({ label: snakeCaseToTitleCase(i), value: i }),
  )

  const [files, setFiles] = useState({})

  const [resultValue, setResultValue] = useState(null)

  const statesOptions = USStates.map((state) => ({ label: state.short, value: state.short }))

  const isValid = useMemo(() => {
    return Object.values(formik.errors).every((v) => !v)
  }, [formik, data])

  function getFieldError(field) {
    return formik.errors[field] && formik.touched[field] ? formik.errors[field] : null
  }

  return (
    <ScreenLayout title={`${id ? 'Edit' : 'Add'} property`} property>
      {isLoading ? (
        <div className="h-full w-full flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          {' '}
          {resultValue && (
            <ActionSuccessModal
              action={`Property`}
              successText="has been successfully upserted."
              errorText="upserting failed. Please try again."
              state={resultValue}
              error={'Please try again later.'}
              skipConfirm
              skipBack
              onClose={() => {
                window.history.back()
              }}
            />
          )}
          <form onSubmit={formik.handleSubmit}>
            <div className="flex items-center mt-[32px]">
              {id && (
                <div className="flex-1 max-w-[10%] mr-[16px]">
                  <Input
                    name="id"
                    label="ID"
                    placeholder="ID"
                    className="cursor-not-allowed"
                    disabled
                    value={formik.values.id}
                    onChange={formik.handleChange}
                  />
                </div>
              )}
              <div className="flex-1 ">
                <Input
                  name="property_name"
                  label="Name"
                  error={getFieldError('property_name')}
                  required
                  onBlur={formik.handleBlur}
                  placeholder="Enter name"
                  value={formik.values.property_name}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="flex-1 ml-[16px]">
                <Input
                  name="long_name"
                  label="Long name"
                  placeholder="Enter name"
                  value={formik.values.long_name}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="flex items-center mt-[16px]">
              <div className="flex-1 max-w-[5%]">
                <div className="text-sm mb-2 opacity-50">Is Active</div>
                <Toggle
                  name="is_active"
                  checked={formik.values.is_active}
                  onChange={() => {
                    formik.setFieldValue('is_active', !formik.values.is_active)
                  }}
                />
              </div>
              <div className="flex-1 ml-[16px]">
                <Input
                  name="number_of_buildings"
                  label="# Buildings"
                  error={getFieldError('number_of_buildings')}
                  onBlur={formik.handleBlur}
                  required
                  placeholder="Enter # of buildings"
                  value={formik.values.number_of_buildings}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="flex-1 ml-[16px]">
                <Input
                  name="number_of_units"
                  label="# Units"
                  error={getFieldError('number_of_units')}
                  onBlur={formik.handleBlur}
                  placeholder="Enter # of units"
                  required
                  value={formik.values.number_of_units}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="flex-1 ml-[16px]">
                <Input
                  name="class"
                  label="Class"
                  placeholder="Enter class"
                  value={formik.values.class}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="flex-1 ml-[16px]">
                <Select
                  name="listing_option"
                  label="Listing Type"
                  options={listingOptions}
                  value={listingOptions.find((o) => o.value === formik.values.listing_option)}
                  onChange={(e) => formik.setFieldValue('listing_option', e.value)}
                />
              </div>
            </div>
            <div className="flex items-center mt-[16px]">
              <div className="flex-1">
                <Input
                  name="leasing_office_main_email"
                  label="Email"
                  placeholder="Enter email"
                  error={getFieldError('leasing_office_main_email')}
                  value={formik.values.leasing_office_main_email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="flex-1 ml-[16px]">
                <Input
                  name="phone_number"
                  label="Tel. Number"
                  placeholder="Enter telephone"
                  value={formik.values.phone_number}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="flex-1 ml-[16px]">
                <Input
                  name="website"
                  label="Website"
                  placeholder="Enter website"
                  value={formik.values.website}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 mt-[16px]">
              <div>
                <div className="flex mt-[16px]">
                  <div className="flex-1">
                    <Input
                      name="city"
                      label="City"
                      placeholder="Enter city"
                      value={formik.values.city}
                      required
                      error={getFieldError('city')}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="flex-1 ml-[16px]">
                    <Input
                      name="zip"
                      label="ZIP Code"
                      placeholder="Enter zip code"
                      value={formik.values.zip}
                      error={getFieldError('zip')}
                      required
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="flex mt-[16px]">
                  <div className="flex-1">
                    <Select
                      required
                      label="State"
                      value={statesOptions.find((option) => option.label === formik.values.state)}
                      options={statesOptions}
                      onChange={(val) => formik.setFieldValue('state', val.label)}
                      onBlur={() => {
                        formik.setFieldTouched('state', true)
                      }}
                    />
                  </div>
                  <div className="flex-1 ml-[16px]">
                    <Input
                      name="country"
                      label="Country"
                      placeholder="Select country"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="flex mt-[16px]">
                  <div className="flex-1">
                    <Input
                      name="street"
                      label="Street"
                      placeholder="Enter street"
                      value={formik.values.street}
                      onChange={formik.handleChange}
                      required
                      error={getFieldError('street')}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-[16px] ml-[16px]">
                <LogoUpload
                  onPicked={(file) => setFiles((files) => ({ ...files, logo_path: file }))}
                  name="property_logo"
                  label="Logo"
                  logo={data.logo_path}
                />
                <LogoUpload
                  onPicked={(file) => setFiles((files) => ({ ...files, property_portrait_image: file }))}
                  name="property_portrait_image"
                  label="Portrait image"
                  logo={data.property_portrait_image}
                />
                <LogoUpload
                  onPicked={(file) => setFiles((files) => ({ ...files, property_landscape_image: file }))}
                  name="property_landscape_image"
                  label="Landscape image"
                  logo={data.property_landscape_image}
                />
              </div>
            </div>

            {data.id && <ExternalPropertyMappingsEditor propertyId={data.id} mappings={data.external_properties} />}

            <div className="my-[32px]">
              <div className="text-sm mb-2 opacity-50">Property settings</div>
              <div className="grid grid-cols-4 gap-[16px]">
                {settings.map((s) => (
                  <div className="flex items-center" key={s.name}>
                    <Checkbox
                      name={s.name}
                      checked={formik.values[s.name]}
                      onChange={() => {
                        formik.setFieldValue(s.name, !formik.values[s.name])
                      }}
                    />
                    <div className="ml-3 truncate">{s.label}</div>
                  </div>
                ))}
              </div>
            </div>
            {!isValid && (
              <div className="flex justify-end w-full mb-[16px]">
                <ErrorMessage
                  text="Some fields are missing value or values are not correct. Please check entered info."
                  className="!w-max"
                />
              </div>
            )}
            <div
              className={`flex justify-end items-center ${formik.isSubmitting ? 'pointer-events-none opacity-50' : ''}`}
            >
              <Button rounded variant="secondary" onClick={() => window.history.back()}>
                Cancel
              </Button>
              <Button disabled={!isValid} loading={formik.isSubmitting} rounded className="ml-[16px]" type="submit">
                {id ? 'Edit' : 'Add'}
              </Button>
            </div>
          </form>
        </>
      )}
    </ScreenLayout>
  )
}

export default PropertyEditScreen
