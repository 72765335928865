import { useEffect, useState } from 'react'

import { useStore } from 'CRM/store'

import { errorMessages } from 'shared/settings'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import ErrorMessage from 'shared/components/ErrorMessage'

import FormWrapper from './components/FormWrapper'
import { useFormik, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { validate } from 'graphql'
import { updatePassword } from 'CRM/services/user'
import { getAuth } from 'CRM/helpers/auth'
import { Link, useNavigate } from '@tanstack/react-location'

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/

export default function UpdatePasswordScreen() {
  const { setTitle } = useStore()
  const [resetPasswordShown, setResetPasswordShown] = useState(false)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current Password is a required field'),
    password: Yup.string()
      .required()
      .matches(
        passwordRules,
        'Passwords should contain a minimum of 8 characters, using a mix of uppercase and lowercase letters and numbers, not matching username',
      )
      .test('asdasd', 'New password should be different', function (value) {
        return this.parent.currentPassword !== value
      }),
    repeatPassword: Yup.string()
      .required('Repeat Password is a required field')
      .oneOf([Yup.ref('password'), null], 'Passwords do not match'),
  })

  useEffect(() => {
    setTitle('Set password')
  }, [setTitle])

  async function onSubmit(values) {
    setLoading(true)
    try {
      await updatePassword({
        token: getAuth(),
        currentPassword: values.currentPassword,
        newPassword: values.password,
      })
      navigate({ to: '/settings' })
    } catch (error) {
      if (error.response.data.error) {
        formik.setFieldError('currentPassword', `Wrong password. Try again or click Forgot Password to reset it`)
        setResetPasswordShown(true)
      }
    } finally {
      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
    },
    validateOnChange: true,
    // validateOnBlur: true,
    validationSchema,
    // validateOnMount:true,
    onSubmit,
  })

  function validateForm() {
    if (!formik.values.password || !formik.values.repeatPassword) {
      return false
    }
    if (Object.values(formik.errors).length > 0) {
      return false
    }
    return true
  }

  function getFieldError(field) {
    return formik.touched[field] && formik.errors[field]
  }

  return (
    <>
      <FormWrapper
        text={
          <>
            Enter your current password and
            <br /> set a new password:
          </>
        }
      >
        <form className="w-full flex flex-col items-center mt-[20px]" onSubmit={formik.handleSubmit}>
          <div className="w-full">
            <Input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.currentPassword}
              required
              error={getFieldError('currentPassword')}
              name="currentPassword"
              type="password"
              placeholder="Current Password"
            />
            <ErrorMessage text={getFieldError('currentPassword')} />
            {resetPasswordShown && getFieldError('currentPassword') && (
              <div className="my-[5px] flex justify-end">
                <Link to="/reset-password" className="underline font-normal text-[18px]">
                  Forgot Password?
                </Link>
              </div>
            )}
          </div>
          <div className="w-full">
            <Input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              required
              error={getFieldError('password')}
              className="mt-[16px]"
              name="password"
              type="password"
              placeholder="New Password"
            />
            <ErrorMessage text={getFieldError('password')} />
          </div>
          <div className="w-full">
            <Input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.repeatPassword}
              required
              error={getFieldError('repeatPassword')}
              name="repeatPassword"
              type="password"
              className="mt-[16px]"
              placeholder="Re-Enter New Password"
            />
            <ErrorMessage text={getFieldError('repeatPassword')} />
          </div>
          <Button
            loading={loading}
            disabled={!validateForm()}
            rounded
            type="submit"
            className="w-full lg:w-[172px] mt-[48px]"
          >
            Continue
          </Button>
        </form>
      </FormWrapper>
    </>
  )
}
