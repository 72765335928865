import ManageLineImages from 'MediaManager/components/Property/LineImages'
import ScreenLayout from 'MediaManager/layouts/ScreenLayout'
import React from 'react'

function ManagePropertyLineImagesScreen(props) {
  return (
    <ScreenLayout title="Manage line images">
      <ManageLineImages />
    </ScreenLayout>
  )
}

export default ManagePropertyLineImagesScreen
