import { getApartmentsWithRooms } from 'CRM/services/apartments'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Loader from 'shared/components/Loader'
import Select from 'shared/components/Select'
import AmenitiesEditor from './Editors/Amenities'
import WithoutDesignEditor from './Editors/WithoutDesign'
import WithDesignEditor from './Editors/WithDesign'

import { smartSort } from 'shared/helpers/arrays'
import { objectCopy, safeConfigurationOptions } from 'shared/helpers/general'
import { getPhoto } from 'MediaManager/services'
import { useSearch } from '@tanstack/react-location'

export const ETypes = {
  WITH_DESIGN: 'With design',
  WITHOUT_DESIGN: 'Without design',
  AMENITIES: 'Amenities',
}

function ImagesUpload(props) {
  const [loading, setLoading] = useState(true)
  const [properties, setProperties] = useState([])
  const [formValues, setFormValues] = useState({})
  const search = useSearch()
  const [photoId, setPhotoId] = useState(null)
  const [photo, setPhoto] = useState()

  const [editMode, setEditMode] = useState(true)

  useEffect(() => {
    if (search.state) {
      const state = JSON.parse(atob(search.state))
      setFormValues({
        ...state,
      })
    }
    if (search.photo) {
      setEditMode(true)
      setPhotoId(Number(search.photo))
    }
  }, [search])

  useEffect(() => {
    ;(async () => {
      const { data: propertiesData } = await getApartmentsWithRooms()
      setProperties(propertiesData.properties)
      setLoading(false)
    })()
  }, [])

  const propertiesOptions = useMemo(() => {
    return objectCopy(properties)
      .sort(smartSort('property_name'))
      .map(({ id, property_name }) => ({ value: id, label: property_name }))
  }, [properties])

  const typeOptions = useMemo(() => {
    return Object.entries(ETypes).map(([key, value]) => ({
      value: value,
      label: value,
    }))
  }, [])

  const handleInputChange = useCallback(
    (name, { value }, extra = {}) => {
      if (formValues[name] === value) {
        return
      }
      setFormValues((values) => ({
        ...values,
        [name]: value,
        ...extra,
      }))
    },
    [formValues],
  )

  const selectedProperty = useMemo(() => {
    return properties.find(({ id }) => id === formValues.property)
  }, [formValues, properties])

  function renderImageTypeEditor() {
    switch (formValues.type) {
      case ETypes.WITH_DESIGN:
        return (
          <WithDesignEditor
            photo={photo}
            editMode={editMode}
            setFormValues={setFormValues}
            property={selectedProperty}
            formValues={formValues}
            line={formValues.line}
            onChange={handleInputChange}
          />
        )
      case ETypes.WITHOUT_DESIGN:
        return (
          <WithoutDesignEditor
            photo={photo}
            setFormValues={setFormValues}
            property={selectedProperty}
            formValues={formValues}
            line={formValues.line}
            onChange={handleInputChange}
          />
        )
      case ETypes.AMENITIES:
        return (
          <AmenitiesEditor
            properties={properties}
            photo={photo}
            setFormValues={setFormValues}
            property={selectedProperty}
            formValues={formValues}
            line={formValues.line}
            onChange={handleInputChange}
          />
        )
      default:
        return null
    }
  }

  const lineOptions = useMemo(() => {
    if (selectedProperty) {
      return objectCopy(selectedProperty)
        .unit_types.sort(smartSort('name'))
        .map(({ id, name, unit_types_configuration_options }) => ({
          value: id,
          label: `${name}${
            safeConfigurationOptions(unit_types_configuration_options).is_mirrored ? ' (mirrored)' : ''
          }`,
        }))
    } else {
      return []
    }
  }, [selectedProperty])

  useEffect(() => {
    if (!photo && editMode && photoId) {
      ;(async () => {
        const p = await getPhoto(photoId)
        setPhoto(p.data.photos[0])
      })()
    }
  }, [photo, editMode, photoId])


  useEffect(() => {
    if (!loading && photo && lineOptions) {
      if(!photo.room_design){
        setFormValues({
          type: ETypes.AMENITIES,
          property: photo.property.id,
          displayName: photo.display_name
        })
      } else {
        setFormValues({
          type: photo.assets.length > 0 ? ETypes.WITH_DESIGN : ETypes.WITHOUT_DESIGN,
          property: photo.captured_in.unit_type.property.id,
          line: photo.captured_in.unit_type.id,
          unit: photo.captured_in.id,
          vendor: photo.room_design.main_vendor.id,
          level: photo.room_design.room.unit_type_level.id,
          room: photo.room_design.room.id,
          design: photo.room_design.id,
          displayName: photo.display_name,
          direction: photo.direction
        })
      }
      
    }
  }, [loading, photo, lineOptions])

  return (
    <div>
      {/* <ImageItemsEditor/> */}
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div>
            <div className="text-sm mb-2 opacity-50">
              Property <span className="text-[red]">*</span>
            </div>
            <Select
              onChange={(val) =>
                handleInputChange('property', val, {
                  type: undefined,
                  line: undefined,
                  unit: undefined,
                  room: undefined,
                })
              }
              value={propertiesOptions.find((property) => property.value === formValues.property) || ''}
              options={propertiesOptions}
              placeholder="Property name"
            />
          </div>
          <div className="mt-3">
            <div className="text-sm mb-2 opacity-50">
              Type <span className="text-[red]">*</span>
            </div>
            <Select
              onChange={(val) => handleInputChange('type', val)}
              value={typeOptions.find((type) => type.value === formValues.type) || ''}
              options={typeOptions}
              placeholder="Select type (with design / without design / amenities)"
              isDisabled={!formValues.property}
            />

            {lineOptions.length > 0 && formValues.type && (formValues.type !== ETypes.AMENITIES) && (
              <div>
                <div className="text-sm mb-2 opacity-50 mt-3">
                  Line <span className="text-[red]">*</span>
                </div>
                <Select
                  onChange={(val) => handleInputChange('line', val, { level: undefined })}
                  // onChange={(val) => onChange('line', val, { design: undefined, unit: undefined, room: undefined })}
                  value={lineOptions.find((line) => line.value === formValues.line) || ''}
                  options={lineOptions}
                  placeholder="Line"
                />
              </div>
            )}
          </div>
          {(formValues.line || formValues.type === ETypes.AMENITIES) && <div className="my-3">{renderImageTypeEditor()}</div>}
        </div>
      )}
    </div>
  )
}

export default ImagesUpload
