import { ApolloClient, gql, InMemoryCache } from '@apollo/client'
import { getAuth, validateToken } from 'CRM/helpers/auth'
import { filterPropertiesData } from 'MediaManager/helpers/general'
import { properties_configuration_options, unit_types_configuration_options } from 'shared/gql'
import { apiHost } from 'shared/helpers/api'
import { objectCopy } from 'shared/helpers/general'
import { mutate, query } from 'shared/helpers/graphql'

export async function getApartments() {
  const { data } = await validateToken(async () => {
    const client = new ApolloClient({
      uri: `${apiHost}/graphql`,
      cache: new InMemoryCache(),
      headers: {
        Authorization: `Bearer ${getAuth()}`,
      },
    })
    return await client.query({
      query: gql`
        query Properties {
          properties {
            property_name
            properties_configuration_options {
              approves_prospect_app
              supports_furniture_as_amenity
            }
            ${properties_configuration_options}
            id
            unit_types {
              unit_type_levels {
                id
                floor_number
                display_name
              }
              id
              name
              ${unit_types_configuration_options}
              nick_name
              size
              size_to_display
              number_of_bathrooms
              number_of_bedrooms
              unit_plans {
                display_name
                id
                src
              }
              property {
                id
                ${properties_configuration_options}
                real_estate_company {
                  id
                  company_logo
                  company_name
                }
              }
              units {
                building_name
                id
                name
                vacant
                unit_number
              }
              
              packages {
                id
                name
                description
                display_name
                vendor {
                  id
                  full_name
                  supports_checkout
                  is_rental
                }
                room_designs {
                  room_design {
                    design_components {
                      assets {
                        asset {
                          id
                          web_page
                          width
                          depth
                          height
                          affiliation_link
                          variant_id
                          asset_name
                          category {
                            id
                            category_name
                            image_path
                          }
                          thumbnail
                          listings {
                            price
                            id
                          }
                        }
                        count
                      }
                    }
                  }
                }
                room_classifications {
                  id
                  room_classification {
                    id
                    weight
                    classification_name
                  }
                  package_assets {
                    id
                    type
                    price
                    category {
                      id
                      category_name
                      image_path
                    }
                    count
                    asset {
                      id
                      thumbnail
                      asset_name
                    }
                  }
                }
                
              }
            }
          }
        }
      `,
    })
  })
  return {
    data: {
      properties: filterPropertiesData(data.properties),
    },
  }
}

export async function getApartmentsForAgent(userId) {
  return await query({
    variables: {
      userId,
    },
    query: gql`
      query Properties_by_user_id($userId: Int!) {
        properties_by_user_id(user_id: $userId) {
          property_name
          invitation_text
          ${properties_configuration_options}
          id
          agents {
            is_admin
            user {
              id
            }
          }
          unit_types {
            id
            name
            ${unit_types_configuration_options}
            nick_name
            size
            size_to_display
            number_of_bathrooms
            number_of_bedrooms
            property {
              id
              ${properties_configuration_options}
              real_estate_company {
                id
                company_logo
                company_name
              }
            }
            units {
              building_name
              id
              name
              vacant
              unit_number
            }
          }
        }
      }
    `,
  })
}

export async function getApartmentsWithRooms() {
  return validateToken(async () => {
    const client = new ApolloClient({
      uri: `${apiHost}/graphql`,
      cache: new InMemoryCache(),
      headers: {
        Authorization: `Bearer ${getAuth()}`,
      },
    })
    return await client.query({
      query: gql`
        query Properties {
          properties {
            property_name
            properties_configuration_options {
              approves_prospect_app
              supports_furniture_as_amenity
            }
            id
            unit_types {
              id
              name
              ${unit_types_configuration_options}
              nick_name
              size
              size_to_display
              number_of_bathrooms
              number_of_bedrooms
              property {
                id
                ${properties_configuration_options}
                real_estate_company {
                  id
                  company_logo
                  company_name
                }
              }
              units {
                building_name
                id
                name
                unit_number
              }
            }
          }
        }
      `,
    })
  })
}

export async function getDesignLayouts() {
  return validateToken(async () => {
    const client = new ApolloClient({
      uri: `${apiHost}/graphql`,
      cache: new InMemoryCache(),
      headers: {
        Authorization: `Bearer ${getAuth()}`,
      },
    })
    return await client.query({
      query: gql`
        query DesignLayouts {
          design_layouts {
            id
            unit_type {
              id
              name
            }
            main_vendor {
              id
              display_name
            }
          }
        }
      `,
    })
  })
}

export async function upsertUnit(unitId, values) {
  return mutate(
    {
      units: [
        {
          id: unitId,
          ...values,
        },
      ],
    },
    gql`
      mutation Mutation($units: [units_input]) {
        upsert_units(units: $units) {
          id
          vacant
        }
      }
    `,
  )
}
