import { useStore } from 'Resident/store'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import Input from 'shared/components/Input'

function ResidentForm({ user, onChange }) {
  const { appData } = useStore()

  const formik = useFormik({
    initialValues: {
      phoneNumber: appData.user.phone_number
    },
    validateOnChange: false,
    validateOnBlur: true,
  })

  useEffect(() => {
    onChange({...formik.values, form: {isValid: formik.isValid}})
  }, [formik.values, formik.isValid, onChange])


  return (
    <div className="w-full lg:w-1/2 ">
      <div className="text-[#C7CCD9]">Resident Contact Information</div>
      <div className="grid gap-[16px] lg:gap-[24px] grid-cols-2 lg:grid-cols-3 mt-[12px] lg:mt-[24px] lg:mr-[80px]">
        <div className="flex">
          <div className="w-full">
            <div className="hidden lg:block font-semibold text-[rgba(95,103,135,0.3)] uppercase text-[12px] mt-[8px]">
              First Name
            </div>
            <div className="hidden lg:block truncate">{user.first_name}</div>
            <Input
              className="lg:hidden"
              name="firstName"
              placeholder="First name"
              value={user.first_name}
              onChange={formik.handleChange}
              disabled
            />
          </div>
        </div>
        <div className="flex">
          <div className="w-full">
            <div className="hidden lg:block font-semibold text-[rgba(95,103,135,0.3)] uppercase text-[12px] mt-[8px]">
              Last Name
            </div>
            <div className="hidden lg:block truncate">{user.last_name}</div>
            <Input className="lg:hidden" placeholder="Last name" value={user.last_name} disabled />
          </div>
        </div>
        <div className="flex col-span-2 lg:col-span-1">
          <div className="w-full ">
            <div className="hidden lg:block font-semibold text-[rgba(95,103,135,0.3)] uppercase text-[12px] mt-[8px]">
              Email
            </div>
            <div className="hidden lg:block truncate">{user.email}</div>
            <Input className="lg:hidden" placeholder="Email" value={user.email} disabled />
          </div>
        </div>
        <div className="flex col-span-2">
          <div className="lg:mr-[80px] w-full">
            <div className="hidden lg:block font-semibold text-[rgba(95,103,135,0.3)] uppercase text-[12px] mt-[8px]">
              Phone Number
            </div>
            <div className="mt-[8px]">
              <Input
                placeholder="Phone Number"
                name="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResidentForm
