import { hasAmenities } from './gallery'
import { ONE_MEGABYTE_IN_BYTES } from '../constants'
import { logDevelopment } from './log'

export function priceSum(arr) {
  return arr.reduce((acc, x) => acc + Number(x), 0)
}

export function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export function pluralEnds(count) {
  return count > 1 ? 's' : ''
}

export const getValueFromObject = (obj, path, def) => {
  let result = obj
  const p = path.replace(/\[/g, '.').replace(/]/g, '').split('.')

  p.forEach((level) => {
    result = Object.prototype.hasOwnProperty.call(result, level) ? result[level] : undefined
  })

  if (result === undefined) {
    return def
  }

  return result
}

export const sort = (key) => (a, b) => {
  const A = getValueFromObject(a, key, '')
  const B = getValueFromObject(b, key, '')

  if (Number.isInteger(+A) && Number.isInteger(+B)) {
    return A - B
  }

  const nameA = A.toUpperCase()
  const nameB = B.toUpperCase()

  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}

export function appEnvPrefix() {
  if (!process.env.REACT_APP_RUNTIME_ENV) {
    throw new Error('REACT_APP_RUNTIME_ENV is not specified')
  }
  if (isProductionEnv()) {
    return 'production'
  }
  return process.env.REACT_APP_RUNTIME_ENV
}

export function isProductionEnv() {
  return process.env.REACT_APP_RUNTIME_ENV === 'production'
}

export function isProductionBuild() {
  return process.env.NODE_ENV === 'production'
}

export function getAppName() {
  return (!isProductionBuild() && localStorage.getItem('reactApp')) || process.env.REACT_APP_NAME
}

export function isPostTourApp() {
  return getAppName() === 'posttour'
}
export function isCRMApp() {
  return getAppName() === 'crm'
}
export function isApartmentApp() {
  return getAppName() === 'apartment'
}
export function isIFrameApp() {
  return getAppName() === 'iframeapp'
}

export function isGalleryAvailable(tourData) {
  const { unit } = tourData.units[0]
  const rooms = unit.unit_type.unit_type_levels.map((l) => l.rooms).flat()
  if (hasAmenities(unit)) {
    return true
  }
  return (
    rooms.length > 0 &&
    rooms
      .filter((room) => room.room_designs.length)
      .filter((room) => room.room_designs.some((layout) => layout.photos.length > 0)).length > 0
  )
}

export function purchaseType(isRentalValue) {
  return isRentalValue ? 'rent' : 'buy'
}

export function arrayUniqueByKey(array, key) {
  return [...new Map(array.map((item) => [item[key], item])).values()]
}

export function getDesignTitle(image, hideDesignNames) {
  const pickedLayout = image.design
  if (hideDesignNames && !pickedLayout.is_more) {
    return `Design ${image.design_index}`
  }
  if (pickedLayout.is_more) {
    return `Other Images${image.display_name ? ` · ${image.display_name}` : ''}`
  }
  if (pickedLayout.short_description) {
    return `${pickedLayout.design_name} (${pickedLayout.short_description}) by ${pickedLayout.main_vendor.full_name}`
  }
  if (!image.assets.length) {
    return null
  }
  return `${pickedLayout.design_name} by ${pickedLayout.main_vendor.full_name}`
}

export function hasVideos(unit) {
  const VIDEOS = unit.unit_type.videos.filter((v) => v.is_official && v.is_verified)
  return VIDEOS.length > 0
}

export function whitespacesToUnderscore(str) {
  return str.replaceAll(' ', '_').replaceAll('#', 'NUM')
}

export function objectCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function bytesToMegabytes(bytes) {
  return bytes / ONE_MEGABYTE_IN_BYTES
}

export function copyToClipboard(text) {
  try {
    logDevelopment(text)
    navigator.clipboard.writeText(text)
  } catch (error) {
    console.log(error)
    console.log(text)
  }
}

export function compareObjects(objA, objB) {
  return JSON.stringify(objA) === JSON.stringify(objB)
}

export function randomID() {
  return Math.random().toString(36).slice(2)
}

export function safeConfigurationOptions(options) {
  return options || {}
}

export function configurationOptions(property) {
  return safeConfigurationOptions(property.properties_configuration_options)
}

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function snakeCaseToTitleCase(str) {
  return str
    .split('_')
    .map((word, index) => index === 0 
      ? word.charAt(0).toUpperCase() + word.slice(1)
      : word.toLowerCase())
    .join(' ')
}
