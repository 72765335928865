import { isApartmentApp, priceSum } from './general'

export function addMoreRoomCategory(apartment) {
  const rooms = apartment.unit_type ? apartment.unit_type.unit_type_levels[0].rooms : apartment.rooms

  const result = JSON.parse(JSON.stringify(rooms)).map((room, roomIndex) => {
    if (
      room.room_designs
        .map((design) => design.photos)
        .flat()
        .every((photo) => photo.assets.length === 0)
    ) {
      return room
    }
    if (
      room.room_designs
        .map((design) => design.photos)
        .flat()
        .every((photo) => photo.assets.length > 0)
    ) {
      return room
    }
    const result = {
      ...room,
      room_designs: room.room_designs
        .filter((design) => design.photos.some((photo) => photo.assets.length > 0))
        .map((design) => {
          return {
            ...design,
            photos: design.photos.filter((photo) => photo.assets.length > 0),
          }
        }),
    }
    result.room_designs.push({
      id: Number(Date.now()) + roomIndex + 1,
      main_vendor: {
        id: -1,
        full_name: 'Other Images',
      },
      is_more: true,
      design_components: [],
      photos: room.room_designs.map((design) => design.photos.filter((photo) => photo.assets.length === 0)).flat(),
    })
    return result
  })

  if(apartment.unit_type){
    apartment.unit_type.unit_type_levels[0].rooms = result
  } else {
    apartment.rooms = result
  }
  
}

export function addAmenitiesRoom(apartment) {
  if (apartment.unit_type.property.photos && apartment.unit_type.property.photos.length) {
    const amenitiesRoom = {
      classification: 'Amenities',
      id: -1,
      room_designs: [
        {
          main_vendor: {},
          is_amenities: true,
          design_components: [],
          photos: apartment.unit_type.property.photos.map((photo) => ({
            ...photo,
            assets: [],
          })),
        },
      ],
    }
    apartment.unit_type.unit_type_levels[apartment.unit_type.unit_type_levels.length - 1].rooms = [...apartment.unit_type.unit_type_levels[apartment.unit_type.unit_type_levels.length - 1].rooms, amenitiesRoom]
  }
}

export function divideRoomsByWeight(apartment) {
  // Rooms with designs. Sorted by weight, in case if weight is same, sorted according to alphabetical order.
  const roomsRaw = apartment.unit_type ? apartment.unit_type.unit_type_levels.map(l=>l.rooms.map(r=>({...r, level: l.id}))).flat() : apartment.rooms

  const rooms = JSON.parse(JSON.stringify(roomsRaw))

  function sortByAlphabet(a, b) {
    return b.room_classification.weight === a.room_classification.weight
      ? (a.name || a.classification).localeCompare(b.name || b.classification)
      : b.room_classification.weight - a.room_classification.weight
  }

  const groupOne = rooms
    .filter(({ room_designs }) => room_designs.some(({ photos }) => photos.some(({ assets }) => assets.length > 0)))
    .sort(sortByAlphabet)

  const groupTwo = rooms
    .filter(({ room_designs }) => !room_designs.some(({ photos }) => photos.some(({ assets }) => assets.length > 0)))
    .sort(sortByAlphabet)

  return [groupOne, groupTwo]
}

export function hasAmenities(apartment) {
  return apartment.unit_type.property.photos && apartment.unit_type.property.photos.length
}

export function showFurnitureDetails(property = {}) {
  const configuration = property.properties_configuration_options || {}
  
  if (isApartmentApp()) {
    return configuration.support_selling_mode_in_ap
  } else {
    return true
  }
}

export function showDesignNames(property = {}) {
  const configuration = property.properties_configuration_options || {}
  if (isApartmentApp()) {
    return configuration.show_design_details === undefined ? false : configuration.show_design_details
  } else {
    return configuration.show_design_details === undefined ? true : configuration.show_design_details
  }
}

export function showPrices(property = {}) {
  const configuration = property.properties_configuration_options || {}
  return configuration.show_furniture_prices
}

export function getDesignLayoutPackagePrice(layout) {
  let assetsWithCounts = []
  layout.design_components.forEach(({ assets }) => assetsWithCounts.push(assets))
  assetsWithCounts = assetsWithCounts.flat()

  const assets = []
  layout.photos.forEach((photo) => {
    photo.assets.forEach((asset) => {
      const count = (assetsWithCounts.find((a) => a.asset.id === asset.asset.id) || { count: 0 }).count
      assets.push({
        ...asset,
        quantity: count,
      })
    })
  })
  return priceSum(
    assets.map(({ asset, quantity }) => (asset.listings.length > 0 ? asset.listings[0].price : 0) * quantity),
  )
}

export function isQuadraticDisplayMode(unit) {
  return unit && unit.unit_type.property.properties_configuration_options.quadratic_design_gallery
}
