import { setAuth } from 'CRM/helpers/auth'
import { getUnitByUnitId } from 'IFrameApp/services/tour.service'
import { EAppType } from 'Resident/constants/appType'
import { isAgentApp, isInstantApp, isMinimalApp } from 'Resident/helpers/app'
import { useStore } from 'Resident/store'
import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { products } from 'shared/analytics'
import { setAppVariant } from 'shared/analytics/hotjar'
import { useSplashError } from 'shared/components/ErrorSplash'
import Loader from 'shared/components/Loader'
import { useRequest } from 'shared/hooks/useRequest'
import { getUserById } from 'shared/services/user.service'

const search = new URLSearchParams(window.location.search)

function RootScreen(props) {
  const { setInviteData, inviteData, appData, setAppData, setSource, setAppType, appType } = useStore()
  const navigate = useNavigate()

  const redirect = useCallback(() => {
    navigate(search.get('flow') ? `/${search.get('flow')}` : '/welcome')
  }, [navigate])

  useEffect(() => {
    //==========================================================================
    // User by mobile client to specify it's source
    //==========================================================================
    if (search.get('source')) {
      setSource(search.get('source'))
    }

    if (search.get('minimal')) {
      setAppVariant(products[EAppType.MINIMAL].abr)
      setAppType(EAppType.MINIMAL)
    }

    if (search.get('app_type')) {
      const appType = search.get('app_type')
      setAppVariant(products[appType].abr)
      setAppType(appType)
    }

    //==========================================================================
    // Used for instant app and agent app
    //==========================================================================

    if (isAgentApp(search.get('app_type')) || isInstantApp(search.get('app_type'))) {
      setAppData({
        agentEmail: search.get('agent_email'),
        agentId: search.get('agent_id'),
        instantUnitId: Number(search.get('instant_unit_id')),
      })
      if (search.get('instant_unit_id')) {
        sessionStorage.setItem('instantUnitId', search.get('instant_unit_id'))
      }

      if (search.get('agent_email')) {
        sessionStorage.setItem('agentEmail', search.get('agent_email'))
      }

      if (search.get('agent_id')) {
        sessionStorage.setItem('agentId', search.get('agent_id'))
      }
    }

    if (search.get('token')) {
      setAuth({ token: search.get('token'), storage: sessionStorage })

      let state = null

      if (search.get('package')) {
        state = { state: { packageId: Number(search.get('package')) } }
      }
      navigate(search.get('flow') ? `/${search.get('flow')}` : '/packages', state)
      return
    }

    const userId = search.get('user_id')

    const unitId = search.get('unit_id')

    if (!userId || !unitId) {
      redirect()
      return
    }

    setInviteData({
      userId: userId ? Number(userId) : null,
      unitId: userId ? Number(unitId) : null,
      code: search.get('code') || null,
    })

    // redirect()
  }, [setInviteData, redirect, setSource, setAppType, navigate, setAppData])

  const splashError = useSplashError()

  const getData = useCallback(async () => {
    if (inviteData !== null) {
      try {
        const [unitResponse, userResponse] = await Promise.all([
          getUnitByUnitId(inviteData.unitId),
          getUserById(inviteData.userId),
        ])

        return {
          user: userResponse.data.user_by_id,
          unit: unitResponse.data.units_by_id,
        }
      } catch (error) {
        splashError.show()
      }
    }
  }, [inviteData, splashError])

  const { data } = useRequest(getData, {
    default: null,
  })
  useEffect(() => {
    if (data) {
      setAppData({
        ...data,
        code: inviteData.code,
      })
    }
  }, [data, inviteData, setAppData])

  useEffect(() => {
    if (appData && !isAgentApp(appType)) {
      redirect()
    }
  }, [appData, redirect, appType])

  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <Loader />
    </div>
  )
}

export default RootScreen
