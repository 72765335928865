import { deletePhotos, getApartments, upsertUnitTypesConfiguration } from 'MediaManager/services'
import { getPropertyById } from 'MediaManager/services/property'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Loader from 'shared/components/Loader'
import Select from 'shared/components/Select'
import styles from './styles.module.scss'
import Button from 'shared/components/Button'
import { Link } from '@tanstack/react-location'

import { smartSort } from 'shared/helpers/arrays'
import { objectCopy } from 'shared/helpers/general'

import TableRow from './components/TableRow'
import { getCdnAssetUrl } from 'shared/helpers/assets'
import TinyModal from 'shared/components/TinyModal'
import { ReactComponent as DeleteInviteIcon } from 'shared/assets/icons/delete-icon-mm.svg'

function PropertyDetails({ property, ...props }) {
  const [propertyDetails, setPropertyDetails] = useState()
  const [loading, setLoading] = useState(true)

  const loadData = useCallback(async () => {
    setLoading(true)
    const { data } = await getPropertyById(Number(property))
    setLoading(false)
    setPropertyDetails(data.properties_by_id)
  }, [property])

  useEffect(() => {
    ;(async () => {
      loadData()
    })()
  }, [loadData])

  function onRefresh() {
    loadData()
  }

  async function onUnitTypeConfigurationChange(unitType, payload){
    try {
      await upsertUnitTypesConfiguration(unitType.unit_types_configuration_options.id, unitType.id, payload)
      const unitTypeIndex = propertyDetails.unit_types.findIndex(({id})=>id === unitType.id)
      const unit_types = objectCopy(propertyDetails.unit_types)
      unit_types[unitTypeIndex] = {
        ...unit_types[unitTypeIndex],
        unit_types_configuration_options: {
          ...unit_types[unitTypeIndex].unit_types_configuration_options,
          ...payload
        }
      }
      setPropertyDetails(details=>({
        ...details,
        unit_types
      }))
    } catch (error) {
      alert("Some error occurred. Please try again")
    }
  }

  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className={`${styles.tableWrap} mt-[32px]`}>
        <table className={`${styles.table}`}>
          <tr className={styles.header}>
            <th>Line</th>
            <th>Designs</th>
            <th>Floor plan</th>
            <th>Walkthrough video</th>
            <th>Images</th>
            <th>Ready to Publish</th>
            <th>Tour Page Emails</th>
          </tr>
          {propertyDetails.unit_types.filter(t=>t.unit_type_levels.length > 0).map((unit_type) => (
            <TableRow
              property={property}
              unitType={unit_type}
              onDelete={() => {
                onRefresh()
              }}
              onUnitTypeConfigurationChange={onUnitTypeConfigurationChange}
            />
          ))}
        </table>
      </div>
      <Amenities
        photos={propertyDetails.photos}
        onDelete={() => {
          onRefresh()
        }}
      />
    </div>
  )
}

function Amenities({ photos, onDelete }) {
  function getAmenitiesPhotosCount() {
    return photos.length
  }

  async function onPhotoDelete(p) {
    if (window.confirm(`Are you sure you want to delete photo ${p.display_name || ''}?`)) {
      await deletePhotos(p.id)
      onDelete()
    }
  }

  const [deleteModalShown, setDeleteModalShown] = useState(false)
  return (
    <div className="flex justify-between items-center mt-[32px]">
      <div className='flex items-center'>
        
          <span>Number of amenities images: {getAmenitiesPhotosCount()}</span>
          {photos.length > 0 && (
            <button
              type="button"
              className='ml-[10px] underline'
              onClick={() => {
                setDeleteModalShown(true)
              }}
            >
              Edit
            </button>
            
          )}
       
      </div>
      {deleteModalShown && (
        <TinyModal
          className="lg:!w-max"
          onClose={() => {
            setDeleteModalShown(false)
          }}
        >
          <div className="text-2xl w-full mb-[20px]">Delete amenities photos</div>
          <div className="max-h-[500px] overflow-y-auto w-full">
            {photos.map((p) => (
              <div className="flex w-full justify-between p-1 px-2 bg-[rgba(0,0,0,.1)] rounded-md mt-[10px] first:mt-[0] items-center">
                <div className="flex items-center">
                  <img src={getCdnAssetUrl(p.src)} className="w-[200px]" />
                  <span className="mx-[32px]">{p.display_name}</span>
                </div>

                <button
                  type="button"
                  className='mx-[32px]'
                  onClick={() => {
                    onPhotoDelete(p)
                  }}
                >
                  <DeleteInviteIcon />
                </button>
                <Link className="ml-5"  to={`/images?photo=${p.id}`}>
                Edit
          </Link>
              </div>
            ))}
          </div>
        </TinyModal>
      )}
      <Link to="/images">
        <Button variant="secondary" rounded>
          Add amenities images
        </Button>
      </Link>
    </div>
  )
}

function ManageProperty(props) {
  const [properties, setProperties] = useState([])
  const [loading, setLoading] = useState(true)
  const [formValues, setFormValues] = useState({})
  useEffect(() => {
    ;(async () => {
      const { data: propertiesData } = await getApartments()
      setProperties(propertiesData.properties)
      setLoading(false)
    })()
  }, [])
  const propertiesOptions = useMemo(() => {
    return objectCopy(properties)
      .sort(smartSort('property_name'))
      .map(({ id, property_name }) => ({ value: id, label: property_name }))
  }, [properties])

  function handleInputChange(name, { value }, extra = {}) {
    if (formValues[name] === value) {
      return
    }
    setFormValues((values) => ({
      ...values,
      [name]: value,
      ...extra,
    }))
  }

  return (
    <div>
      {!loading && (
        <>
          <div className="flex justify-center">
            <div className="w-1/4">
              <Select
                onChange={(val) =>
                  handleInputChange('property', val, {
                    type: undefined,
                    line: undefined,
                    unit: undefined,
                    room: undefined,
                  })
                }
                value={propertiesOptions.find((property) => property.value === formValues.property) || ''}
                options={propertiesOptions}
                placeholder="Property name"
              />
            </div>
          </div>

          {formValues.property ? <PropertyDetails property={formValues.property}></PropertyDetails> : null}
        </>
      )}
      {loading && <Loader />}
    </div>
  )
}

export default ManageProperty
